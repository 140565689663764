import { memo } from "react";
import { Grid, Box } from "@mui/material";

function HintsContainer(props) {
  const { viewOnly } = props;

  return (
    <Box sx={{ width: "100%", mb: viewOnly ? 0 : 6 }}>
      <Grid
        className="hints"
        container
        spacing={0}
        columns={16}
        sx={{ mt: -1 }}
      >
        {props.children}
      </Grid>
    </Box>
  );
}

export default memo(HintsContainer);
