import "./Header.css";
import { memo } from "react";
import { Grid, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GlobalMenu from "./GlobalMenu/GlobalMenu";
import Logo from "./Logo/Logo";
import ExportMenu from "./ExportMenu/ExportMenu";
import UserMenu from "./UserMenu/UserMenu";
import Title from "./Title/Title";
import Version from "./Version/Version";
import { useLocation } from "react-router-dom";
import UndoRedo from "./UndoRedo/UndoRedo";
import Notification from "./Notification";
import CreditCounter from "./CreditCounter/CreditCounter";

function Header(props) {
  const { saveAllData, hitlistId, title, tableData, saving, hitlists, user } =
    props;
  const theme = useTheme();
  const location = useLocation();

  return (
    <Grid container spacing={0} columns={24}>
      <Grid
        item
        id="header-identity"
        sx={{ backgroundColor: theme.palette.primary.main }}
        xs={4}
        flex
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{ ml: 3, alignItems: "center", height: "100%" }}
        >
          <GlobalMenu
            title={title}
            hitlistId={hitlistId}
            hitlists={hitlists}
            user={user}
          />
          <Logo />
        </Stack>
      </Grid>
      <Grid item xs={2} sx={{ mt: 2 }} flex>
        {location.pathname === "/hitlist" && (
          <Title saveAllData={saveAllData} title={title} />
        )}
        {location.pathname === "/view" && (
          <Title saveAllData={saveAllData} title={title} viewOnly={true} />
        )}
      </Grid>
      <Grid item id="header-menus" xs={18} flex>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mr: 2 }}
        >
          {location.pathname === "/hitlist" && (
            <Version hideDate={false} hideVersion={true} saving={saving} />
          )}
          {location.pathname === "/hitlist" && <UndoRedo />}
          {user.email && <CreditCounter />}
          {user.email && <Notification />}
          {location.pathname === "/hitlist" && (
            <ExportMenu tableData={tableData} />
          )}
          {user.email && <UserMenu user={user} />}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default memo(Header);
