import { memo } from "react";
import {
  Box,
  LinearProgress,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

function HintLoader(props) {
  const { handleCancel } = props;

  return (
    <Box>
      {props.loading ? (
        <Stack direction="row" spacing={1} sx={{ zIndex: 2000 }}>
          <LinearProgress
            color="secondary"
            sx={{ mt: 1.3, justifyContent: "flex-end", width: 150 }}
          />
          <Tooltip title="Cancel">
            <IconButton
              aria-label="Settings"
              onClick={handleCancel}
              size="small"
              color="error"
            >
              <ClearOutlinedIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        ""
      )}
    </Box>
  );
}

export default memo(HintLoader);
