import { memo } from "react";
import Checkbox from "@mui/material/Checkbox";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { Tooltip } from "@mui/material";
function HitlistTableStarred(props) {
  const { row, handleStarred, viewOnly } = props;
  const label = { inputProps: { "aria-label": "Ready checkbox" } };

  return (
    <>
      <Tooltip title="Star row" arrow enterDelay={1000} enterNextDelay={1000}>
        <span>
          <Checkbox
            {...label}
            disabled={!row.title || viewOnly}
            icon={<StarOutlineOutlinedIcon fontSize="small" />}
            checkedIcon={<StarOutlinedIcon fontSize="small" color="primary" />}
            onClick={() => handleStarred(row)}
            checked={row.starred || false}
            sx={{ display: row.title ? "flex" : "none" }}
          />
        </span>
      </Tooltip>
    </>
  );
}

export default memo(HitlistTableStarred);
