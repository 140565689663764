import { memo, useContext } from "react";
import { Button, Typography } from "@mui/material";
import { AppConfigContext } from "../../AppConfigContext";
import { useTranslation } from "react-i18next";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

function RegisterOption() {
  const config = useContext(AppConfigContext);
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {t("hitlist.register.registerHeading")}
      </Typography>
      <Typography variant="body2" sx={{ mb: 3 }}>
        {t("hitlist.register.registerText")}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        fullWidth
        href={config.REGISTER_URL}
        target="_blank"
        endIcon={<OpenInNewOutlinedIcon />}
      >
        {t("hitlist.register.registerButton")}
      </Button>
    </>
  );
}

export default memo(RegisterOption);
