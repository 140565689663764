const ArrayUtils = {
  insertAfter: (arr, index, newItem) => {
    return [...arr.slice(0, index), newItem, ...arr.slice(index)];
  },
  shuffle: (arr) => {
    let currentIndex = arr.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);

      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = arr[currentIndex];

      arr[currentIndex] = arr[randomIndex];

      arr[randomIndex] = temporaryValue;
    }

    return arr;
  },
  addUniqueItemToArray: (arr, item) => {
    if (arr.indexOf(item) === -1) {
      arr.push(item);
    }
    return arr;
  },
  convertToString: (arr, key, delimiter = ",") => {
    if (Array.isArray(arr) && arr.length > 0) {
      return arr.map((item) => item[key]).join(delimiter);
    } else {
      return arr.toString();
    }
  },
  getValues: (arr, key, asString = false) => {
    let returnArray = arr.map(function (obj) {
      return obj[key];
    });

    return asString ? returnArray : returnArray.join(", ");
  },
  uniqueOnly: (arr) => {
    var prims = { boolean: {}, number: {}, string: {} },
      objs = [];

    return arr.filter(function (item) {
      var type = typeof item;
      if (type in prims)
        return prims[type].hasOwnProperty(item)
          ? false
          : (prims[type][item] = true);
      else return objs.indexOf(item) >= 0 ? false : objs.push(item);
    });
  },
  reIndex: (arr, idLabel) => {
    if (Array.isArray(arr)) {
      arr.forEach((item, index) => {
        item[idLabel] = index;
      });
      return arr;
    } else {
      console.warn("Could not re-index, it is not an array");
      return arr;
    }
  },
  mergeAndIndex: (arr1, arr2, idLabel) => {
    if (Array.isArray(arr1) && Array.isArray(arr2)) {
      const combinedArray = arr1.concat(arr2);
      const uniqueArray = ArrayUtils.uniqByKeepFirst(
        combinedArray,
        (item) => item.title
      );

      uniqueArray.forEach((item, index) => {
        item.id = index;
      });

      return uniqueArray;
    } else {
      if (Array.isArray(arr1)) {
        return arr1;
      } else if (Array.isArray(arr2)) {
        return arr2;
      } else {
        return [];
      }
    }
  },
  matchAndCombine: (data, propertyName, keywordProperty) => {
    return data.reduce((acc, item) => {
      if (item[propertyName] && item[propertyName].trim() !== "") {
        const existingItem = acc.find(
          (i) => i[propertyName] === item[propertyName]
        );
        if (existingItem) {
          if (item[keywordProperty] && item[keywordProperty].trim() !== "") {
            existingItem[keywordProperty] = [
              ...new Set([
                ...existingItem[keywordProperty],
                item[keywordProperty],
              ]),
            ];
          }
        } else {
          if (item[keywordProperty] && item[keywordProperty].trim() !== "") {
            acc.push({ ...item, [keywordProperty]: [item[keywordProperty]] });
          } else {
            acc.push(item);
          }
        }
      }
      return acc;
    }, []);
  },
  removeDuplicates: (arr, propName) => {
    const uniqueValues = new Set();
    return arr.filter((obj) => {
      const propValue = obj[propName].toLowerCase();
      if (propValue !== "" && uniqueValues.has(propValue)) {
        return false;
      } else {
        uniqueValues.add(propValue);
        return true;
      }
    });
  },
  // Function to remove duplicates from an array of objects
  uniqByKeepFirst: (arr, key) => {
    let seen = new Set();

    if (Array.isArray(arr)) {
      if (arr.length === 1) return arr;

      return arr.filter((item) => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
      });
    } else {
      return [];
    }
  },
  uniqByKeepLast: (arr, key) => {
    return [...new Map(arr.map((x) => [key(x), x])).values()];
  },
  combineSimilarTitles: (arr) => {
    let combined = {};
    let threshold = 6; // Maximum allowed Levenshtein distance between titles
    for (let item of arr) {
      let title = item.title;
      let matched = false;
      for (let combinedTitle in combined) {
        if (ArrayUtils.levenshteinDistance(title, combinedTitle) <= threshold) {
          combined[combinedTitle].volume += item.volume;
          combined[combinedTitle].difficulty += item.difficulty;
          combined[combinedTitle].count += 1;
          matched = true;
          break;
        }
      }
      if (!matched) {
        combined[title] = {
          ...item,
          volume: item.volume,
          difficulty: item.difficulty,
          count: 1,
        };
      }
    }

    let result = [];
    for (let title in combined) {
      let stats = combined[title];
      let avgDifficulty = Math.floor(stats.difficulty / stats.count);
      let avgVolume = Math.floor(stats.volume / stats.count);
      result.push({
        ...combined,
        title: title,
        volume: avgVolume,
        difficulty: avgDifficulty,
      });
    }

    return result;
  },
  levenshteinDistance: (s1, s2) => {
    let d = [];
    let n = s1.length;
    let m = s2.length;

    if (n === 0) return m;
    if (m === 0) return n;

    for (let i = 0; i <= n; i++) {
      d[i] = [i];
    }

    for (let j = 0; j <= m; j++) {
      d[0][j] = j;
    }

    for (let j = 1; j <= m; j++) {
      for (let i = 1; i <= n; i++) {
        if (s1[i - 1] === s2[j - 1]) {
          d[i][j] = d[i - 1][j - 1];
        } else {
          d[i][j] = Math.min(
            d[i - 1][j] + 1, // Deletion
            d[i][j - 1] + 1, // Insertion
            d[i - 1][j - 1] + 1 // Substitution
          );
        }
      }
    }

    // console.log("Score: " + d[n][m] + "//");
    // console.log(s1 + " /n " + s2);

    return d[n][m];
  },
};

export default ArrayUtils;
