import { memo, useState, useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ExpandOutlinedIcon from "@mui/icons-material/ExpandOutlined";
import { Tooltip } from "@mui/material";
import {
  CustomEvents,
  subscribe,
  unsubscribe,
} from "../../../../Events/CustomEvents";

function HitlistTableActionExpand(props) {
  const { handleExpandRow, row, generating } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExternalExpandRow = (e) => {
    const { data } = e.detail;

    if (!data || !row) return;

    if (data.id === row.id) {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    subscribe(CustomEvents.EXPAND_ROW, handleExternalExpandRow);

    return () => {
      unsubscribe(CustomEvents.EXPAND_ROW, handleExternalExpandRow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridActionsCellItem
      disabled={!row.keywords || row.keywords.length < 1 || generating}
      icon={
        <Tooltip title={!isExpanded ? "Expand row" : "Collapse row"}>
          <ExpandOutlinedIcon fontSize="small" />
        </Tooltip>
      }
      label="Expand"
      onClick={() => {
        handleExpandRow();
        setIsExpanded(!isExpanded);
      }}
    />
  );
}

export default memo(HitlistTableActionExpand);
