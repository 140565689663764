import "./SettingsSlider.css";
import { Slider, FormControl, Typography, Box } from "@mui/material";
import StringUtils from "../Utils/StringUtils";
import { useTheme } from "@mui/material/styles";

function SettingsSlider(props) {
  const {
    min,
    max,
    step,
    value,
    sx,
    label,
    help,
    onChange,
    marks,
    valueLabelDisplay,
    valueLabelFormat,
  } = props;

  const theme = useTheme();

  const sliderId = label
    ? `settings-target-${StringUtils.replaceSpacesWithDashes(label)}`
    : "settings-dropdown";

  const labelId = `${sliderId}-label`;

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <>
      <Box sx={sx}>
        <Typography
          id={labelId}
          gutterBottom
          sx={{ fontSize: 12, color: theme.palette.text.label, fontWeight: 500 }}
        >
          {label}
        </Typography>
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <Slider
            aria-label={label}
            defaultValue={value}
            step={step}
            marks={marks}
            min={min}
            max={max}
            onChange={handleChange}
            valueLabelDisplay={valueLabelDisplay || "off"}
            valueLabelFormat={valueLabelFormat || ((value) => value)}
          />
          <Typography sx={{ fontSize: 12, color: theme.palette.text.label, mt:0.5, mb:2 }}>
            {help}
          </Typography>
        </FormControl>
      </Box>
    </>
  );
}

export default SettingsSlider;
