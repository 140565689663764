import { Tooltip, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { publish } from "../../../Events/CustomEvents";
import { ToastEvents } from "../../../../Toast/Toast";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { memo } from "react";

function CopyPaste(props) {
  const { t } = useTranslation();

  // TODO: Account for different data structures
  const handleCopy = () => {
    switch (typeof props.hints) {
      case "string":
        navigator.clipboard.writeText(props.hints);
        break;
      case "object":
        if (Array.isArray(props.hints)) {
          const copyText = props.hints.map((hint) => hint.label).join(props.delimiter || "\n");
          navigator.clipboard.writeText(copyText);
        }
        break;
      default:
        break;
    }

    publish(ToastEvents.SUCCESS, t("hitlist.assistant.hints.copied"));
  };

  return (
    <Tooltip title="Copy">
      <span>
        <IconButton
          onClick={handleCopy}
          disabled={props.disabled}
          sx={{ display: props.lock ? "none" : "inline-flex" }}
        >
          <FileCopyOutlinedIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default memo(CopyPaste);
