import { memo, useContext, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicSharpIcon from "@mui/icons-material/MicSharp";
import MicNoneSharpIcon from "@mui/icons-material/MicNoneSharp";
import {
  CustomEvents,
  publish,
  subscribe,
  unsubscribe,
} from "../../Events/CustomEvents";
import { IconButton } from "@mui/material";
import { AppConfigContext } from "../../../AppConfigContext";

// TODO: Add polyfill for SpeechRecognition (https://www.npmjs.com/package/react-speech-recognition#basic-example)

const Dictaphone = (props) => {
  const {onListeningChange} = props;

  const config = useContext(AppConfigContext);
  const {
    finalTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    console.warn("Browser doesn't support speech recognition.");
  }

  const toggleMic = async () => {
    console.log(
      "🚀 ~ file: Dictaphone.js:28 ~ toggleMic ~ listening:",
      listening
    );
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening({
        continuous: true,
        language: navigator.language,
      });
    }

    onListeningChange(!listening);
  };

  useEffect(() => {
    // Check if any of the stop phrases are in the transcript
    let stopPhraseMatch = config.chat.stopPhrases.some((phrase) =>
      finalTranscript.includes(phrase)
    );

    // If a stop phrase is found, stop listening and reset the transcript
    if (stopPhraseMatch) {
      stopPhraseMatch = false;
      toggleMic();
      return;
    }

    // If the transcript is not empty, publish it
    if (finalTranscript.length) {
      publish(CustomEvents.VOICE_MSG, { text: finalTranscript });
      resetTranscript();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalTranscript]);

  const handleStopVoice = (e) => {
    SpeechRecognition.stopListening();
  };

  const handlePauseVoice = (e) => {
    if (e.detail.data.pause) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening({
        continuous: true,
        language: navigator.language,
      });
    }
  };

  useEffect(() => {
    subscribe(CustomEvents.STOP_VOICE, handleStopVoice);
    subscribe(CustomEvents.PAUSE_VOICE, handlePauseVoice);
    return () => {
      SpeechRecognition.stopListening();
      unsubscribe(CustomEvents.STOP_VOICE, handleStopVoice);
      unsubscribe(CustomEvents.PAUSE_VOICE, handlePauseVoice);
    }; // cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IconButton onClick={toggleMic} sx={{ mx: 1, my: 0.5 }}>
      {listening ? (
        <MicSharpIcon color="success" />
      ) : (
        <MicNoneSharpIcon color="neutral.main" />
      )}
    </IconButton>
  );
};

export default memo(Dictaphone);
