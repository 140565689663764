import { memo } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";

function HitlistTableActionDeleteMenu(props) {
  const { row, handleDelete, hidden } = props;

  return (
    <GridActionsCellItem
      sx={{ display: hidden ? "none" : "flex", fontSize: 15 }}
      disabled={!row.title}
      icon={
        <Tooltip title="Delete idea">
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </Tooltip>
      }
      label="Delete"
      onClick={() => handleDelete(row)}
      showInMenu={true}
    />
  );
}

export default memo(HitlistTableActionDeleteMenu);
