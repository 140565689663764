import { memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login/Login";
import Mobile from "./Mobile/Mobile";
import Reset from "./Reset/Reset";
import Hitlist from "./Hitlist/Hitlist";
import Profile from "./Profile/Profile";
import Settings from "./Settings/Settings";
import PageNotFound from "./PageNotFound/PageNotFound";
import RequireAuth from "./RequireAuth";
import { createTheme } from "@mui/material";
import { HitlistThemeOptions } from "./ThemeOptions";
import { LoginThemeOptions } from "./ThemeOptions";
import SubscriptionSettings from "./SubscriptionSettings/SubscriptionSettings";
import AddCreditsThanks from "./AddCredits/AddCreditsThanks";
import AddCredits from "./AddCredits/AddCredits";
import View from "./View/View";

function AppRoutes(props) {
  const {
    appInitialized,
    domains,
    generating,
    getHitlist,
    handleSaveSettings,
    headlineStyle,
    hitlistId,
    keywords,
    loggedIn,
    loggingIn,
    onDomainConfirm,
    onTopicConfirm,
    resetHitlist,
    resettingPassword,
    saveAllData,
    status,
    tabs,
    tableData,
    tableState,
    targetCountry,
    topic,
    updatingProfile,
    onGenerateCancel,
  } = props;

  const hitlistTheme = createTheme(HitlistThemeOptions);
  const loginTheme = createTheme(LoginThemeOptions);

  return (
    <Routes>
      <Route exact path="/" element={<Navigate replace to="/login" />} />
      <Route
        path="/login"
        element={
          <Login theme={loginTheme} loggedIn={loggedIn} loggingIn={loggingIn} />
        }
      />
      <Route
        path="/credits/thanks"
        element={
          <RequireAuth>
            <AddCreditsThanks theme={hitlistTheme} loggedIn={loggedIn} />
          </RequireAuth>
        }
      />
      <Route
        path="/mobile"
        element={<Mobile theme={hitlistTheme} loggedIn={loggedIn} />}
      />
      <Route
        path="/reset"
        element={
          <Reset
            theme={loginTheme}
            loggedIn={loggedIn}
            resettingPassword={resettingPassword}
          />
        }
      />
      <Route
        path="/hitlist"
        element={
          <RequireAuth>
            <Hitlist
              appInitialized={appInitialized}
              domains={domains}
              generating={generating}
              getHitlist={getHitlist}
              handleSaveSettings={handleSaveSettings}
              headlineStyle={headlineStyle}
              hitlistId={hitlistId}
              keywords={keywords}
              loggedIn={loggedIn}
              onDomainConfirm={onDomainConfirm}
              onTopicConfirm={onTopicConfirm}
              onGenerateCancel={onGenerateCancel}
              resetHitlist={resetHitlist}
              saveAllData={saveAllData}
              status={status}
              tabs={tabs}
              tableData={tableData}
              tableState={tableState}
              targetCountry={targetCountry}
              theme={hitlistTheme}
              topic={topic}
            />
          </RequireAuth>
        }
      />
      <Route
        path="/view"
        element={
          <View
            appInitialized={appInitialized}
            domains={domains}
            generating={generating}
            getHitlist={getHitlist}
            handleSaveSettings={handleSaveSettings}
            headlineStyle={headlineStyle}
            hitlistId={hitlistId}
            keywords={keywords}
            loggedIn={loggedIn}
            onDomainConfirm={onDomainConfirm}
            onTopicConfirm={onTopicConfirm}
            onGenerateCancel={onGenerateCancel}
            resetHitlist={resetHitlist}
            saveAllData={saveAllData}
            status={status}
            tableData={tableData}
            tableState={tableState}
            targetCountry={targetCountry}
            theme={hitlistTheme}
            topic={topic}
          />
        }
      />
      <Route
        path="/profile"
        element={
          <RequireAuth>
            <Profile
              theme={hitlistTheme}
              loggedIn={loggedIn}
              updatingProfile={updatingProfile}
              hitlistId={hitlistId}
            />
          </RequireAuth>
        }
      />
      <Route
        path="/settings"
        element={
          <RequireAuth>
            <Settings
              theme={hitlistTheme}
              loggedIn={loggedIn}
              hitlistId={hitlistId}
              handleSaveSettings={handleSaveSettings}
            />
          </RequireAuth>
        }
      />
      <Route
        path="/add-credits"
        element={
          <RequireAuth>
            <AddCredits
              theme={hitlistTheme}
              loggedIn={loggedIn}
              hitlistId={hitlistId}
            />
          </RequireAuth>
        }
      />
      <Route
        path="/subscription"
        element={
          <RequireAuth>
            <SubscriptionSettings
              theme={hitlistTheme}
              loggedIn={loggedIn}
              hitlistId={hitlistId}
            />
          </RequireAuth>
        }
      />
      <Route
        path="*"
        element={<PageNotFound loggedIn={loggedIn} hitlistId={hitlistId} />}
      />
    </Routes>
  );
}

export default memo(AppRoutes);
