import { Badge } from "@mui/material";
import { useEffect, useState } from "react";

function HitlistTabBadge(props) {
  const { badgeContent } = props;

  const [badge, setBadge] = useState(badgeContent);

  useEffect(() => {
    setBadge(badgeContent);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgeContent]);

  return (
    <Badge
      color="primary"
      badgeContent={badge > 0 ? badge : "0"}
      sx={{ mt: 1.8, ml: 3, mr: 1 }}
    />
  );
}

export default HitlistTabBadge;
