import "./HitlistTableKeywords.css";
import { memo } from "react";
import { useEffect, useRef, useState } from "react";
import { Chip, Tooltip, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import StringUtils from "../../../Utils/StringUtils";
import { APP_CONSTANTS } from "../../Constants";
import { CustomEvents, publish } from "../../Events/CustomEvents";

function HitlistTableKeywords(props) {
  const {
    row,
    value,
    handleKeywordDelete,
    handleExpandRow,
    condensedRows,
    loading,
    viewOnly,
  } = props;

  const [expanded, setExpanded] = useState(condensedRows);

  const expandRef = useRef(condensedRows);

  const minDisplayChips = 3;
  const maxDisplayChips = 15;

  const [maxChips, setMaxChips] = useState(maxDisplayChips);

  const handleDeleteKeyword = (chipToDelete) => () => {
    const newChips = value.filter((chip) => {
      return chip.label !== chipToDelete.label;
    });
    handleKeywordDelete(row, newChips);
  };

  const updateExpanded = () => {
    if (expandRef.current) {
      setMaxChips(maxDisplayChips);
      setExpanded(true);
    } else {
      setMaxChips(minDisplayChips);
      setExpanded(false);
    }
  };

  useEffect(() => {
    expandRef.current = row.expand;
    updateExpanded();
  }, [row.expand]);

  if (
    loading === true ||
    loading === APP_CONSTANTS.LOADING_TYPE_KEYWORDS ||
    loading === APP_CONSTANTS.LOADING_TYPE_ENHANCE
  ) {
    return <LinearProgress color="secondary" sx={{ width: "150px" }} />;
  } else {
    if (value) {
      let keywords = value;

      if (typeof value === "string") {
        return [value.split(",")[0], value.split(",")[1]];
      }

      return (
        <>
          {keywords.map((keywordObj, index) => {
            if (index < maxChips) {
              return (
                <Tooltip
                  key={index}
                  title={`Add "${StringUtils.titleCaseString(
                    keywordObj.label
                  )}" to Research Keywords`}
                >
                  <Chip
                    disabled={viewOnly}
                    className={`table-chip ${expanded ? "" : "condensed"}`}
                    color="info"
                    deleteIcon={<CancelOutlinedIcon />}
                    key={index}
                    label={StringUtils.titleCaseString(keywordObj.label)}
                    onClick={(e) => {
                      if (!viewOnly) {
                        publish(CustomEvents.KEYWORD_ADD, {
                          keyword: keywordObj.label,
                        });
                      }
                    }}
                    onDelete={handleDeleteKeyword(keywordObj)}
                    size="small"
                    value={keywordObj.label}
                    variant="outlined"
                    sx={{
                      lineHeight: 1.5,
                      maxWidth: expanded
                        ? "100%"
                        : `calc(${100 / Math.min(maxChips, value.length)}% - ${
                            Math.min(value.length, maxChips) * minDisplayChips
                          }px - ${value.length > maxChips ? 10 : 0}px)`,
                      mb: 0.5,
                      mr: "3px",
                      fontSize: 12,
                      overflow: expanded ? "visible" : "hidden",
                    }}
                  />
                </Tooltip>
              );
            } else if (index === maxChips) {
              let remainingKeywords = "",
                i,
                l = value.length;
              for (i = maxChips; i < l; i++) {
                remainingKeywords += value[i].label + (i === l - 1 ? "" : ", ");
              }
              return (
                <Tooltip key={index} title={remainingKeywords}>
                  <Chip
                    key={index}
                    size="small"
                    label={`+${l - maxChips}`}
                    sx={{ mt: -0.5, ml: 0.5 }}
                    onClick={viewOnly ? handleExpandRow : () => {}}
                  ></Chip>
                </Tooltip>
              );
            }
            return <Typography key={index}></Typography>;
          })}
        </>
      );
    } else {
      return "";
    }
  }
}

export default memo(HitlistTableKeywords);
