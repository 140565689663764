import "./UserMenu.css";
import { memo, useState } from "react";
import { NavLink } from "react-router-dom";
import ManageAccountsSharpIcon from "@mui/icons-material/ManageAccountsSharp";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import SettingsSharp from "@mui/icons-material/Settings";
import {
  Box,
  Menu,
  MenuItem,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomEvents, publish } from "../../Hitlist/Events/CustomEvents";
import Userfront from "@userfront/core";
import UserProfileImage from "../../User/UserProfileImage";
import { useTranslation } from "react-i18next";

function UserMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    publish(CustomEvents.LOGOUT);
    handleClose();
  };

  return (
    <Box>
      <Box>
        <Tooltip title={Userfront.user.email}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <UserProfileImage
              width={50}
              height={50}
              border={"3px solid #fff"}
              alt={"Account Icon"}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose} component={NavLink} to="/profile">
          <ManageAccountsSharpIcon sx={{ mr: 2 }} />
          <Typography>{t("header.userMenu.userProfile.text")}</Typography>
        </MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} to="/subscription">
          <CreditCardOutlinedIcon sx={{ mr: 2 }} /> Subscription
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose} component={NavLink} to="/settings">
          <SettingsSharp sx={{ mr: 2 }} />
          <Typography>{t("header.userMenu.settings.text")}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <LogoutSharpIcon sx={{ mr: 2 }} />
          <Typography>{t("header.userMenu.logout.text")}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default memo(UserMenu);
