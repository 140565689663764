import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import { Stack, IconButton, Divider, Tooltip } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import {
  CustomEvents,
  publish,
  subscribe,
  unsubscribe,
} from "../../Hitlist/Events/CustomEvents";
import { ToastEvents } from "../../Toast/Toast";
import { STORAGE_KEYS, getValue, storeValue } from "../../Storage";
import { useTranslation } from "react-i18next";

function UndoRedo() {
  const [undoActive, setUndoActive] = useState(false);
  const [redoActive, setRedoActive] = useState(false);

  const buttonSize = "24px";

  let historyIndex = useRef(0);

  const { t } = useTranslation();

  const undo = () => {
    const history = getValue(STORAGE_KEYS.PAST_STATES);
    historyIndex.current = Math.max(historyIndex.current - 1, 0);

    if (history && history.length > 0) {
      publish(CustomEvents.HISTORY_CHANGED, history[historyIndex.current]);
    } else {
      publish(ToastEvents.INFO, "Nothing else to undo");
    }

    setUndoActive(false);
    setRedoActive(false);
  };

  const redo = () => {
    const history = getValue(STORAGE_KEYS.PAST_STATES);
    historyIndex.current = Math.min(
      historyIndex.current + 1,
      history.length - 1
    );

    if (history && history.length > 0) {
      publish(CustomEvents.HISTORY_CHANGED, history[historyIndex.current]);
    } else {
      publish(ToastEvents.INFO, "Nothing else to redo");
    }

    setUndoActive(false);
    setRedoActive(false);
  };

  const handleDataSaved = (e) => {
    const { data } = e.detail;

    let current = getValue(STORAGE_KEYS.CURRENT_STATE) || null;
    let history = getValue(STORAGE_KEYS.PAST_STATES) || [];

    if (current) {
      if (
        JSON.stringify(data) !== JSON.stringify(history[historyIndex.current])
      ) {
        // insert the new state after the current index
        history.splice(historyIndex.current + 1, 0, data);
        // remove all states after the current index
        history.splice(historyIndex.current + 2, history.length);
        // only keep the 10 most recently added states, with the most recent state being at the end of the array
        history = history.slice(Math.max(history.length - 10, 0));
        // set the current index to the new state
        storeValue(STORAGE_KEYS.PAST_STATES, history);

        historyIndex.current = history.length - 1;
        setRedoActive(false);
      }
    } else {
      history.push(data);
      storeValue(STORAGE_KEYS.PAST_STATES, history);
    }

    storeValue(STORAGE_KEYS.CURRENT_STATE, data);

    setUndoActive(historyIndex.current > 0);
    setRedoActive(historyIndex.current < history.length - 1);
  };

  useEffect(() => {
    subscribe(CustomEvents.HITLIST_SAVED, handleDataSaved);

    return () => {
      unsubscribe(CustomEvents.HITLIST_SAVED, handleDataSaved);
      storeValue(STORAGE_KEYS.PAST_STATES, []);
      storeValue(STORAGE_KEYS.CURRENT_STATE, null);
    };
  }, []);

  return (
    <Stack direction="row" alignItems="center" className="undo-redo">
      <Divider
        orientation="vertical"
        variant="middle"
        sx={{ height: 28, mr: 1.5 }}
      />
      <Tooltip
        arrow
        enterDelay={600}
        enterNextDelay={1200}
        title={t("header.undoredo.undo.tooltip")}
        placement="bottom"
      >
        <span>
          <IconButton onClick={undo} disabled={!undoActive}>
            <UndoOutlinedIcon
              sx={{
                width: `${buttonSize}`,
                height: `${buttonSize}`,
                borderRadius: "50%",
              }}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip
        arrow
        enterDelay={600}
        enterNextDelay={1200}
        title={t("header.undoredo.redo.tooltip")}
        placement="bottom"
      >
        <span>
          <IconButton onClick={redo} disabled={!redoActive}>
            <RedoOutlinedIcon
              sx={{
                width: `${buttonSize}`,
                height: `${buttonSize}`,
                borderRadius: "50%",
              }}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Divider
        orientation="vertical"
        variant="middle"
        sx={{ height: 28, ml: 1.5 }}
      />
    </Stack>
  );
}

export default memo(UndoRedo);
