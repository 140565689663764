import { memo, useEffect, useState } from "react";
import { Button, Tooltip } from "@mui/material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ResetDialog from "../ResetDialog/ResetDialog";
import { useTranslation } from "react-i18next";

function ResetButton(props) {
  const { generating, resetHitlist, sx, keywordsLoading, domainsLoading } =
    props;

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation();

  const handleResetOpen = () => {
    setOpen(true);
  };

  const handleResetClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setDisabled(generating);
  }, [generating]);

  return (
    <>
      <Tooltip
        title={t("hitlist.resetButton.resetButtonTooltip")}
        arrow
        enterDelay={600}
        enterNextDelay={1200}
      >
        <span>
          <Button
            size="large"
            variant="contained"
            color="error"
            onClick={handleResetOpen}
            disabled={disabled || keywordsLoading || domainsLoading}
            sx={sx}
          >
            <RestartAltOutlinedIcon />
          </Button>
        </span>
      </Tooltip>
      <ResetDialog
        open={open}
        onClose={handleResetClose}
        handleResetClose={handleResetClose}
        resetAllData={resetHitlist}
      />
    </>
  );
}

export default memo(ResetButton);
