import { memo } from "react";
import MessageBar from "../ChatField/MessageBar/MessageBar";

function User(props) {
  const { img, name, msg, align } = props;
  return (
    <MessageBar img={img} name={name} msg={msg} align={align} type="user" />
  );
}

export default memo(User);
