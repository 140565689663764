import { memo } from "react";
import { Typography, Divider } from "@mui/material";

function ExportMenuDivider(props) {
  const { heading } = props;
  return (
    <Divider variant="middle" sx={{ mt: 1 }}>
      <Typography sx={{ fontSize: "11px" }}>{heading}</Typography>
    </Divider>
  );
}

export default memo(ExportMenuDivider);
