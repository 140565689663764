import "./GlobalMenu.css";
import { memo, useEffect, useContext, useState, useRef, useCallback } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  Menu,
  Stack,
  List,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ControlPointDuplicateOutlinedIcon from "@mui/icons-material/ControlPointDuplicateOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { HitlistAPI } from "../../Apis/HitlistAPI";
import { useNavigate } from "react-router-dom";
import { publish } from "../../Hitlist/Events/CustomEvents";
import HitlistTableWarningDialogue from "../../Hitlist/Assistant/HitlistTable/HitlistTableWarningDialogue";
import { ToastEvents } from "../../Toast/Toast";
import Version from "../Version/Version";
import Userfront from "@userfront/core";
import { STORAGE_KEYS, removeValue, storeValue } from "../../Storage";
import { useTranslation } from "react-i18next";
import GlobalMenuItem from "./GlobalMenuItem";
import GlobalMenuDivider from "./GlobalMenuDivider";
import GlobalMenuContainer from "./GlobalMenuContainer";
import { AppConfigContext } from "../../AppConfigContext";

function GlobalMenu(props) {
  const { hitlistId, title, hitlists, user } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchor] = useState(null);
  const [hitlistList, setHitlistList] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const theme = useTheme();

  const hitlistRef = useRef([]);
  const hitlistIdRef = useRef(null);
  const hitlistDeleteRef = useRef(null);
  const apiControllerRef = useRef([]);

  const config = useContext(AppConfigContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const getHitlistList = async (autoLoad = false) => {
    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      const hitlists = await HitlistAPI.getAllHitlists(
        controller.signal,
        Userfront.user.email
      );

      updateHitlistList(hitlists, autoLoad);
    } catch (err) {
      setHitlistList([]);
    }
  };

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
    setOpen(event.currentTarget ? true : false);
  };

  const handleClose = () => {
    setAnchor(null);
    setOpen(false);
  };

  const handleBuyCredits = () => {
    navigate("/add-credits");
    handleClose();
  };

  const handleNewHitlist = async (e) => {
    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      const newHitlist = await HitlistAPI.saveNewHitlist(
        controller.signal,
        Userfront.user.email,
        "** New Hitlist **"
      );

      if (newHitlist.id) {
        publish(ToastEvents.SUCCESS, "New hitlist created successfully.");
        getHitlistList();
        handleGotoHitlist(newHitlist.id);
      }
    } catch (err) {}
  };

  const handleDuplicateHitlist = async (hitlistId, newTitle) => {
    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      const newHitlist = await HitlistAPI.duplicateHitlist(
        controller.signal,
        hitlistId,
        Userfront.user.email,
        newTitle
      );
      if (newHitlist.id) {
        publish(ToastEvents.SUCCESS, "Hitlist duplicated successfully.");
        getHitlistList();
      }
    } catch (err) {}
  };

  const handleDeleteHitlist = async (hitlistId) => {
    hitlistDeleteRef.current = hitlistId;
    setDeleteWarning(true);
  };

  const handleGotoHitlist = useCallback((hitlistId) => {
    // clear all local storage fields that are specific to a hitlist
    removeValue(STORAGE_KEYS.ID);
    removeValue(STORAGE_KEYS.CURRENT_STATE);
    removeValue(STORAGE_KEYS.DOMAIN);
    removeValue(STORAGE_KEYS.DOMAINS);
    removeValue(STORAGE_KEYS.FUTURE_STATES);
    removeValue(STORAGE_KEYS.HITLIST);
    removeValue(STORAGE_KEYS.HITLIST_STATE);
    removeValue(STORAGE_KEYS.PAST_STATES);
    removeValue(STORAGE_KEYS.RELATED_TERMS);
    removeValue(STORAGE_KEYS.TARGET_COUNTRY);
    removeValue(STORAGE_KEYS.TITLE);
    removeValue(STORAGE_KEYS.TOPIC);

    // navigate to the new hitlist and set the hitlist id in local storage
    storeValue(STORAGE_KEYS.ID, hitlistId);
    window.location.href = `/hitlist?id=${hitlistId}`;

    // close the menu
    handleClose();
  }, []);

  const handleWarningClose = () => {
    setDeleteWarning(false);
  };

  const handleWarningYes = async () => {
    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      const deletedHitlist = await HitlistAPI.deleteHitlist(
        controller.signal,
        hitlistDeleteRef.current,
        Userfront.user.email
      );

      if (deletedHitlist) {
        setDeleteWarning(false);

        publish(ToastEvents.SUCCESS, "Hitlist deleted successfully.");

        // check if the current hitlist id is the same as the one deleted, if so then send a reset event to clear the current hitlist shown
        if (hitlistIdRef.current === hitlistDeleteRef.current) {
          getHitlistList(true);
        } else {
          getHitlistList();
        }
      }
    } catch (err) {}
  };

  const updateHitlistList = useCallback(
    (hitlists, autoLoad = false) => {
      if (Array.isArray(hitlists) && hitlists.length > 0) {
        hitlistRef.current = hitlists;

        // sort the hitlists by title, ignoring blank titles
        hitlistRef.current.sort((a, b) => {
          if (a.title === "" || a.title === null || a.title === undefined) {
            return 1;
          } else if (b.title === "") {
            return -1;
          } else {
            return a.title.localeCompare(b.title);
          }
        });

        if (autoLoad) {
          handleGotoHitlist(hitlistRef.current[0].id);
        } else {
          setHitlistList(hitlistRef.current);
        }
      } else {
        setHitlistList([]);
      }
    },
    [handleGotoHitlist]
  );

  useEffect(() => {
    updateHitlistList(hitlists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hitlists]);

  useEffect(() => {
    hitlistIdRef.current = hitlistId;
    // getHitlistList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hitlistId]);

  useEffect(() => {
    // getHitlistList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    hitlistIdRef.current = hitlistId;

    getHitlistList();

    // instantiate the api controller array
    apiControllerRef.current = [];

    return () => {
      // cancel all api calls
      apiControllerRef.current.forEach((controller) => controller.abort());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box>
        <Tooltip title={t("header.globalMenu.tooltip")}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 0, mr: 2 }}
            aria-controls={open ? "global-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 40, height: 40, backgroundColor: "#fff" }}>
              <GridViewIcon sx={{ color: theme.palette.primary.main }} />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="global-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 2,
              ml: -3,
              width: 350,
              backgroundColor: "#fff",
              borderRadius: 0,
              height: "calc(100% - 80px)",
              "& .MuiList-root": {
                height: "100%",
                ml: 0,
              },
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                backgroundColor: "#fff",
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgColor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <GlobalMenuContainer sx={{ my: 2 }}>
          {user.email && (
            <span>
              <Stack direction="row" alignItems="center" sx={{ ml: 2 }}>
                <Avatar
                  className="global-icon"
                  sx={{ ml: 1, transform: "rotate(-90deg)" }}
                >
                  <SpaceDashboardOutlinedIcon
                    fontSize="medium"
                    sx={{ color: theme.palette.text.primary }}
                  />
                </Avatar>
                <Typography sx={{ mt: 0.25 }}>
                  {t("header.globalMenu.hitlists.heading")}
                </Typography>
                <Tooltip
                  arrow
                  title={t("header.globalMenu.hitlists.addButton.tooltip")}
                  enterDelay={600}
                  enterNextDelay={1200}
                >
                  <Button
                    className="global-menu-hitlist-add-button"
                    variant="outlined"
                    size="small"
                    color="secondary"
                    sx={{ ml: 2, mt: 0.5, pt: 0.25, pb: 0, px: 0.5 }}
                    endIcon={
                      <AddOutlinedIcon
                        fontSize="small"
                        sx={{ mt: -0.25, ml: -0.5 }}
                      />
                    }
                    onClick={handleNewHitlist}
                  >
                    <Typography variant="caption">
                      {t("header.globalMenu.hitlists.addButton.text")}
                    </Typography>
                  </Button>
                </Tooltip>
              </Stack>
            </span>
          )}
          {!user.email && (
            <Box sx={{ mx: 2 }}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                {t("hitlist.register.registerHeading")}
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }}>
                {t("hitlist.register.registerText")}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                href={config.REGISTER_URL}
                target="_blank"
                endIcon={<OpenInNewOutlinedIcon />}
              >
                {t("hitlist.register.registerButton")}
              </Button>
            </Box>
          )}
          <List sx={{ mt: 1.5 }} className="global-menu-hitlist-list">
            {hitlistList.map((hitlist) => (
              <ListItem
                className={`global-menu-hitlist-item`}
                key={hitlist.id}
                disableGutters
                secondaryAction={
                  <>
                    <Tooltip
                      arrow
                      enterDelay={600}
                      enterNextDelay={1200}
                      title={t(
                        "header.globalMenu.hitlists.duplicateButton.tooltip"
                      )}
                    >
                      <IconButton
                        aria-label={t(
                          "header.globalMenu.hitlists.duplicateButton.text"
                        )}
                        size="small"
                        onClick={() =>
                          handleDuplicateHitlist(
                            hitlist.id,
                            hitlist.title + " (copy)"
                          )
                        }
                      >
                        <ContentCopyOutlinedIcon fontSize="12px" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      arrow
                      enterDelay={600}
                      enterNextDelay={1200}
                      title={t(
                        "header.globalMenu.hitlists.deleteButton.tooltip"
                      )}
                    >
                      <IconButton
                        aria-label={t(
                          "header.globalMenu.hitlists.deleteButton.text"
                        )}
                        size="small"
                        onClick={() => handleDeleteHitlist(hitlist.id)}
                      >
                        <DeleteForeverOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                sx={{ mb: 0, pl: 2, pr: 0, py: 0.75 }}
              >
                <ListItemIcon sx={{ minWidth: "36px" }}>
                  <ChevronRightOutlinedIcon
                    fontSize="5px"
                    color={
                      hitlist.id === hitlistId ? "secondary" : "text.primary"
                    }
                  />
                </ListItemIcon>
                <Link
                  color={
                    hitlist.id === hitlistId ? "secondary.main" : "text.primary"
                  }
                  onClick={() => handleGotoHitlist(hitlist.id)}
                  sx={{ cursor: "pointer", textDecoration: "none" }}
                >
                  <Typography variant="body2">{hitlist.title}</Typography>
                </Link>
              </ListItem>
            ))}
          </List>
        </GlobalMenuContainer>
        <GlobalMenuDivider />
        {user.email && (
          <span>
            <GlobalMenuContainer>
              <GlobalMenuItem
                onClick={handleBuyCredits}
                StartIcon={ControlPointDuplicateOutlinedIcon}
                newTab={false}
                text={t("header.globalMenu.addCredits.text")}
              />
              <GlobalMenuItem
                onClick={handleClose}
                StartIcon={SettingsOutlinedIcon}
                link="/settings"
                newTab={false}
                text={t("header.globalMenu.settings.text")}
              />
            </GlobalMenuContainer>
            <GlobalMenuDivider />
          </span>
        )}
        <GlobalMenuContainer>
          <GlobalMenuItem
            onClick={handleClose}
            StartIcon={HelpCenterOutlinedIcon}
            EndIcon={OpenInNewOutlinedIcon}
            link="https://hitlist.ai/docs/"
            newTab={true}
            text={t("header.globalMenu.help.text")}
          />
          <GlobalMenuItem
            onClick={handleClose}
            StartIcon={SlideshowOutlinedIcon}
            EndIcon={OpenInNewOutlinedIcon}
            link="https://www.youtube.com/channel/UCWCS9VETZoPVemc6LNRGW1g"
            newTab={true}
            text={t("header.globalMenu.youtube.text")}
          />
          <GlobalMenuItem
            onClick={handleClose}
            StartIcon={RateReviewOutlinedIcon}
            EndIcon={OpenInNewOutlinedIcon}
            link="https://feedback.hitlist.ai/b/kvjy6jve/feature-ideas"
            newTab={true}
            text={t("header.globalMenu.ideas.text")}
          />
          <GlobalMenuItem
            onClick={handleClose}
            StartIcon={MapOutlinedIcon}
            EndIcon={OpenInNewOutlinedIcon}
            to="https://feedback.hitlist.ai/roadmap"
            newTab={true}
            text={t("header.globalMenu.roadmap.text")}
          />
        </GlobalMenuContainer>
        <GlobalMenuContainer
          sx={{ position: "absolute", bottom: 10, width: "100%" }}
        >
          <GlobalMenuDivider />
          <GlobalMenuItem
            onClick={handleClose}
            StartIcon={OpenInNewOutlinedIcon}
            link="https://hitlist.ai/privacy-policy/"
            newTab={true}
            fontSize="12px"
            text={t("header.globalMenu.privacy.text")}
          />
          <GlobalMenuItem
            onClick={handleClose}
            StartIcon={OpenInNewOutlinedIcon}
            link="https://hitlist.ai/terms-conditions/"
            newTab={true}
            fontSize="12px"
            text={t("header.globalMenu.terms.text")}
          />
          <Grid
            container
            direction="row"
            justifyContent="right"
            alignItems="center"
            sx={{ mt: 1, ml: -1, pr: 1 }}
          >
            <Version color="#dfdfdf" hideDate={true} hideVersion={false} />
          </Grid>
        </GlobalMenuContainer>
      </Menu>
      <HitlistTableWarningDialogue
        open={deleteWarning}
        noAction={handleWarningClose}
        yesAction={handleWarningYes}
        title="Are you sure?"
        msg="Are you sure you want to delete this Hitlist?"
        yes="Yes"
        no="No"
      />
    </>
  );
}

export default memo(GlobalMenu);
