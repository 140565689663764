import { memo, useEffect, useRef, useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import parse from "html-react-parser";
import StringUtils from "../../../../Utils/StringUtils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import "./InfoBar.css";

function InfoBar(props) {
  const { msg } = props;
  const theme = useTheme();

  return (
    <Box
      className={`message message-info message-container message-align-left`}
      sx={{
        my: 2,
        backgroundColor: "rgba(3,169,244,0.1)",
        border: `1px solid ${theme.palette.info.light}`,
        display: "flex",
        alignItems: "flex-start",
        borderRadius: "5px",
        px: 2,
        py: 0,
        flexDirection: "row",
      }}
      hidden={msg.length === 0}
    >
      <Stack direction="row" alignItems="center">
        <InfoOutlinedIcon
          fontSize="11px"
          sx={{ mr: 1, color: theme.palette.info.main, mt: -0.125 }}
        />
        <MessageText
          theme={theme}
          className="chat-info-message"
          msg={msg}
          animate={false}
        />
      </Stack>
    </Box>
  );
}

const MessageText = (props) => {
  const { msg, theme } = props;
  const [displayMessage, setDisplayMessage] = useState("");

  // const proceed = () => {
  //   publish(CustomEvents.CHAT_MESSAGE_FINISHED);
  // };

  let parsedMsgRef = useRef(msg);

  useEffect(() => {
    parsedMsgRef.current = StringUtils.convertNewLines(msg);
    setDisplayMessage(parsedMsgRef.current);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // <WindupChildren key={new Date()}>
    //   <Pace ms={0}>
    //     <OnChar fn={proceed}>
          <Typography
            className="info-message-msg"
            variant="subtitle2"
            component="span"
            color={theme.palette.info.main}
            sx={{ my: 0, py: 0 }}
          >
            {parse(displayMessage)}
          </Typography>
    //     </OnChar>
    //   </Pace>
    // </WindupChildren>
  );
};

export default memo(InfoBar);
