import { Button, Container, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function ErrorPage(props) {
  const { error, resetErrorBoundary } = props;

  const { t } = useTranslation();

  useEffect(() => {
    console.log("ErrorPage: error: ", error);
  }, [error]);

  return (
    <Container>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ mx: "auto", width: "80%" }}
      >
        <Grid item xs={12} sx={{ my: 10, textAlign: "center" }}>
          <Typography variant="h4" component="h1" gutterBottom>
            {t("error.errorHeading")}
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            {t("error.errorMessage")}
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={resetErrorBoundary}
            >
              {t("error.tryAgainButton")}
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ErrorPage;
