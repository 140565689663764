import { memo } from "react";
import { Typography, Icon, Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

function ExportMenuItem(props) {
  const { StartIcon, text, tooltip, onClick, onIconClick } = props;
  return (
    <MenuItem onClick={onClick}>
      <Tooltip title={tooltip} arrow enterDelay={300} enterNextDelay={600}>
        <Icon sx={{ mr: 2 }} onClick={onIconClick}>
          <StartIcon />
        </Icon>
      </Tooltip>
      <Typography>{text}</Typography>
    </MenuItem>
  );
}

export default memo(ExportMenuItem);
