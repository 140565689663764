import { memo } from "react";
import { Box } from "@mui/material";

function LoginImage() {
  return (
    <Box
      className="login-image"
      sx={{
        backgroundImage: `url("${process.env.PUBLIC_URL}/img/login-bg.jpg")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100%",
        width: "100%",
        borderRadius: "0 80px 80px 0",
      }}
    >
      <Box sx={{ position: "absolute", left: 45, top: 45 }}>
        <img
          src={process.env.PUBLIC_URL + "/img/hitlist-logo-full.png"}
          alt="logo"
          width="140px"
        />
      </Box>
    </Box>
  );
}

export default memo(LoginImage);
