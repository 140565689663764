import { Typography, Stack, Divider, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState, useRef } from "react";
import {
  CustomEvents,
  subscribe,
  unsubscribe,
} from "../../Hitlist/Events/CustomEvents";
import TollOutlinedIcon from "@mui/icons-material/TollOutlined";
import { HitlistAPI } from "../../Apis/HitlistAPI";
import Userfront from "@userfront/core";
import { STORAGE_KEYS, getValue, storeValue } from "../../Storage";
import CreditCounterBuy from "./CreditCounterBuy";
import { useTranslation } from "react-i18next";

function CreditCounter() {
  const [creditsUsed, setCreditsUsed] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);

  const creditsUsedRef = useRef(null);
  const creditsTotalRef = useRef(null);
  const creditExpiryRef = useRef(null);
  const apiControllerRef = useRef([]);

  const creditCountTotalRef = useRef(0);

  const theme = useTheme();

  const { t } = useTranslation();

  const handleTokensUsed = async (e) => {
    const { data } = e.detail;

    const creditsUsed = convertTokens(data.tokens.total_tokens);

    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      const credits = await HitlistAPI.updateUsedCredits(
        controller.signal,
        Userfront.tokens.accessToken,
        Userfront.user.email,
        creditsUsed
      );

      updateCredits(credits);
    } catch (error) {}
  };

  const getCredits = async () => {
    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      let credits = await HitlistAPI.getCredits(
        controller.signal,
        Userfront.tokens.accessToken,
        Userfront.user.email
      );

      updateCredits(credits);
    } catch (error) {
      updateCredits(getValue(STORAGE_KEYS.CREDITS) || 0);
    }
  };

  const updateCredits = (credits) => {
    if (!credits) return;

    creditsTotalRef.current =
      parseFloat(credits.credits_total) + parseFloat(credits.pack_credits) ||
      convertTokens(0);
    creditsUsedRef.current =
      parseFloat(credits.credits_used) || convertTokens(0);
    creditExpiryRef.current = credits.credits_expiry;

    setTotalCredits(creditsTotalRef.current);
    setCreditsUsed(creditsUsedRef.current);

    // save the current credits to local storage
    storeValue(
      STORAGE_KEYS.CREDITS,
      creditsTotalRef.current - creditsUsedRef.current
    );
  };

  const convertTokens = (tokens) => {
    creditCountTotalRef.current += Math.ceil(tokens / 1000);
    console.log("🚀 ~ file: CreditCounter.js:90 ~ convertTokens ~ creditCountTotalRef.current:", creditCountTotalRef.current)

    return tokens / 1000;
  };

  useEffect(() => {
    // reset the abort controller
    apiControllerRef.current = [];

    getCredits();

    // subscribe to events
    subscribe(CustomEvents.TOKENS_USED, handleTokensUsed);

    return () => {
      // abort all pending requests
      apiControllerRef.current.forEach((controller) => controller.abort());

      // unsubscribe from events
      unsubscribe(CustomEvents.TOKENS_USED, handleTokensUsed);

      // reset the credits
      updateCredits(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="row">
      <Stack
        direction="column"
        alignItems="flex-start"
        className="credit-counter"
      >
        <Box sx={{ width: 70, height: "1.3em", textAlign: "center" }}>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ cursor: "default" }}
          >
            {t("header.creditCounter.text")}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" sx={{ height: "1em" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ width: 70, height: "1em" }}
          >
            <TollOutlinedIcon
              fontSize="small"
              sx={{ color: theme.palette.primary.main, mr: 0.5 }}
            />

            <Typography
              variant="subtitle2  "
              color={
                totalCredits - creditsUsed < 100
                  ? theme.palette.error.main
                  : theme.palette.text.primary
              }
              sx={{ cursor: "default", fontSize: "14px", fontWeight: "500" }}
            >
              {`${Math.floor(
                Math.max(0, totalCredits - creditsUsed)
              ).toLocaleString()}`}
            </Typography>
          </Stack>
          <Box sx={{ mt: "-1em", ml: 1 }}>
            <CreditCounterBuy theme={theme} />
          </Box>
        </Stack>
      </Stack>
      <Divider
        orientation="vertical"
        variant="middle"
        sx={{ height: 28, ml: 1.5 }}
      />
    </Stack>
  );
}

export default CreditCounter;
