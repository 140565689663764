import { GPT3 } from "../Apis/OpenAiApi";
import { publish } from "../Hitlist/Events/CustomEvents";
import { ToastEvents } from "../Toast/Toast";
import StringUtils from "../Utils/StringUtils";

const keywordChatSystemObject = {
  role: "system",
  content:
    "You are an SEO keyword researcher. Your purpose is to research short-tail keyword ideas to write engaging articles on based on the user's topic.",
};

let keywordChatHistory = [keywordChatSystemObject];

/**
 * A function to reset the keyword chat history to the original system object (or a given object)
 * @param {Object} setupObj An OpenAI API system object to set the original keyword chat history to
 */
const resetKeywordChatHistory = () => {
  keywordChatHistory = [keywordChatSystemObject];
};

/**
 * A function to get related keywords from the OpenAI API from a given topic using the OpenAI API
 *
 * @param {AbortSignal} signal A signal to cancel the request
 * @param {String} topic the topic to find keywords for
 * @param {Int} resultNumber the number of results to return
 * @param {Boolean} more whether or not to return more results that are longer tail from the originals
 * @param {String} targetCountry the country to find keywords for
 * @returns Array of keywords
 * @throws {Error} Throws an error if the response is invalid
 */
const getRelatedKeywords = async (
  signal,
  topic,
  resultNumber = 10,
  more = false,
  targetCountry = "US",
  targetLanguage = "en"
) => {
  if (!more) resetKeywordChatHistory();

  const languageName = StringUtils.getLanguageCodeName(targetLanguage);

  let userPrompt = {
    role: "user",
    content: `Return ${resultNumber} new keywords that are different types, brands, products, or niched down sub-topic of '${topic}'. You must return an array in this exact format [{label: keyword1}, ..., {label: keyword${resultNumber}}]. Topic: ${topic}`,
  };

  keywordChatHistory.push(userPrompt);

  try {
    const returnResultSchema = [
      {
        name: "process_keywords",
        description: `A javascript function to process an array of keywords`,
        parameters: {
          type: "object",
          properties: {
            keywords: {
              type: "array",
              description: `An array of ${resultNumber} keywords in ${languageName}`,
              length: resultNumber,
              items: {
                type: "object",
                description: `A single keyword object`,
                properties: {
                  label: {
                    type: "string",
                    description: `The keyword phrase`,
                  },
                },
              },
            },
          },
          required: ["keywords"],
        },
      },
    ];

    let response = await GPT3.generateChat(
      signal,
      keywordChatHistory,
      0.9,
      150,
      returnResultSchema
    );

    if (response) {
      if (response.choices.length > 0) {
        let resultsArray = [];

        let functionCall = response.choices[0].message.function_call;

        if (functionCall && functionCall.name === "process_keywords") {
          resultsArray = JSON.parse(
            response.choices[0].message.function_call.arguments
          ).keywords;
        } else if (response.choices[0].message.content) {
          resultsArray = JSON.parse(
            response.choices[0].message.content
          ).keywords;
        }

        if (resultsArray.length) {
          let resultsString = "";
          // convert all the .label nodes to strings and store them in the history
          resultsArray.forEach((keyword) => {
            resultsString += keyword.label + ",";
          });

          // remove any with the same labels
          resultsArray = resultsArray.filter(
            (keyword, index, self) =>
              index ===
              self.findIndex(
                (k) => k.label.toLowerCase() === keyword.label.toLowerCase()
              )
          );

          keywordChatHistory.push({
            role: "assistant",
            content: resultsString,
          });
        }

        // return the first resultNumber results
        return resultsArray.slice(0, resultNumber);
      } else {
        throw new Error("No keywords found in response: ", response);
      }
    } else {
      throw new Error("Response doesn't look valid: ", response);
    }
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
    return;
  }
};

/**
 * A function to get extra keywords from the OpenAI API from a given title or URL using the OpenAI API
 * @param {String} title A title to use for keyword research
 * @param {String} url A URL to use for keyword research
 * @param {Int} totalOptions The total number of options to return
 * @param {Boolean} more Whether or not to return more options that are different from the last
 * @returns Array of keywords
 */
const getExtraKeywords = async (
  signal,
  title,
  seedKeywords = [],
  totalOptions = 5,
  more = false,
  targetCountry = "US",
  targetLanguage = "en"
) => {
  if (!more) resetKeywordChatHistory();

  const languageName = StringUtils.getLanguageCodeName(targetLanguage);
  const countryName = StringUtils.getCountryCodeName(targetCountry);

  let userPrompt = {
    role: "user",
    content: `Generate ${totalOptions} two, three, or four-word ${languageName} summaries of what people in ${countryName} search for to find ${title} that are not ${seedKeywords.toString()}. Return the results in ${languageName} in this exact JSON format: [{label: summary1}, ..., {label: summary${totalOptions}}]: Title: ${title}`,
  };

  keywordChatHistory.push(userPrompt);

  try {
    const returnResultSchema = [
      {
        name: "process_keywords",
        description: `A function to process an array of keywords`,
        parameters: {
          type: "object",
          properties: {
            keywords: {
              type: "array",
              description: `An array of ${totalOptions} summaries in ${languageName}`,
              length: totalOptions,
              items: {
                type: "object",
                description: `A single summary object`,
                properties: {
                  label: {
                    type: "string",
                    description: `A single summary in ${languageName}`,
                    example: "dog training",
                  },
                },
              },
            },
          },
          required: ["keywords"],
        },
      },
    ];

    let response = await GPT3.generateChat(
      signal,
      keywordChatHistory,
      0.9,
      150,
      returnResultSchema
    );

    if (response) {
      if (response.choices.length > 0) {
        let resultsArray = [];

        let functionCall = response.choices[0].message.function_call;

        if (functionCall && functionCall.name === "process_keywords") {
          resultsArray = JSON.parse(
            response.choices[0].message.function_call.arguments
          ).keywords;
        } else if (response.choices[0].message.content) {
          resultsArray = JSON.parse(
            response.choices[0].message.content
          ).keywords;
        }

        keywordChatHistory.push({
          role: "assistant",
          content: resultsArray.toString(),
        });

        // append the seed keywords to the new keywords if we are adding to existing keywords
        if (more) resultsArray = seedKeywords.concat(resultsArray);

        // remove any with duplicate labels
        resultsArray = resultsArray.filter(
          (keyword, index, self) =>
            index ===
            self.findIndex(
              (k) => k.label.toLowerCase() === keyword.label.toLowerCase()
            )
        );

        // return the first resultNumber results
        return resultsArray;
      } else {
        throw new Error("No keywords found in response: ", response);
      }
    } else {
      throw new Error("Response doesn't look valid: ", response);
    }
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
    return;
  }
};

export { getRelatedKeywords, getExtraKeywords, resetKeywordChatHistory };
