import { memo } from "react";
import { Tooltip, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";

function HitlistTableLink(props) {
  const { value } = props;

  const theme = useTheme();

  return (
    <Tooltip
      title="Open top result in new tab"
      arrow
      enterDelay={300}
      enterNextDelay={600}
    >
      <span>
        {value && (
          <Link href={value} target="_blank" rel="noreferrer" disabled={!value}>
            <TravelExploreOutlinedIcon
              fontSize="10px"
              sx={{
                mt: 1,
                color: value ? theme.palette.text.primary : theme.palette.muted,
              }}
            />
          </Link>
        )}
        {!value && (
          <TravelExploreOutlinedIcon
            fontSize="10px"
            sx={{
              mt: 1,
              color: value ? theme.palette.text.primary : theme.palette.muted,
            }}
          />
        )}
      </span>
    </Tooltip>
  );
}

export default memo(HitlistTableLink);
