import { memo } from "react";
import { Box, Skeleton } from "@mui/material";

function HitlistTableWait() {
  const skeletonElements = [];
  const skeletonElementCount = 15;
  const skeletonElementHeight = 35;
  const skeletonAnimate = false;
  const skeletonBGLight = "grey.50";
  const skeletonBGDark = "grey.100";

  for (let i = 0; i < skeletonElementCount; i++) {
    skeletonElements.push(
      <Box
        key={i}
        sx={{
          display: "flex",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={skeletonElementHeight + 30}
          height={skeletonElementHeight}
          sx={{
            mr: 2,
            bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={skeletonElementHeight + 30}
          height={skeletonElementHeight}
          sx={{
            mr: 2,
            bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={skeletonElementHeight + 30}
          height={skeletonElementHeight}
          sx={{
            mr: 2,
            bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={skeletonElementHeight + 30}
          height={skeletonElementHeight}
          sx={{
            mr: 2,
            bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width="75%"
          height={skeletonElementHeight}
          sx={{
            mr: 2,
            bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width="50%"
          height={skeletonElementHeight}
          sx={{
            mr: 2,
            bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={120}
          height={skeletonElementHeight}
          sx={{
            mr: 2,
            bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={70}
          height={skeletonElementHeight}
          sx={{
            mr: 2,
            bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={skeletonElementHeight + 30}
          height={skeletonElementHeight}
          sx={{
            mr: 2,
            bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={skeletonElementHeight + 30}
          height={skeletonElementHeight}
          sx={{
            mr: 2,
            bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={skeletonElementHeight + 30}
          height={skeletonElementHeight}
          sx={{ bgcolor: i % 2 === 1 ? skeletonBGLight : skeletonBGDark }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ pt: 2, pl: 2, pr: 2, pb: 3 }}>
    <Box
        key={999}
        sx={{
          alignItems: "center",
          display: "flex",
          mb: 8,
          ml: 1
        }}
      >
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={160}
          height={skeletonElementHeight}
          sx={{
            bgcolor: skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={85}
          height={skeletonElementHeight}
          sx={{
            mr: 3,
            bgcolor: skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={85}
          height={skeletonElementHeight}
          sx={{
            mr: 3,
            bgcolor: skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={85}
          height={skeletonElementHeight}
          sx={{
            mr: 3,
            bgcolor: skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={90}
          height={skeletonElementHeight}
          sx={{
            bgcolor: skeletonBGDark,
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          height={skeletonElementHeight}
          sx={{
            flexGrow: 1,
            bgcolor: "white",
          }}
        />
        <Skeleton
          animation={skeletonAnimate}
          variant="rectangular"
          width={85}
          height={skeletonElementHeight}
          sx={{
            bgcolor: skeletonBGDark,
          }}
        />
      </Box>
      <Box>{skeletonElements}</Box>
    </Box>
  );
}

export default memo(HitlistTableWait);
