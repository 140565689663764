import { memo, useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Stack,
  Divider,
  Grid,
  Link,
} from "@mui/material";
import { CustomEvents, publish } from "../../Hitlist/Events/CustomEvents";
import { ToastEvents } from "../../Toast/Toast";
import { NavLink, useLocation } from "react-router-dom";
import ResetPasswordModal from "../../Login/ResetPasswordModal/ResetPasswordModal";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { HitlistAPI } from "../../Apis/HitlistAPI";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";

import "./ResetOptions.css";

function ResetOptions(props) {
  const { resettingPassword } = props;

  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const { t } = useTranslation();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const uuidRef = useRef(null);
  const tokenRef = useRef(null);

  const passwordRef = useRef("");
  const confirmPasswordRef = useRef("");
  const emailRef = useRef("");
  const apiControllerRef = useRef([]);

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleSendNewLink = () => {
    setOpen(true);
  };

  const handleResetPassword = async (e) => {
    if (!passwordRef.current.value || !confirmPasswordRef.current.value) {
      publish(ToastEvents.ERROR, "Please fill out all fields.");
      setPasswordEmpty(passwordRef.current.value === "");
      setConfirmPasswordEmpty(confirmPasswordRef.current.value === "");
      return;
    }

    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      publish(ToastEvents.ERROR, "Passwords do not match. Please try again.");
      setConfirmPasswordEmpty(true);
      return;
    }

    if (emailRef.current && confirm) {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      const credentials = await getCredentials(
        controller.signal,
        emailRef.current,
        "reset"
      );
      
      if (credentials) {
        uuidRef.current = credentials.uuid;
        tokenRef.current = credentials.token;
      }
    }

    publish(CustomEvents.RESET_PASSWORD, {
      uuid: uuidRef.current,
      token: tokenRef.current,
      password: passwordRef.current.value,
      redirect: emailRef.current ? true : false,
    });
  };

  const getCredentials = async (email, type) => {
    if (email === null) {
      publish(ToastEvents.ERROR, "Invalid reset link.");
      return;
    }

    try {
      const response = await HitlistAPI.getCredentials(email, type);
      if (response.message === "OK") {
        // set the uuid and token
        return response.result;
      } else {
        publish(ToastEvents.ERROR, response.message);
      }
    } catch (error) {
      publish(ToastEvents.ERROR, "Invalid reset link.");
      return;
    }
  };

  useEffect(() => {
    // get and store the uuid and token from the url
    const urlParams = new URLSearchParams(window.location.search);
    uuidRef.current = urlParams.get("uuid");
    tokenRef.current = urlParams.get("token");

    // check the url if this is a user confirming their account and setting their initial password
    emailRef.current = urlParams.get("email");

    apiControllerRef.current = [];

    setConfirm(
      urlParams.get("confirm") === "true" && emailRef.current !== null
    );

    return () => {
      // cancel any pending api calls
      apiControllerRef.current.forEach((controller) => controller.abort());

      // clear the uuid and token
      uuidRef.current = null;
      tokenRef.current = null;
      emailRef.current = null;

      // reset the confirm state
      setConfirm(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: 350 }} id="reset-form">
      <Stack
        direction="column"
        alignItems="center"
        sx={{ mb: 4, display: confirm ? "flex" : "none" }}
      >
        <CheckCircleOutlinedIcon
          color="success"
          sx={{ mr: 1, fontSize: 80, mb: 2 }}
        />
        <Typography component="p" variant="subheading" align="center">
          Your account is confirmed! You can now set your password below and log
          in.
        </Typography>
      </Stack>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Set New Password.
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <Stack spacing={1}>
          <TextField
            id="reset-password"
            label="New Password"
            type="password"
            className={`flat-textfield ${passwordEmpty ? "error" : ""}`}
            inputRef={passwordRef}
            onKeyUp={(e) => {
              if (e.key === "Enter") handleResetPassword();
            }}
          />
          <TextField
            id="reset-password-confirm"
            label="Confirm New Password"
            type="password"
            className={`flat-textfield ${confirmPasswordEmpty ? "error" : ""}`}
            inputRef={confirmPasswordRef}
            onKeyUp={(e) => {
              if (e.key === "Enter") handleResetPassword();
            }}
          />
        </Stack>
        <LoadingButton
          loading={resettingPassword}
          loadingPosition="end"
          endIcon={<ChevronRightOutlinedIcon />}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={handleResetPassword}
          className="flat-button"
          sx={{ mt: 2 }}
        >
          {!resettingPassword
            ? t("hitlist.reset.resetButton")
            : t("hitlist.reset.resetButtonLoading")}
        </LoadingButton>
        <Divider sx={{ mt: 4, mb: 2 }}></Divider>
        <Grid className="login-meta" container columns={2} alignItems="center">
          <Grid item xs={1} align="left">
            <NavLink sx={{ cursor: "pointer" }} to="/login">
              Login
            </NavLink>
          </Grid>
          <Grid item xs={1} align="right">
            <Link sx={{ cursor: "pointer" }} onClick={handleSendNewLink}>
              Send New Reset Link
            </Link>
          </Grid>
        </Grid>
      </Box>

      {/* ****************** Forgot Password Modal ****************** */}

      <ResetPasswordModal
        open={open}
        handleClose={handleClose}
        params={params}
      />
    </Box>
  );
}

export default memo(ResetOptions);
