import { memo } from "react";
import { Tooltip, Chip } from "@mui/material";
import { APP_CONSTANTS } from "../../Constants";
import { BallSpinner } from "react-spinners-kit";
import { useTheme } from "@mui/material/styles";

function HitlistTableOpportunityFactor(props) {
  const { value, loading } = props;

  const theme = useTheme();

  const getColor = (value) => {
    if (loading) return "default";
    if (value >= 65) return "error";
    if (value >= 50 && value < 65) return "warning";
    if (value >= 40 && value < 50) return "info";
    if (value < 40 && value > 0) return "success";
    return "default";
  };

  switch (loading === APP_CONSTANTS.LOADING_TYPE_VOLUME || loading === APP_CONSTANTS.LOADING_TYPE_ENHANCE) {
    case true:
      return <BallSpinner size={14} color={theme.palette.muted} />;
    case false:
      return (
        <Tooltip
          title={`Opportunity Factor`}
          arrow
          enterDelay={300}
          enterNextDelay={600}
        >
          <span>
            <Chip
              color={getColor(value)}
              sx={{
                height: 16,
                width: 16,
                fontSize: 9,
                "& .MuiChip-label": {
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: "1px",
                },
              }}
              label={value}
            ></Chip>
          </span>
        </Tooltip>
      );
    default:
      return "";
  }
}

export default memo(HitlistTableOpportunityFactor);
