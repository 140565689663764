import { memo } from "react";
import { Typography } from "@mui/material";
function HitlistTableDifficulty(props) {
  const { params } = props;
  return (
    <Typography
      variant={params.value < 40 ? "subtitle2" : "main2"}
      color={params.value < 40 ? "success.main" : "neutral"}
    >
      {params.value}
    </Typography>
  );
}

export default memo(HitlistTableDifficulty);
