import { memo } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";

function HitlistTableActionDelete(props) {
  const { row, handleDelete, showInMenu } = props;

  return (
    <GridActionsCellItem
      disabled={!row.title}
      icon={
        <Tooltip title="Delete idea">
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </Tooltip>
      }
      label="Delete"
      onClick={() => handleDelete(row)}
      showInMenu={showInMenu}
    />
  );
}

export default memo(HitlistTableActionDelete);
