import { useTheme, Stack, Typography, Divider } from "@mui/material";
import { memo, useCallback, useEffect, useState, useRef } from "react";
import packageJson from "../../../package.json";
import { storeValue, STORAGE_KEYS } from "../../Storage";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";

function Version(props) {
  const { color, hideDate, hideVersion, saving } = props;
  const [savedState, setSavedState] = useState(null);

  const theme = useTheme();

  const saveRef = useRef(null);
  const displayTimeout = useRef(null);

  const getTime = () => {
    let formattedTime = new Date().toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "numeric",
      minute: "numeric",
    });
    return formattedTime;
  };

  const handleSaving = useCallback(
    (saving) => {
      if (saving === true) {
        clearTimeout(displayTimeout.current);
        setSavedState("Saving...");
      } else if (saving === false) {
        saveRef.current = getTime();

        displayTimeout.current = setTimeout(() => {
          setSavedState(saveRef.current);
        }, 1500);
      } else {
        displayTimeout.current = setTimeout(() => {
          setSavedState((saveRef.current) ? saveRef.current : getTime());
        }, 1500);
      }
    },
    [displayTimeout]
  );

  useEffect(() => {
    handleSaving(saving);
  }, [saving, handleSaving]);

  useEffect(() => {
    storeValue(STORAGE_KEYS.VERSION, packageJson.version, false);

    console.log(
      "🤖 Hitlist v",
      packageJson.version,
      ". More info @ https://hitlist.ai. A Lifers PTY LTD venture. 🤖"
    );

    handleSaving(false);

    return () => {
      clearTimeout(displayTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="row" alignItems="center">
      {!hideVersion && (
        <Typography
          variant="body2"
          color={color || theme.palette.divider}
          sx={{ mt: 0.5 }}
        >
          v{packageJson.version}
        </Typography>
      )}
      {savedState !== null && !hideDate && (
        <Stack direction="row" alignItems="center" sx={{ width: "auto" }}>
          {!hideVersion && (
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{ height: 28, mx: 1.5 }}
            />
          )}
          <CloudDoneOutlinedIcon
            fontSize="small"
            variant="inherit"
            sx={{ mr: 1, color: theme.palette.divider }}
          />
          <Typography
            variant="body2"
            color={theme.palette.divider}
            sx={{ mt: 0.5 }}
          >
            {savedState}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

export default memo(Version);
