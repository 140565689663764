import axios from "axios";
import axiosRetry from "axios-retry";
import { CustomEvents, publish } from "../Hitlist/Events/CustomEvents";
import { STORAGE_KEYS, getValue } from "../Storage";
import { throwError } from "../ErrorHandler/ErrorHandler";

const apiKey = process.env.REACT_APP_SPYFU_KEY;
const minCredit = 1;

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  baseURL: process.env.REACT_APP_SPYFU_API,
});

const retryCondition = (error) => {
  console.error("🚀 ~ file: OpenAiApi.js:34 ~ error:", error);
  return (
    axiosRetry.isNetworkError(error) ||
    axiosRetry.isRetryableError(error) ||
    error.code === "ECONNABORTED" ||
    error.response.status === 429 ||
    error.response.status === 500
  );
};

axiosRetry(api, {
  retries: 5,
  retryCondition: retryCondition,
  shouldResetTimeout: true,
});

const getCredits = async () => {
  return getValue(STORAGE_KEYS.CREDITS) || null;
};

const logTokens = (response) => {
  console.log("🚀 ~ file: SpyFuApi.js:238 ~ response:", response);

  const tokenFactor = parseInt(process.env.REACT_APP_SPYFU_MULTIPLE); // how much more expensive than the baseline of GPT3.5

  if (!response) return;
  if (!response.data) return;
  if (!response.data.results) return;

  if (response.data.results.length > 0) {
    console.log("RAW TOKENS: ", response.data.resultCount);

    publish(CustomEvents.TOKENS_USED, {
      date: new Date(),
      model: "spyfu-v2",
      tokens: {
        prompt_tokens: 0,
        completion_tokens: response.data.resultCount * tokenFactor,
        total_tokens: response.data.resultCount * tokenFactor,
      },
    });
  }
};

const SpyFuAPI = {
  COUNTRY_CODES: {
    AU: "AU",
    BR: "BR",
    CA: "CA",
    DE: "DE",
    ES: "ES",
    FR: "FR",
    GB: "UK",
    IE: "IE",
    IN: "IN",
    IT: "IT",
    MX: "MX",
    NL: "NL",
    SG: "SG",
    UK: "UK",
    US: "US",
  },
  SORT_BY: {
    SearchVolume: "SearchVolume",
    RankingDifficulty: "RankingDifficulty",
    Rank: "Rank",
    RankChange: "RankChange",
    SeoClicks: "SeoClicks",
    SeoClicksChange: "SeoClicksChange",
    PercentMobileSearches: "PercentMobileSearches",
    PercentDesktopSearches: "PercentDesktopSearches",
    PercentNotClicked: "PercentNotClicked",
    PercentPaidClicks: "PercentPaidClicks",
    PercentOrganicClicks: "PercentOrganicClicks",
    BroadCostPerClick: "BroadCostPerClick",
    ExactCostPerClick: "ExactCostPerClick",
    PhraseCostPerClick: "PhraseCostPerClick",
    BroadMonthlyCost: "BroadMonthlyCost",
    ExactMonthlyCost: "ExactMonthlyCost",
    PhraseMonthlyCost: "PhraseMonthlyCost",
    TotalMonthlyClicks: "TotalMonthlyClicks",
    PaidCompetitors: "PaidCompetitors",
    RankingHomepages: "RankingHomepages",
  },
  SORT_ORDER: {
    Ascending: "Ascending",
    Descending: "Descending",
  },
  /**
   * Method to get the keywords that their clicks provide the most value to a given domain
   * Use try catch when calling this method to catch errors
   * @method getMostValuableKeywords
   * @param {String} url
   * @param {Int} pageSize
   * @param {String} country Any of: AU | BR | CA | DE | ES | FR | IE | IN | IT | MX | NL | SG | UK | US
   * @param {String} sortBy Any of: SearchVolume | RankingDifficulty | Rank | RankChange | SeoClicks | SeoClicksChange | PercentMobileSearches | PercentDesktopSearches | PercentNotClicked | PercentPaidClicks | PercentOrganicClicks | BroadCostPerClick | ExactCostPerClick | PhraseCostPerClick | BroadMonthlyCost | ExactMonthlyCost | PhraseMonthlyCost | TotalMonthlyClicks | PaidCompetitors | RankingHomepages
   * @param {String} sortOrder Any of: Ascending | Descending
   * @param {Boolean} adultFilter
   * @returns {Object} response.data
   */
  getMostValuableKeywords: async (
    signal,
    url,
    pageNumber = 1,
    pageSize = 10,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US,
    sortBy = SpyFuAPI.SORT_BY.SearchVolume,
    sortOrder = SpyFuAPI.SORT_ORDER.Descending,
    adultFilter = false,
    includeTerms = ""
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry] || targetCountry;

      if (availableCredits >= minCredit) {
        try {
          const response = await api.get(
            `/serp_api/v2/seo/getMostValuableKeywords?api_key=${apiKey}&query=${url}&sortBy=${sortBy}&sortOrder=${sortOrder}&startingRow=${pageNumber}&pageSize=${pageSize}&countryCode=${targetCountry}&adultFilter=${adultFilter}&includeTerms=${includeTerms}&includeAnyTerm=true&excludeHomepageKeywords=true`,
            { signal: signal }
          );

          console.log("🚀 ~ file: SpyFuApi.js:108 ~ response:", response);

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
      throw e;
    }
  },
  /**
   * Method to get top SEO competitors of a given domain
   * Use try catch when calling this method to catch errors
   * @param {String} url
   * @param {Int} pageSize
   * @param {String} country
   * @returns {Object}
   */
  getTopSEOCompetitors: async (
    signal,
    url,
    pageNumber = 1,
    pageSize = 10,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry =
        SpyFuAPI.COUNTRY_CODES[targetCountry.toUpperCase()] ||
        SpyFuAPI.COUNTRY_CODES.US;

      if (availableCredits >= minCredit) {
        try {
          const response = await api.get(
            `/competitors_api/v2/seo/getTopCompetitors?api_key=${apiKey}&domain=${url}&startingRow=${
              pageSize * (pageNumber - 1) + 1
            }&pageSize=${pageSize}&countryCode=${targetCountry}`,
            { signal: signal }
          );

          console.log("🚀 ~ file: SpyFuApi.js:145 ~ response:", response);

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:221 ~ e", e);
      throw e;
    }
  },
  /**
   * Method to get the top PPC competitors of a given domain
   * Use try catch when calling this method to catch errors
   * @param {String} url
   * @param {Int} pageSize
   * @param {String} country
   * @returns {Object}
   */
  getTopPPCCompetitors: async (
    signal,
    url,
    pageSize = 25,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry];

      if (availableCredits >= minCredit) {
        try {
          const response = await api.get(
            `/competitors_api/v2/ppc/getTopCompetitors?api_key=${process.env.REACT_APP_SPYFU_KEY}&domain=${url}&startingRow=1&pageSize=${pageSize}&countryCode=${targetCountry}`,
            { signal: signal }
          );

          console.log(
            "🚀 ~ file: SpyFuApi.js:175 ~ getTopPPCCompetitors: ~ response:",
            response
          );

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:276 ~ e", e);
      throw e;
    }
  },
  /**
   * Method to get the most successful paid keywords of a given domain
   * Use try catch when calling this method to catch errors
   * @param {String} url
   * @param {Int} pageSize
   * @param {String} country
   * @param {String} excludedDomain
   * @param {String} sortBy
   * @param {Boolean} adultFilter
   * @returns {Object}
   */
  getTopPPCKeywords: async (
    signal,
    url,
    pageSize = 10,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US,
    excludedDomain = "",
    sortBy = SpyFuAPI.SORT_BY.SearchVolume,
    adultFilter = false
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry];

      if (availableCredits >= minCredit) {
        try {
          const response = await api.get(
            `/keyword_api/v2/ppc/getMostSuccessful?query=${url}&excludeDomain=${excludedDomain}&sortBy=${sortBy}&startingRow=1&pageSize=${pageSize}&countryCode=${targetCountry}&adultFilter=${adultFilter}`,
            { signal: signal }
          );

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:332 ~ e", e);
      throw e;
    }
  },
  /**
   * Method to get the most up to date stats for a given domain
   * Use try catch when calling this method to catch errors
   * @param {String} url
   * @param {String} country
   * @returns {Object}
   */
  getLatestDomainStats: async (signal, url, targetCountry = "US") => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry];

      if (availableCredits >= minCredit) {
        try {
          const response = await api.get(
            `/domain_stats_api/v2/GetLatestDomainStats?domain=${url}&countryCode=${targetCountry}`,
            { signal: signal }
          );

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:376 ~ e", e);
      throw e;
    }
  },
  /**
   * Method to retrieve questions for a keyword
   * Use try catch when calling this method to catch errors
   * @method getQuestionKeywords
   * @param {String} keyword
   * @param {Int} pageSize
   * @param {String} country
   * @param {Boolean} adultFilter
   * @returns {Object} response.data
   */
  getQuestionKeywords: async (
    signal,
    keyword,
    pageNumber = 1,
    pageSize = 10,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US,
    sortBy = SpyFuAPI.SORT_BY.SearchVolume,
    sortOrder = SpyFuAPI.SORT_ORDER.Descending,
    adultFilter = false
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry];

      if (availableCredits >= minCredit) {
        try {
          const response = await api.get(
            `/keyword_api/v2/related/getQuestionKeywords?api_key=${
              process.env.REACT_APP_SPYFU_KEY
            }&query=${keyword}&sortBy=${sortBy}&sortOrder=${sortOrder}&startingRow=${
              pageSize * (pageNumber - 1) + 1
            }&pageSize=${pageSize}&countryCode=${targetCountry}&adultFilter=${adultFilter}`,
            { signal: signal }
          );

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:436 ~ e", e);
      throw e;
    }
  },
  /**
   * Method to retrieve related keywords for a keyword
   * Use try catch when calling this method to catch errors
   * @method getRelatedKeywords
   * @param {String} keyword
   * @param {Int} pageSize
   * @param {String} country
   * @param {Boolean} adultFilter
   * @returns {Object} response.data
   */
  getRelatedKeywords: async (
    signal,
    keyword,
    pageSize = 10,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US,
    sortBy = SpyFuAPI.SORT_BY.SearchVolume,
    sortOrder = SpyFuAPI.SORT_ORDER.Descending,
    adultFilter = false
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry];

      if (availableCredits >= minCredit) {
        try {
          const response = await api.get(
            `/keyword_api/v2/related/getRelatedKeywords?api_key=${process.env.REACT_APP_SPYFU_KEY}&query=${keyword}&sortBy=${sortBy}&sortOrder=${sortOrder}&startingRow=1&pageSize=${pageSize}&countryCode=${targetCountry}&adultFilter=${adultFilter}`,
            { signal: signal }
          );

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:491 ~ e", e);
      throw e;
    }
  },

  getTransactionKeywords: async (
    signal,
    keyword,
    pageNumber = 1,
    pageSize = 10,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US,
    sortBy = SpyFuAPI.SORT_BY.SearchVolume,
    sortOrder = SpyFuAPI.SORT_ORDER.Descending,
    adultFilter = false
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry];

      if (availableCredits >= minCredit) {
        try {
          const response = await api.get(
            `/keyword_api/v2/related/getTransactionKeywords?api_key=${
              process.env.REACT_APP_SPYFU_KEY
            }&query=${keyword}&sortBy=${sortBy}&sortOrder=${sortOrder}&startingRow=${
              pageSize * (pageNumber - 1) + 1
            }&pageSize=${pageSize}&countryCode=${targetCountry}&adultFilter=${adultFilter}`,
            { signal: signal }
          );

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:542 ~ e", e);
      throw e;
    }
  },

  getKeywordInformation: async (
    signal,
    keyword,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry];

      if (availableCredits >= minCredit) {
        try {
          const response = await api.get(
            `/keyword_api/v2/related/getKeywordInformation?api_key=${process.env.REACT_APP_SPYFU_KEY}&keywords=${keyword}&countryCode=${targetCountry}`,
            { signal: signal }
          );

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:513 ~ e:", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:584 ~ e", e);
      throw e;
    }
  },
};

export default SpyFuAPI;
