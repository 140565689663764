import { memo } from "react";
import { ThemeProvider, Grid, Box } from "@mui/material";
import LoginOptions from "./LoginOptions/LoginOptions";
import LoginImage from "./LoginImage/LoginImage";
import Version from "../Header/Version/Version";

const Login = (props) => {
  const { theme, loggedIn, loggingIn, initialized } = props;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box position="absolute" sx={{ right: 30, top: 20 }}>
          <Version color="#dfdfdf" hideDate={true} />
        </Box>
        <Grid
          id="login-container"
          container
          spacing={0}
          columns={16}
          backgroundColor="background.paper"
        >
          <Grid item className="login-image" xs={9}>
            <LoginImage />
          </Grid>
          <Grid
            item
            className="login-form"
            xs={7}
            sx={{
              height: "100vh",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoginOptions
              loggedIn={loggedIn}
              loggingIn={loggingIn}
              initialized={initialized}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default memo(Login);
