import { isValidElement, memo } from "react";
import {
  useGridSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
} from "@mui/x-data-grid-premium";
import { Tooltip, IconButton } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";

function HitlistTableIdeas(props) {
  const { id, apiRef, value: isExpanded, loading } = props;

  const theme = useTheme();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <Tooltip title={isExpanded ? "Close Ideas" : "Get Ideas"}>
      <span>
        <IconButton
          size="small"
          tabIndex={-1}
          disabled={loading !== false || !hasDetail} 
          aria-label={isExpanded ? "Close" : "Open"}
        >
          <TipsAndUpdatesOutlinedIcon
            sx={{
              color: isExpanded
                ? theme.palette.primary.main
                : theme.palette.text.main,
              transition: (theme) =>
                theme.transitions.create("transform", {
                  duration: theme.transitions.duration.shortest,
                }),
            }}
            fontSize="inherit"
          />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default memo(HitlistTableIdeas);
