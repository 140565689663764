import { memo } from "react";
import { Tooltip, Link } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';

function HitlistTableSource(props) {
  const { value, row } = props;

  const theme = useTheme();

  return (value && row.initialized) ? (
    <Tooltip title="Open source material" arrow enterDelay={1000} enterNextDelay={1000}>
      <Link href={value} target="_blank" rel="noreferrer">
        <InsertLinkOutlinedIcon
          fontSize="10px"
          sx={{ mt: 1, color: theme.palette.text.primary }}
        />
      </Link>
    </Tooltip>
  ) : (
    ""
  );
}

export default memo(HitlistTableSource);
