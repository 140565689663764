import { GPT3, GPT4 } from "../Apis/OpenAiApi";
import StringUtils from "../Utils/StringUtils";

const topicSystemInstruction = {
  role: "system",
  content: `You are a friendly AI who helps a user refine a topic for them to write a series of blog articles on. You always ask and wait for the users confirmation after you reply. When a topic is confirmed, you summarize the agreed topic into a medium-tail keyword, and reply with a valid JSON object in this exact format always using double quotes to surround nodes: { "topic": "summarized topic", "reply": "reply. I have added it as the topic for your hitlist."}`,
};

const topicSummaryInstruction = {
  role: "system",
  content: `You are an AI that only replies in Arrays. You summarize more extended topics into a medium-tail keyword. Return the data as an Array with a single item. You will reply in this exact format [long-tail keyword]`,
};

let topicChatHistory = [topicSystemInstruction];

let topicSummaryHistory = [topicSummaryInstruction];

const resetTopicChatHistory = () => {
  topicChatHistory = [topicSystemInstruction];
};

const resetTopicSummaryHistory = () => {
  topicSummaryHistory = [topicSummaryInstruction];
};

const addToTopicContext = (msg) => {
  topicChatHistory.push({
    role: "assistant",
    content: msg,
  });
};

// TODO: Provide a toggle for this in the future for the user
const botVersion = 1; // 1 = GPT3, 2 = GPT4
const maxLength = 250; // max = 2048

/**
 * Function to discuss a topic with the user and figure out a niche for them
 * @param {String} msg The message to discuss the topic of
 * @param {Int} version The version of the bot to use (1 = GPT3, 2 = GPT4)
 * @returns {*} The response from the bot (either a string or a JSON object)
 */
const topicChat = async (signal, msg, reset = false, version = botVersion, allowance = maxLength) => {
  if (!signal) throw new Error("No signal provided to topicChat function");

  const trainingData = ``;

  // For GPT3, the system message is not used by the model very much, and so it should be included in the prompt as well. For GPT4, it takes the system message into account more, so it should be left out of the prompt
  let userPrompt = {
    role: "user",
    content:
      version === 2
        ? `${trainingData} ${msg}`
        : topicChatHistory.length > 2
        ? `${trainingData} ${msg}`
        : `${trainingData} ${topicSystemInstruction.content}: ${msg}`,
  };

  if (reset) resetTopicChatHistory();

  topicChatHistory.push(userPrompt);
  console.log("🚀 ~ file: TopicBot.js:62 ~ topicChat ~ topicChatHistory:", topicChatHistory)

  try {
    let response =
      version === 2
        ? await GPT4.generateChat(signal, topicChatHistory, 0.7, allowance)
        : await GPT3.generateChat(signal, topicChatHistory, 0.7, allowance);

    if (!response) throw new Error("Response doesn't look valid: ", response);
    if (!response.choices.length > 0)
      throw new Error("No response found: ", response);
    if (response.choices[0].finish_reason !== "stop")
      throw new Error("Response didn't finish: ", response);

    let rawResponse = response.choices[0].message.content.trim();

    let endResponse = StringUtils.extractObjectFromString(rawResponse);

    // Clean up the response
    if (endResponse === null) {
      rawResponse = StringUtils.convertNewLines(rawResponse);
      rawResponse = StringUtils.removeQuotes(rawResponse);
      rawResponse = StringUtils.removeBackSlashes(rawResponse);
    } else {
      endResponse.reply = StringUtils.convertNewLines(endResponse.reply);
      endResponse.reply = StringUtils.removeQuotes(endResponse.reply);
      endResponse.reply = StringUtils.removeBackSlashes(endResponse.reply);
    }

    const returnResponse = endResponse !== null ? endResponse : rawResponse;

    topicChatHistory.push({
      role: "assistant",
      content: JSON.stringify(returnResponse),
    });

    return returnResponse;
  } catch (err) {
    // TODO: Do we reset the chat history here?
    // resetTopicChatHistory();
    throw err.message;
  }
};

// a function to reduce a sentence down into a single SEO friendly topic
const getTopicSummary = async (topic, reset = true, version = botVersion, allowance = maxLength) => {
  if (!topic) throw new Error("No topic provided to getTopicSummary function");
  if (reset) resetTopicSummaryHistory();

  topicSummaryHistory.push({
    role: "user",
    content: topic,
  });

  const controller = new AbortController();
  const signal = controller.signal;

  try {
    let response =
      version === 2
        ? await GPT4.generateChat(signal, topicSummaryHistory, 0.7, allowance)
        : await GPT3.generateChat(signal, topicSummaryHistory, 0.7, allowance);

    if (!response) throw new Error("Response doesn't look valid: ", response);

    if (!response.choices.length > 0)
      throw new Error("No response found: ", response);

    if (response.choices[0].finish_reason !== "stop")
      throw new Error("Response didn't finish: ", response);

    let rawResponse = response.choices[0].message.content.trim();
    let endResponse = StringUtils.extractArrayFromString(rawResponse);
    
    let returnResponse = endResponse !== null ? endResponse[0] : rawResponse;

    topicSummaryHistory.push({
      role: "assistant",
      content: JSON.stringify(returnResponse),
    });

    return returnResponse;
  } catch (err) {
  } finally {
    if (reset) resetTopicSummaryHistory();
  }
};

export { topicChat, addToTopicContext, resetTopicChatHistory, resetTopicSummaryHistory, getTopicSummary };
