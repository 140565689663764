import { Grid } from "@mui/material";
import HitlistTableMoreArticles from "./HitlistTableMoreArticles";
import HitlistTableMoreKeywords from "./HitlistTableMoreKeywords";
import HitlistTableMoreTitles from "./HitlistTableMoreTitles";
import { memo } from "react";

function HitlistTableMoreDetails(props) {
  const { data, onTitleUpdate, onKeywordsUpdate, onArticleUpdate } = props;
  return (
    <Grid
      container
      className="more-details-panel"
      spacing={4}
      columns={24}
      sx={{ pt: 2, pb: 3, px: 2 }}
    >
      <Grid item xs={9}>
        <HitlistTableMoreTitles
          row={data.row}
          title={data.row.title}
          link={data.row.link}
          onTitleUpdate={onTitleUpdate}
        />
      </Grid>
      <Grid item xs={6}>
        <HitlistTableMoreKeywords
          data={data}
          onKeywordsUpdate={onKeywordsUpdate}
        />
      </Grid>
      <Grid item xs={9}>
        <HitlistTableMoreArticles
          data={data}
          onArticleUpdate={onArticleUpdate}
        />
      </Grid>
    </Grid>
  );
}

export default memo(HitlistTableMoreDetails);
