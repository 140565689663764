import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack, Tooltip, Typography } from "@mui/material";

function HitlistTableHeading(props) {
  const { heading, tooltip } = props;

  return (
    <Stack alignItems="center" direction="row">
      {heading && heading.length > 0 && (
        <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
          {heading}
        </Typography>
      )}
      {tooltip && tooltip.length > 0 && (
        <Tooltip title={tooltip}>
          <InfoOutlinedIcon fontSize="7px" sx={{ mt: -0.125, ml: 0.375 }} />
        </Tooltip>
      )}
    </Stack>
  );
}

export default HitlistTableHeading;
