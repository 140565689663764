import { memo, useEffect, useState, useRef } from "react";
import {
  Box,
  ThemeProvider,
  Grid,
  Typography,
  Card,
  Stack,
  Button,
  Chip,
} from "@mui/material";
import SettingsHeading from "../Settings/SettingsHeading";
import SettingsMenu from "../Settings/SettingsMenu";
import { useTranslation } from "react-i18next";
import Userfront from "@userfront/core";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import { HitlistAPI } from "../Apis/HitlistAPI";

function AddCredits(props) {
  const { loggedIn, theme } = props;

  const [products, setProducts] = useState([]);

  const { t } = useTranslation();

  const apiControllerRef = useRef([]);

  const bundleRef = useRef([]);

  const handlePay = (link) => {
    window.location.href = `${link}?prefilled_email=${Userfront.user.email}`;
  };

  const getBundles = async () => {
    const controller = new AbortController();
    apiControllerRef.current.push(controller);

    try {
      const bundles = await HitlistAPI.getBundleProducts(controller.signal);

      if (bundles) {
        // order them by credits
        bundles.sort((a, b) => a.credits - b.credits);

        // loop through bundle amounts and match them again bundles from api then update the bundle amounts with the price, product id, and link and then add all details to bundleRef

        bundles.forEach((b) => {
          let bundle = {};
          bundle.name = b.product_name;
          bundle.price = b.price;
          bundle.product = b.id;
          bundle.link = b.product_link;
          bundle.includes = [
            `${(b.price / b.credits).toFixed(3)} per credit`,
            "Never expire",
          ];
          bundle.credits = b.credits;
          bundle.sub = `${b.credits} credits bundle for HitlistAI`;
          bundle.best = b.credits === "5000";
          bundle.most = b.credits === "500";
          bundleRef.current.push(bundle);
        });

        setProducts(bundleRef.current);
      }
    } catch (error) {}
  };

  useEffect(() => {
    apiControllerRef.current = [];
    getBundles();
    return () => {
      apiControllerRef.current.forEach((controller) => controller.abort());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {loggedIn && (
        <>
          <Grid
            container
            columns={12}
            spacing={2}
            sx={{
              px: 2,
              height: "calc(100vh - 50px)",
              pt: 3,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Grid item xs={3}>
              <SettingsMenu theme={theme} />
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  mb: 4,
                  pr: 2,
                  scrollBehavior: "smooth",
                  overflowY: "auto",
                  height: "calc(100vh - 110px)",
                }}
              >
                <Box
                  id="application-settings"
                  sx={{
                    backgroundColor: theme.palette.background.main,
                    border: `1px solid ${theme.palette.divider}`,
                    px: 2,
                    pt: 2.5,
                    pb: 3,
                    mb: 2,
                  }}
                >
                  <SettingsHeading heading={t("hitlist.add.heading")} />
                  <Box>
                    <Typography variant="body">
                      {t("hitlist.add.message")}
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      sx={{ px: 3, my: 4 }}
                      columns={3}
                      justifyContent={"center"}
                    >
                      {products.map((bundle, index) => (
                        <Grid item xs={1} key={`grid-${index}`}>
                          <Card
                            variant="outlined"
                            sx={{ px: 4, pt: 2, pb: 5 }}
                          >
                            <Box sx={{ mb: 2 }}>
                              {bundle.best && (
                                <Chip
                                  size="small"
                                  color="primary"
                                  icon={<StarOutlinedIcon />}
                                  label={bundle.best ? "Best Deal" : ""}
                                  sx={{
                                    fontSize: 11,
                                    opacity: bundle.best ? 1 : 0,
                                  }}
                                ></Chip>
                              )}
                              {bundle.most && (
                                <Chip
                                  size="small"
                                  color={"error"}
                                  icon={<LocalFireDepartmentOutlinedIcon />}
                                  label={bundle.most ? "Most Popular" : ""}
                                  sx={{ fontSize: 11 }}
                                ></Chip>
                              )}
                              {!bundle.best && !bundle.most && (
                                <Chip
                                  size="small"
                                  label=""
                                  sx={{ fontSize: 11, opacity: 0 }}
                                ></Chip>
                              )}
                            </Box>
                            <Typography
                              variant="body"
                              sx={{
                                fontSize: 18,
                                fontWeight: 500,
                                lineHeight: 1.1,
                              }}
                            >
                              {bundle.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="muted"
                              sx={{ mt: 0.5 }}
                            >
                              {bundle.sub}
                            </Typography>
                            <Typography
                              variant="h4"
                              sx={{ fontWeight: 600, mt: 2 }}
                            >
                              ${bundle.price}
                            </Typography>
                            <Button
                              variant="contained"
                              fullWidth
                              color="secondary"
                              sx={{ mt: 2 }}
                              size="large"
                              onClick={() => handlePay(bundle.link)}
                            >
                              Pay
                            </Button>
                            <Typography sx={{ mt: 2 }} variant="body2">
                              This includes:
                            </Typography>
                            <Stack spacing={1} sx={{ mt: 1 }}>
                              {bundle.includes.map((include, index) => (
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  key={`stack-${index}`}
                                >
                                  <CheckCircleOutlineOutlinedIcon fontSize="11px" />
                                  <Typography variant="body2">
                                    {include}
                                  </Typography>
                                </Stack>
                              ))}
                            </Stack>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </ThemeProvider>
  );
}

export default memo(AddCredits);
