import { Grid, Chip, Typography, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { memo } from "react";

function HintsDisplay(props) {
  const {
    tip,
    hints,
    getMore,
    handleClick,
    handleDelete,
    handleMoreLikeThis,
    viewOnly,
  } = props;

  const theme = useTheme();

  const handleMoreClick = (e, label) => {
    e.stopPropagation();
    e.preventDefault();
    handleMoreLikeThis(label);
  };

  return (
    <Grid item xs={16}>
      {Array.isArray(hints) && hints.length ? (
        hints.map((item, index) => (
          <Tooltip title={tip} key={index}>
            <span>
              <Chip
                className="hint-chip"
                color="primary"
                icon={
                  !viewOnly && (
                    <Tooltip title="More like this">
                      <AddCircleOutlinedIcon
                        fontSize="small"
                        sx={{ display: !getMore ? "none" : "flex", p: "1px" }}
                        onClick={(e) => handleMoreClick(e, item.label)}
                      />
                    </Tooltip>
                  )
                }
                deleteIcon={
                  <CancelOutlinedIcon
                    sx={{
                      color: `${theme.palette.secondary.contrastText} !important`,
                      display: viewOnly ? "none" : "flex",
                    }}
                  />
                }
                sx={{
                  mr: 1,
                  mb: 0.75,
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: "4px",
                  color: theme.palette.secondary.contrastText,
                }}
                size="small"
                key={index}
                label={item.label}
                value={item.label}
                onClick={handleClick}
                onDelete={handleDelete(item)}
                disabled={viewOnly}
              ></Chip>
            </span>
          </Tooltip>
        ))
      ) : (
        <Typography></Typography>
      )}
    </Grid>
  );
}

export default memo(HintsDisplay);
