import { memo } from "react";
import { Tooltip } from "@mui/material";
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";

function HitlistTableActionPull(props) {
  const { handlePull, row, generating } = props;
  return (
    <GridActionsCellItem
      icon={
        <Tooltip title="Send back to Research" enterDelay={300} enterNextDelay={600}>
          <ReadMoreOutlinedIcon fontSize="medium" sx={{ transform: "rotate(180deg)" }} />
        </Tooltip>
      }
      label="Pull"
      onClick={() => {
        handlePull(row);
      }}
    />
  );
}

export default memo(HitlistTableActionPull);
