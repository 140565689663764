import { memo, useState, useRef, useEffect } from "react";
import {
  Stack,
  Button,
  Divider,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined";
import MergeTypeOutlinedIcon from "@mui/icons-material/MergeTypeOutlined";
import JoinInnerOutlinedIcon from "@mui/icons-material/JoinInnerOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined";
import HitlistTableWarningDialogue from "./HitlistTableWarningDialogue";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import {
  CustomEvents,
  publish,
  subscribe,
  unsubscribe,
} from "../../Events/CustomEvents";
import { ToastEvents } from "../../../Toast/Toast";
import { getValue, STORAGE_KEYS } from "../../../Storage";

function HitlistTableCustomToolbar(props) {
  const {
    apiRef,
    condensedRows,
    showColumns,
    disabled,
    handleBulkDelete,
    handleBulkEnhance,
    handleBulkKeywords,
    handleBulkRemoveSimilar,
    handleBulkRewriteTitles,
    handleBulkStarred,
    handleCombineRows,
    handleCondenseRows,
    handleShowColumns,
    handleBulkPush,
    handleBulkPull,
    rows,
    viewOnly,
    columnVisibility,
  } = props;

  const [warningOpen, setWarningOpen] = useState(false);
  const [warningTitle, setWarningTitle] = useState("");
  const [warningMsg, setWarningMsg] = useState("");
  const [warningYes, setWarningYes] = useState("");
  const [warningNo, setWarningNo] = useState("");
  const [warningType, setWarningType] = useState("");
  const [rowCount, setRowCount] = useState(rows.length || 0);
  const [starCount, setStarCount] = useState(0);

  const combineYesRef = useRef(() => handleCombineRows());
  const deleteYesRef = useRef(() => handleBulkDelete());
  const similarYesRef = useRef(() => handleBulkRemoveSimilar());
  const starRef = useRef(0);

  const theme = useTheme();

  const shareUrl = `${window.location.protocol}//${
    window.location.host
  }/view?id=${getValue(STORAGE_KEYS.ID)}&share=true`;

  const showDeleteWarning = () => {
    setWarningTitle("Delete Rows");
    setWarningMsg("Are you sure you want to delete the selected rows?");
    setWarningYes("Yes");
    setWarningNo("No");
    setWarningType("delete");
    setWarningOpen(true);
  };

  const showCombineWarning = () => {
    setWarningTitle("Combine Rows");
    setWarningMsg("Are you sure you want to combine the selected rows?");
    setWarningYes("Yes");
    setWarningNo("No");
    setWarningType("combine");
    setWarningOpen(true);
  };

  const showShareWarning = () => {
    setWarningTitle(
      `Share ${getValue(STORAGE_KEYS.TITLE)} Hitlist (View Only)`
    );
    setWarningMsg(
      `Copy the link below to share this hitlist in view only mode. Please note anyone on the internet with the link will be able to view this hitlist.</p><br/><br/><a style="fontSize: 14px" href="${shareUrl}" target="_blank">${shareUrl}</a>`
    );
    setWarningYes("Copy Link");
    setWarningNo("Cancel");
    setWarningType("share");
    setWarningOpen(true);
    handleShareYes();
  };

  const showSimilarWarning = () => {
    setWarningTitle("Remove Similar Rows?");
    setWarningMsg("Are you sure you want to remove similar rows?");
    setWarningYes("Yes");
    setWarningNo("No");
    setWarningType("similar");
    setWarningOpen(true);
  };

  const handleShareYes = () => {
    navigator.clipboard.writeText(shareUrl);
    publish(ToastEvents.SUCCESS, "Link copied to clipboard");
  };

  const handleWarningYes = () => {
    switch (warningType) {
      case "delete":
        deleteYesRef.current();
        setWarningOpen(false);
        break;
      case "combine":
        combineYesRef.current();
        setWarningOpen(false);
        break;
      case "similar":
        similarYesRef.current();
        setWarningOpen(false);
        break;
      case "share":
        handleShareYes();
        break;
      default:
        break;
    }
  };

  const handleWarningClose = () => {
    setWarningOpen(false);
  };

  useEffect(() => {
    if (!rows) return;

    // count how many rows are starred
    let count = 0;
    rows.forEach((row) => {
      if (row.starred) {
        count++;
      }
    });

    starRef.current = count;

    setStarCount(starRef.current);
    setRowCount(rows ? rows.length : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    subscribe(CustomEvents.SHARE, showShareWarning);
    return () => {
      unsubscribe(CustomEvents.SHARE, showShareWarning);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <>
      <GridToolbarContainer sx={{ mt: 0.5 }}>
        <Stack direction="row">
          {!viewOnly && (
            <>
              <GridToolbarQuickFilter sx={{ mr: 3 }} />
              <Divider flexItem orientation="vertical" variant="middle" />
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mx: 2 }}
              >
                {/* <Tooltip
                  title="Remove similar rows"
                  enterDelay={300}
                  enterNextDelay={600}
                >
                  <span>
                    <Button
                      startIcon={<JoinInnerOutlinedIcon />}
                      size="small"
                      onClick={showSimilarWarning}
                    >
                      <Typography
                        fontSize="inherit"
                        sx={{ mt: 0.25, fontWeight: "500" }}
                      >
                        Similar
                      </Typography>
                    </Button>
                  </span>
                </Tooltip> */}
                <Tooltip
                  title="Merge selected rows"
                  enterDelay={300}
                  enterNextDelay={600}
                >
                  <span>
                    <Button
                      disabled={disabled}
                      startIcon={<MergeTypeOutlinedIcon />}
                      size="small"
                      onClick={showCombineWarning}
                    >
                      <Typography
                        fontSize="inherit"
                        sx={{ mt: 0.25, fontWeight: "500" }}
                      >
                        Combine
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title="Star selected rows"
                  enterDelay={300}
                  enterNextDelay={600}
                >
                  <span>
                    <Button
                      disabled={disabled}
                      startIcon={<StarOutlineOutlinedIcon />}
                      size="small"
                      onClick={handleBulkStarred}
                    >
                      <Typography
                        fontSize="inherit"
                        sx={{ mt: 0.25, fontWeight: "500" }}
                      >
                        Star
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title="Enhance selected rows"
                  enterDelay={300}
                  enterNextDelay={600}
                >
                  <span>
                    <Button
                      disabled={disabled}
                      startIcon={<AutoFixHighOutlinedIcon />}
                      size="small"
                      onClick={handleBulkEnhance}
                    >
                      <Typography
                        fontSize="inherit"
                        sx={{ mt: 0.25, fontWeight: "500" }}
                      >
                        Enhance
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title="Rewrite selected rows titles"
                  enterDelay={300}
                  enterNextDelay={600}
                >
                  <span>
                    <Button
                      disabled={disabled}
                      startIcon={<ReplayOutlinedIcon />}
                      size="small"
                      onClick={handleBulkRewriteTitles}
                    >
                      <Typography
                        fontSize="inherit"
                        sx={{ mt: 0.25, fontWeight: "500" }}
                      >
                        Rewrite
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title="Get more keywords for selected rows"
                  enterDelay={300}
                  enterNextDelay={600}
                >
                  <span>
                    <Button
                      disabled={disabled}
                      startIcon={<KeyOutlinedIcon />}
                      size="small"
                      onClick={handleBulkKeywords}
                    >
                      <Typography
                        fontSize="inherit"
                        sx={{ mt: 0.25, fontWeight: "500" }}
                      >
                        Keywords
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title="Delete selected rows"
                  enterDelay={300}
                  enterNextDelay={600}
                >
                  <span>
                    <Button
                      disabled={disabled}
                      startIcon={<DeleteOutlinedIcon />}
                      size="small"
                      onClick={showDeleteWarning}
                    >
                      <Typography
                        fontSize="inherit"
                        sx={{ mt: 0.25, fontWeight: "500" }}
                      >
                        Delete
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title="Send to Final Hitlist"
                  enterDelay={300}
                  enterNextDelay={600}
                  hidden={!columnVisibility?.push ?? true}
                >
                  <span>
                    <Button
                      disabled={disabled}
                      startIcon={<ReadMoreOutlinedIcon />}
                      size="small"
                      onClick={() =>
                        handleBulkPush(apiRef.current.getSelectedRows())
                      }
                    >
                      <Typography
                        fontSize="inherit"
                        sx={{ mt: 0.25, fontWeight: "500" }}
                      >
                        Send
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title="Send back to Research"
                  enterDelay={300}
                  enterNextDelay={600}
                  hidden={!columnVisibility?.pull ?? true}
                >
                  <span>
                    <Button
                      disabled={disabled}
                      startIcon={
                        <ReadMoreOutlinedIcon
                          sx={{ transform: "rotate(180deg)" }}
                        />
                      }
                      size="small"
                      onClick={() =>
                        handleBulkPull(apiRef.current.getSelectedRows())
                      }
                    >
                      <Typography
                        fontSize="inherit"
                        sx={{ mt: 0.25, fontWeight: "500" }}
                      >
                        Send
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
            </>
          )}
          {!viewOnly && (
            <>
              <Divider flexItem orientation="vertical" variant="middle" />
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mx: 2 }}
              >
                <Tooltip
                  title="Expand/Collapse all rows"
                  enterDelay={300}
                  enterNextDelay={600}
                >
                  <Button
                    startIcon={<TableRowsOutlinedIcon />}
                    size="small"
                    onClick={handleCondenseRows}
                  >
                    <Typography
                      fontSize="inherit"
                      sx={{ mt: 0.25, fontWeight: "500" }}
                    >
                      {condensedRows ? "Collapse" : "Expand"}
                    </Typography>
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Show/Hide extra columns"
                  enterDelay={300}
                  enterNextDelay={600}
                >
                  <Button
                    startIcon={<ViewWeekOutlinedIcon />}
                    size="small"
                    onClick={handleShowColumns}
                  >
                    <Typography
                      fontSize="inherit"
                      sx={{ mt: 0.25, fontWeight: "500" }}
                    >
                      {showColumns ? "Hide" : "Show"}
                    </Typography>
                  </Button>
                </Tooltip>
              </Stack>
            </>
          )}
          {/* <Divider flexItem orientation="vertical" variant="middle" /> */}
          <Stack
            direction="row"
            sx={{ ml: "auto", mr: 1 }}
            spacing={1}
            alignItems="center"
          >
            <Stack direction="row" alignItems="center">
              {!viewOnly && (
                <>
                  <StarOutlinedIcon
                    fontSize="medium"
                    sx={{
                      ml: 1,
                      mr: 0.25,
                      color:
                        starCount > 0
                          ? theme.palette.primary.main
                          : theme.palette.text.main,
                    }}
                  />
                  <Typography variant="subtitle2" sx={{ mt: 0.25 }}>
                    {starCount}
                  </Typography>
                </>
              )}
              <ModeStandbyOutlinedIcon
                fontSize="medium"
                sx={{ ml: 1.5, mr: 0.25 }}
              />
              <Typography variant="subtitle2" sx={{ mt: 0.25 }}>
                {rowCount}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Divider
                flexItem
                orientation="vertical"
                variant="middle"
                sx={{ height: 32, mx: 1 }}
              />

              {!viewOnly && (
                <Tooltip title="Share">
                  <IconButton
                    aria-label="share"
                    component="label"
                    onClick={() => showShareWarning()}
                  >
                    <ShareOutlinedIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </Stack>
      </GridToolbarContainer>
      <HitlistTableWarningDialogue
        open={warningOpen}
        noAction={handleWarningClose}
        yesAction={handleWarningYes}
        title={warningTitle}
        msg={warningMsg}
        yes={warningYes}
        no={warningNo}
      />
    </>
  );
}

export default memo(HitlistTableCustomToolbar);
