function GoogleIcon() {
  return (
    <img
      height="20"
      src={process.env.PUBLIC_URL + "/img/google-icon.png"}
      alt="Google Icon"
    />
  );
}

export default GoogleIcon;
