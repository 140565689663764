import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {
  Stack,
  Badge,
  Menu,
  IconButton,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import { memo, useRef, useState } from "react";
import { HitlistAPI } from "../Apis/HitlistAPI";
import parse from "html-react-parser";
import { STORAGE_KEYS, getValue, storeValue } from "../Storage";

function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const apiControllerRef = useRef([]);
  const unReadRef = useRef(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
    storeValue(STORAGE_KEYS.NOTIFICATIONS_LAST_READ, Date.now());
    setUnreadNotifications(0);
  };

  const _getNotifications = async () => {
    const controller = new AbortController();
    apiControllerRef.current.push(controller);

    const lastReadTime = parseInt(getValue(STORAGE_KEYS.NOTIFICATIONS_LAST_READ)) || 0;

    const notifications = await HitlistAPI.getNotifications(controller.signal);

    if (notifications) {
      // loop through each notification object and use the notification.text property to set the notifications array
      let notificationsArray = [];

      unReadRef.current = 0;

      notifications.forEach((notification) => {
        notificationsArray.push(notification);

        // check the date of the notification against the last read date and if it's newer, increment the unread notifications counter
        if (
          parseInt(notification.posted_date) >
          lastReadTime
        ) {
          unReadRef.current++;
          setUnreadNotifications(unReadRef.current);
        }
      });

      // order the notifications by date
      notificationsArray.sort((a, b) => {
        return parseInt(b.posted_date) - parseInt(a.posted_date);
      });

      setNotifications(notificationsArray);
    }
  };

  useState(() => {
    _getNotifications();

    setAnchorEl(document.querySelector(".notification-icon"));

    let apiCalls = apiControllerRef.current;

    return () => {
      apiCalls.forEach((apiCall) => {
        apiCall.abort();
      });
    };
  }, []);

  return (
    <>
      <Stack direction="row" alignItems="center" className="notification">
        <IconButton
          size="small"
          className="notification-icon"
          onClick={handleClick}
        >
          <Badge badgeContent={unreadNotifications} color="error">
            <NotificationsNoneOutlinedIcon fontSize="medium" />
          </Badge>
        </IconButton>
      </Stack>
      <Menu
        onClose={() => setOpen(false)}
        open={open}
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            sx: {
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              ml: "-128px",
              mt: 0.5,
              textWrap: "wrap",
              maxWidth: "260px",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        {notifications.map((notification, index) => (
          <MenuItem key={index}>
            <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
              <Typography color={"muted"} sx={{ fontSize: 10 }}>
                {new Date(
                  parseInt(notification.posted_date)
                ).toLocaleString() || "JUST NOW"}
              </Typography>
              <Typography variant="body2" sx={{ textWrap: "wrap" }}>
                {parse(notification.notification.text)}
              </Typography>
              <Divider variant="left" sx={{ mt: 1 }} />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default memo(Notification);
