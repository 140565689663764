import { memo } from "react";
import { Typography } from "@mui/material";
import StringUtils from "../../../Utils/StringUtils";
import { APP_CONSTANTS } from "../../Constants";
import { BallSpinner } from "react-spinners-kit";
import { useTheme } from "@mui/material/styles";

function HitlistTableVolume(props) {
  const { value, loading } = props;

  const theme = useTheme();

  switch (loading === APP_CONSTANTS.LOADING_TYPE_VOLUME || loading === APP_CONSTANTS.LOADING_TYPE_ENHANCE) {
    case true:
      return (
        <BallSpinner size={14} color={theme.palette.muted} />
      );
    case false:
      return (
        <Typography
          color={loading || !value ? "muted" : "text"}
          variant="body2"
        >
          {value ? StringUtils.abbreviateNumber(parseInt(value)) : ""}
        </Typography>
      );
    default:
      return "";
  }
}

export default memo(HitlistTableVolume);
