import { memo } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import RepartitionOutlinedIcon from "@mui/icons-material/RepartitionOutlined";

function HitlistTableActionNewKeywords(props) {
  const { row, handleGetKeywords, loading } = props;

  return (
    <GridActionsCellItem
      disabled={loading !== false || row.id === null || row.id === undefined}
      icon={<RepartitionOutlinedIcon fontSize="small" />}
      label={`Replace Keywords`}
      title="Replace keywords for selected article ideas"
      onClick={() => handleGetKeywords(row, false)}
      showInMenu
      sx={{ fontSize: 15 }}
    />
  );
}

export default memo(HitlistTableActionNewKeywords);
