import { memo, useContext, useEffect, useRef, useState } from "react";
import { Pace, Pause, WindupChildren, OnChar } from "windups";
import { AppConfigContext } from "../../../../AppConfigContext";
import { AppThemeContext } from "../../../../AppThemeContext";
import { Avatar, Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import parse from "html-react-parser";
import UserProfileImage from "../../../../User/UserProfileImage";
import StringUtils from "../../../../Utils/StringUtils";
import { CustomEvents, publish } from "../../../Events/CustomEvents";

function MessageBar(props) {
  const { name, msg, align, type, img, pause } = props;
  
  const theme = useTheme();

  let cssName = name.toLowerCase();

  return (
    <Box
      className={`message message-${cssName} message-${type} message-container message-align-${align}`}
      sx={{
        my: 2,
        backgroundColor:
          type === "user" ? theme.palette.background.paper : "white",
        border: type === "user" ? "none" : `1px solid ${theme.palette.divider}`,
        display: "flex",
        alignItems: "flex-start",
        borderRadius: "10px",
        px: align === "right" ? 0 : 2,
        pt: 1.5,
        flexDirection: align === "right" ? "row-reverse" : "row",
      }}
    >
      {type === "user" && <UserProfileImage sx={{ mr: 2 }} />}
      {type === "robot" && (
        <MessagePic src={img} name={name} align={align} type={type} />
      )}

      <MessageText
        className="message-message"
        msg={msg}
        animate={false}
        pause={pause}
      />
    </Box>
  );
}

const MessagePic = (props) => {
  const { align, type, name, src } = props;
  const theme = useContext(AppThemeContext);
  return (
    <Avatar
      sx={{
        width: 40,
        height: 40,
        mr: align === "right" ? 0 : 2,
        ml: align === "right" ? 1.5 : 0,
        border:
          type === "robot" ? `1.5px solid ${theme.borderActiveColor}` : "none",
      }}
    >
      <img width="100%" src={src} alt={name}></img>
    </Avatar>
  );
};

const MessageText = (props) => {
  const { animate, pause, msg } = props;
  const config = useContext(AppConfigContext);
  const [displayMessage, setDisplayMessage] = useState("");

  const proceed = () => {
    // TODO: Emit an event to make the reply box available again
    publish(CustomEvents.CHAT_MESSAGE_FINISHED);
  };

  let parsedMsgRef = useRef(msg);

  useEffect(() => {
    parsedMsgRef.current = StringUtils.convertNewLines(msg);
    setDisplayMessage(parsedMsgRef.current);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (animate) {
    return (
      <WindupChildren onFinished={proceed} key={new Date()}>
        <Pause ms={pause} />
        <Pace
          getPace={(char) => {
            switch (char) {
              case ".":
              case "?":
              case "!":
                return 600;
              case ",":
              case ":":
                return 100;
              default:
                return config.chat.talkingSpeed;
            }
          }}
        >
          <OnChar fn={proceed}>
            <Typography
              className="message-msg"
              variant="subtitle2"
              sx={{ mt: "-3px", mb: "3px" }}
            >
              {parse(displayMessage)}
            </Typography>
          </OnChar>
        </Pace>
      </WindupChildren>
    );
  } else {
    return (
      <Typography
        className="message-msg"
        variant="subtitle2"
        sx={{ mt: "-3px", mb: "3px" }}
      >
        {parse(displayMessage)}
      </Typography>
    );
  }
};

export default memo(MessageBar);
