import { gridStringOrNumberComparator } from "@mui/x-data-grid-premium";

export const nullSorter = (v1, v2, cellParams1, cellParams2) => {
  if (v1 === null || v2 === null) {
    if (v1 === v2) return 0;
    else {
      const sortModel = cellParams1.api.getSortModel();
      const sortColumn = sortModel.find((sm) => sm.field === cellParams1.field);
      if (sortColumn && sortColumn.sort === "desc") {
        if (v1 === null) return -1;
        else return 1;
      } else {
        if (v1 === null) return 1;
        else return -1;
      }
    }
  } else {
    return gridStringOrNumberComparator(v1, v2, cellParams1, cellParams2);
  }
};

export const zeroSorter = (v1, v2, cellParams1, cellParams2) => {
  if (v1 === 0 || v2 === 0) {
    if (v1 === v2) return 0;
    else {
      const sortModel = cellParams1.api.getSortModel();
      const sortColumn = sortModel.find((sm) => sm.field === cellParams1.field);
      if (sortColumn && sortColumn.sort === "desc") {
        if (v1 === 0) return -1;
        else return 1;
      } else {
        if (v1 === 0) return 1;
        else return -1;
      }
    }
  } else {
    return gridStringOrNumberComparator(v1, v2, cellParams1, cellParams2);
  }
};

export const blankSorter = (v1, v2, cellParams1, cellParams2) => {
  if (v1 === "" || v2 === "") {
    if (v1 === v2) return 0;
    else {
      const sortModel = cellParams1.api.getSortModel();
      const sortColumn = sortModel.find((sm) => sm.field === cellParams1.field);
      if (sortColumn && sortColumn.sort === "desc") {
        if (v1 === "") return -1;
        else return 1;
      } else {
        if (v1 === "") return 1;
        else return -1;
      }
    }
  } else {
    return gridStringOrNumberComparator(v1, v2, cellParams1, cellParams2);
  }
};

export const adjustRankingDifficulty = ({ row, value }) => {
  const item = row.data;

  if (!item) return value;
  if (!item.rankingDifficulty) return value;

  let rankingDifficulty = item.rankingDifficulty || 0;
  let searchVolume = item.searchVolume || 0;
  let percentSearchesNotClicked = item.percentSearchesNotClicked || 0;
  let paidCompetitors = item.paidCompetitors || 0;
  let percentPaidClicks = item.percentPaidClicks || 0;
  let percentOrganicClicks = item.percentOrganicClicks || 0;

  let adjustedRankingDifficulty = Math.round(
      rankingDifficulty +
      Math.max(0, searchVolume / 10000) +
      percentSearchesNotClicked * 10 +
      paidCompetitors / 10 +
      (percentPaidClicks - percentOrganicClicks) * 10
  );

  if (adjustedRankingDifficulty < 0) adjustedRankingDifficulty = 0;
  if (adjustedRankingDifficulty > 100) adjustedRankingDifficulty = 100;

  return adjustedRankingDifficulty;
};
