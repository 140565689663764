import { memo, useRef } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ProfileHeading from "./ProfileHeading";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { APP_CONSTANTS } from "../Hitlist/Constants";
import Userfront from "@userfront/core";

import "./ProfileSettings.css";

function ProfileSettings(props) {
  const { theme, handleSaveProfile, heading, updatingProfile, savingPassword } =
    props;

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);

  const { t } = useTranslation();

  return (
    <Box
      id="profile-settings"
      sx={{
        backgroundColor: theme.palette.background.main,
        border: `1px solid ${theme.palette.divider}`,
        px: 2,
        pt: 2.5,
        pb: 3,
        mb: 2,
      }}
    >
      <ProfileHeading heading={heading} />
      <Stack direction="column">
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: "80%" }}
        >
          <TextField
            id="profile-first-name"
            label="First Name"
            defaultValue={Userfront.user.data.firstName}
            variant="standard"
            inputRef={firstNameRef}
            sx={{ width: "50%" }}
          />
          <TextField
            id="profile-last-name"
            label="Last Name"
            defaultValue={Userfront.user.data.lastName}
            variant="standard"
            inputRef={lastNameRef}
            sx={{ width: "50%" }}
          />
        </Stack>
        <TextField
          id="profile-email"
          label="Email"
          defaultValue={Userfront.user.email}
          helperText={
            Userfront.user.data.loginType !== APP_CONSTANTS.LOGIN_TYPE_EMAIL
              ? `You are logged in with your social account. You cannot change your email here.`
              : "All data is tied to your email. You cannot change your email here. If you need to change your email, please contact support."
          }
          variant="standard"
          type="email"
          inputRef={emailRef}
          sx={{ mt: 3, width: "80%" }}
          disabled={true}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
          sx={{ mt: 4 }}
        >
          <LoadingButton
            loading={updatingProfile}
            loadingPosition="start"
            startIcon={<SaveOutlinedIcon />}
            variant="contained"
            color="secondary"
            size="large"
            disabled={savingPassword}
            onClick={() =>
              handleSaveProfile(
                firstNameRef.current.value,
                lastNameRef.current.value,
                emailRef.current.value
              )
            }
            className="flat-button"
          >
            {!savingPassword ? t("Save Profile") : t("Updating profile...")}
          </LoadingButton>
        </Stack>
        {Userfront.user.data.loginType !== APP_CONSTANTS.LOGIN_TYPE_EMAIL && (
          <>
            <Typography
              variant="body2"
              color="muted"
              sx={{ mt: 3, width: "80%" }}
            >
              You are logged in with your social account. You can change your
              name, but cannot change your profile information. Please note any
              changes to the name in your social profile may update this
              information as well.
            </Typography>
          </>
        )}
      </Stack>
    </Box>
  );
}

export default memo(ProfileSettings);
