import { useEffect, memo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = memo(function GridCellExpand(props) {
  const { row, width, value, fontSize, loading, placeholder } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const hoverId = useRef(null);

  const handleMouseEnter = () => {
    if (loading) return;

    const isCurrentlyOverflown = isOverflown(cellValue.current);

    hoverId.current = setTimeout(() => {
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    }, 600);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
    clearTimeout(hoverId.current);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  useEffect(() => {
    if (loading) {
      setShowFullCell(false);
    }
  }, [loading]);

  useEffect(() => {
    return () => {
      clearTimeout(hoverId.current);
    };
  }, []);

  return (
    <>
      <Box
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          alignItems: "center",
          lineHeight: "1.2em",
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
        }}
      >
        <Box
          ref={cellDiv}
          sx={{
            height: "100%",
            width,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        <Box
          ref={cellValue}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: fontSize || "inherit",
            color: value ? "text" : "text.disabled",
          }}
        >
          <Typography fontSize={11} sx={{ my: 0.5, lineHeight: 1.2 }}>
            {row.title ? value || placeholder : ""}
          </Typography>
        </Box>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width: width }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
              <Typography variant="body2" sx={{ p: 1 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </Box>
    </>
  );
});

export default GridCellExpand;
