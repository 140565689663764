import { memo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import parse from "html-react-parser";

function HitlistTableWarningDialogue(props) {
  const { open, noAction, yesAction, title, msg, yes, no } = props;
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={noAction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography id="alert-dialog-description">
          {parse(msg)}
        </Typography>
      </DialogContent>
      <DialogActions sx={{px:3, pb:2}}>
        <Button
          variant="text"
          onClick={noAction}
          size="small"
          sx={{ color: theme.palette.neutral.main }}
        >
          {no}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={yesAction}
          autoFocus
          size="small"
        >
          {yes}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(HitlistTableWarningDialogue);
