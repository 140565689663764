import { memo, useState } from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { getValue, STORAGE_KEYS } from "../../Storage";

function Logo() {
  const [logo] = useState(
    process.env.PUBLIC_URL + "/img/hitlist-logo-full-inverse.png"
  );

  return (
    <Box>
      <Link to={`/hitlist?id=${getValue(STORAGE_KEYS.ID)}`}>
        <img
          height="22"
          style={{ marginTop: "6px" }}
          src={logo}
          alt="Hitlist Tools Logo"
        />
      </Link>
    </Box>
  );
}

export default memo(Logo);
