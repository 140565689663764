import { Button, Tooltip } from "@mui/material";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";

function HitlistTabsFullScreen(props) {
  const { isFullScreen, handleFullScreen } = props;

  return (
    <Tooltip title={isFullScreen ? `Exit Full Screen` : `Full Screen`}>
      <Button
        aria-label={isFullScreen ? "exit full screen" : "enter full screen"}
        component="label"
        onClick={() => handleFullScreen(!isFullScreen)}
        className={isFullScreen ? "exit-full-screen" : "enter-full-screen"}
        variant="text"
        color="neutral"
        endIcon={
          isFullScreen ? (
            <FullscreenExitOutlinedIcon sx={{ fontSize: "28px" }} />
          ) : (
            <FullscreenOutlinedIcon sx={{ fontSize: "28px" }} />
          )
        }
      >
        {isFullScreen ? "MINIMIZE" : "MAXIMIZE"}
      </Button>
    </Tooltip>
  );
}

export default HitlistTabsFullScreen;
