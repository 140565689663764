import { memo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Userfront from "@userfront/core";

function RequireAuth({ children }) {
  let location = useLocation();

  // console.log("🚀 ~ file: RequireAuth.js:11 ~ RequireAuth ~ Userfront.tokens.accessToken:", Userfront.user);

  if (!Userfront.tokens.accessToken) {
    
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default memo(RequireAuth);
