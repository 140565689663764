import {
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  Button,
  LinearProgress,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { memo, useEffect, useState, useRef, useContext } from "react";
import { rewriteTitle } from "../../../../Bots/TitleBot";
import { AppConfigContext } from "../../../../AppConfigContext";
import { useCookies } from "react-cookie";
import { STORAGE_KEYS, getValue } from "../../../../Storage";

function HitlistTableMoreTitles(props) {
  const { row, title, onTitleUpdate } = props;

  const [titleIdeas, setTitleIdeas] = useState([]);
  const [loading, setLoading] = useState(false);

  const apiControllerRef = useRef([]);

  const config = useContext(AppConfigContext);

  const [cookies] = useCookies();

  const getTitleIdeas = async () => {
    if (!title && !row.title) return;

    setLoading(true);

    try {
      const controller = new AbortController();

      let ideas = await rewriteTitle(
        controller.signal,
        title,
        getValue(STORAGE_KEYS.TOPIC) || title,
        cookies.Settings.headlineStyle || config.hitlist.generate.headlineStyle,
        5,
        getValue(STORAGE_KEYS.TARGET_COUNTRY) ||
          cookies.Settings.targetCountry ||
          config.hitlist.generate.targetCountry,
        cookies.Settings.targetLanguage ||
          config.hitlist.generate.targetLanguage,
        true
      );

      if (Array.isArray(ideas) && ideas.length) {
        setTitleIdeas(ideas);
        row.titleIdeas = ideas;
      } else {
        setTitleIdeas(["No ideas found"]);
      }
    } catch (error) {
      setTitleIdeas(["No ideas found"]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!row.titleIdeas && !loading) {
      getTitleIdeas();
    } else {
      setTitleIdeas(row.titleIdeas);
    }

    let apiCalls = apiControllerRef.current;

    return () => {
      apiCalls.forEach((controller) => controller.abort());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Grid container columns={12} alignItems="center" flex>
        <Grid item xs={6}>
          <Typography variant="subtitle2">{`Title Ideas:`}</Typography>
        </Grid>
        <Grid item justifyContent="flex-end" xs={6}>
          <Tooltip title="Refresh Title Ideas">
            <IconButton
              color="neutral"
              size="small"
              onClick={getTitleIdeas}
              sx={{ float: "right" }}
            >
              <RefreshOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
      <LinearProgress
        sx={{ display: loading ? "flex" : "none", width: 100, mt: 3 }}
      />
      <List dense={true} sx={{ display: !loading ? "block" : "none" }}>
        {titleIdeas.map((idea, index) => (
          <ListItem alignItems="flex-start" sx={{ pl: 0 }} key={index}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              sx={{
                borderRadius: 3,
                py: 0,
                pl: 1,
                pr: 0,
                mr: 2,
                justifyContent: "center",
              }}
              onClick={() => onTitleUpdate(row, idea)}
            >
              <Typography fontSize="inherit" sx={{ mr: 0.25 }}>
                Use
              </Typography>
              <CheckOutlinedIcon
                fontSize="small"
                color="secondary"
                sx={{ pr: 0.5 }}
              />
            </Button>
            <Typography variant="body2" sx={{ fontSize: 12 }}>
              {idea}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default memo(HitlistTableMoreTitles);
