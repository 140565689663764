import { memo, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";

function GenerateButton(props) {
  const {
    getHitlist,
    generating,
    sx,
    keywordsLoading,
    domainsLoading,
  } = props;

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(generating);
  }, [generating]);
  
  return (
    <Tooltip
      title={t("hitlist.generateButton.generateButtonTooltip")}
      arrow
      enterDelay={600}
      enterNextDelay={1200}
    >
      <span>
        <LoadingButton
          loading={loading}
          loadingPosition="end"
          endIcon={<AddCircleIcon />}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={getHitlist}
          disabled={loading || keywordsLoading || domainsLoading}
          sx={sx}
        >
          {!generating
            ? t("hitlist.generateButton.generateButtonText")
            : t("hitlist.generateButton.generateButtonLoading")}
        </LoadingButton>
      </span>
    </Tooltip>
  );
}

export default memo(GenerateButton);
