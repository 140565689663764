import { useState, useEffect, useRef } from "react";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  Box,
} from "@mui/material";
import Userfront from "@userfront/core";
import ControlPointDuplicateOutlinedIcon from "@mui/icons-material/ControlPointDuplicateOutlined";
import { HitlistAPI } from "../../Apis/HitlistAPI";
import { useTranslation } from "react-i18next";

function CreditCounterBuy(props) {
  const { theme } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [products, setProducts] = useState([]);
  const open = Boolean(anchorEl);

  const apiControllerRef = useRef([]);

  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreditClick = (link) => {
    window.location.href = `${link}?prefilled_email=${Userfront.user.email}`;
    handleClose();
  };

  const getBundles = async () => {
    const controller = new AbortController();
    apiControllerRef.current.push(controller);

    try {
      const dbProducts = await HitlistAPI.getBundleProducts(controller.signal);

      if (dbProducts) {
        // sort the products by credits
        dbProducts.sort((a, b) => a.credits - b.credits);
        setProducts(dbProducts);
      }
    } catch (error) {}
  };

  useEffect(() => {
    // get the product list from the database
    apiControllerRef.current = [];
    getBundles();

    return () => {
      // cancel all pending requests
      apiControllerRef.current.forEach((controller) => controller.abort());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Tooltip
        arrow
        enterDelay={600}
        enterNextDelay={1200}
        title={t("header.creditCounter.addCredits.tooltip")}
      >
        <IconButton
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <ControlPointDuplicateOutlinedIcon
            sx={{
              fontSize: 22,
              color: theme.palette.text.main,
              cursor: "pointer",
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 15,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {products.map((product) => (
          <MenuItem
            onClick={() => handleCreditClick(product.product_link)}
            key={product.credits}
          >
            <ListItemIcon>
              <ControlPointDuplicateOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Add {product.credits} Credits
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default CreditCounterBuy;
