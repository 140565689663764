import axios from "axios";
import axiosRetry from "axios-retry";
import { CustomEvents, publish } from "../Hitlist/Events/CustomEvents";
import { STORAGE_KEYS, getValue } from "../Storage";
import { throwError } from "../ErrorHandler/ErrorHandler";
import axiosThrottle from "axios-request-throttle";

const minCredit = 1;

axiosThrottle.use(axios, { requestsPerSecond: 25 });

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
  },
  baseURL: process.env.REACT_APP_OPENAI_API,
});

const retryCondition = (error) => {
  console.error("🚀 ~ file: OpenAiApi.js:34 ~ error:", error);
  return (
    axiosRetry.isNetworkError(error) ||
    axiosRetry.isRetryableError(error) ||
    error.code === "ECONNABORTED" ||
    error.type === "server_error" ||
    error.response.status === 429 ||
    error.response.status === 500
  ); // too many requests
};

axiosRetry(api, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: retryCondition,
});

const getCredits = async () => {
  // console.log("🚀 ~ file: OpenAiApi.js:38 ~ getCredits ~ getValue(STORAGE_KEYS.CREDITS):", getValue(STORAGE_KEYS.CREDITS))
  return getValue(STORAGE_KEYS.CREDITS) || 0;
};

export const GPT3 = {
  availableEngines: {
    STANDARD: "gpt-3.5-turbo",
    LARGE: "gpt-3.5-turbo-16k",
    TEXT: "text-curie-001",
  },
  textEngine: "text-davinci-003",
  chatEngine: process.env.REACT_APP_GPT3_VERSION,
  maxTokens: 150,
  temperature: 0.9,
  presencePenalty: 0.6,
  frequencyPenalty: 0,
  topP: 1,
  stops: ["Human:", "AI:"],
  logTokens: (response) => {
    // console.trace();
    console.log("🚀 ~ file: GPT3:40 ~ response:", response);

    const tokenFactor = parseInt(process.env.REACT_APP_GPT3_MULTIPLE); // how much more expensive than the baseline of GPT3.5

    if (!response) return;
    if (!response.data) return;
    if (!response.data.usage) return;

    const tokens = {
      prompt_tokens: response.data.usage.prompt_tokens,
      completion_tokens: response.data.usage.completion_tokens,
      total_tokens:
        (response.data.usage.prompt_tokens +
          response.data.usage.completion_tokens) *
        tokenFactor,
    };

    console.log(
      "RAW TOKENS: ",
      tokens.prompt_tokens + tokens.completion_tokens
    );

    publish(CustomEvents.TOKENS_USED, {
      date: new Date(),
      model: response.data.model,
      tokens: tokens,
    });
  },

  /**
   * Function to generate text using the OpenAI API and GPT3
   * @param {AbortSignal} signal A signal to cancel the request
   * @param {String} prompt The prompt to send to the API
   * @param {Number} temp How creative to get with responses
   * @param {Int} maxLength The maximum number of tokens to generate
   * @param {String} stops A string of characters to stop at
   * @returns {Object} The response from the OpenAI API as an Object. Responses are contained in the choices array.
   */
  generateText: async (
    signal,
    prompt,
    temp = GPT3.temperature,
    maxLength = GPT3.maxTokens,
    model = GPT3.textEngine,
    stops = GPT3.stops
  ) => {
    if (!signal) throwError("No signal provided", 400);

    try {
      const availableCredits = await getCredits();

      if (availableCredits >= minCredit) {
        let params = {
          prompt: prompt,
          model: model,
          max_tokens: maxLength,
          temperature: temp,
          frequency_penalty: GPT3.frequencyPenalty,
          presence_penalty: GPT3.presencePenalty,
          stop: stops,
        };

        try {
          const response = await api.post("/completions", params, {
            signal: signal,
          });

          GPT3.logTokens(response);

          return response.data;
        } catch (error) {
          throwError(error.response.data.error.message, error.response.status);
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (error) {}
  },
  /**
   * Function to generate a chat response using the OpenAI API and ChatGPT3
   * @param {Array} messages An array of chatGPT message objects. Follows the format of:
   * [
      {“role”: “system”, “content”: “You are a helpful assistant that translates English to French.”},
      {“role”: “user”, “content”: ‘Translate the following English text to French: “{text}”’}
    ]
   * @param {Number} temp How creative to get with responses
   * @param {Int} maxLength The maximum number of tokens to generate
   * @returns {Object} The response from the OpenAI API as an Object. Responses are contained in the choices array.
   */
  generateChat: async (
    signal,
    messages,
    temp = GPT3.temperature,
    maxLength = GPT3.maxTokens,
    functions = null,
    presencePenalty = GPT3.presencePenalty,
    frequencyPenalty = GPT3.frequencyPenalty,
    model = GPT3.chatEngine
  ) => {
    if (!signal) throwError("No signal provided", 400);

    try {
      const availableCredits = await getCredits();

      if (availableCredits >= minCredit) {
        let params = {
          model: model,
          messages: messages,
          temperature: temp,
          max_tokens: maxLength,
          presence_penalty: presencePenalty,
          frequency_penalty: frequencyPenalty,
          top_p: GPT3.topP,
        };

        if (functions) params.functions = functions;

        try {
          const response = await api.post("/chat/completions", params, {
            signal: signal,
          });

          GPT3.logTokens(response);

          return response.data;
        } catch (error) {
          throwError(error.response.data.error.message, error.response.status);
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (error) {}
  },
  streamChat: async (
    signal,
    messages,
    onData,
    temp = GPT3.temperature,
    maxLength = GPT3.maxTokens,
    presencePenalty = GPT3.presencePenalty,
    frequencyPenalty = GPT3.frequencyPenalty,
    model = GPT3.chatEngine
  ) => {
    if (!signal) throwError("No signal provided", 400);

    try {
      const availableCredits = await getCredits();

      if (availableCredits >= minCredit) {
        let params = {
          model: model,
          messages: messages,
          temperature: temp,
          max_tokens: maxLength,
          presence_penalty: presencePenalty,
          frequency_penalty: frequencyPenalty,
          top_p: GPT3.topP,
          stream: true,
        };

        try {
          const response = await fetch(
            process.env.REACT_APP_OPENAI_API + "/chat/completions",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              },
              body: JSON.stringify(params),
              signal: signal,
            }
          );

          const reader = response.body?.getReader();
          if (!reader) {
            console.error("Error: fail to read data from response");
            return;
          }

          let deltaText = "";
          let fullResponse = "";

          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }

            const textDecoder = new TextDecoder("utf-8");
            const chunk = textDecoder.decode(value);

            for (const line of chunk.split("\n")) {
              const trimmedLine = line.trim();
              if (!trimmedLine || trimmedLine === "data: [DONE]") {
                continue;
              }

              const json = trimmedLine.replace("data: ", "");
              const obj = JSON.parse(json);
              let content = obj.choices[0].delta.content;

              if (obj.choices[0]?.finish_reason === "stop") {
                console.log("STOPPING: ", fullResponse);
                continue;
              }

              // strip content string of any newlines or tabs
              content = content.replace(/(\r\n|\n|\r)/gm, "");

              deltaText +=
                content !== undefined && content.indexOf("  ") === -1
                  ? content
                  : "";
              fullResponse +=
                content !== undefined && content.indexOf("  ") === -1
                  ? content
                  : "";

              // look for the start of the response
              let startMatch = deltaText.match(/\{\s*"data"\s*:\s*\[/);
              let boundary = startMatch ? startMatch.index + startMatch[0].length : -1;

              // look for the end of each returned object
              let endMatch = deltaText.match(/,\s*\{\s*"id"\s*:/);
              let endBoundary = endMatch ? endMatch.index : -1;

              if (boundary > -1 && endBoundary > -1) {
                const input = deltaText.slice(boundary, endBoundary);

                // strip the input from the deltaText so we can look for the next input and not recheck the same input
                deltaText = deltaText.replace(input + ",", "");

                try {
                  const obj = JSON.parse(input);

                  // call the callback function with the object
                  onData(obj);

                  // emit the obj to listeners here
                } catch (e) {
                  // An error occurred, which means the data was not a complete JSON object
                  // console.error('Error parsing JSON object', e);
                }
              }
            }
          }

          console.log("ALL DONE");

          return { complete: true, data: [] };
        } catch (error) {
          throwError(error.response.data.error.message, error.response.status);
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (error) {}
  },
};

export const GPT4 = {
  availableEngines: {
    STANDARD: "gpt-4-0613",
    LARGE: "gpt-4-0613",
  },
  chatEngine: process.env.REACT_APP_GPT4_VERSION,
  maxTokens: 150,
  temperature: 0.7,
  presencePenalty: 0.6,
  frequencyPenalty: 0,
  topP: 1,
  stop: "",
  logTokens: (response) => {
    console.log("🚀 ~ file: GPT4:126 ~ response:", response);

    const tokenFactor =
      GPT4.maxTokens > 8000
        ? parseInt(process.env.REACT_APP_GPT4_32K_MULTIPLE)
        : parseInt(process.env.REACT_APP_GPT4_8K_MULTIPLE); // how much more expensive than the baseline of GPT3.5

    if (!response) return;
    if (!response.data) return;
    if (!response.data.usage) return;

    const tokens = {
      prompt_tokens: response.data.usage.prompt_tokens,
      completion_tokens: response.data.usage.completion_tokens,
      total_tokens:
        (response.data.usage.prompt_tokens +
          response.data.usage.completion_tokens) *
        tokenFactor,
    };

    publish(CustomEvents.TOKENS_USED, {
      date: new Date(),
      model: response.data.model,
      tokens: tokens,
    });
  },
  /**
   * Function to generate a chat response using the OpenAI API and ChatGPT3
   * @param {Array} messages An array of chatGPT message objects. Follows the format of:
   * [
      {“role”: “system”, “content”: “You are a helpful assistant that translates English to French.”},
      {“role”: “user”, “content”: ‘Translate the following English text to French: “{text}”’}
    ]
   * @param {Number} temp How creative to get with responses
   * @param {Int} maxLength The maximum number of tokens to generate
   * @returns {Object} The response from the OpenAI API as an Object. Responses are contained in the choices array.
   */
  generateChat: async (
    signal,
    messages,
    temp = GPT4.temperature,
    maxLength = GPT4.maxTokens,
    functions = null,
    presencePenalty = GPT4.presencePenalty,
    frequencyPenalty = GPT4.frequencyPenalty,
    model = GPT4.chatEngine
  ) => {
    if (!signal) throwError("No signal provided", 400);

    try {
      const availableCredits = await getCredits();

      if (availableCredits >= minCredit) {
        let params = {
          model: model,
          messages: messages,
          temperature: temp,
          max_tokens: maxLength,
          presence_penalty: presencePenalty,
          frequency_penalty: frequencyPenalty,
          top_p: GPT4.topP,
        };

        if (functions) params.functions = functions;

        try {
          const response = await api.post("/chat/completions", params, {
            signal: signal,
          });

          GPT4.logTokens(response);

          return response.data;
        } catch (error) {
          throwError(error, 500);
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (error) {}
  },
};
