import { createContext } from "react";
import { red, green, grey, blue } from "@mui/material/colors";

const themes = {
  dashboard: {
    headerColor: grey[400],
    iconColor: grey[400],
    buttonColor: blue[500],
    textColor: "#4B4C4C",
    textColorError: red[900],
    textColorSuccess: green[900],
    backgroundColor: grey[50],
    backgroundColorError: red[50],
    backgroundColorSuccess: green[50],
    borderColor: grey[300],
    dividerColor: grey[300],
  },
  hitlist: {
    primaryColor: "#FFC800",
    headerColor: "#FFF",
    iconColor: "#FFC800",
    buttonColor: "#17A5E6",
    loaderColor: "#17A5E6",
    textColor: "#4B4C4C",
    textColorError: red[900],
    textColorSuccess: green[900],
    backgroundColor: grey[50],
    backgroundColorError: red[50],
    backgroundColorSuccess: green[50],
    borderColor: grey[300],
    borderActiveColor: green["A400"],
    dividerColor: grey[300],
  },
};

export const AppThemeContext = createContext(themes.hitlist);