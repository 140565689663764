import { memo } from "react";
import { Badge } from "@mui/material";

function HintCounter(props) {
  const { hints, sx } = props;
  return (
    <Badge
      color={Array.isArray(hints) && hints.length > 0 ? "primary" : "warning"}
      badgeContent={
        Array.isArray(hints) ? (hints.length > 0 ? hints.length : null) : null
      }
      sx={sx}
    />
  );
}

export default memo(HintCounter);
