import { memo, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import { Button, FormControl, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import SendIcon from "@mui/icons-material/Send";
import Dictaphone from "./Dictaphone";
import {
  CustomEvents,
  subscribe,
  unsubscribe,
} from "../../Events/CustomEvents";
import UserProfileImage from "../../../User/UserProfileImage";

import "./ChatField.css";

const ariaLabel = { "aria-label": "description" };

function ChatField(props) {
  const { onReply, onListeningChange } = props;
  const valueRef = useRef(""); //creating a reference for TextField Component
  const theme = useTheme();

  const sendResponse = (prompt) => {
    onReply(prompt);
    valueRef.current.value = "";
  };

  /**
   * Function to look for the user hitting enter in the chat window
   * @param {Object} e Click event object
   */
  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      sendResponse(valueRef.current.value);
    }
  };

  const handleVoiceChat = (e) => {
    valueRef.current.value = e.detail.data.text;
    sendResponse(e.detail.data.text);
  };

  useEffect(() => {
    subscribe(CustomEvents.VOICE_MSG, handleVoiceChat);
    return () => {
      unsubscribe(CustomEvents.VOICE_MSG, handleVoiceChat);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: "110px",
        backgroundColor: theme.palette.background.paper,
        px: 2,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
      }}
    >
      <Box id="chat-field">
        <FormControl
          id="chat-field-entry"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flexGrow: "1",
          }}
        >
          <UserProfileImage />
          <Dictaphone onListeningChange={onListeningChange} />
          <TextField
            placeholder="or Type Reply"
            variant="standard"
            sx={{ width: "100%", mt: 0 }}
            inputProps={ariaLabel}
            inputRef={valueRef}
            onKeyUp={handleKeyUp}
            focused
            autoComplete="off"
          />
          <Button
            sx={{ color: theme.palette.primary.main }}
            endIcon={<SendIcon />}
            type="submit"
            onClick={() => sendResponse(valueRef.current.value)}
          >
            Send
          </Button>
        </FormControl>
      </Box>
    </Box>
  );
}

export default memo(ChatField);