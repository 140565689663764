import "./HitlistTableTitle.css";
import { memo } from "react";
import { Tooltip, Typography, Stack, LinearProgress } from "@mui/material";
import { APP_CONSTANTS } from "../../Constants";
import StringUtils from "../../../Utils/StringUtils";

function HitlistTableTitle(props) {
  const { value, loading } = props;

  switch (
    loading === true ||
    loading === APP_CONSTANTS.LOADING_TYPE_TITLE ||
    loading === APP_CONSTANTS.LOADING_TYPE_ENHANCE
  ) {
    case true:
      return <LinearProgress color="secondary" sx={{ width: "150px" }} />;
    case false:
      return (
        <Tooltip title="Double click to edit">
          <Stack direction="row" alignItems="top" spacing={1}>
            <Typography
              className="hitlist-table-title"
              variant="body2"
              sx={{ mt: 0.5, textTransform: "capitalize" }}
            >
              {StringUtils.titleCaseString(value)}
            </Typography>
          </Stack>
        </Tooltip>
      );
    default:
      return "";
  }
}

export default memo(HitlistTableTitle);
