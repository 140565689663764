import { memo } from "react";
import {
  ListItemIcon,
  MenuItem,
  Typography,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NavLink } from "react-router-dom";

function GlobalMenuItem(props) {
  const { onClick, text, StartIcon, EndIcon, link, newTab, fontSize } = props;
  const theme = useTheme();
  return (
    <MenuItem
      onClick={onClick}
      component={link ? NavLink : "div"}
      to={link}
      target={newTab ? "_blank" : "_self"}
    >
      {StartIcon && (
        <ListItemIcon>
          <StartIcon
            fontSize="small"
            sx={{ color: theme.palette.text.primary, mt: "-1px" }}
          />
        </ListItemIcon>
      )}
      {text && (
        <ListItemText
          primaryTypographyProps={{
            variant: "body2",
            fontSize: fontSize ? fontSize : "14px",
          }}
        >
          {text ? text : null}
        </ListItemText>
      )}
      {EndIcon && (
        <Typography variant="body2" color="text.secondary">
          <EndIcon sx={{ fontSize: "14px", mt: "4px" }} />
        </Typography>
      )}
    </MenuItem>
  );
}

export default memo(GlobalMenuItem);
