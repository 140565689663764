import { Box, Tooltip } from "@mui/material";
import { memo, useEffect, useState } from "react";
import Flag from "react-world-flags";
import { STORAGE_KEYS, getValue } from "../../../Storage";

function HitlistTableCountry(props) {
  const { value } = props;

  const [countryDisplay, setCountryDisplay] = useState(value || "");

  useEffect(() => {
    setCountryDisplay(value || getValue(STORAGE_KEYS.TARGET_COUNTRY) || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <Tooltip
        title={`Results from ${value}`}
        arrow
        enterDelay={1000}
        enterNextDelay={1000}
      >
        <Box
          sx={{
            width: 20,
            height: 13,
            overflow: "hidden",
          }}
        >
          <Flag code={countryDisplay} height="100%" />
        </Box>
      </Tooltip>
    </>
  );
}

export default memo(HitlistTableCountry);
