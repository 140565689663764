import { memo } from "react";
import LoginImage from "../Login/LoginImage/LoginImage";
import { ThemeProvider, Grid, Box } from "@mui/material";
import Version from "../Header/Version/Version";
import ResetOptions from "./ResetOptions/ResetOptions";
import { Link } from "react-router-dom";

const Reset = (props) => {
  const { theme, loggedIn, resettingPassword } = props;

  return (
    <ThemeProvider theme={theme}>
      <Box position="absolute" sx={{ right: 30, top: 20 }}>
        <Version color="#dfdfdf" hideDate={true} />
      </Box>
      <Grid
        id="reset-container"
        container
        spacing={0}
        columns={16}
        backgroundColor="background.paper"
      >
        <Grid item className="login-image" xs={9}>
          <Link to="/hitlist">
            <LoginImage />
          </Link>
        </Grid>
        <Grid
          item
          className="reset-form"
          xs={7}
          sx={{
            height: "100vh",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ResetOptions
            loggedIn={loggedIn}
            resettingPassword={resettingPassword}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default memo(Reset);
