const _Patches = {
  patchKeywords: (oldData) => {
    if (!oldData) return oldData;

    if (oldData.data && Array.isArray(oldData.data)) {
      const newData = oldData.data.map((item) => {
        if (item.keywords && Array.isArray(item.keywords)) {
          const newKeywords = item.keywords.map((keyword, index) => {
            if (typeof keyword === "string") {
              return {
                label: keyword,
                id: index,
              };
            } else {
              return keyword;
            }
          });

          return {
            ...item,
            keywords: newKeywords,
          };
        }
        return item;
      });

      return {
        ...oldData,
        data: newData,
      };
    }

    return oldData;
  },
};

export default _Patches;
