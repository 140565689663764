import "./App.css";
import {
  memo,
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import Header from "./Header/Header";
import Userfront from "@userfront/core";
import AppRoutes from "./AppRoutes";
import ArrayUtils from "./Utils/ArrayUtils";
import AnnouncementBar from "./AnnouncementBar/AnnouncementBar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Grid,
  Box,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { HitlistThemeOptions } from "./ThemeOptions";
import { useCookies } from "react-cookie";
import {
  CustomEvents,
  publish,
  subscribe,
  unsubscribe,
} from "./Hitlist/Events/CustomEvents";
import { STORAGE_KEYS, clearStorage, getValue, storeValue } from "./Storage";
import { HitlistAPI } from "./Apis/HitlistAPI";
import { Toast, ToastEvents } from "./Toast/Toast";
import { APP_CONSTANTS } from "./Hitlist/Constants";
import { AppConfigContext } from "./AppConfigContext";
import { useTranslation } from "react-i18next";
import {
  getDomainIdeas,
  getDomainKeywords,
  getKeywordIdeas,
} from "./Bots/IdeasBot";
import { v4 as uuidv4 } from "uuid";
import _Patches from "./_Patches/_Patches";
import SpyFuAPI from "./Apis/SpyFuApi";
import StringUtils from "./Utils/StringUtils";
import SimilarityApi from "./Apis/SimilarityApi";

function App() {
  const config = useContext(AppConfigContext);

  const [appInitialized, setAppInitialized] = useState(false);
  const [buyCreditsOpen, setBuyCreditsOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(null);
  const [domains, setDomains] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [hitlistId, setHitlistId] = useState("");
  const [hitlists, setHitlists] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [loggingIn, setLoggingIn] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [saving, setSaving] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableState, setTableState] = useState(null);
  const [targetCountry, setTargetCountry] = useState("");
  const [headlineStyle, setHeadlineStyle] = useState("");
  const [tabs, setTabs] = useState([]);
  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const [status, setStatus] = useState("");

  const apiControllerRef = useRef([]);
  const initRef = useRef(false);
  const loggedIn = useRef(false);
  const saveControllerRef = useRef([]);
  const volumeRef = useRef([]);
  const generatingRef = useRef(false);
  const tableDataRef = useRef([]);

  const { t } = useTranslation();

  const hitlistTheme = createTheme(HitlistThemeOptions);

  const location = useLocation();

  const navigate = useNavigate();

  const updateGenerating = (value) => {
    setGenerating(value);
    generatingRef.current = value;
  };

  /**
   * Public function to reset all cookies
   * @public
   */
  const resetCookies = useCallback(() => {
    removeCookie("AuthToken", { path: "/" });
    removeCookie("Email", { path: "/" });
    removeCookie("FirstName", { path: "/" });
    removeCookie("LastName", { path: "/" });
    removeCookie("ProfileImage", { path: "/" });
    removeCookie("Locale", { path: "/" });
    removeCookie("LoginType", { path: "/" });
    removeCookie("Settings", { path: "/" });
  }, [removeCookie]);

  /**
   * Public function to set the user's details in cookies and save them to Userfront
   * @param {String} authToken The user's auth token
   * @param {String} email The user's email address
   * @param {String} firstName The user's first name
   * @param {String} lastName The user's last name
   * @param {String} profileImage The url of the user's profile image
   * @param {String} locale The user's locale
   * @param {String} loginType The user's login type (email, google, facebook, etc)
   * @returns {void}
   * @public
   */
  const setUserDetails = useCallback(
    async (
      authToken,
      email,
      firstName,
      lastName,
      profileImage,
      locale,
      loginType
    ) => {
      // clearStorage();

      setCookie("AuthToken", authToken);
      setCookie("Email", email);
      setCookie("FirstName", firstName || "");
      setCookie("LastName", lastName || "");
      setCookie("ProfileImage", profileImage || "");

      if (locale !== undefined) setCookie("Locale", locale);
      if (loginType !== undefined) setCookie("LoginType", loginType);

      Userfront.user
        .update({
          data: {
            ...Userfront.user.data,
            locale: locale || cookies.Locale,
            loginType: loginType || cookies.LoginType,
          },
        })
        .then((response) => {
          if (response) {
            // publish(ToastEvents.SUCCESS, "Profile loaded");
          }
        })
        .catch((error) => {
          publish(ToastEvents.ERROR, error.message);
        });
    },
    [cookies, setCookie]
  );

  /**
   * Public function to save all data of the hitlist to the database
   * @param {String} saveId The id of the hitlist to save
   * @param {String} saveTitle The title of the hitlist to save
   * @param {String} saveTargetCountry The target country of the hitlist to save
   * @param {String} saveTopic The topic of the hitlist to save
   * @param {String} saveDomain The domain of the hitlist to save
   * @param {Array} saveKeywords The keywords of the hitlist to save
   * @param {Array} saveDomains The domains of the hitlist to save
   * @param {Array} saveTableData The table data of the hitlist to save
   * @param {Object} saveTableState The table state of the hitlist to save
   * @param {String} saveHeadlineStyle The headline style of the hitlist to save
   * @param {Object} saveTabs The tabs of the hitlist to save
   * @returns
   * @public
   */
  const saveAllData = useCallback(
    async (
      saveId = getValue(STORAGE_KEYS.ID) || hitlistId || null,
      saveTitle = getValue(STORAGE_KEYS.TITLE) || title || null,
      saveTargetCountry = getValue(STORAGE_KEYS.TARGET_COUNTRY) ||
        targetCountry ||
        config.hitlist.generate.targetCountry,
      saveTopic = getValue(STORAGE_KEYS.TOPIC) || topic || null,
      saveDomain = getValue(STORAGE_KEYS.DOMAIN) || null,
      saveKeywords = getValue(STORAGE_KEYS.RELATED_TERMS) || keywords,
      saveDomains = getValue(STORAGE_KEYS.DOMAINS) || domains,
      saveTableData = getValue(STORAGE_KEYS.HITLIST) || tableData,
      saveTableState = getValue(STORAGE_KEYS.HITLIST_STATE || tableState),
      saveHeadlineStyle = getValue(STORAGE_KEYS.HEADLINE_STYLE) ||
        headlineStyle,
      saveTabs = getValue(STORAGE_KEYS.TABS) ||
        tabs ||
        config.hitlist.tabs ||
        {}
    ) => {
      // check if the user is logged in
      if (!Userfront.tokens.accessToken || !Userfront.user.email) {
        publish(ToastEvents.ERROR, "User not logged in. Cannot save hitlist.");
        return;
      }

      if (!saveId) {
        console.warn("No id provided to save hitlist");
        return;
      }

      if (!saveTopic) {
        console.warn("No topic provided to save hitlist");
        return;
      }

      if (!saveTitle) {
        console.warn("No title provided to save hitlist");
        return;
      }

      console.log(
        "🚀 ~ file: App.js:221 ~ App ~ saveTableData:",
        saveTableData
      );
      console.log(
        "🚀 ~ file: App.js:221 ~ App ~ getValue(STORAGE_KEYS.HITLIST):",
        getValue(STORAGE_KEYS.HITLIST)
      );

      if (!Array.isArray(saveTableData)) {
        console.warn("No table data provided to save hitlist");
        return;
      }

      setSaving(true);

      // check we have the essential data to save
      if (!saveTargetCountry) console.warn("No target country provided.");
      if (!saveKeywords) console.warn("No keywords provided.");
      if (!saveDomains) console.warn("No domains provided.");
      if (!saveHeadlineStyle) console.warn("No headline style provided.");
      if (!saveTabs) console.warn("No tabs provided.");

      // detect what changed
      const changed = {
        keywords: saveKeywords !== keywords,
        domains: saveDomains !== domains,
        headlineStyle: saveHeadlineStyle !== headlineStyle,
        tableData: saveTableData !== tableData,
        tableState: saveTableState !== tableState,
        targetCountry: saveTargetCountry !== targetCountry,
        title: saveTitle !== title,
        topic: saveTopic !== topic,
        tabs: saveTabs !== tabs,
      };

      try {
        let savedData = null;

        publish(CustomEvents.HITLIST_SAVING);

        // abort the last save attempt if it's still running
        saveControllerRef.current.forEach((controller) => controller.abort());

        const controller = new AbortController();
        saveControllerRef.current.push(controller);

        savedData = await HitlistAPI.updateHitlist(
          controller.signal,
          saveId,
          Userfront.user.email,
          saveTitle,
          saveTableData,
          saveKeywords,
          saveDomains,
          saveTopic,
          saveDomain,
          saveTargetCountry,
          saveTableState,
          saveHeadlineStyle,
          saveTabs
        );

        if (savedData) {
          // update the hitlist list
          const controller = new AbortController();
          apiControllerRef.current.push(controller);

          try {
            const hitlistList = await HitlistAPI.getAllHitlists(
              controller.signal,
              Userfront.user.email,
              Userfront.tokens.accessToken
            );

            if (hitlistList && Array.isArray(hitlistList))
              setHitlists(hitlistList);

            publish(CustomEvents.HITLIST_SAVED, savedData);

            // update the state only if something changed
            if (changed.title) setTitle(saveTitle);
            if (changed.topic) setTopic(saveTopic);
            if (changed.keywords) setKeywords(saveKeywords);
            if (changed.domains) setDomains(saveDomains);
            if (changed.tableData) setTableData(saveTableData);
            if (changed.tableState) setTableState(saveTableState);
            if (changed.targetCountry) setTargetCountry(saveTargetCountry);
            if (changed.headlineStyle) setHeadlineStyle(saveHeadlineStyle);
            if (changed.tabs) setTabs(saveTabs);

            // setTitle(savedData.title);
            // setTargetCountry(savedData.target_country);
            // setTopic(savedData.topic);
            // setKeywords(savedData.keywords);
            // setDomains(savedData.competitors);
            // setTableData(savedData.data);
            // setHitlistId(savedData.id);
            // setTableState(savedData.table_data);
          } catch (err) {
            console.warn(err);
            publish(CustomEvents.HITLIST_NOT_SAVED);
          }
        } else {
          publish(CustomEvents.HITLIST_NOT_SAVED);
        }
      } catch (error) {
        console.warn(error);
        publish(CustomEvents.HITLIST_NOT_SAVED);
      } finally {
        setSaving(false);
      }
    },
    [
      config,
      domains,
      headlineStyle,
      hitlistId,
      keywords,
      tableData,
      tableState,
      targetCountry,
      title,
      topic,
      tabs,
    ]
  );

  const handleSaveSettings = useCallback(
    async (settings, refresh = false) => {
      if (!settings || typeof settings !== "object") {
        // publish(ToastEvents.ERROR, "Cannot save settings");
        console.log("Cannot save settings");
        return;
      }

      // check if the user is logged in
      if (!Userfront.tokens.accessToken) {
        // publish(ToastEvents.ERROR, "You must be logged in to save settings");
        publish(CustomEvents.LOGOUT);
        return;
      }

      try {
        setCookie("Settings", settings, {
          path: "/",
          maxAge: 60 * 60 * 24 * 365,
        });

        const controller = new AbortController();
        apiControllerRef.current.push(controller);

        const response = await HitlistAPI.saveSettings(
          controller.signal,
          Userfront.tokens.accessToken,
          Userfront.user.email,
          settings
        );

        if (response.detail) {
          // publish(ToastEvents.ERROR, response.detail);
          return;
        }

        publish(ToastEvents.SUCCESS, "Application settings saved");

        if (refresh) {
          // refresh the page
          window.location.reload();
        }
      } catch (err) {
        // publish(ToastEvents.ERROR, `Error saving settings (${err})`);
      }
    },
    [setCookie]
  );

  const handleBuyCredits = useCallback(() => {
    setBuyCreditsOpen(true);
  }, []);

  const handleLogin = useCallback(
    (e) => {
      let email = e.detail.data.email;
      let password = e.detail.data.password;
      let loginType = e.detail.data.loginType;

      setCookie("LoginType", loginType);

      switch (loginType) {
        case APP_CONSTANTS.LOGIN_TYPE_EMAIL:
          setLoggingIn(true);

          Userfront.login({
            method: "password",
            email: email,
            password: password,
          })
            .then((response) => {
              setUserDetails(
                response.tokens.access.value,
                Userfront.user.email,
                Userfront.user.data.firstName,
                Userfront.user.data.lastName,
                Userfront.user.image,
                Userfront.user.data.locale,
                loginType
              );

              loggedIn.current = true;

              publish(
                ToastEvents.SUCCESS,
                `User ${Userfront.user.email} logged in successfully`
              );
            })
            .catch((error) => {
              publish(ToastEvents.ERROR, error.message);
            })
            .finally(() => {
              setLoggingIn(false);
            });
          break;
        case APP_CONSTANTS.LOGIN_TYPE_GOOGLE:
        case APP_CONSTANTS.LOGIN_TYPE_FACEBOOK:
        case APP_CONSTANTS.LOGIN_TYPE_LINKEDIN:
          Userfront.login({
            method: loginType,
          })
            .then((response) => {
              if (response) {
                setUserDetails(
                  Userfront.tokens.accessToken,
                  Userfront.user.email,
                  Userfront.user.data.firstName,
                  Userfront.user.data.lastName,
                  Userfront.user.image,
                  Userfront.user.data.locale || cookies.Locale,
                  Userfront.user.data.loginType || cookies.LoginType
                );

                loggedIn.current = true;

                publish(
                  ToastEvents.SUCCESS,
                  `User ${Userfront.user.email} logged in successfully`
                );
              }
            })
            .catch((error) => {
              publish(ToastEvents.ERROR, error.message);
            });
          break;
        default:
          publish(ToastEvents.ERROR, "Invalid login type");
          break;
      }
    },
    [cookies, setCookie, setUserDetails]
  );

  const handleRegister = useCallback(
    async (e) => {
      let email = e.detail.data.email;
      let password = e.detail.data.password;
      let firstName = e.detail.data.firstName || "";
      let lastName = e.detail.data.lastName || "";
      let locale = e.detail.data.locale || "en";
      let loginType = e.detail.data.loginType;

      if (!loginType) {
        publish(ToastEvents.ERROR, "Login type is required");
        return;
      }

      try {
        switch (loginType) {
          case APP_CONSTANTS.LOGIN_TYPE_EMAIL:
            if (!email || !password) {
              publish(ToastEvents.ERROR, "Email and password are required");
              return;
            }

            Userfront.signup({
              method: "password",
              email: email,
              password: password,
              name: firstName + " " + lastName,
              data: {
                firstName: firstName,
                lastName: lastName,
                locale: locale,
                loginType: loginType,
              },
            })
              .then((response) => {
                if (response) {
                  loggedIn.current = true;

                  setUserDetails(
                    Userfront.tokens.accessToken,
                    Userfront.user.email,
                    Userfront.user.data.firstName,
                    Userfront.user.data.lastName,
                    Userfront.user.image,
                    Userfront.user.data.locale,
                    Userfront.user.data.loginType
                  );

                  publish(
                    ToastEvents.SUCCESS,
                    `User ${Userfront.user.email} created successfully`
                  );
                }
              })
              .catch((error) => {
                publish(ToastEvents.ERROR, error.message);
              });
            break;
          case APP_CONSTANTS.LOGIN_TYPE_GOOGLE:
          case APP_CONSTANTS.LOGIN_TYPE_FACEBOOK:
          case APP_CONSTANTS.LOGIN_TYPE_LINKEDIN:
            Userfront.signup({ method: loginType })
              .then((response) => {
                if (response) {
                  if (response.uuid && response.token) {
                    Userfront.login({
                      method: loginType,
                      uuid: response.uuid,
                      token: response.token,
                    })
                      .then((response) => {
                        if (response) {
                          loggedIn.current = true;

                          Userfront.user
                            .update({
                              data: {
                                ...Userfront.user.data,
                                loginType: loginType,
                                locale: locale,
                              },
                            })
                            .then((response) => {
                              if (response) {
                                setUserDetails(
                                  Userfront.tokens.accessToken,
                                  Userfront.user.email,
                                  Userfront.user.data.firstName,
                                  Userfront.user.data.lastName,
                                  Userfront.user.image,
                                  Userfront.user.data.locale,
                                  Userfront.user.data.loginType
                                );

                                publish(
                                  ToastEvents.SUCCESS,
                                  `User ${Userfront.user.email} created successfully`
                                );
                              }
                            })
                            .catch((error) => {
                              publish(ToastEvents.ERROR, error.message);
                            });
                        }
                      })
                      .catch((error) => {
                        publish(ToastEvents.ERROR, error.message);
                      });
                  }
                }
              })
              .catch((error) => {
                publish(ToastEvents.ERROR, error.message);
              });
            break;
          default:
            break;
        }
      } catch (error) {
        publish(ToastEvents.ERROR, error.message);
      }
    },
    [setUserDetails]
  );

  /**
   * Public function to handle the saving of the user profile details
   * @param {Event} e The event object
   * @returns {void}
   * @public
   */
  const handleUserUpdated = useCallback(
    async (e) => {
      let newEmail = e.detail.data.email || Userfront.user.email;
      let firstName = e.detail.data.firstName;
      let lastName = e.detail.data.lastName;

      if (!Userfront.tokens.accessToken || !Userfront.user.email || !newEmail) {
        publish(
          ToastEvents.ERROR,
          "Not enough information to update your profile"
        );
        return;
      }

      setUpdatingProfile(true);

      Userfront.user
        .update({
          email: newEmail,
          name: firstName + " " + lastName,
          data: {
            ...Userfront.user.data,
            firstName: firstName,
            lastName: lastName,
          },
        })
        .then((response) => {
          if (response) {
            setUserDetails(
              Userfront.tokens.accessToken,
              Userfront.user.email,
              Userfront.user.data.firstName,
              Userfront.user.data.lastName,
              Userfront.user.image,
              Userfront.user.data.locale,
              Userfront.user.data.loginType
            );
            publish(ToastEvents.SUCCESS, "Profile updated successfully");
            setUpdatingProfile(false);
          }
        })
        .catch((error) => {
          publish(ToastEvents.ERROR, error.message);
        });
    },
    [setUserDetails]
  );

  /**
   * Public function to handle the logout event
   * @returns {void}
   * @public
   */
  const handleLogout = useCallback(() => {
    if (Userfront.tokens.accessToken) {
      try {
        loggedIn.current = false;
        resetCookies();
        clearStorage();
        Userfront.logout()
          .then((response) => {
            if (response) {
              // loggedIn.current = false;
              // resetCookies();
              // clearStorage();
              publish(ToastEvents.SUCCESS, "User logged out successfully");
            }
          })
          .catch((error) => {
            publish(ToastEvents.ERROR, error.message);
          });
      } catch (error) {
        publish(ToastEvents.ERROR, error.message);
      }
    } else {
      loggedIn.current = false;
      resetCookies();
      clearStorage();
      publish(ToastEvents.SUCCESS, "User logged out successfully");
      navigate("/login");
    }
  }, [navigate, resetCookies]);

  /**
   * Public function to handle the forgot password event
   * @param {Event} e The click event
   * @returns {void}
   * @public
   */
  const handleForgotPassword = useCallback((e) => {
    let email = e.detail.data.email || null;

    if (email) {
      Userfront.sendResetLink(email)
        .then((response) => {
          if (response) {
            publish(ToastEvents.SUCCESS, "Reset link sent successfully");
          }
        })
        .catch((error) => {
          publish(ToastEvents.ERROR, error.message);
        });
    } else {
      publish(ToastEvents.ERROR, "Please enter an email address");
      return;
    }
  }, []);

  /**
   * Public function to handle the close event on the announcement
   * @param {Event} e The click event
   * @returns {void}
   * @public
   */
  const handleAnnouncementClose = useCallback((e) => {
    setBuyCreditsOpen(false);
  }, []);

  /**
   * Public function to handle the click event on the announcement
   * @param {Event} e The click event
   * @returns {void}
   * @public
   */
  const handleAnnouncementClick = useCallback(
    (e) => {
      // go to route /add-credits
      navigate("/add-credits");
      setBuyCreditsOpen(false);
    },
    [navigate]
  );

  const handleResetPassword = useCallback((e) => {
    let password = e.detail.data.password || null;
    let uuid = e.detail.data.uuid || null;
    let token = e.detail.data.token || null;
    let redirect = e.detail.data.redirect || false;

    if (!password || !uuid || !token) {
      publish(
        ToastEvents.ERROR,
        "Could not update password. Please try again."
      );
      return;
    }

    setResettingPassword(true);

    const payload = {
      password: password,
      uuid: uuid,
      token: token,
      redirect: redirect,
    };

    Userfront.updatePassword(payload)
      .then((response) => {
        if (response) {
          if (response.message === "OK") {
            setResettingPassword(false);
            publish(ToastEvents.SUCCESS, "Password reset successfully");
          } else {
            publish(ToastEvents.ERROR, "Error resetting password");
          }
        } else {
          publish(ToastEvents.INFO, "Updating password...");
        }
      })
      .catch((error) => {
        publish(ToastEvents.ERROR, error.message);
      });
  }, []);

  const handleHistoryChanged = useCallback(
    (e) => {
      if (e.detail.data) {
        storeValue(STORAGE_KEYS.TITLE, e.detail.data.title);
        storeValue(STORAGE_KEYS.HITLIST, e.detail.data.data);
        storeValue(STORAGE_KEYS.DOMAINS, e.detail.data.competitors);
        storeValue(STORAGE_KEYS.RELATED_TERMS, e.detail.data.keywords);
        storeValue(STORAGE_KEYS.TOPIC, e.detail.data.topic);
        storeValue(STORAGE_KEYS.TARGET_COUNTRY, e.detail.data.target_country);
        storeValue(STORAGE_KEYS.HEADLINE_STYLE, e.detail.data.headline_style);

        setTitle(e.detail.data.title);
        setTableData(e.detail.data.data);
        setDomains(e.detail.data.competitors);
        setKeywords(e.detail.data.keywords);
        setTopic(e.detail.data.topic);
        setTargetCountry(e.detail.data.target_country);
        setHeadlineStyle(e.detail.data.headline_style);
        setTableState(e.detail.data.table_state);

        updateGenerating(false);

        saveAllData();
      }
    },
    [saveAllData]
  );

  /**
   * Public function to check if the user is logged in, and if not, redirect to the login page
   * @returns {boolean} true if the user is logged in, false if not
   * @public
   */
  const checkLogin = async () => {
    // check if the url contains a uuid and token, but is not a reset link, we login
    let urlParams = new URLSearchParams(window.location.search);
    let uuid = urlParams.get("uuid") || null;
    let token = urlParams.get("token") || null;

    if (uuid && token) {
      Userfront.login({ method: "link", uuid, token })
        .then((response) => {
          if (response) {
            loggedIn.current = true;

            setUserDetails(
              Userfront.tokens.accessToken,
              Userfront.user.email,
              Userfront.user.data.firstName || "",
              Userfront.user.data.lastName || "",
              Userfront.user.image || "",
              Userfront.user.data.locale || cookies.Locale || "en",
              Userfront.user.data.loginType ||
                cookies.LoginType ||
                APP_CONSTANTS.LOGIN_TYPE.EMAIL
            );

            publish(
              ToastEvents.SUCCESS,
              `User ${Userfront.user.email} logged in successfully`
            );
          } else {
            loggedIn.current = false;
            publish(ToastEvents.ERROR, "Error logging in user");
          }
        })
        .catch((error) => {
          loggedIn.current = false;
          publish(ToastEvents.ERROR, error.message);
        });
    } else {
      // check if the user is logged in
      if (Userfront.tokens.accessToken !== undefined) {
        loggedIn.current = true;

        setUserDetails(
          Userfront.tokens.accessToken,
          Userfront.user.email,
          Userfront.user.data.firstName || "",
          Userfront.user.data.lastName || "",
          Userfront.user.image || "",
          Userfront.user.data.locale || "en",
          Userfront.user.data.loginType || APP_CONSTANTS.LOGIN_TYPE_EMAIL
        );
      } else {
        loggedIn.current = false;
        publish(CustomEvents.LOGOUT);
      }
    }
  };

  /**
   * Public function to get the settings of the user
   * @returns {Object} The settings object
   * @public
   */
  const getSettings = async () => {
    // check if the user is logged in
    if (Userfront.tokens.accessToken || Userfront.user.email) {
      try {
        const controller = new AbortController();
        apiControllerRef.current.push(controller);

        const response = await HitlistAPI.getSettings(
          controller.signal,
          Userfront.tokens.accessToken,
          Userfront.user.email
        );

        if (response) {
          if (response.detail) {
            setCookie("Settings", {});
            return;
          }
          setCookie("Settings", response);
        }
      } catch (err) {}
    }
  };

  /**
   * Public function to load the hitlist data from the database
   * @param {String} id The id of the hitlist to load
   * @public
   */
  const loadAllData = async (id) => {
    // check if the user is logged in
    if (Userfront.tokens.accessToken || Userfront.user.email) {
      // check if we have an id, and if not, try to get one from the server
      if (!id) {
        try {
          let controller = new AbortController();
          apiControllerRef.current.push(controller);

          let hitlistList = await HitlistAPI.getAllHitlists(
            controller.signal,
            Userfront.user.email
          );

          if (hitlistList && hitlistList.length > 0) {
            id = hitlistList[0].id;
            storeValue(STORAGE_KEYS.ID, id);
            setHitlistId(id);
            setHitlists(hitlistList);
          } else {
            console.warn("No hitlists found for user");

            // create a new blank hitlist so the user has something to start with
            try {
              const newHitlist = await HitlistAPI.saveNewHitlist(
                controller.signal,
                Userfront.user.email,
                "** New Hitlist **"
              );

              if (newHitlist.id) {
                id = newHitlist.id;
                storeValue(STORAGE_KEYS.ID, id);
                setHitlistId(id);
                setHitlists([newHitlist]);
              }
            } catch (err) {}
          }
        } catch (err) {
          console.warn(err);
          return;
        }
      }

      _loadHitlistData(id);

    } else {
      console.log("You are not logged in. Please sign in to continue.");
      _loadHitlistData(id, !Userfront.user.email);
    }
  };

  const _loadHitlistData = async (id, viewOnly = false) => {
    let initialData = null;

    try {
      let controller = new AbortController();
      apiControllerRef.current.push(controller);

      if (viewOnly) {
        initialData = await HitlistAPI.getSingleHitlistViewOnly(
          controller.signal,
          id
        );
      } else {
        initialData = await HitlistAPI.getSingleHitlist(
          controller.signal,
          id,
          Userfront.user.email
        );
      }

      if (initialData) {
        // set the row loading status to false
        if (initialData.data) {
          initialData.data = initialData.data.map((item) => {
            item.loading = false;
            return item;
          });
        }

        // loop through initialData.data and check if keywords is an array of strings, if so, convert it to an array of objects for backwards compatibility
        initialData = _Patches.patchKeywords(initialData);

        setDomains(initialData.competitors);
        setHeadlineStyle(initialData.headline_style);
        setHitlistId(initialData.id);
        setKeywords(initialData.keywords);
        setTableData(initialData.data);
        setTableState(initialData.table_state);
        setTargetCountry(initialData.target_country);
        setTitle(initialData.title);
        setTopic(initialData.topic);
        setTabs(initialData.tabs || config.hitlist.tabs);

        // set the app as initialized after a delay to allow the table to render
        initRef.current = setTimeout(() => {
          console.log("--- APP INITIALIZED ---");
          setAppInitialized(true);
        }, 1000);
      }
    } catch (error) {}
  };

  /**
   * Private function to update the status message on screen
   * @param {Int} counter The current number of items that have been researched
   * @param {Int} total The total number of items to research
   * @param {String} descriptor The type of items being researched (keywords or domains)
   * @private
   */
  const _updateStatus = (counter, total, descriptor = null) => {
    setStatus(`Researching ${counter}/${total} ${descriptor}`);
  };

  // Handle domain-based idea generation
  const handleDomainIdea = async (signal, term, topic, maxResults, reset) => {
    // Domain-based idea generation logic here. (Based on the provided code, this part was commented out)
  };

  // Handle keyword-based idea generation
  const handleKeywordIdea = useCallback(
    async (signal, term, topic, maxResults, reset) => {
      const currentTerms = getValue(STORAGE_KEYS.RELATED_TERMS) || [];
      const currentTermLabels = currentTerms.map((term) => term.label);

      const _onData = async (data) => {
        let ideas = [];

        // check the idea against those already received and only add it if it is not similar to any of them
        try {
          const controller = new AbortController();
          apiControllerRef.current.push(controller);

          // get all the titles from ideas and tableData as an array of strings to use for similarity checking
          const titles = [...tableDataRef.current.map((idea) => idea.title)];

          const isUnique = await SimilarityApi.isUnique(
            controller.signal,
            data.title,
            titles,
            cookies.Settings.similarFilter ||
              config.hitlist.generate.defaultSimilarFilter
          );

          // const isUnique = true;

          if (isUnique === true) {
            ideas.push(data);
          } else {
            return;
          }
        } catch (err) {
          console.log(err);
        }

        if (ideas.length) {
          let keywordData = null;
          let updatedIdeas = [];

          // get the keyword data for the new idea only
          try {
            keywordData = await _getKeywordData([data]);

            if (keywordData) {
              updatedIdeas = ideas.map((idea) => {
                let volume = 0;
                let opportunity = 0;
                let link = "";

                keywordData.forEach((item) => {
                  // if item.keyword is found in any of the labels of idea.keywords, add the volume to the total
                  if (
                    idea.keywords.some((keyword) => {
                      return (
                        keyword.label.toLowerCase() ===
                        item.keyword.toLowerCase()
                      );
                    })
                  ) {
                    volume += item.volume;
                    opportunity = Math.max(opportunity, item.difficulty);
                    link = item.link;
                  }
                });

                return {
                  ...idea,
                  volume,
                  type:
                    idea.title.indexOf("?") > -1
                      ? APP_CONSTANTS.ARTICLE_TYPE_QUESTION
                      : APP_CONSTANTS.ARTICLE_TYPE_RELATED_ARTICLE,
                  uuid: uuidv4(),
                  opportunity,
                  link,
                  countryCode: getValue(STORAGE_KEYS.TARGET_COUNTRY) || "US",
                  loading: false,
                  tabId: 0,
                };
              });
            }

            const originalData =
              getValue(STORAGE_KEYS.HITLIST) || tableDataRef.current || [];
            const combinedData = [...originalData, ...updatedIdeas];

            // ensure all rows have a unique id as is required by the table component
            const indexedData = ArrayUtils.reIndex(combinedData, "id");

            tableDataRef.current = indexedData;
            storeValue(STORAGE_KEYS.HITLIST, tableDataRef.current);
            setTableData(tableDataRef.current);
            saveAllData();
          } catch (error) {
            console.log(error);
          }
        }
      };

      const response = await getKeywordIdeas(
        signal,
        term,
        topic,
        currentTermLabels,
        _onData,
        getMaxResults(maxResults, cookies, config),
        getHeadlineStyle(cookies, config),
        getTargetCountry(cookies, config),
        getTargetLanguage(cookies, config),
        getSimilarFilter(cookies, config),
        reset
      );

      if (response) {
        // save the data to the database and update the table
        // saveAllData();
        return { complete: true, data: response };
      }
    },
    [config, cookies, saveAllData]
  );

  // Utility functions
  const getMaxResults = (maxResults, cookies, config) =>
    maxResults ||
    cookies.Settings.maxDomainResults ||
    config.hitlist.generate.maxDomainResults;
  const getHeadlineStyle = (cookies, config) =>
    getValue(STORAGE_KEYS.HEADLINE_STYLE) ||
    config.hitlist.generate.headlineStyle;
  const getTargetCountry = (cookies, config) =>
    getValue(STORAGE_KEYS.TARGET_COUNTRY) ||
    config.hitlist.generate.targetCountry;
  const getTargetLanguage = (cookies, config) =>
    cookies.Settings.targetLanguage || config.hitlist.generate.targetLanguage;
  const getSimilarFilter = (cookies, config) =>
    cookies.Settings.similarFilter ||
    config.hitlist.generate.defaultSimilarFilter;

  const _researchIdea = useCallback(
    async (term, topic, maxResults, reset = false) => {
      if (!term || !topic || !generatingRef.current) return;

      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      // Check if term is URL
      const url = StringUtils.extractURL(term);

      if (url) {
        await handleDomainIdea(
          controller.signal,
          term,
          topic,
          maxResults,
          reset
        );
      } else {
        return await handleKeywordIdea(
          controller.signal,
          term,
          topic,
          maxResults,
          reset
        );
      }
    },
    [handleKeywordIdea]
  );

  /**
   * Private function to get the keyword data for a list of ideas
   * @param {Array} arr An array of search terms
   * @param {String} searchTopic The topic to search for
   * @param {String} configKey The key to use to get the max number of results from the config
   * @param {String} statusString The string to use in the status message
   * @private
   * @returns The number of items that have been researched
   */
  const _getUpdatedIdeas = useCallback(
    async (arr, searchTopic, configKey, statusString) => {
      let counter = 0;

      for (const term of arr) {
        const ideasResponse = await _researchIdea(
          term.label,
          searchTopic,
          cookies.Settings[configKey] ||
            config.hitlist.generate[configKey] ||
            10,
          false
        );

        if (ideasResponse) {
          console.log("🚀 ~ file: App.js:1248 ~ ideasResponse:", ideasResponse);
        }

        counter++;
        _updateStatus(counter, arr.length, statusString);
      }

      return counter;
    },
    [_researchIdea, config, cookies]
  );

  const onGenerateCancel = useCallback(() => {
    updateGenerating(false);
    apiControllerRef.current.forEach((controller) => controller.abort());
  }, []);

  /**
   * Public function to generate a new hitlist
   * @public
   */
  const getHitlist = useCallback(async () => {
    let searchTopic = topic || getValue(STORAGE_KEYS.TOPIC) || null;
    let searchTerms = getValue(STORAGE_KEYS.RELATED_TERMS) || [];
    let searchDomains = getValue(STORAGE_KEYS.DOMAINS) || [];

    if (!searchTopic) {
      publish(
        ToastEvents.ERROR,
        "Please enter a topic before generating your hitlist."
      );
      return;
    }

    // Add the original topic to the front of the search terms array if it is empty
    if (searchTerms.length === 0) {
      searchTerms.unshift({ label: searchTopic.toLowerCase() });
      setStatus(`Performing topic analysis on ${topic}...`);
    } else {
      setStatus(`Generating hitlist for ${topic}...`);
    }

    updateGenerating(true);

    // Run a similarity check on the search terms and remove any that are too similar

    try {
      const keywordsResponse = await _getUpdatedIdeas(
        searchTerms,
        searchTopic,
        "maxKeywordResults",
        "keywords"
      );

      if (keywordsResponse) {
        console.log(
          "🚀 ~ file: App.js:1304 ~ getHitlist ~ keywordsResponse:",
          keywordsResponse
        );
      }
    } catch (err) {
      console.warn(err);
    }

    // get just the domain strings
    let domainLabels = searchDomains.map((domain) => domain.label);

    // convert them to full urls
    domainLabels = domainLabels.map((domain) => {
      return StringUtils.extractURL(domain);
    });

    // get just the search term labels to feed into the domain check
    let searchTermLabels = searchTerms.map((term) => term.label);

    // add the topic to the search term labels
    searchTermLabels.unshift(searchTopic);

    const controller = new AbortController();
    apiControllerRef.current.push(controller);

    // get the main keyword a domain ranks for and run that keyword through the keywords endpoint
    let domainKeywords = await getDomainKeywords(
      controller.signal,
      domainLabels,
      searchTermLabels.toString(),
      1,
      getValue(STORAGE_KEYS.TARGET_COUNTRY) || "US"
    );

    // convert the domainKeywords back to the original format
    domainKeywords = domainKeywords.map((keyword) => {
      return { label: keyword };
    });

    if (domainKeywords?.length > 0) {
      try {
        const domainResponse = await _getUpdatedIdeas(
          domainKeywords,
          searchTopic,
          "maxDomainResults",
          "domains"
        );

        if (domainResponse) {
          console.log(
            "🚀 ~ file: App.js:1350 ~ getHitlist ~ domainResponse:",
            domainResponse
          );
        }
      } catch (err) {
        console.warn(err);
      }
    }

    updateGenerating(false);
    setStatus("");
    publish(ToastEvents.SUCCESS, "Hitlist generated successfully.");
  }, [topic, _getUpdatedIdeas]);

  /**
   * Private function to get the keyword data for a list of keywords
   * @param {Array} keywordArray An array of keyword strings
   * @private
   * @returns An array of keyword data objects in the format {keyword: "keyword", volume: 0}
   */
  const _getKeywordData = async (keywordArray) => {
    let uniqueKeywordArray = [];

    // Get all unique keywords from the hitlist that are not already in volumeRef.current
    keywordArray.forEach((row) => {
      row.keywords.forEach((keyword) => {
        if (
          !volumeRef.current.some(
            (volumeData) =>
              volumeData.keyword.toLowerCase() === keyword.label.toLowerCase()
          )
        ) {
          uniqueKeywordArray.push(keyword.label.toLowerCase());
        }
      });
    });

    // Get volume data for all unique keywords
    if (uniqueKeywordArray.length > 0) {
      let controller = new AbortController();
      apiControllerRef.current.push(controller);

      let response = await SpyFuAPI.getKeywordInformation(
        controller.signal,
        uniqueKeywordArray
      );

      if (response && response.results && response.results.length > 0) {
        // Update volumeRef.current with new data avoiding duplicates
        response.results.forEach((volumeData) => {
          if (
            !volumeRef.current.some(
              (item) => item.keyword === volumeData.keyword
            )
          ) {
            // TODO: Save this to persistent storage so that it can be used again later
            volumeRef.current.push({
              keyword: volumeData.keyword.toLowerCase(),
              volume: volumeData.searchVolume,
              difficulty: volumeData.rankingDifficulty,
              clicks: volumeData.totalMonthlyClicks,
              link:
                volumeData.distinctCompetitors.length > 0
                  ? StringUtils.extractURL(
                      volumeData.distinctCompetitors[
                        Math.floor(
                          Math.random() * volumeData.distinctCompetitors.length
                        )
                      ]
                    )
                  : "",
            });
          }
        });
      }
    }

    return volumeRef.current;
  };

  /**
   * Public function to reset the hitlist, removing all data from local storage and resetting the state variables
   * @public
   * @returns {void}
   */
  const resetHitlist = useCallback(() => {
    storeValue(STORAGE_KEYS.TOPIC, "");
    storeValue(STORAGE_KEYS.HITLIST, []);
    storeValue(STORAGE_KEYS.RELATED_TERMS, []);
    storeValue(STORAGE_KEYS.DOMAINS, []);
    storeValue(
      STORAGE_KEYS.TARGET_COUNTRY,
      config.hitlist.generate.targetCountry
    );
    storeValue(
      STORAGE_KEYS.HEADLINE_STYLE,
      config.hitlist.generate.headlineStyle
    );

    setTargetCountry(config.hitlist.generate.targetCountry);
    setHeadlineStyle(config.hitlist.generate.headlineStyle);
    setTopic("");
    setKeywords([]);
    setDomains([]);
    setTableData([]);
    updateGenerating(false);
    setTableState(null);

    saveAllData();
  }, [config, saveAllData]);

  const onTopicConfirm = useCallback((value) => {
    setTopic(value);
  }, []);

  const onDomainConfirm = useCallback((value) => {
    setDomains(value);
  }, []);

  useEffect(() => {
    let path = window.location.pathname;

    // exit if the link is a reset link as we don't want automatic redirection in this instance
    if (path !== "/reset") {
      checkLogin();
      getSettings();
    }

    // get the id from the id parameter of the url
    let id =
      new URLSearchParams(window.location.search).get("id") ||
      getValue(STORAGE_KEYS.ID) ||
      null;

    if (id) storeValue(STORAGE_KEYS.ID, id);

    // if no id is found, create a new hitlist and use that ID

    loadAllData(id);

    const apiCalls = apiControllerRef.current;
    const saveCalls = saveControllerRef.current;

    subscribe(CustomEvents.LOGOUT, handleLogout);
    subscribe(CustomEvents.LOGIN, handleLogin);
    subscribe(CustomEvents.REGISTER, handleRegister);
    subscribe(CustomEvents.FORGOT_PASSWORD, handleForgotPassword);
    subscribe(CustomEvents.USER_UPDATED, handleUserUpdated);
    subscribe(CustomEvents.RESET_PASSWORD, handleResetPassword);
    subscribe(CustomEvents.BUY_CREDITS, handleBuyCredits);
    subscribe(CustomEvents.HISTORY_CHANGED, handleHistoryChanged);

    return () => {
      apiCalls.forEach((controller) => controller.abort());
      saveCalls.forEach((controller) => controller.abort());

      unsubscribe(CustomEvents.LOGOUT, handleLogout);
      unsubscribe(CustomEvents.LOGIN, handleLogin);
      unsubscribe(CustomEvents.REGISTER, handleRegister);
      unsubscribe(CustomEvents.FORGOT_PASSWORD, handleForgotPassword);
      unsubscribe(CustomEvents.USER_UPDATED, handleUserUpdated);
      unsubscribe(CustomEvents.RESET_PASSWORD, handleResetPassword);
      unsubscribe(CustomEvents.BUY_CREDITS, handleBuyCredits);
      unsubscribe(CustomEvents.HISTORY_CHANGED, handleHistoryChanged);

      loggedIn.current = false;
      clearTimeout(initRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid id="app-container">
      <CssBaseline />
      <ThemeProvider theme={hitlistTheme}>
        <AppBar
          sx={{
            display:
              location.pathname === "/login" ||
              location.pathname === "/mobile" ||
              location.pathname === "/reset" ||
              location.pathname === "/confirm"
                ? "none"
                : "flex",
          }}
        >
          <Toolbar disableGutters sx={{ p: 0 }}>
            <Header
              saveAllData={saveAllData}
              hitlistId={hitlistId}
              title={title}
              tableData={tableData}
              saving={saving}
              hitlists={hitlists}
              user={Userfront.user}
            />
          </Toolbar>
          <AnnouncementBar
            open={buyCreditsOpen}
            msg={t("hitlist.add.announcementMessage")}
            button={t("hitlist.add.announcementButton")}
            handleClick={handleAnnouncementClick}
            handleClose={handleAnnouncementClose}
          />
        </AppBar>
      </ThemeProvider>
      <ThemeProvider theme={hitlistTheme}>
        <Box
          id="tool-container"
          position="fixed"
          component="main"
          width="100%"
          sx={{
            top:
              location.pathname === "/login" || location.pathname === "/reset"
                ? 0
                : buyCreditsOpen
                ? "90px"
                : "60px",
          }}
        >
          <AppRoutes
            appInitialized={appInitialized}
            domains={domains}
            generating={generating}
            getHitlist={getHitlist}
            handleSaveSettings={handleSaveSettings}
            headlineStyle={headlineStyle}
            hitlistId={hitlistId}
            keywords={keywords}
            loggedIn={loggedIn.current}
            loggingIn={loggingIn}
            onDomainConfirm={onDomainConfirm}
            onTopicConfirm={onTopicConfirm}
            onGenerateCancel={onGenerateCancel}
            resetHitlist={resetHitlist}
            resettingPassword={resettingPassword}
            saveAllData={saveAllData}
            status={status}
            tabs={tabs}
            tableData={tableData}
            tableState={tableState}
            targetCountry={targetCountry}
            topic={topic}
            updatingProfile={updatingProfile}
          />
        </Box>
      </ThemeProvider>
      <Toast />
    </Grid>
  );
}

export default memo(App);
