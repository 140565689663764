import "./Assistant.css";
import { memo, useCallback, useEffect, useState } from "react";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TopicField from "./TopicField";
import GenerateButton from "./GenerateButton/GenerateButton";
import ResetButton from "./ResetButton/ResetButton";
import KeywordHints from "./Hints/KeywordHints";
import DomainHints from "./Hints/DomainHints";
import CountryPicker from "./CountryPicker/CountryPicker";
import HeadlineStylePicker from "./HeadlineStylePicker";
import StatusLoader from "./StatusLoader";
import SettingsButton from "../../Header/SettingsButton/SettingsButton";
import HitlistTabs from "./HitlistTabs/HitlistTabs";

function Assistant(props) {
  const {
    appInitialized,
    domain,
    domains,
    generating,
    getHitlist,
    handleError,
    headlineStyle,
    hitlistId,
    keywords,
    resetHitlist,
    saveAllData,
    status,
    tableData,
    tabs,
    tableState,
    targetCountry,
    topic,
    onGenerateCancel,
    viewOnly,
  } = props;

  const [keywordsLoading, setKeywordsLoading] = useState(false);
  const [domainsLoading, setDomainsLoading] = useState(false);

  const theme = useTheme();

  const handleKeywordsLoading = useCallback((loading) => {
    if (loading) {
      setKeywordsLoading(true);
    } else {
      setKeywordsLoading(false);
    }
  }, []);

  const handleDomainsLoading = useCallback((loading) => {
    if (loading) {
      setDomainsLoading(true);
    } else {
      setDomainsLoading(false);
    }
  }, []);

  return (
    <Box id="assistant" sx={{ px: 2, pt: 2 }}>
      <Grid
        id="assistant-inputs"
        columns={24}
        container
        className="assistant-container"
        sx={{
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack
          sx={{ my: 1.5, mx: 2.5, width: "100%" }}
          direction="row"
          alignItems="center"
          spacing={3}
        >
          <TopicField
            xs={8.5}
            saveAllData={saveAllData}
            topic={topic}
            generating={generating}
            viewOnly={viewOnly}
          />
          <HeadlineStylePicker
            xs={viewOnly ? 7.5 : 6.5}
            saveAllData={saveAllData}
            headlineStyle={headlineStyle}
            generating={generating}
            viewOnly={viewOnly}
          />
          <CountryPicker
            xs={viewOnly ? 7 : 5}
            saveAllData={saveAllData}
            targetCountry={targetCountry}
            generating={generating}
            viewOnly={viewOnly}
          />
          {!viewOnly && (
            <>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={3.5}>
                <GenerateButton
                  sx={{ width: "100%" }}
                  disabled={false}
                  generating={generating}
                  getHitlist={getHitlist}
                  keywordsLoading={keywordsLoading}
                  domainsLoading={domainsLoading}
                  viewOnly={viewOnly}
                />
              </Grid>
              <Grid item xs={1}>
                <ResetButton
                  sx={{ width: "100%", height: 42.25 }}
                  disabled={false}
                  generating={generating}
                  resetHitlist={resetHitlist}
                  keywordsLoading={keywordsLoading}
                  domainsLoading={domainsLoading}
                  viewOnly={viewOnly}
                />
              </Grid>
              <Divider
                orientation="vertical"
                variant="middle"
                sx={{ height: 32, ml: "32px !important" }}
              />
              <Grid item xs={0.5} sx={{ ml: "7px !important" }}>
                <SettingsButton viewOnly={viewOnly} />
              </Grid>
            </>
          )}
        </Stack>
      </Grid>

      <Grid
        id="assistant-hints"
        container
        columns={12}
        spacing={1}
        sx={{ mt: 0 }}
      >
        <KeywordHints
          topic={topic}
          msg="Research Keywords"
          onError={handleError}
          disableChat={true}
          disableOpenAll={true}
          saveAllData={saveAllData}
          keywords={keywords}
          generating={generating}
          appInitialized={appInitialized}
          handleKeywordsLoading={handleKeywordsLoading}
          viewOnly={viewOnly}
        />

        <DomainHints
          domain={domain}
          topic={topic}
          msg={"Research Domains"}
          onError={handleError}
          disableChat={true}
          disableOpenAll={true}
          saveAllData={saveAllData}
          domains={domains}
          generating={generating}
          appInitialized={appInitialized}
          handleDomainsLoading={handleDomainsLoading}
          viewOnly={viewOnly}
        />

        {/* <StatusLoader
          status={status}
          generating={generating}
          onGenerateCancel={onGenerateCancel}
        /> */}

        <Grid item xs={12}>
          <HitlistTabs
            generating={generating}
            hitlistId={hitlistId}
            saveAllData={saveAllData}
            tableData={tableData}
            tabs={tabs}
            tableState={tableState}
            viewOnly={viewOnly}
            status={status}
            onGenerateCancel={onGenerateCancel}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Assistant;
