import "./CountryPicker.css";
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Grid,
  Select,
} from "@mui/material";
import { memo, useContext, useEffect, useState } from "react";
import { AppConfigContext } from "../../../AppConfigContext";
import Flag from "react-world-flags";
import {
  CustomEvents,
  subscribe,
  unsubscribe,
} from "../../Events/CustomEvents";
import { STORAGE_KEYS, getValue, storeValue } from "../../../Storage";
import { useTranslation } from "react-i18next";

function CountryPicker(props) {
  const { xs, saveAllData, targetCountry, generating, viewOnly } = props;

  const config = useContext(AppConfigContext);

  const [hidden, setHidden] = useState(false);
  const [countryDisplay, setCountryDisplay] = useState("");

  const { t } = useTranslation();

  const updateCountry = (e) => {
    if (!e.target.value) return;

    setCountryDisplay(e.target.value);

    if (e.target.value !== targetCountry) {
      storeValue(STORAGE_KEYS.TARGET_COUNTRY, e.target.value);
      saveAllData();
    }
  };

  const handleChatRequest = () => {
    setHidden(true);
  };

  const handleChatClosed = () => {
    setHidden(false);
  };

  useEffect(() => {
    let country =
      targetCountry && targetCountry.length
        ? targetCountry
        : getValue(STORAGE_KEYS.TARGET_COUNTRY) ||
          config.hitlist.generate.targetCountry;

    setCountryDisplay(country);
    storeValue(STORAGE_KEYS.TARGET_COUNTRY, country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetCountry]);

  useEffect(() => {
    subscribe(CustomEvents.CHAT, handleChatRequest);
    subscribe(CustomEvents.CHAT_CLOSED, handleChatClosed);

    if (!getValue(STORAGE_KEYS.TARGET_COUNTRY)) {
      storeValue(config.hitlist.generate.targetCountry);
    }

    setCountryDisplay(getValue(STORAGE_KEYS.TARGET_COUNTRY));

    return () => {
      unsubscribe(CustomEvents.CHAT, handleChatRequest);
      unsubscribe(CustomEvents.CHAT_CLOSED, handleChatClosed);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid xs={xs} item hidden={hidden}>
      <FormControl
        variant="standard"
        size="small"
        margin="none"
        sx={{ width: "100%" }}
      >
        <InputLabel id="settings-target-country-select-label" shrink>
          {t("hitlist.countryPicker.label")}
        </InputLabel>
        <Select
          autoWidth
          labelId="settings-target-country-select-label"
          id="settings-target-country-select"
          value={countryDisplay}
          label="Target Country"
          className="country-picker"
          onChange={updateCountry}
          sx={{ height: 35 }}
          disabled={generating || viewOnly}
        >
          {config.settings.TARGET_COUNTRY_OPTIONS.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              <ListItemIcon>
                <Flag
                  code={option.value}
                  width="20"
                  style={{ opacity: viewOnly ? 0.4 : 1 }}
                />
              </ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

export default memo(CountryPicker);
