import { memo, useState, useEffect } from "react";
import HintButtons from "./HintButtons/HintButtons";
import HintsDisplay from "./HintsDisplay";
import HintsContainer from "./HintsContainer";
import HintLoader from "./HintLoader";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AddHintField from "./AddHintField/AddHintField";
import StringUtils from "../../../Utils/StringUtils";

import "./HintsAccordion.css";
import {
  CustomEvents,
  subscribe,
  unsubscribe,
} from "../../Events/CustomEvents";
import HintCounter from "./HintCounter";

function HintsAccordion(props) {
  const {
    className,
    disableBadge,
    disableChat,
    disabled,
    disableDelete,
    disableMore,
    disableOwnHints,
    disableOpenAll,
    errorMessage,
    handleChat,
    handleClear,
    handleCancel,
    handleClick,
    handleCopy,
    handleDelete,
    handleErrorClose,
    handleHintAdded,
    handleMore,
    handleMoreLikeThis,
    handleRefresh,
    handleSummarize,
    handleOpenAll,
    hints,
    loading,
    lock,
    more,
    msg,
    type,
    tip,
    getMore,
    generating,
    viewOnly,
  } = props;

  const [expanded, setExpanded] = useState(true);

  const theme = useTheme();

  const handleGenerateHitlist = () => {
    setExpanded(false);
  };

  useEffect(() => {
    if (generating) setExpanded(false);
  }, [generating]);

  useEffect(() => {
    // setExpanded(false); // whether they should be open or closed by default on app load
    subscribe(CustomEvents.GENERATE_HITLIST, handleGenerateHitlist);
    return () => {
      unsubscribe(CustomEvents.GENERATE_HITLIST, handleGenerateHitlist);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion
      className={`${className} hints-accordion`}
      sx={{
        boxShadow: "none",
        border: `1px solid ${theme.palette.divider}`,
        borderBottomLeftRadius: 0,
        backgroundColor:
          hints && hints.length > 0
            ? theme.palette.background.main
            : theme.palette.background.paper,
      }}
      // expanded={!lock ? expanded === `${type}-panel` : false}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        className={`hint-header ${lock ? "locked" : "unlocked"}`}
        expandIcon={lock ? "" : <ExpandMoreIcon />}
        aria-controls={`${type}-panel`}
        id={`${type}-bh-header`}
        sx={{ alignItems: "center", borderRadius: 0 }}
      >
        <Typography
          sx={{
            flexShrink: 0,
            textTransform: "capitalize",
            mt: lock ? 1 : 0,
            mr: 2,
          }}
        >
          {StringUtils.capitalizeString(msg)}
        </Typography>
        <Box
          className="hint-badges"
          sx={{ display: "flex", mt: 1.5, ml: 1, justifyContent: "flex-end" }}
        >
          {!viewOnly && <HintCounter hints={props.hints} />}
        </Box>
        <Box sx={{ flexGrow: 1, mr: 3 }}>
          <ErrorMessage
            msg={errorMessage}
            handleErrorClose={handleErrorClose}
            handleRefresh={handleRefresh}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        <HintsContainer viewOnly={viewOnly}>
          <HintsDisplay
            msg={msg}
            locked={lock}
            count={hints ? hints.length : 0}
            loading={loading}
            hints={hints}
            handleClick={handleClick}
            handleDelete={handleDelete}
            handleMoreLikeThis={handleMoreLikeThis}
            tip={tip}
            getMore={getMore}
            viewOnly={viewOnly}
          />
          {!viewOnly && (
            <Grid
              container
              columns={16}
              direction="row"
              alignItems="center"
              className="hint-buttons-footer"
              sx={{ pt: 0 }}
            >
              <Grid xs={10} item flex>
                {!disableOwnHints ? (
                  <AddHintField
                    className="add-hint-field"
                    onHintAdded={handleHintAdded}
                    viewOnly={viewOnly}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid xs={6} item flex sx={{ pt: 0 }}>
                <HintButtons
                  className="hint-buttons-bottom"
                  hints={hints}
                  handleChat={handleChat}
                  handleClear={handleClear}
                  handleCopy={handleCopy}
                  handleMore={handleMore}
                  handleRefresh={handleRefresh}
                  handleSummarize={handleSummarize}
                  handleOpenAll={handleOpenAll}
                  loading={loading}
                  disableMore={disableMore}
                  disableChat={disableChat}
                  disableDelete={disableDelete}
                  disableOpenAll={disableOpenAll}
                  more={more}
                  generating={generating}
                  type={type}
                  viewOnly={viewOnly}
                />
              </Grid>
            </Grid>
          )}
        </HintsContainer>
      </AccordionDetails>
      <Box
        sx={{
          color: "text.secondary",
          position: "absolute",
          height: 20,
          right: 50,
          top: 12,
          zIndex: 2000,
        }}
      >
        <HintLoader
          className="hint-loader"
          hints={hints}
          loading={loading}
          lock={lock}
          type={msg}
          disableBadge={disableBadge}
          sx={{ display: disabled ? "none" : "flex" }}
          handleCancel={handleCancel}
        />
      </Box>
    </Accordion>
  );
}

export default memo(HintsAccordion);
