export const HitlistThemeOptions = {
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    fontSize: 14,
  },

  components: {
    MuiAppBar: {
      color: "#FFC800",
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "FFC800",
          borderRadius: "20px",
          height: "40px",
          marginTop: "10px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "60px !important",
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#FFC800",
      contrastText: "#4b4c4c",
    },
    secondary: {
      main: "#17A5E6",
      contrastText: "#FFF",
    },
    neutral: {
      main: "#4b4c4c",
      contrastText: "#FFF",
    },
    text: {
      primary: "#4b4c4c",
      secondary: "#86868B",
      disabled: "#E8E8E8",
      label: "#86868B",
    },
    error: {
      main: "#B71C1C",
      contrastText: "#FFF",
    },
    background: {
      main: "#FFF",
      paper: "#F7F7F7",
      assistant: "#CECECE",
      error: "#ffebee",
    },
    divider: "#E2E2E2",
    muted: "#B6B6B7",
    caption: "#86868B",
  },
  shape: {
    borderRadius: 5,
  },
  props: {
    MuiAppBar: {
      color: "#FFC800",
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    },
    MuiButton: {
      size: "small",
    },
    MuiButtonGroup: {
      size: "small",
    },
    MuiCheckbox: {
      size: "small",
    },
    MuiFab: {
      size: "small",
    },
    MuiFormControl: {
      margin: "dense",
      size: "small",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiRadio: {
      size: "small",
    },
    MuiSwitch: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
      size: "small",
    },
  },
  spacing: 8,
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#689f38",
        color: "#fff",
      },
    },
  },
};

export const LoginThemeOptions = {
  components: {
    MuiAppBar: {
      color: "#FFC800",
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "FFC800",
          borderRadius: "20px",
          height: "40px",
          marginTop: "10px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "60px !important",
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#17A5E6",
      contrastText: "#4b4c4c",
    },
    secondary: {
      main: "#17A5E6",
      contrastText: "#FFF",
    },
    text: {
      primary: "#4b4c4c",
      secondary: "#86868B",
      disabled: "#E8E8E8",
    },
    warning: {
      main: "#B71C1C",
      contrastText: "#FFF",
    },
    error: {
      main: "#B71C1C",
      contrastText: "#FFF",
    },
    background: {
      main: "#FFF",
      paper: "#F7F7F7",
      assistant: "#CECECE",
      error: "#ffebee",
    },
    divider: "#E2E2E2",
  },
  shape: {
    borderRadius: 5,
  },
  props: {
    MuiAppBar: {
      color: "#FFC800",
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    },
    MuiButton: {
      size: "small",
    },
    MuiButtonGroup: {
      size: "small",
    },
    MuiCheckbox: {
      size: "small",
    },
    MuiFab: {
      size: "small",
    },
    MuiFormControl: {
      margin: "dense",
      size: "small",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiRadio: {
      size: "small",
    },
    MuiSwitch: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
      size: "small",
    },
  },
  spacing: 8,
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#689f38",
        color: "#fff",
      },
    },
  },
};
