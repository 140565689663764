import { memo } from "react";
import { IconButton, Tooltip } from "@mui/material";
import SettingsSharp from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";

function SettingsButton() {
  return (
    <>
      <IconButton aria-label="Settings" component={Link} to="/settings">
        <Tooltip title="Settings" enterDelay={300} enterNextDelay={600}>
          <SettingsSharp />
        </Tooltip>
      </IconButton>
    </>
  );
}

export default memo(SettingsButton);
