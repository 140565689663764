import "./HitlistTable.css";
import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import {
  CustomEvents,
  publish,
  subscribe,
  unsubscribe,
} from "../../Events/CustomEvents";
import {
  DataGridPremium,
  GridRow,
  GridColumnHeaders,
  GridColumnMenu,
  useGridApiRef,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from "@mui/x-data-grid-premium";
import { Box, Tooltip, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import HitlistTableLoader from "./HitlistTableLoader";
import HitlistTableWait from "./HitlistTableWait";
import { useCallback } from "react";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { enhanceSingleResult, reWriteTitle } from "../../../Bots/HitlistBot";
import { getExtraKeywords } from "../../../Bots/KeywordBot";
import HitlistTableMoreDetails from "./HitlistTableMoreDetails/HitlistTableMoreDetails";
import HitlistTableCustomToolbar from "./HitlistTableCustomToolbar";
import HitlistTableRowNumber from "./HitlistTableRowNumber";
import HitlistTableIdeas from "./HitlistTableIdeas";
import HitlistTableOpportunityFactor from "./HitlistTableOpportunityFactor";
import HitlistTableType from "./HitlistTableType";
import HitlistTableLink from "./HitlistTableLink";
import HitlistTableTitle from "./HitlistTableTitle";
import HitlistTableKeywords from "./HitlistTableKeywords";
import { APP_CONSTANTS } from "../../Constants";
import { getValue, STORAGE_KEYS, storeValue } from "../../../Storage";
import { ToastEvents } from "../../../Toast/Toast";
import { useCookies } from "react-cookie";
import parse from "html-react-parser";
import HitlistTableVolume from "./HitlistTableVolume";
import HitlistTableStarred from "./HitlistTableStarred";
import {
  HitlistTableActionEnhance,
  HitlistTableActionExpand,
  HitlistTableActionDelete,
  HitlistTableActionRewrite,
  HitlistTableActionMoreKeywords,
  HitlistTableActionNewKeywords,
  HitlistTableActionDivider,
  HitlistTableActionEnhanceMenu,
  HitlistTableActionDeleteMenu,
} from "./HitlistTableActions/HitlistTableActions";
import { AppConfigContext } from "../../../AppConfigContext";
import HitlistTableDifficulty from "./HitlistTableDifficulty";
import StringUtils from "../../../Utils/StringUtils";
import { zeroSorter } from "../../../Utils/DataGridUtils";
import HitlistTableCountry from "./HitlistTableCountry";
import GridCellExpand from "./GridCellExpand";
import GridCellEditMultiline from "./GridCellEditMultiline";
import HitlistTableSource from "./HitlistTableSource";
import SpyFuAPI from "../../../Apis/SpyFuApi";
import ArrayUtils from "../../../Utils/ArrayUtils";
import HitlistTableHeading from "./HitlistTableHeading";
import HitlistTableActionPush from "./HitlistTableActions/HitlistTableActionPush/HitlistTableActionPush";
import HitlistTableActionPull from "./HitlistTableActions/HitlistTableActionPull/HitlistTableActionPull";
import SimilarityApi from "../../../Apis/SimilarityApi";

const MemoizedRow = React.memo(GridRow);
const MemoizedColumnHeaders = React.memo(GridColumnHeaders);

function updateRowPosition(initialIndex, newIndex, rows) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

function HitlistTable(props) {
  const {
    generating,
    handlePush,
    handlePull,
    handleDelete,
    isFullScreen,
    updateTableData,
    tableData,
    tableId,
    viewOnly,
    columnVisibility,
    condensed,
  } = props;

  const [rowData, setRowData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [hidden, setHidden] = useState(true);
  const [showColumns, setShowColumns] = useState(!viewOnly);

  const [cookies] = useCookies();

  const apiRef = useGridApiRef();
  const currData = useRef([]);
  const condensedRef = useRef(false);
  const showColumnsRef = useRef(!viewOnly);
  const fullScreenRef = useRef(false);
  const apiControllerRef = useRef([]);

  const theme = useTheme();
  const config = useContext(AppConfigContext);

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "#",
        renderHeader: () => <HitlistTableHeading />,
        type: "number",
        minWidth: 35,
        maxWidth: 35,
        align: "left",
        valueGetter: ({ value }) => value,
        renderCell: ({ row }) => (
          <HitlistTableRowNumber row={row} apiRef={apiRef} />
        ),
      },
      {
        field: "uuid",
        headerName: "Unique ID",
        renderHeader: () => <HitlistTableHeading />,
        type: "number",
        minWidth: 35,
        maxWidth: 35,
        align: "left",
        valueGetter: ({ value }) => value,
        renderCell: ({ row }) => (
          <HitlistTableRowNumber row={row} apiRef={apiRef} />
        ),
      },
      {
        field: "type",
        headerName: "Type",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: 40,
        maxWidth: 40,
        align: "center",
        editable: false,
        filterable: true,
        resizable: false,
        sortable: true,
        valueGetter: ({ value }) => value,
        renderCell: ({ value, row }) => (
          <HitlistTableType value={value} row={row} loading={row.loading} />
        ),
      },
      {
        field: "starred",
        headerName: "Starred",
        renderHeader: () => <HitlistTableHeading />,
        type: "boolean",
        minWidth: 40,
        maxWidth: 40,
        align: "center",
        editable: !viewOnly,
        filterable: true,
        resizable: false,
        sortable: true,
        valueGetter: ({ row }) => row.starred,
        renderCell: ({ value, row }) => (
          <HitlistTableStarred
            value={value}
            row={row}
            handleStarred={handleStarred}
            loading={row.loading}
            viewOnly={viewOnly}
          />
        ),
      },
      {
        field: "countryCode",
        headerName: "Country Code",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: 40,
        maxWidth: 40,
        align: "center",
        editable: false,
        filterable: true,
        resizable: false,
        sortable: true,
        valueGetter: ({ value }) => value,
        renderCell: ({ value, row }) => (
          <HitlistTableCountry
            value={value}
            theme={theme}
            row={row}
            loading={row.loading}
          />
        ),
      },
      {
        field: "link",
        headerName: "URL",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: 40,
        maxWidth: 40,
        align: "center",
        editable: false,
        filterable: true,
        resizable: false,
        sortable: true,
        groupable: false,
        renderCell: ({ value, row }) => (
          <HitlistTableLink value={value} row={row} loading={row.loading} />
        ),
      },
      {
        field: "source",
        headerName: "Source",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: 40,
        maxWidth: 40,
        align: "center",
        editable: false,
        filterable: true,
        groupable: false,
        resizable: false,
        sortable: true,
        renderCell: ({ value, row }) => (
          <HitlistTableSource value={value} row={row} loading={row.loading} />
        ),
      },
      {
        field: "title",
        headerName: "Article Title",
        renderHeader: () => (
          <HitlistTableHeading
            heading="Article Title"
            tooltip="The suggested headline idea to write an article on. Headline ideas are based on a combination of competition, keyword, niche, and organic search analysis."
          />
        ),
        flex: (!showColumns || viewOnly) ? 0.4 : 0.65,
        editable: !viewOnly,
        filterable: true,
        resizable: true,
        sortable: true,
        renderCell: ({ value, row }) => (
          <HitlistTableTitle
            value={value}
            row={row}
            loading={row.loading}
            handleEnhance={handleEnhance}
          />
        ),
        renderEditCell: (params) => (
          <GridCellEditMultiline
            params={params}
            sx={{ fontSize: "14px !important" }}
          />
        ),
      },
      {
        field: "keywords",
        headerName: "Keywords to Target",
        renderHeader: () => (
          <HitlistTableHeading
            heading="Keywords to Target"
            tooltip="The suggested keywords to target for the article idea."
          />
        ),
        flex: (!showColumns || viewOnly) ? 0.3 : 0.35,
        editable: false,
        filterable: true,
        resizable: true,
        sortable: false,
        groupable: false,
        // custom value formatter for export
        valueFormatter: ({ value }) => {
          if (value) {
            return value
              .map((keyword) => keyword.label.toLowerCase())
              .toString();
          } else {
            return "";
          }
        },
        renderCell: (params) => (
          <Box sx={{ mt: 1.5, mb: 1, width: "100%", overflowX: "hidden" }}>
            <HitlistTableKeywords
              params={params}
              value={params.value}
              row={params.row}
              // condensedRows={!condensedRef.current}
              handleKeywordDelete={handleKeywordDelete}
              handleExpandRow={() => handleExpandRow(params.row)}
              loading={params.row.loading}
              viewOnly={viewOnly}
            />
          </Box>
        ),
      },
      {
        field: "ranked",
        headerName: "Ranked",
        minWidth: 80,
        maxWidth: 80,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "volume",
        headerName: "Volume",
        renderHeader: () => (
          <HitlistTableHeading
            heading="Vol"
            tooltip="Vol = Volume. The estimated monthly search volume for the listed keywords to target in the research country specified. If no volume is listed, try enhancing, getting more keywords from the action menu of each row, or changing your target country."
          />
        ),
        type: "number",
        align: "center",
        headerAlign: "center",
        editable: false,
        filterable: true,
        resizable: false,
        sortable: true,
        sortComparator: zeroSorter,
        valueGetter: ({ value }) => value,
        renderCell: ({ value, row }) => (
          <HitlistTableVolume value={value} row={row} loading={row.loading} />
        ),
      },
      {
        field: "opportunity",
        headerName: "Opportunity Factor",
        renderHeader: () => (
          <HitlistTableHeading
            heading="OF"
            tooltip="OF = Opportunity Factor. Our custom measurement of the ranking opportunity for each idea. The lower the number, the better the ranking opportunity. 0 means we do not have enough data to calculate a value from. Try adding more keywords or enhancing the idea from the actions menu of each row."
          />
        ),
        type: "number",
        align: "center",
        headerAlign: "center",
        editable: false,
        filterable: true,
        resizable: true,
        sortable: true,
        hideable: false,
        sortComparator: zeroSorter,
        // valueGetter: adjustRankingDifficulty,
        renderCell: ({ value, row }) => (
          <HitlistTableOpportunityFactor
            value={value}
            row={row}
            loading={row.loading}
          />
        ),
      },
      {
        field: "difficulty",
        headerName: "RD",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: 70,
        maxWidth: 70,
        align: "center",
        headerAlign: "center",
        renderCell: ({ value }) => <HitlistTableDifficulty value={value} />,
      },
      {
        field: "subHeadings",
        headerName: "Sub Headings",
        renderHeader: () => (
          <HitlistTableHeading heading="Sub Headings" tooltip="" />
        ),
        minWidth: 300,
        maxWidth: 300,
        renderCell: ({ value }) => (
          <Tooltip title="Double click to edit">
            <Stack direction="row" alignItems="top" spacing={1}>
              <Typography
                variant="caption"
                color={value ? "text.primary" : "text.disabled"}
                sx={{ mt: 0.5, textTransform: "capitalize" }}
              >
                {value ? parse(value) : "Add your subheadings here..."}
              </Typography>
            </Stack>
          </Tooltip>
        ),
      },
      {
        field: "wordCount",
        headerName: "Word Count",
        renderHeader: () => (
          <HitlistTableHeading heading="Word Count" tooltip="" />
        ),
        minWidth: 110,
        maxWidth: 110,
        align: "center",
        headerAlign: "center",
        valueFormatter: ({ value }) =>
          parseInt(value) > 0 ? parseInt(value) : 2000,
        renderCell: ({ value }) => (
          <Typography variant="body2">
            {parseInt(value) > 0 ? value : 2000}
          </Typography>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        renderHeader: () => (
          <HitlistTableHeading
            heading="Status"
            tooltip="The status of an article. You can change these options in the App settings."
          />
        ),
        minWidth: 180,
        maxWidth: 180,
        editable: true,
        type: "singleSelect",
        valueGetter: ({ value }) => value || "",
        valueOptions: config.hitlist.statuses.STATUS_SELECTIONS,
        renderCell: ({ value }) => (
          <div style={{ fontSize: "13px" }}>{value}</div>
        ),
      },
      {
        field: "assigned",
        headerName: "Assigned",
        renderHeader: () => (
          <HitlistTableHeading
            heading="Assigned"
            tooltip="Who this article is assigned to. You can add people to this list in the App settings."
          />
        ),
        minWidth: 120,
        maxWidth: 120,
        editable: true,
        type: "singleSelect",
        valueGetter: ({ value }) => value || "",
        valueOptions: config.hitlist.members.ADMINS,
        renderCell: ({ value }) => (
          <div style={{ fontSize: "13px" }}>{value}</div>
        ),
      },
      {
        field: "postType",
        headerName: "Post Type",
        renderHeader: () => (
          <HitlistTableHeading heading="Post Type" tooltip="" />
        ),
        minWidth: 120,
        maxWidth: 120,
        editable: true,
        type: "singleSelect",
        valueOptions: [],
      },
      {
        field: "notes",
        headerName: "Notes",
        renderHeader: () => (
          <HitlistTableHeading
            heading="Notes"
            tooltip="Your notes and instructions for each article idea. Notes are also included in hitlist exports."
          />
        ),
        editable: true,
        filterable: false,
        groupable: false,
        resizable: true,
        sortable: false,
        minWidth: 110,
        maxWidth: 110,
        valueFormatter: ({ value }) => {
          if (value === "Add your notes...") {
            return "";
          } else if (value) {
            return value;
          } else {
            return "";
          }
        },
        renderCell: (params) => (
          <GridCellExpand
            row={params.row}
            value={params.value}
            width={params.colDef.computedWidth}
            fontSize="12px"
            loading={params.row.loading}
            placeholder="Add your notes..."
          />
        ),
        renderEditCell: (params) => (
          <GridCellEditMultiline
            params={params}
            rows={3}
            sx={{ fontSize: "12px !important" }}
          />
        ),
      },
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        minWidth: viewOnly ? 0 : 30,
        maxWidth: viewOnly ? 0 : 30,
        type: "string",
        filterable: false,
        resizable: false,
        sortable: false,
        hideable: false,
        renderCell: ({ row, id, value }) => {
          if (viewOnly) return null;
          return (
            <HitlistTableIdeas
              id={id}
              value={value}
              apiRef={apiRef}
              loading={row.loading}
              generating={generating}
              viewOnly={viewOnly}
            />
          );
        },
      },
      {
        field: "expand",
        headerName: "Expand",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: 30,
        maxWidth: 30,
        disableColumnMenu: true,
        resizable: false,
        sortable: false,
        hideable: false,
        renderCell: ({ row, id, value }) => (
          <HitlistTableActionExpand
            id={id}
            row={row}
            handleExpandRow={() => handleExpandRow(row)}
            value={value}
            loading={row.loading}
            generating={generating}
          />
        ),
      },
      {
        field: "enhance",
        headerName: "Enhance",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: viewOnly ? 0 : 30,
        maxWidth: viewOnly ? 0 : 30,
        resizable: false,
        sortable: false,
        hideable: false,
        renderCell: ({ row, value }) => (
          <HitlistTableActionEnhance
            row={row}
            handleEnhance={() => handleEnhance(row)}
            loading={row.loading}
            generating={generating}
          />
        ),
      },
      {
        field: "delete",
        headerName: "Delete",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: viewOnly ? 0 : 30,
        maxWidth: viewOnly ? 0 : 30,
        resizable: false,
        sortable: false,
        hideable: false,
        renderCell: ({ row, value }) => (
          <HitlistTableActionDelete
            row={row}
            handleDelete={handleDelete}
            loading={row.loading}
            generating={generating}
          />
        ),
      },
      {
        field: "push",
        headerName: "Push",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: viewOnly ? 0 : 30,
        maxWidth: viewOnly ? 0 : 30,
        disableColumnMenu: true,
        resizable: false,
        sortable: false,
        hideable: false,
        renderCell: ({ row, value }) => (
          <HitlistTableActionPush
            row={row}
            handlePush={handlePush}
            generating={generating}
            loading={row.loading}
          />
        ),
      },
      {
        field: "pull",
        headerName: "Pull",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: 30,
        maxWidth: 30,
        disableColumnMenu: true,
        resizable: false,
        sortable: false,
        hideable: false,
        renderCell: ({ row, value }) => (
          <HitlistTableActionPull
            row={row}
            handlePull={handlePull}
            generating={generating}
            loading={row.loading}
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "",
        renderHeader: () => <HitlistTableHeading />,
        minWidth: 55,
        maxWidth: 55,
        align: "right",
        filterable: false,
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        getActions: ({ row, value }) => [
          <HitlistTableActionEnhanceMenu
            row={row}
            handleEnhance={() => handleEnhance(row)}
            loading={row.loading}
            generating={generating}
            hidden={showColumnsRef.current}
            showInMenu={true}
          />,
          <HitlistTableActionDeleteMenu
            row={row}
            handleDelete={handleDelete}
            loading={row.loading}
            generating={generating}
            hidden={showColumnsRef.current}
            showInMenu={true}
          />,
          <HitlistTableActionDivider
            showInMenu={true}
            label={"TITLE ACTIONS"}
          />,
          <HitlistTableActionRewrite
            row={row}
            handleTitleRewrite={(row) => handleTitleRewrite(row)}
            showInMenu={true}
            loading={row.loading}
            generating={generating}
          />,
          <HitlistTableActionDivider
            showInMenu={true}
            label={"KEYWORD ACTIONS"}
          />,
          <HitlistTableActionMoreKeywords
            row={row}
            handleGetKeywords={handleGetKeywords}
            showInMenu={true}
            loading={row.loading}
            generating={generating}
          />,
          <HitlistTableActionNewKeywords
            row={row}
            handleGetKeywords={handleGetKeywords}
            showInMenu={true}
            loading={row.loading}
            generating={generating}
          />,
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleStarred = useCallback(
    (row) => {
      let currentData = currData.current;
      let updatedData = currentData.map((item) => {
        if (item.uuid === row.uuid) {
          item.starred = !item.starred;
        }
        return item;
      });
      updateTableData(updatedData, tableId);
    },
    [updateTableData, tableId]
  );

  const getVolume = useCallback(
    async (row, keywords, targetCountry = "US") => {
      if (!row) return;

      row.loading = APP_CONSTANTS.LOADING_TYPE_VOLUME;

      setTable([...currData.current]);

      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      let totalVolume = 0;
      let opportunity = 0;
      let link = row.link;

      let keywordString = "";

      // convert the keyword array of objects to a string
      keywords.forEach((item) => {
        item = item.label;
        keywordString += item + ",";
      });

      if (keywords && keywords.length) {
        try {
          const newVolume = await SpyFuAPI.getKeywordInformation(
            controller.signal,
            keywordString,
            targetCountry || "US"
          );

          newVolume?.results.forEach((item) => {
            // TODO: Assign the individual volume number to each keyword label
            totalVolume += item.searchVolume;
            opportunity = Math.max(opportunity, item.rankingDifficulty);
            if (item.distinctCompetitors.length > 0) {
              link = StringUtils.extractURL(item.distinctCompetitors[0]);
            }
          });
        } catch (error) {
          console.log("🚀 ~ file: HitlistTable.js ~ getVolume ~ error:", error);
        }
      } else {
        totalVolume = 0;
        opportunity = 0;
      }

      const updatedData = currData.current.map((item) => {
        if (item.uuid === row.uuid) {
          return {
            ...item,
            volume: totalVolume,
            opportunity: opportunity,
            link: link,
            loading: false,
          };
        }
        return item;
      });

      updateTableData(updatedData, tableId);
    },
    [updateTableData, tableId]
  );

  const handleKeywordDelete = useCallback(
    async (row, keywords) => {
      let currentData = currData.current;

      let updatedData = currentData.map((item) => {
        if (item.uuid === row.uuid) {
          item.keywords = keywords;
          item.volume = 0;
          item.opportunity = 0;
        }
        return item;
      });

      updateTableData(updatedData, tableId);

      // get updated volume information only if the volume is greater than 0 to start with to avoid unnecessary API calls
      if (keywords.length > 0) {
        getVolume(row, row.keywords, getValue(STORAGE_KEYS.TARGET_COUNTRY));
      }
    },
    [updateTableData, getVolume, tableId]
  );

  const handleTitleRewrite = useCallback(
    async (row, resetHistory = false) => {
      apiRef.current.updateRows([
        { id: row.id, loading: APP_CONSTANTS.LOADING_TYPE_TITLE },
      ]);

      try {
        const controller = new AbortController();
        apiControllerRef.current.push(controller);

        let titleOptions = await reWriteTitle(
          controller.signal,
          row.title,
          config.hitlist.generate.titleRewrites,
          resetHistory,
          cookies.Settings.targetLanguage ||
            config.hitlist.generate.targetLanguage ||
            "en"
        );

        if (titleOptions.length > 0) {
          row.title =
            titleOptions[Math.floor(Math.random() * titleOptions.length) | 0];
          let currentData = currData.current;
          let updatedData = currentData.map((item) => {
            if (item.uuid === row.uuid) {
              item = row;
            }
            return item;
          });

          updateTableData(updatedData, tableId);
        }
      } catch (error) {
      } finally {
        apiRef.current.updateRows([{ id: row.id, loading: false }]);
      }
    },
    [apiRef, apiControllerRef, updateTableData, config, cookies, tableId]
  );

  const handleGetKeywords = useCallback(
    async (row, more = true) => {
      apiRef.current.updateRows([
        { id: row.id, loading: APP_CONSTANTS.LOADING_TYPE_KEYWORDS },
      ]);

      try {
        const controller = new AbortController();
        apiControllerRef.current.push(controller);

        let keywords = await getExtraKeywords(
          controller.signal,
          row.title,
          row.keywords,
          3,
          false,
          getValue(STORAGE_KEYS.TARGET_COUNTRY) ||
            config.hitlist.generate.targetCountry,
          cookies.Settings.targetLanguage ||
            config.hitlist.generate.targetLanguage
        );

        if (keywords.length > 0) {
          if (more) {
            row.keywords = [...row.keywords, ...keywords];
          } else {
            row.keywords = keywords;
          }

          // remove keywords with duplicate labels
          row.keywords = ArrayUtils.removeDuplicates(row.keywords, "label");

          let currentData = currData.current;
          let updatedData = currentData.map((item) => {
            if (item.uuid === row.uuid) {
              item = row;
            }
            return item;
          });

          updateTableData(updatedData, tableId);
        }
      } catch (error) {
      } finally {
        row.loading = false;
        getVolume(row, row.keywords, getValue(STORAGE_KEYS.TARGET_COUNTRY));
      }
    },
    [
      apiRef,
      apiControllerRef,
      updateTableData,
      getVolume,
      config,
      cookies,
      tableId,
    ]
  );

  const handleRowUpdate = useCallback(
    async (newRow, prevRow) => {
      let currentData = currData.current;
      let updatedData = currentData.map((item) => {
        if (item.uuid === newRow.uuid) {
          item = newRow;
        }
        return item;
      });

      updateTableData(updatedData, tableId);

      return newRow;
    },
    [updateTableData, tableId]
  );

  const handleExportRequest = (e) => {
    let filePrefix = "";
    let topic = StringUtils.replaceSpacesWithDashes(
      getValue(STORAGE_KEYS.TOPIC)
    ).toLowerCase();
    let topicTitle = StringUtils.titleCaseString(getValue(STORAGE_KEYS.TOPIC));

    switch (e.detail.data.format) {
      case "print":
        apiRef.current.exportDataAsPrint({
          fileName: `${topic}-${filePrefix}-hitlist`,
          hideFooter: true,
          hideToolbar: true,
          fields: ["title", "keywords", "volume", "opportunity", "notes"],
        });
        break;
      case "xls":
        apiRef.current.exportDataAsExcel({
          fileName: `${topic}-${filePrefix}-hitlist`,
          includeHeaders: true,
          fields: [
            "id",
            "title",
            "link",
            "keywords",
            "notes",
            "volume",
            "opportunity",
            "uuid",
          ],
          exceljsPreProcess: (doc) => {
            doc.category = "Hitlist";
            doc.subCategory = topic;
            doc.title = `${topic}-${filePrefix}-hitlist`;
            doc.worksheet.getCell(
              "A1"
            ).value = `Values from the: ${topicTitle} hitlist.`;
            doc.worksheet.addRow([]);
          },
          exceljsPostProcess: (doc) => {
            if (doc.worksheet.columns.length > 0) {
              let i = 0;
              doc.worksheet.columns.forEach((column) => {
                i++;
                switch (i) {
                  case 1:
                    column.width = 10;
                    column.alignment = { horizontal: "left" };
                    break;
                  case 2:
                    column.width = 80;
                    break;
                  case 3:
                    column.width = 45;
                    break;
                  case 4:
                    column.width = 85;
                    break;
                  case 5:
                    column.width = 85;
                    break;
                  case 6:
                    column.width = 12;
                    column.alignment = { horizontal: "center" };
                    break;
                  case 7:
                    column.width = 18;
                    column.alignment = { horizontal: "center" };
                    break;
                  case 8:
                    column.width = 45;
                    column.alignment = { horizontal: "left" };
                    break;
                  default:
                    column.width = 12;
                    column.alignment = { horizontal: "left" };
                    break;
                }
              });
            }
            // change the worksheet font size to 10
            doc.worksheet.eachRow(function (row) {
              row.font = { size: 9, name: "Lato" };
            });
            doc.worksheet.getRow(1).font = {
              bold: true,
              name: "Lato Black",
              size: 10,
              letter: 1,
            };
            doc.worksheet.getRow(3).font = {
              bold: true,
              name: "Lato Black",
              size: 10,
              letter: 1,
            };
            // doc.worksheet.getCell("B3").value = "Link";
            // doc.worksheet.getCell("H3").value = "Unique ID";
            doc.worksheet.name = topicTitle;
          },
        });
        break;
      case "contentAtScale":
        filePrefix = "c@s-";
        apiRef.current.exportDataAsCsv({
          fileName: `${topic}-${filePrefix}-hitlist`,
          includeHeaders: true,
          fields: ["title", "subheadings", "keywords", "notes"],
        });
        break;
      case "contentWarrior":
        filePrefix = "cw-";
        apiRef.current.exportDataAsCsv({
          fileName: `${topic}-${filePrefix}-hitlist`,
          includeHeaders: true,
          fields: ["postType", "title", "status", "keywords", "notes"],
        });
        break;
      case "shopia":
        filePrefix = "shopia-";
        apiRef.current.exportDataAsCsv({
          fileName: `${topic}-${filePrefix}-hitlist`,
          includeHeaders: true,
          fields: ["notes", "keywords", "title"],
        });
        break;
      case "weWriteBlogPosts":
        filePrefix = "wwbp-";
        apiRef.current.exportDataAsExcel({
          fileName: `${topic}-${filePrefix}-hitlist`,
          includeHeaders: true,
          fields: ["title", "wordCount", "subHeadings", "keywords", "notes"],
          exceljsPostProcess: (doc) => {
            if (doc.worksheet.columns.length > 0) {
              let i = 0;
              doc.worksheet.columns.forEach((column) => {
                i++;
                switch (i) {
                  case 1:
                    column.width = 50;
                    break;
                  case 2:
                    column.width = 10;
                    break;
                  case 3:
                    column.width = 50;
                    break;
                  case 4:
                    column.width = 50;
                    break;
                  case 5:
                    column.width = 50;
                    break;
                  default:
                    column.width = 20;
                    break;
                }
              });
            }
          },
        });

        break;
      case "csv":
      default:
        apiRef.current.exportDataAsCsv({
          fileName: `${topic}-${filePrefix}-hitlist`,
          fields: [
            "id",
            "type",
            "starred",
            "countryCode",
            "link",
            "title",
            "keywords",
            "volume",
            "opportunity",
            "notes",
          ],
        });
        break;
    }
  };

  /**
   * Enhances a single row of data by making an API call and updating the table display.
   * @param {object} row - The row object to enhance.
   * @param {boolean} resetHistory - Flag indicating whether to reset the enhancement history. Default is true.
   * @param {string} type - The type of article. Default is ARTICLE_TYPE_ENHANCED.
   * @returns {Promise<void>}
   */
  const handleEnhance = useCallback(
    async (
      row,
      resetHistory = true,
      type = APP_CONSTANTS.ARTICLE_TYPE_ENHANCED,
      getRelated = false
    ) => {
      if (!row) return;
      if (row.id === undefined || row.id === null) return;

      // Update the row in the table data to show that it is loading
      let initData = currData.current.map((item) =>
        item.uuid === row.uuid
          ? { ...item, loading: APP_CONSTANTS.LOADING_TYPE_ENHANCE }
          : item
      );

      // Update the table display
      setTable(initData);

      try {
        const controller = new AbortController();
        apiControllerRef.current.push(controller);

        // Call the enhanceSingleResult function to retrieve enhanced data
        let enhancedData = await enhanceSingleResult(
          controller.signal,
          row,
          getValue(STORAGE_KEYS.TOPIC),
          getValue(STORAGE_KEYS.HEADLINE_STYLE) ||
            config.settings.HEADLINE_STYLES[0].value,
          getRelated,
          resetHistory,
          getValue(STORAGE_KEYS.TARGET_COUNTRY) ||
            config.hitlist.generate.targetCountry,
          cookies.Settings.targetLanguage ||
            config.hitlist.generate.targetLanguage ||
            "en"
        );

        if (enhancedData) {
          const containsYear = enhancedData.title.match(/\d{4}/g);

          const updatedData = currData.current.map((item) =>
            item.uuid === row.uuid
              ? {
                  ...item,
                  countryCode:
                    getValue(STORAGE_KEYS.TARGET_COUNTRY) || item.countryCode,
                  loading: false,
                  type: type,
                  title: containsYear
                    ? StringUtils.replaceYear(enhancedData.title)
                    : enhancedData.title,
                  keywords: enhancedData.keywords,
                }
              : {
                  ...item,
                }
          );

          updateTableData(updatedData, tableId);

          getVolume(
            row,
            enhancedData.keywords,
            getValue(STORAGE_KEYS.TARGET_COUNTRY)
          );
        }
      } catch (error) {
        // Update the table data if an error occurs
        const updatedData = currData.current.map((item) =>
          item.uuid === row.uuid ? { ...item, loading: false } : item
        );

        updateTableData(updatedData, tableId);
      }
    },
    [updateTableData, getVolume, config, cookies, tableId]
  );

  /**
   * Closes all expanded rows in the table.
   * @returns {void}
   */
  const closeAllExpandedRows = useCallback(() => {
    // Get the array of expanded rows using the getExpandedDetailPanels function of the apiRef
    const expandedRows = apiRef.current.getExpandedDetailPanels();

    let i = 0,
      l = expandedRows.length;

    // Iterate over the expandedRows array and toggle the detail panel for each row
    for (i = 0; i < l; i++) {
      apiRef.current.toggleDetailPanel(expandedRows[i]);
    }
  }, [apiRef]);

  const handleBulkPush = useCallback(async () => {
    const currentData = currData.current;
    const selectedRows = apiRef.current.getSelectedRows();
    let i,
      l = currentData.length;

    // iterate over the selected rows and delete each one
    for (i = 0; i < l; i++) {
      if (selectedRows.has(currentData[i].id)) {
        try {
          await handlePush(currentData[i]);
        } catch (error) {
          console.log(error);
        }
      }
    }

    // clear the selected rows
    apiRef.current.setRowSelectionModel([]);

    // show success message as a toast
    publish(
      ToastEvents.SUCCESS,
      `Moved ${selectedRows.size} rows to the Final Hitlist tab`
    );
  }, [apiRef, handlePush]);

  const handleBulkPull = useCallback(async () => {
    const currentData = currData.current;
    const selectedRows = apiRef.current.getSelectedRows();
    let i,
      l = currentData.length;

    // iterate over the selected rows and delete each one
    for (i = 0; i < l; i++) {
      if (selectedRows.has(currentData[i].id)) {
        try {
          await handlePull(currentData[i]);
        } catch (error) {
          console.log(error);
        }
      }
    }

    // clear the selected rows
    apiRef.current.setRowSelectionModel([]);

    // show success message as a toast
    publish(
      ToastEvents.SUCCESS,
      `Moved ${selectedRows.size} rows to the Research tab`
    );
  }, [apiRef, handlePull]);

  const handleBulkDelete = useCallback(async () => {
    const currentData = currData.current;
    const selectedRows = apiRef.current.getSelectedRows();
    let i,
      l = currentData.length;

    // iterate over the selected rows and delete each one
    for (i = 0; i < l; i++) {
      if (selectedRows.has(currentData[i].id)) {
        try {
          await handleDelete(currentData[i]);
        } catch (error) {
          console.log(error);
        }
      }
    }

    // clear the selected rows
    apiRef.current.setRowSelectionModel([]);

    closeAllExpandedRows();

    // show success message as a toast
    publish(
      ToastEvents.SUCCESS,
      `Successfully deleted ${selectedRows.size} rows`
    );
  }, [apiRef, currData, closeAllExpandedRows, handleDelete]);

  const handleBulkRewriteTitles = useCallback(() => {
    let selectedRows = apiRef.current.getSelectedRows();

    // for each selected row, call the rewrite function
    selectedRows.forEach(async (row) => {
      try {
        await handleTitleRewrite(row, true);
      } catch (error) {
        console.log(error);
      }
    });

    apiRef.current.setRowSelectionModel([]);
  }, [apiRef, handleTitleRewrite]);

  const handleBulkKeywords = useCallback(() => {
    let selectedRows = apiRef.current.getSelectedRows();

    // for each selected row, call the rewrite function
    selectedRows.forEach(async (row) => {
      await handleGetKeywords(row);
    });

    // clear the selected rows
    apiRef.current.setRowSelectionModel([]);
  }, [apiRef, handleGetKeywords]);

  const handleBulkEnhance = useCallback(
    async (rows) => {
      let selectedRows = Array.isArray(rows)
        ? rows
        : apiRef.current.getSelectedRows();

      if (selectedRows.length === 0) {
        return;
      }

      selectedRows.forEach(async (row) => {
        await handleEnhance(
          row,
          true,
          row.initialized ? APP_CONSTANTS.ARTICLE_TYPE_ENHANCED : row.type
        );
      });

      // clear the selected rows
      apiRef.current.setRowSelectionModel([]);
    },
    [apiRef, handleEnhance]
  );

  const handleBulkRemoveSimilar = useCallback(
    async (rows) => {
      let selectedRows = Array.isArray(rows)
        ? rows
        : apiRef.current.getSelectedRows();

      if (selectedRows.length === 0) {
        return;
      }

      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      publish(ToastEvents.INFO, "Removing similar articles...");

      let similarRows = await SimilarityApi.findSimilarRows(
        controller.signal,
        selectedRows
      );

      if (similarRows) {
        // create an array of uuid values
        const similarUuids = similarRows.map((row) => row.uuid);

        // filter out items whose uuid is in the similarUuids array
        let updatedData = currData.current.filter((item) => {
          return !similarUuids.includes(item.uuid);
        });

        // re-index the array
        updatedData = ArrayUtils.reIndex(updatedData);

        updateTableData(updatedData, tableId);

        publish(ToastEvents.SUCCESS, "Successfully removed similar articles");
      }

      // clear the selected rows
      apiRef.current.setRowSelectionModel([]);
    },
    [apiRef, updateTableData, currData, tableId]
  );

  /**
   * Bulk star the selected rows
   * @returns {Promise<void>}
   * @private
   */
  const handleBulkStarred = useCallback(async () => {
    let selectedRows = apiRef.current.getSelectedRows();

    // for each selected row, call the star function
    selectedRows.forEach((row) => {
      handleStarred(row);
    });

    // clear the selected rows
    apiRef.current.setRowSelectionModel([]);
  }, [apiRef, handleStarred]);

  /**
   * Bulk combine the selected rows
   * @returns {Promise<void>}
   * @private
   */
  const handleCombineRows = useCallback(async () => {
    let selectedRows = apiRef.current.getSelectedRows();

    const firstRow = Array.from(selectedRows.entries())[0][1];

    // for each selected row, combine them into one row, and then delete the other rows
    let combinedRow = {
      id: firstRow.id,
      title: firstRow.title,
      link: firstRow.link,
      type: firstRow.type,
      keywords: firstRow.keywords,
      volume: firstRow.volume,
      opportunity: firstRow.opportunity,
      countryCode: firstRow.countryCode,
      loading: false,
      initialized: true,
    };

    selectedRows.forEach((row) => {
      // combinedRow.title += ` ${row.title}`;
      combinedRow.keywords = [...combinedRow.keywords, ...row.keywords];
      combinedRow.volume = Math.max(combinedRow.volume, row.volume);
      combinedRow.opportunity =
        row.opportunity > 0
          ? Math.min(combinedRow.opportunity, row.opportunity)
          : combinedRow.opportunity;
    });

    // remove duplicates from the keywords array
    combinedRow.keywords = [...new Set(combinedRow.keywords)];

    // remove the selected rows from the table
    let currentData = currData.current;
    let updatedData = [];
    let i,
      l = currentData.length;

    for (i = 0; i < l; i++) {
      if (!selectedRows.has(currentData[i].id)) {
        updatedData.push(currentData[i]);
      } else if (combinedRow.id === currentData[i].id) {
        updatedData.push(combinedRow); // add the combined row to the table
      }
    }

    updateTableData(updatedData, tableId);

    apiRef.current.setRowSelectionModel([]);

    closeAllExpandedRows();

    // show success message as a toast
    publish(
      ToastEvents.SUCCESS,
      `Successfully combined ${selectedRows.size} rows`
    );
  }, [apiRef, updateTableData, closeAllExpandedRows, tableId]);

  /**
   * Toggles the expand state of a row and updates the table display.
   * @param {object} row - The row object to expand.
   * @returns {void}
   */
  const handleExpandRow = useCallback(
    (row) => {
      if (!row || row.uuid === undefined || row.uuid === null) return;

      // Toggle the expand state of the row
      row.expand = !row.expand;

      // Update the table display by calling the updateRows function of the apiRef
      apiRef.current.updateRows([row]);
    },
    [apiRef]
  );

  /**
   * Toggles the condensed state of the table.
   * @returns {void}
   */
  const handleCondenseRows = useCallback(() => {
    condensedRef.current = !condensedRef.current;
    updateTableData(
      currData.current.map(
        (item) => ({
          ...item,
          expand: condensedRef.current,
        }),
        tableId
      )
    );
  }, [updateTableData, tableId]);

  const handleShowColumns = useCallback(() => {
    showColumnsRef.current = !showColumnsRef.current;
    setShowColumns(showColumnsRef.current);
    updateTableData(currData.current, tableId);
  }, [updateTableData, tableId]);

  /**
   * Updates the title of a row.
   * @param {Object} row The row to update
   * @param {String} title The new title
   */
  const handleTitleUpdate = useCallback(
    (row, title) => {
      row.title = title;
      updateTableData(
        currData.current.map((item) => (item.uuid === row.uuid ? row : item)),
        tableId
      );
    },
    [updateTableData, tableId]
  );

  /**
   * Updates the keywords of a row.
   * @param {Object} row The row to update
   * @param {String} keyword The new keyword
   */
  const handleKeywordsUpdate = useCallback(
    (row, keyword) => {
      row.keywords.unshift(keyword);
      updateTableData(
        currData.current.map((item) => (item.uuid === row.uuid ? row : item)),
        tableId
      );
      // get updated volume information
      getVolume(row, row.keywords, getValue(STORAGE_KEYS.TARGET_COUNTRY));
    },
    [updateTableData, getVolume, tableId]
  );

  /**
   * Adds a new row to the table.
   * @param {Object} row The new row to add
   * @param {String} article The title of the article
   */
  const handleArticleUpdate = useCallback(
    async (row, article) => {
      let controller = new AbortController();
      apiControllerRef.current.push(controller);

      const newData = {
        ...row,
        loading: false,
        expand: false,
        initialized: true,
        id: row.id + 1,
        type: APP_CONSTANTS.ARTICLE_TYPE_AI_IDEA,
        starred: false,
        link: ``,
        title: article,
        status: "Select a status",
        assigned: "Assign",
      };

      const currentData = JSON.parse(JSON.stringify(currData.current));
      currentData.splice(row.id + 1, 0, newData);

      updateTableData(currentData, tableId);
    },
    [updateTableData, tableId]
  );

  /**
   * Calculates the remaining view height below the input fields.
   * @returns {number} The remaining view height.
   * @todo Make the offset dynamic.
   */
  const getRemainingViewHeight = useCallback(() => {
    const viewportHeight = window.innerHeight;
    const bodyHeight = document.body.clientHeight;
    let remainingHeight = viewportHeight - bodyHeight;
    return Math.max(10, remainingHeight - 265);
  }, []);

  const getDetailPanelContent = useCallback((data) => {
    return (
      <HitlistTableMoreDetails
        data={data}
        onTitleUpdate={handleTitleUpdate}
        onKeywordsUpdate={handleKeywordsUpdate}
        onArticleUpdate={handleArticleUpdate}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function CustomToolbar() {
    return (
      <HitlistTableCustomToolbar
        apiRef={apiRef}
        handleBulkDelete={handleBulkDelete}
        handleBulkRewriteTitles={handleBulkRewriteTitles}
        handleBulkEnhance={handleBulkEnhance}
        handleCombineRows={handleCombineRows}
        handleBulkKeywords={handleBulkKeywords}
        handleCondenseRows={handleCondenseRows}
        handleShowColumns={handleShowColumns}
        handleBulkStarred={handleBulkStarred}
        handleFullScreen={handleFullScreen}
        handleBulkRemoveSimilar={handleBulkRemoveSimilar}
        handleBulkPush={handleBulkPush}
        handleBulkPull={handleBulkPull}
        isFullScreen={isFullScreen}
        condensedRows={condensedRef.current}
        showColumns={showColumns}
        disabled={!selectionModel.length}
        rows={rowData}
        viewOnly={viewOnly}
        columnVisibility={columnVisibility}
      />
    );
  }

  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuGroupingItem: null,
          columnMenuAggregationItem: null,
          columnMenuPinningItem: null,
          columnMenuColumnsItem: null,
        }}
      />
    );
  }

  const getRowId = useCallback((row) => row.id, []);

  const handleFullScreen = useCallback((fullScreen) => {
    fullScreenRef.current = fullScreen;
    // setIsFullScreen(fullScreenRef.current);
  }, []);

  const handleChatRequest = () => {
    setHidden(false);
  };

  const handleChatClose = () => {
    setHidden(true);
  };

  const handleRowOrderChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      tableData
    );

    updateTableData(newRows, tableId);
  };

  const setTable = (data) => {
    if (!Array.isArray(data)) return;

    currData.current = data;

    setRowData(data);

    // storeValue(STORAGE_KEYS.HITLIST, data);
  };

  const setTableState = () => {
    const state = apiRef.current.exportState();
    storeValue(STORAGE_KEYS.HITLIST_STATE, state);
  };

  useEffect(() => {
    showColumnsRef.current = !condensed;
    setShowColumns(showColumnsRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condensed]);

  useEffect(() => {
    if (
      tableData === null ||
      tableData === undefined ||
      !Array.isArray(tableData)
    ) {
      console.warn("Table data is not in the correct format: ", tableData);
      return;
    }

    // here we don't want to call updateTableData, because we don't want to save to the database again and get caught in a loop. Instead we just want to display the new data.
    setTable(tableData);

    // set the row sorting and filtering as it was before
    setTableState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  useEffect(() => {
    let apiCalls = apiControllerRef.current;

    // listen for when the table source data changes
    subscribe(CustomEvents.EXPORT_HITLIST, handleExportRequest);
    subscribe(CustomEvents.CHAT, handleChatRequest);
    subscribe(CustomEvents.CHAT_CLOSED, handleChatClose);

    return () => {
      // cancel any pending api requests
      apiCalls.forEach((controller) => controller.abort());

      // unsubscribe from all events
      unsubscribe(CustomEvents.EXPORT_HITLIST, handleExportRequest);
      unsubscribe(CustomEvents.CHAT, handleChatRequest);
      unsubscribe(CustomEvents.CHAT_CLOSED, handleChatClose);
    }; //cleanup

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      flex
      sx={{
        backgroundColor: theme.palette.background.main,
        border: `1px solid ${theme.palette.divider}`,
        mb: 2,
      }}
      className={
        (isFullScreen ? "full-screen" : "") + " hitlist-table-container"
      }
      key={`table-${tableId}`}
    >
      <Box
        hidden={hidden}
        sx={{ height: "calc(100vh - 247px)", overflow: "hidden" }}
      >
        <HitlistTableWait />
      </Box>
      <Box
        className={
          (isFullScreen ? "full-screen" : "") + " hitlist-table-holder"
        }
        hidden={!hidden}
        position="relative"
      >
        <DataGridPremium
          sx={{
            "@media print": {
              "[data-field='__check__']": {
                display: "none",
              },
              "[data-field='__reorder__']": {
                display: "none",
              },
              "[data-field='__detail_panel_toggle__']": {
                display: "none",
              },
              "[data-field='actions']": {
                display: "none",
              },
              "[data-field='link']": {
                display: "none",
              },
            },
          }}
          rows={rowData}
          columns={columns}
          rowReordering={!viewOnly}
          hideFooter
          checkboxSelection={!viewOnly}
          disableRowSelectionOnClick
          disableAggregation
          // disableColumnSelector
          columnVisibilityModel={{
            __detail_panel_toggle__: columnVisibility?.ideas ?? !viewOnly,
            actions: !viewOnly,
            assigned: columnVisibility?.assigned ?? false,
            countryCode: viewOnly || showColumns,
            delete: !viewOnly && showColumns,
            difficulty: false,
            enhance: showColumns,
            expand: columnVisibility?.expand ?? true,
            id: false,
            link: showColumns,
            notes: !viewOnly && showColumns,
            postType: false,
            push: !viewOnly && (columnVisibility?.push ?? false),
            pull: !viewOnly && (columnVisibility?.pull ?? false),
            ranked: false,
            source: false,
            starred: true,
            status: columnVisibility?.status ?? false,
            subHeadings: false,
            title: true,
            type: false,
            uuid: false,
            wordCount: false,
          }}
          groupingColDef={{
            headerName: "Group",
          }}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: cookies.Settings
                    ? cookies.Settings.sortBy
                    : config.hitlist.generate.sortBy,
                  sort: cookies.Settings
                    ? cookies.Settings.sortOrder
                    : config.hitlist.generate.sortOrder,
                },
              ],
            },
            rowReordering: true,
            pagination: { paginationModel: { pageSize: 50 } },
          }}
          className={
            (tableData &&
              tableData.length &&
              "hitlist-table hitlist-table-full") ||
            "hitlist-table hitlist-table-empty"
          }
          apiRef={apiRef}
          getRowId={getRowId}
          onRowOrderChange={handleRowOrderChange}
          onSortModelChange={() => {
            setTableState();
          }}
          onFilterModelChange={() => {
            setTableState();
          }}
          onRowGroupingModelChange={() => {
            setTableState();
          }}
          onPinnedColumnsChange={() => {
            setTableState();
          }}
          onColumnResize={() => {
            setTableState();
          }}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={({ row }) => "auto"}
          getRowHeight={({ row }) => "auto"}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          density={getRemainingViewHeight() < 650 ? "compact" : "standard"}
          editMode="cell"
          color="secondary"
          slots={{
            columnHeaders: MemoizedColumnHeaders,
            detailPanelExpandIcon: TipsAndUpdatesOutlinedIcon,
            // loadingOverlay: HitlistTableLoader,
            noRowsOverlay: HitlistTableWait,
            row: MemoizedRow,
            toolbar: CustomToolbar,
            columnMenu: CustomColumnMenu,
          }}
          // loading={props.loading}
          processRowUpdate={handleRowUpdate}
        />
      </Box>
    </Box>
  );
}

export default memo(HitlistTable);
