import { memo } from "react";
import { ThemeProvider, Button, Grid, Typography, Box } from "@mui/material";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";

function PageNotFound(props) {
  const { theme } = props;

  return (
    <ThemeProvider theme={theme}>
      <Grid
        className="scrollable"
        container
        spacing={0}
        columns={12}
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "calc(100vh - 60px)" }}
      >
        <Grid item id="pricing" xs={12}>
          <Typography component="h1" variant="h2" align="center" gutterBottom>
            404
          </Typography>
          <Typography component="h1" variant="h4" align="center">
            Whoops! Page Not Found
          </Typography>
          <Typography
            component="p"
            variant="subtitle"
            align="center"
            sx={{ mt: 3 }}
          >
            Sometimes finding the right content can be hit and miss right?
          </Typography>
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Button
              variant="contained"
              color="secondary"
              href="/hitlist"
              size="large"
              sx={{ width: 200, mx: "auto" }}
              startIcon={<KeyboardArrowLeftOutlinedIcon />}
            >
              Back To Hitlist
            </Button>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default memo(PageNotFound);
