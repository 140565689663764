import { memo, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const MobileRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      navigate("/mobile");
    }
  }, [navigate]);

  return null;
};

export default memo(MobileRedirect);
