import { memo, useContext, useEffect, useRef, useState } from "react";
import HintsAccordion from "./HintsAccordion";
import { AppConfigContext } from "../../../AppConfigContext";
import {
  CustomEvents,
  publish,
  subscribe,
  unsubscribe,
} from "../../Events/CustomEvents";
import { getRelatedKeywords } from "../../../Bots/KeywordBot";
import { getValue, STORAGE_KEYS, storeValue } from "../../../Storage";
import { useCookies } from "react-cookie";
import ArrayUtils from "../../../Utils/ArrayUtils";
import { ToastEvents } from "../../../Toast/Toast";
import StringUtils from "../../../Utils/StringUtils";
import { Grid } from "@mui/material";

function KeywordHints(props) {
  const {
    appInitialized,
    disableChat,
    disableOpenAll,
    generating,
    handleChange,
    keywords,
    msg,
    saveAllData,
    expanded,
    topic,
    handleKeywordsLoading,
    viewOnly,
  } = props;

  const [hints, setHints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hidden, setHidden] = useState(false);
  const [gridSize, setGridSize] = useState(6);

  const [cookies] = useCookies();

  const config = useContext(AppConfigContext);

  const currKeywords = useRef([]);
  const topicRef = useRef(getValue(STORAGE_KEYS.TOPIC));
  const maxKeywordsRef = useRef(5);
  const apiControllerRef = useRef([]);

  // Get a unique identifier for this component to use with the accordion
  const type = config.hitlist.constants.STEP_KEYWORDS;

  const handleHistoryChanged = (e) => {
    updateKeywords(e.detail.data.keywords, true);
  };

  const handleClick = (e) => {};

  const handleCancel = () => {
    apiControllerRef.current.forEach((controller) => controller.abort());
    setLoading(false);
  };

  const handleClear = () => {
    updateKeywords([]);
  };

  const handleMore = () => {
    getKeywords(topicRef.current || getValue(STORAGE_KEYS.TOPIC), true);
  };

  const handleMoreLikeThis = (topic = null) => {
    if (topic) getKeywords(topic, true, false);
  };

  const handleRefresh = (e) => {
    getKeywords(topicRef.current || getValue(STORAGE_KEYS.TOPIC), false, true);
  };

  const handleErrorClose = () => {
    setErrorMessage("");
  };

  const handleDelete = (keywordToDelete) => () => {
    let newKeywords = currKeywords.current.filter(
      (keyword) => keyword !== keywordToDelete
    );
    updateKeywords(newKeywords);
  };

  const addKeyword = (keyword) => {
    // if the keyword string is comma separated, split it into an array
    if (keyword.includes(",")) {
      let splitHint = keyword.split(",");
      splitHint.forEach((h) => {
        addKeyword(h.trim());
      });
      return;
    }

    let newKeyword = { label: keyword, index: currKeywords.current.length };
    let newKeywords = [...currKeywords.current, newKeyword];

    updateKeywords(newKeywords);

    publish(
      ToastEvents.SUCCESS,
      `Keyword "${keyword}" added to Research Keywords`
    );
  };

  const getKeywords = async (
    newTopic = null,
    more = false,
    refresh = false
  ) => {
    if (!newTopic) {
      publish(
        ToastEvents.ERROR,
        "Please enter a topic before getting related keywords"
      );
      return;
    }

    if (refresh) {
      updateKeywords([]);
    }

    setLoading(true);
    handleKeywordsLoading(true);
    setErrorMessage("");

    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      let keywords = await getRelatedKeywords(
        controller.signal,
        newTopic,
        maxKeywordsRef.current,
        more,
        getValue(STORAGE_KEYS.TARGET_COUNTRY) ||
          config.hitlist.generate.targetCountry,
        cookies.Settings.targetLanguage ||
          config.hitlist.generate.targetLanguage
      );

      // append the new keywords to the existing list
      currKeywords.current = more
        ? [...currKeywords.current, ...keywords]
        : keywords;

      // make sure all items are unique
      currKeywords.current = ArrayUtils.uniqByKeepFirst(
        currKeywords.current,
        (item) => item.label
      );

      updateKeywords(currKeywords.current);
    } catch (err) {
      console.log("🚀 ~ file: KeywordHints.js:149 ~ KeywordHints ~ err:", err);
      updateKeywords(currKeywords.current || []);
    } finally {
      setLoading(false);
      handleKeywordsLoading(false);
    }
  };

  const handleKeywordAdded = (e) => {
    if (e.detail.data.keyword) {
      // if it is not already in the list, add it
      let hintToAdd = e.detail.data.keyword;
      let currentKeywords =
        currKeywords.current || getValue(STORAGE_KEYS.RELATED_TERMS) || [];

      if (
        !currentKeywords.some(
          (item) => item.label.toLowerCase() === hintToAdd.toLowerCase()
        )
      ) {
        addKeyword(hintToAdd);
      } else {
        publish(
          ToastEvents.ERROR,
          `"${StringUtils.capitalizeFirstLetter(
            hintToAdd
          )}" is already in your Research Keywords list.`
        );
      }
    }
  };

  const updateKeywords = (newKeywords) => {
    if (newKeywords === null || newKeywords === undefined) return;
    if (!Array.isArray(newKeywords)) return;

    setKeywords(newKeywords);

    saveAllData();
  };

  const handleChat = () => {
    publish(CustomEvents.CHAT, {
      data: {
        type: type,
        hints: hints,
        topic: topicRef.current,
        mode: config.hitlist.constants.GUIDE_CHAT_MODE,
      },
    });
  };

  const handleChatRequest = (e) => {
    setHidden(e.detail.data.type === config.hitlist.constants.STEP_DOMAIN);
    setGridSize(
      e.detail.data.type === config.hitlist.constants.STEP_NICHE ? 12 : 6
    );
  };

  const handleChatClosed = (e) => {
    setHidden(false);
    setGridSize(6);
  };

  const setKeywords = (keywords) => {
    if (!Array.isArray(keywords)) return;

    // convert the keywords to an array of objects if it is an array of strings (for backwards compatibility)
    if (keywords.length > 0 && typeof keywords[0] === "string") {
      keywords = keywords.map((keyword, index) => {
        return { label: keyword, index: index };
      });
    }

    currKeywords.current = keywords;
    setHints(keywords);
    storeValue(STORAGE_KEYS.RELATED_TERMS, keywords);
  };

  // Every time the topic changes, generate new keywords
  useEffect(() => {
    if (!topic || !appInitialized) return;
    if (topicRef.current !== topic) {
      // getKeywords(topic, true, false);
      topicRef.current = topic;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  useEffect(() => {
    if (!Array.isArray(keywords)) return;
    setKeywords(keywords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  useEffect(() => {
    // get the current max keywords from settings
    maxKeywordsRef.current = cookies.Settings
      ? cookies.Settings.maxKeywords
      : config.hitlist.generate.maxKeywordsGenerated || 5;

    // initialize the api controller
    let apiCalls = apiControllerRef.current;

    // subscribe to events
    subscribe(CustomEvents.KEYWORD_ADD, handleKeywordAdded);
    subscribe(CustomEvents.HISTORY_CHANGED, handleHistoryChanged);
    subscribe(CustomEvents.CHAT, handleChatRequest);
    subscribe(CustomEvents.CHAT_CLOSED, handleChatClosed);

    return () => {
      // cancel any pending api calls
      apiCalls.forEach((controller) => controller.abort());

      // unsubscribe from all events
      unsubscribe(CustomEvents.KEYWORD_ADD, handleKeywordAdded);
      unsubscribe(CustomEvents.HISTORY_CHANGED, handleHistoryChanged);
      unsubscribe(CustomEvents.CHAT, handleChatRequest);
      unsubscribe(CustomEvents.CHAT_CLOSED, handleChatClosed);

      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={gridSize} hidden={hidden}>
      <HintsAccordion
        disableChat={disableChat}
        disableOpenAll={disableOpenAll}
        errorMessage={errorMessage}
        expanded={expanded}
        generating={generating}
        getMore={true}
        handleCancel={handleCancel}
        handleChange={handleChange}
        handleChat={handleChat}
        handleClear={handleClear}
        handleClick={handleClick}
        handleDelete={handleDelete}
        handleErrorClose={handleErrorClose}
        handleHintAdded={addKeyword}
        handleMore={handleMore}
        handleMoreLikeThis={handleMoreLikeThis}
        handleRefresh={handleRefresh}
        hints={hints}
        loading={loading}
        more={maxKeywordsRef.current}
        msg={msg}
        type={type}
        viewOnly={viewOnly}
      />
    </Grid>
  );
}

export default memo(KeywordHints);
