import secureLocalStorage from "react-secure-storage";

const STORAGE_KEYS = {
  AUTH: "hitlistAuth",
  CREDITS: "hitlistCredits",
  CURRENT_STATE: "hitlistCurrentState",
  DESCRIPTION: "hitlistDescription",
  DOMAIN: "hitlistDomain",
  DOMAINS: "hitlistDomains",
  FUTURE_STATES: "hitlistFutureStates",
  HEADLINE_STYLE: "hitlistHeadlineStyle",
  HITLIST: "hitlist",
  HITLIST_STATE: "hitlistState",
  ID: "hitlistId",
  NOTIFICATIONS_LAST_READ: "hitlistNotificationsLastRead",
  PAST_STATES: "hitlistPastStates",
  RELATED_TERMS: "hitlistRelatedTerms",
  TABS: "hitlistTabs",
  TARGET_COUNTRY: "hitlistTargetCountry",
  TARGET_LANGUAGE: "hitlistTargetLanguage",
  TITLE: "hitlistTitle",
  TOPIC: "hitlistTopic",
  VERSION: "hitlistVersion",
};

const storeValue = (key, value, encrypted = true) => {
  if (encrypted) {
    secureLocalStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const getValue = (key, encrypted = true) => {
  if (encrypted) {
    if (
      secureLocalStorage.getItem(key) &&
      secureLocalStorage.getItem(key) !== "undefined"
    ) {
      return JSON.parse(secureLocalStorage.getItem(key));
    } else {
      return null;
    }
  } else {
    if (
      localStorage.getItem(key) &&
      localStorage.getItem(key) !== "undefined"
    ) {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return null;
    }
  }
};

const removeValue = (key) => {
  secureLocalStorage.removeItem(key);
};

const clearStorage = () => {
  secureLocalStorage.clear();
};

const getAllValues = () => {
  return localStorage;
};

const getAllKeys = () => {
  let keys = [];

  for (let i = 0; i < localStorage.length; i++) {
    keys.push(localStorage.key(i));
  }

  return keys;
};

const getAllValuesAsArray = () => {
  let values = [];

  for (let i = 0; i < localStorage.length; i++) {
    values.push(secureLocalStorage.getItem(localStorage.key(i)));
  }

  return values;
};

const getAllValuesAsObject = () => {
  let values = {};

  for (let i = 0; i < localStorage.length; i++) {
    values[localStorage.key(i)] = secureLocalStorage.getItem(
      localStorage.key(i)
    );
  }

  return values;
};

const getAllValuesAsJson = () => {
  let values = {};

  for (let i = 0; i < localStorage.length; i++) {
    values[localStorage.key(i)] = secureLocalStorage.getItem(
      localStorage.key(i)
    );
  }

  return JSON.stringify(values);
};

const getAllValuesAsJsonPretty = () => {
  let values = {};

  for (let i = 0; i < localStorage.length; i++) {
    values[localStorage.key(i)] = secureLocalStorage.getItem(
      localStorage.key(i)
    );
  }

  return JSON.stringify(values, null, 2);
};

export {
  storeValue,
  getValue,
  removeValue,
  getAllValues,
  getAllKeys,
  getAllValuesAsArray,
  getAllValuesAsObject,
  getAllValuesAsJson,
  getAllValuesAsJsonPretty,
  clearStorage,
  STORAGE_KEYS,
};
