import { memo } from "react";
import { Box, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import { APP_CONSTANTS } from "../../../../Constants";
import { BallSpinner } from "react-spinners-kit";
import { useTheme } from "@mui/material/styles";

function HitlistTableActionEnhance(props) {
  const { handleEnhance, loading, showInMenu } = props;

  const theme = useTheme();

  return (
    <>
      <GridActionsCellItem
        icon={
          loading === APP_CONSTANTS.LOADING_TYPE_ENHANCE ? (
            <Box sx={{ width: 20 }}>
              <BallSpinner size={15} color={theme.palette.muted} />
            </Box>
          ) : (
            <Tooltip title="Enhance Idea">
              <AutoFixHighOutlinedIcon fontSize="small" />
            </Tooltip>
          )
        }
        label={
          loading === APP_CONSTANTS.LOADING_TYPE_ENHANCE
            ? "Enhancing..."
            : "Enhance"
        }
        onClick={
          loading === APP_CONSTANTS.LOADING_TYPE_ENHANCE ? "" : handleEnhance
        }
        disabled={loading !== false}
        showInMenu={showInMenu}
      />
    </>
  );
}

export default memo(HitlistTableActionEnhance);
