import { GPT3 } from "../Apis/OpenAiApi";
import { publish } from "../Hitlist/Events/CustomEvents";
import { ToastEvents } from "../Toast/Toast";
import StringUtils from "../Utils/StringUtils";

let chatHistory = [];

const resetHistory = (systemObject) => {
  chatHistory = [systemObject];
};

// const reWriteTitle = async (
//     signal,
//     title,
//     totalOptions = 5,
//     resetHistory = false,
//     targetLanguage = "en"
//   ) => {
//     if (!signal) return [];
//     if (!title) return [];

//     if (resetHistory) resetChatHistory();

//     let userPrompt = {
//       role: "user",
//       content: `Rewrite this title in ${totalOptions} different ways to be more descriptive for the reader, SEO-friendly, and targeted at ${targetLanguage}. Remove any years from the new titles. Return the results as an object that contains an array of titles attached to a data node in this exact format: {data: [title1, title2, title 3]}: ${title}`,
//     };

//     hitlistChatHistory.push(userPrompt);

//     try {
//       let response = await GPT3.generateChat(signal, hitlistChatHistory);

//       if (response) {
//         if (response.choices.length > 0) {
//           hitlistChatHistory.push({
//             role: "assistant",
//             content: response.choices[0].message.content,
//           });

//           let responseObject = StringUtils.extractObjectFromString(
//             response.choices[0].message.content
//           );

//           return responseObject.data;
//         } else {
//           throw new Error("No title found in response: ", response);
//         }
//       } else {
//         throw new Error("Response doesn't look valid: ", response);
//       }
//     } catch (err) {
//       if (err.status === 402) publish(ToastEvents.ERROR, err.message);
//       return null;
//     }
//   };

const rewriteTitle = async (
  signal,
  headline,
  topic,
  headlineStyle = "SEO headline",
  totalOptions = 5,
  targetCountry = "US",
  targetLanguage = "en",
  reset = false
) => {

  const languageName = StringUtils.getLanguageCodeName(targetLanguage);
  const countryName = StringUtils.getCountryCodeName(targetCountry);

  const systemObject = {
    role: "system",
    content: `You are an copywriter that is an expert in writing ${languageName} headlines for ${countryName} readers. You are writing ${headlineStyle}s for ${topic} articles. Return the results as an object that contains an array of titles attached to a data node in this exact format: {titles: [title1, title2, ..., title${totalOptions}]}`,
  };

  if (reset || chatHistory.length === 0) resetHistory(systemObject);

  const returnResultSchema = [
    {
      name: "process_titles",
      description: "Process titles",
      parameters: {
        type: "object",
        properties: {
          titles: {
            type: "array",
            length: totalOptions,
            items: {
              type: "string",
              description: `${totalOptions} new titles`,
            },
          },
        },
        required: ["titles"],
      },
    },
  ];

  try {
    let userPrompt = {
      role: "user",
      content: `Rewrite this headline in ${languageName} in exactly ${totalOptions} different ways to be more descriptive for the reader, SEO-friendly, and targeted at ${countryName}. Return the results as an object that contains an array of titles attached to a data node in this exact format: {titles: [title1, ..., title ${totalOptions}]}: ${headline}`,
    };

    chatHistory.push(userPrompt);

    let response = await GPT3.generateChat(
      signal,
      chatHistory,
      0.8,
      2048,
      returnResultSchema,
      1.32,
      0
    );

    if (response) {
      if (response.choices.length > 0) {
        let resultsArray = [];

        switch (response.choices[0].finish_reason) {
          case "stop":
            resultsArray = JSON.parse(
              JSON.stringify(response.choices[0].message.content)
            );
            break;
          case "function_call":
            resultsArray = JSON.parse(
              response.choices[0].message.function_call.arguments
            ).titles;
            break;
          default:
            console.log("No results found");
            break;
        }

        if (resultsArray.length) {
          chatHistory.push({
            role: "assistant",
            content: resultsArray.toString(),
          });
        }

        return typeof resultsArray === "string"
          ? JSON.parse(resultsArray)
          : resultsArray;
      }
    }

    return [];
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
    return [];
  }
};

export { rewriteTitle };
