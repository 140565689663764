const CustomEvents = {
  BUY_CREDITS: "buyCredits",
  CHAT_CLOSED: "chatClosed",
  CHAT_MESSAGE_FINISHED: "chatMessageFinished",
  CHAT: "chatRequest",
  EXPAND_ROW: "expandRow",
  EXPORT_HITLIST: "exportHitlist",
  FORGOT_PASSWORD: "forgotPassword",
  FULLSCREEN: "fullscreen",
  GENERATE_HITLIST_UPDATE: "generateHitlistUpdate",
  GENERATE_HITLIST: "generateHitlist",
  GENERATE_HITLIST_CANCEL: "generateHitlistCancel",
  HISTORY_CHANGED: "historyChanged",
  HITLIST_CHANGED: "hitlistChanged",
  HITLIST_NOT_SAVED: "hitlistNotSaved",
  HITLIST_SAVED: "hitlistSaved",
  HITLIST_SAVING: "hitlistSaving",
  HITLIST_UPDATE: "hitlistUpdate",
  KEYWORD_ADD: "keywordAdd",
  LOGIN: "login",
  LOGOUT: "logout",
  REGISTER: "register",
  RESET_PASSWORD: "resetPassword",
  SHARE: "share",
  TOKENS_USED: "tokensUsed",
  TOPIC_CHANGED: "topicChanged",
  USER_UPDATED: "userUpdated",
  VOICE_MSG: "voiceMsg",
};

function subscribe(eventName, listener) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName, listener) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName, data) {
  const event = new CustomEvent(eventName, { detail: { data: data } });
  document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe, CustomEvents };
