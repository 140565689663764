import { memo } from "react";
import {
  Divider,
  MenuItem,
  MenuList,
  ListItemText,
  ListItemIcon,
  Box,
} from "@mui/material";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import ControlPointDuplicateOutlinedIcon from "@mui/icons-material/ControlPointDuplicateOutlined";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SettingsMenu(props) {
  const { theme } = props;

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.main,
        border: `1px solid ${theme.palette.divider}`,
        px: 2,
        pt: 2.5,
        pb: 3,
        mb: 2,
      }}
    >
      <MenuList>
        <MenuItem component={NavLink} to="/profile#profile-settings">
          <ListItemIcon>
            <ManageAccountsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {t("hitlist.settings.menu.profileSettings")}
          </ListItemText>
        </MenuItem>
        <MenuItem
          component={NavLink}
          to="/profile#profile-password-settings"
          sx={{ mb: 3 }}
        >
          <ListItemIcon>
            <PasswordOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {t("hitlist.settings.menu.passwordSettings")}
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem component={NavLink} to="/add-credits" sx={{ mt: 3 }}>
          <ListItemIcon>
            <ControlPointDuplicateOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {t("hitlist.settings.menu.creditsSettings")}
          </ListItemText>
        </MenuItem>
        <MenuItem component={NavLink} to="/subscription" sx={{ mb: 3 }}>
          <ListItemIcon>
            <CreditCardOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {t("hitlist.settings.menu.subscriptionSettings")}
          </ListItemText>
        </MenuItem>
        <Divider variant="vertical" />
        <MenuItem component={NavLink} to="/settings" sx={{ mt: 3 }}>
          <ListItemIcon>
            <SettingsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {t("hitlist.settings.menu.applicationSettings")}
          </ListItemText>
        </MenuItem>
      </MenuList>
    </Box>
  );
}

export default memo(SettingsMenu);
