import { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import GoogleIcon from "../../Icons/GoogleIcon";
// import FacebookIcon from "../../Icons/FacebookIcon";
// import LinkedInIcon from "../../Icons/LinkedInIcon";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { CustomEvents, publish } from "../../Hitlist/Events/CustomEvents";
import { useCookies } from "react-cookie";
import ResetPasswordModal from "../ResetPasswordModal/ResetPasswordModal";
import { ToastEvents } from "../../Toast/Toast";
import RegisterOption from "./RegisterOption";
import { APP_CONSTANTS } from "../../Hitlist/Constants";

import "./LoginOptions.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function LoginOptions(props) {
  const { loggedIn, loggingIn } = props;

  const [emailEmpty, setEmailEmpty] = useState(false);
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [cookies] = useCookies(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const emailRef = useRef(cookies.Email || null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleGoogleLogin = () => {
    if (isRegistering) {
      publish(CustomEvents.REGISTER, {
        loginDate: new Date(),
        loginType: APP_CONSTANTS.LOGIN_TYPE_GOOGLE,
      });
    } else {
      publish(CustomEvents.LOGIN, {
        loginDate: new Date(),
        loginType: APP_CONSTANTS.LOGIN_TYPE_GOOGLE,
      });
    }
  };

  // const handleFacebookLogin = () => {
  //   if (isRegistering) {
  //     publish(CustomEvents.REGISTER, {
  //       loginDate: new Date(),
  //       loginType: APP_CONSTANTS.LOGIN_TYPE_FACEBOOK,
  //     });
  //   } else {
  //     publish(CustomEvents.LOGIN, {
  //       loginDate: new Date(),
  //       loginType: APP_CONSTANTS.LOGIN_TYPE_FACEBOOK,
  //     });
  //   }
  // };

  // const handleLinkedInLogin = () => {
  //   if (isRegistering) {
  //     publish(CustomEvents.REGISTER, {
  //       loginDate: new Date(),
  //       loginType: APP_CONSTANTS.LOGIN_TYPE_LINKEDIN,
  //     });
  //   } else {
  //     publish(CustomEvents.LOGIN, {
  //       loginDate: new Date(),
  //       loginType: APP_CONSTANTS.LOGIN_TYPE_LINKEDIN,
  //     });
  //   }
  // };

  const handleEmailLogin = (e) => {
    setIsRegistering(false);

    setEmailEmpty(
      isRegistering
        ? false
        : emailRef.current.value === "" || emailRef.current.value === undefined
    );

    setPasswordEmpty(
      isRegistering
        ? false
        : passwordRef.current.value === "" ||
            passwordRef.current.value === undefined
    );

    if (emailRef.current.value === "" || passwordRef.current.value === "") {
      publish(ToastEvents.ERROR, "Please enter a valid email and password.");
      return;
    } else {
      publish(CustomEvents.LOGIN, {
        loginDate: new Date(),
        email: emailRef.current.value,
        password: passwordRef.current.value,
        loginType: APP_CONSTANTS.LOGIN_TYPE_EMAIL,
      });
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setEmailEmpty(
      isRegistering
        ? emailRef.current.value === "" || emailRef.current.value === undefined
        : false
    );

    setPasswordEmpty(isRegistering ? passwordRef.current.value === "" : false);

    if (confirmPasswordRef.current) {
      setConfirmPasswordEmpty(
        isRegistering ? confirmPasswordRef.current.value === "" : false
      );
    } else {
      setConfirmPasswordEmpty(false);
    }

    if (isRegistering) {
      if (
        emailRef.current.value === "" ||
        passwordRef.current.value === "" ||
        confirmPasswordRef.current.value === ""
      ) {
        publish(ToastEvents.ERROR, "Please enter a valid email and password.");
        return;
      } else if (
        passwordRef.current.value !== confirmPasswordRef.current.value
      ) {
        publish(ToastEvents.ERROR, "Passwords do not match.");
        return;
      } else {
        publish(CustomEvents.REGISTER, {
          email: emailRef.current.value,
          password: passwordRef.current.value,
          loginType: APP_CONSTANTS.LOGIN_TYPE_EMAIL,
        });
      }
    }

    setIsRegistering(true);
  };

  const handleLogout = () => {
    publish(CustomEvents.LOGOUT);
  };

  const handleContinue = () => {
    navigate("/hitlist");
  };

  const handleForgotPassword = () => {
    setOpen(true);
  };

  useEffect(() => {
    return () => {
      setOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: 350 }} id="login-form">
      <Typography variant="h4" sx={{ mb: 2 }}>
        {loggedIn
          ? "Welcome Back!"
          : !isRegistering
          ? "Please Log In."
          : "Register New User."}
      </Typography>
      {loggedIn && (
        <Grid container columns={2} spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={1} align="right">
            <Button
              variant="contained"
              color="background"
              size="large"
              fullWidth
              onClick={handleLogout}
              className="flat-button"
              startIcon={<LogoutOutlinedIcon />}
            >
              Logout
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleContinue}
              className="flat-button"
              endIcon={<ArrowRightOutlinedIcon />}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      )}
      {!loggedIn && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="login tabs">
              <Tab
                label="Login"
                {...a11yProps(0)}
                onClick={() => {
                  setIsRegistering(false);
                }}
                sx={{ width: "50%" }}
              />
              <Tab
                label="Register"
                {...a11yProps(1)}
                onClick={() => {
                  setIsRegistering(true);
                }}
                sx={{ width: "50%" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Stack spacing={1}>
              <Button
                variant="contained"
                color="background"
                size="large"
                startIcon={<GoogleIcon />}
                onClick={handleGoogleLogin}
                className="flat-button"
              >
                {isRegistering ? "Register" : "Login"} with Google
              </Button>
              {/* <Button
                variant="contained"
                color="background"
                size="large"
                startIcon={<FacebookIcon />}
                onClick={handleFacebookLogin}
                className="flat-button"
              >
                {isRegistering ? "Register" : "Login"} with Facebook
              </Button>
              <Button
                variant="contained"
                color="background"
                size="large"
                startIcon={<LinkedInIcon />}
                onClick={handleLinkedInLogin}
                className="flat-button"
              >
                {isRegistering ? "Register" : "Login"} with LinkedIn
              </Button> */}
            </Stack>
            <Divider sx={{ my: 4 }}>
              or {isRegistering ? "register" : "login"} with email
            </Divider>
            <Box component="form" noValidate autoComplete="off">
              <Stack spacing={1}>
                <TextField
                  id="login-email"
                  label="Your Email"
                  type="email"
                  className={`flat-textfield ${emailEmpty ? "error" : ""}`}
                  defaultValue={cookies.Email || null}
                  inputRef={emailRef}
                  onKeyUp={
                    isRegistering
                      ? (e) => {
                          if (e.key === "Enter") handleRegister();
                        }
                      : (e) => {
                          if (e.key === "Enter") handleEmailLogin();
                        }
                  }
                />
                <TextField
                  id="login-password"
                  label="Password"
                  type="password"
                  className={`flat-textfield ${passwordEmpty ? "error" : ""}`}
                  inputRef={passwordRef}
                  onKeyUp={
                    isRegistering
                      ? (e) => {
                          if (e.key === "Enter") handleRegister();
                        }
                      : (e) => {
                          if (e.key === "Enter") handleEmailLogin();
                        }
                  }
                />
                {isRegistering && (
                  <TextField
                    id="login-password-confirm"
                    label="Confirm Password"
                    type="password"
                    className={`flat-textfield ${
                      confirmPasswordEmpty ? "error" : ""
                    }`}
                    inputRef={confirmPasswordRef}
                    onKeyUp={
                      isRegistering
                        ? (e) => {
                            if (e.key === "Enter") handleRegister();
                          }
                        : (e) => {
                            if (e.key === "Enter") handleEmailLogin();
                          }
                    }
                  />
                )}
              </Stack>
              <Grid container columns={1} spacing={2} sx={{ mt: 1 }}>
                {!isRegistering && (
                  <Grid item xs={1}>
                    <LoadingButton
                      loading={loggingIn}
                      loadingPosition="end"
                      endIcon={<ChevronRightOutlinedIcon />}
                      variant="contained"
                      color="secondary"
                      size="large"
                      fullWidth
                      onClick={handleEmailLogin}
                      className="flat-button"
                    >
                      {!loggingIn
                        ? t("hitlist.login.loginButton")
                        : t("hitlist.login.loginButtonLoading")}
                    </LoadingButton>
                  </Grid>
                )}
                {isRegistering && (
                  <Grid item xs={1} align="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      fullWidth
                      onClick={handleRegister}
                      className="flat-button"
                    >
                      Register
                    </Button>
                  </Grid>
                )}
              </Grid>
              {!isRegistering && (
                <>
                  <Divider sx={{ mt: 4, mb: 1 }}></Divider>
                  <Grid
                    className="login-meta"
                    container
                    columns={2}
                    alignItems="center"
                  >
                    <Grid item xs={1}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Remember me"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={1} align="right">
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={handleForgotPassword}
                      >
                        Forgot Password?
                      </Link>
                    </Grid>
                  </Grid>

                  {/* ****************** Forgot Password Modal ****************** */}
                  <ResetPasswordModal
                    open={open}
                    handleClose={handleClose}
                    params={params}
                  />
                </>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RegisterOption />
          </TabPanel>
        </>
      )}
    </Box>
  );
}

export default memo(LoginOptions);
