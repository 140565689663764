import { createContext } from "react";

const initialState = {
  userFirstName: "Raeleen",
  userLastName: "Robertson",
  userBusiness: "Lifers PTY LTD",
  userNickName: "Raels",
  userImage: "https://secure.gravatar.com/avatar/bb707c99f683c16ff8ef1ede9a0f92ee?s=500&d=mm&r=g",
};

export const AppUserContext = createContext(initialState);
