import "./HitlistTabs.css";
import {
  // memo,
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Stack, TextField, Tab, Tabs, Box } from "@mui/material";
import { STORAGE_KEYS, getValue, storeValue } from "../../../Storage";
// import ArrayUtils from "../../../Utils/ArrayUtils";
import HitlistTabBadge from "./HitlistTabBadge";
import HitlistTable from "../HitlistTable/HitlistTable";
// import HitlistTabNew from "./HitlistTabNew";
import HitlistTabPanel from "./HitlistTabPanel";
// import HitlistTabsClose from "./HitlistTabsClose";
import HitlistTabsFullScreen from "./HitlistTabsFullScreen";
import { AppConfigContext } from "../../../AppConfigContext";
import { resetIdeaHistory } from "../../../Bots/IdeasBot";
import StatusLoader from "../StatusLoader";
import { CustomEvents, publish } from "../../Events/CustomEvents";

function a11yProps(index) {
  return {
    id: `hitlist-tab-${index}`,
    "aria-controls": `hitlist-tabpanel-${index}`,
    index: index,
  };
}

function HitlistTabs(props) {
  const {
    generating,
    hitlistId,
    saveAllData,
    tableData,
    topic,
    viewOnly,
    status,
    onGenerateCancel,
  } = props;

  const [value, setValue] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tableTabData, setTableTabData] = useState([]);

  const fullScreenRef = useRef(false);

  const config = useContext(AppConfigContext);

  const tableDataRef = useRef(tableData);
  const tabRegisterRef = useRef(config.hitlist.tabs);

  const _saveTableData = useCallback(
    async (updatedData) => {
      console.log("🚀 ~ file: HitlistTabs.js:57 ~ updatedData:", updatedData)
      if (!updatedData) return;
      tableDataRef.current = updatedData;
      setTableTabData(updatedData);
      storeValue(STORAGE_KEYS.HITLIST, updatedData);
      saveAllData();
    },
    [saveAllData]
  );

  const updateTableData = useCallback(
    (newData) => {
      if (!newData) return;

      // loop through the newData, compare it to the tableTabDataRef, and update anything that has changed
      const updatedTableData = tableDataRef.current.map((row) => {
        const newRow = newData.find((r) => r.uuid === row.uuid);
        if (newRow) {
          return newRow;
        } else {
          return row;
        }
      });

      // save the table data to the DB
      _saveTableData(updatedTableData);
    },
    [_saveTableData]
  );

  // const _saveTabs = useCallback(() => {
  //   storeValue(STORAGE_KEYS.TABS, tabRegisterRef.current);

  //   // combine the table data from all tabs
  //   let combinedTableData = [];

  //   tabRegisterRef.current.forEach((tab) => {
  //     combinedTableData = [...combinedTableData, ...tab.tableData];
  //   });

  //   tableDataRef.current = combinedTableData;

  //   storeValue(STORAGE_KEYS.HITLIST, tableDataRef.current);

  //   saveAllData();
  // }, [saveAllData]);

  // const _setTabData = useCallback(
  //   (data, saveToDB = true) => {
  //     if (!data) return;

  //     const newData = ArrayUtils.reIndex(data);

  //     // reset the tab register tableData
  //     tabRegisterRef.current.forEach((tab) => (tab.tableData = []));

  //     // reset each tab count to 0
  //     tabRegisterRef.current.forEach((tab) => (tab.count = 0));

  //     newData.forEach((row) => {
  //       // default the row.tabId to 0 if it doesn't exist
  //       row.tabId = row.tabId || 0;

  //       // add the row to the tab register
  //       tabRegisterRef.current[row.tabId].tableData = [
  //         ...tabRegisterRef.current[row.tabId].tableData,
  //         row,
  //       ];

  //       // update the tab count
  //       tabRegisterRef.current[row.tabId].count =
  //         tabRegisterRef.current[row.tabId].tableData.length;
  //     });

  //     // re-index each tabData array in the register
  //     tabRegisterRef.current.forEach((tab) => {
  //       tab.tableData = ArrayUtils.reIndex(tab.tableData);
  //     });

  //     // update the table tab data display
  //     setTableTabData(tabRegisterRef.current);

  //     // save the tabs to the database
  //     if (saveToDB) _saveTabs();
  //   },
  //   [_saveTabs]
  // );

  // const handleCloseTab = useCallback(
  //   (event, index) => {
  //     console.log("🚀 ~ file: HitlistTabs.js:89 ~ HitlistTabs ~ event:", event);
  //     event.stopPropagation();

  //     // remove the tab from the tab register
  //     tabRegisterRef.current.splice(index, 1);

  //     // re-index the tab register
  //     tabRegisterRef.current = ArrayUtils.reIndex(tabRegisterRef.current);

  //     // update the table data
  //     _setTabData(tableData);

  //     // update the value of the tab
  //     setValue(tabRegisterRef.current.length - 1);

  //     // save the tabs
  //     _saveTabs();
  //   },
  //   [tableData, _setTabData, _saveTabs]
  // );

  const handleTitleBlur = useCallback((event, tabIndex) => {
    // set the textfield back to disabled
    event.target.disabled = true;

    // update the tab title in the tab register
    tabRegisterRef.current[tabIndex].title = event.target.value;

    // save the tabs
    // _saveTabs();
  }, []);

  const handleTitleKeyUp = useCallback(
    (event, tabIndex) => {
      // if the user presses enter, blur the textfield
      if (event.key === "Enter" || event.keyCode === 13) {
        handleTitleBlur(event, tabIndex);
      }
    },
    [handleTitleBlur]
  );

  const handleTitleDoubleClick = useCallback((event, tabIndex) => {
    // set the textfield to editable
    event.target.disabled = false;

    // select the text
    event.target.select();

    // focus the textfield
    event.target.focus();
  }, []);

  const _renderTab = useCallback(
    (tab, index) => {
      return (
        <Tab
          {...a11yProps(index)}
          key={index}
          sx={{ px: 0, mx: 0 }}
          label={
            <Stack direction="row">
              <HitlistTabBadge
                badgeContent={
                  index === 0
                    ? tableTabData.filter(
                        (row) => row.tabId === index || !row.tabId
                      ).length
                    : tableTabData.filter((row) => row.tabId === index).length
                }
              />
              <TextField
                sx={{ ml: 1.5, pr: 0 }}
                InputProps={{
                  disableUnderline: true,
                  className: "tab-title",
                  sx: { borderRight: "1px solid #dedede" },
                }}
                variant="standard"
                disabled={true}
                onDoubleClick={(e) => handleTitleDoubleClick(e, index)}
                onBlur={(e) => handleTitleBlur(e, index)}
                onKeyUp={(e) => handleTitleKeyUp(e, index)}
                defaultValue={tab.title}
                fullWidth
              />
              {/* <HitlistTabRename handleCloseTab={handleCloseTab} index={index} /> */}
              {/* {index > 0 && (
                <>
                  <HitlistTabsClose
                    handleCloseTab={handleCloseTab}
                    index={index}
                  />
                </>
              )} */}
            </Stack>
          }
        />
      );
    },
    [handleTitleBlur, handleTitleDoubleClick, handleTitleKeyUp, tableTabData]
  );

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const handlePush = useCallback(
    (row) => {
      let newData = [...tableDataRef.current];

      // find the row in the table data and assign it a tabId
      let index = newData.findIndex(
        (item) => item.uuid === row.uuid && item.tabId !== 1
      );

      if (index > -1) {
        newData[index].tabId = 1;
        _saveTableData(newData);
      }
    },
    [_saveTableData]
  );

  const handlePull = useCallback(
    (row) => {
      let newData = [...tableDataRef.current];

      // find the row in the table data and assign it a tabId
      let index = newData.findIndex(
        (item) => item.uuid === row.uuid && item.tabId === 1
      );

      if (index > -1) {
        newData[index].tabId = 0;
        _saveTableData(newData);
      }
    },
    [_saveTableData]
  );

  const handleDelete = useCallback(
    (row) => {
      const updatedData = tableDataRef.current.filter(
        (item) => item.uuid !== row.uuid
      );

      if (updatedData.length === 0) {
        publish(CustomEvents.FULLSCREEN, false);
        resetIdeaHistory();
      }

      // save the table data to the DB
      _saveTableData(updatedData);
    },
    [_saveTableData]
  );

  // const handleNewTab = useCallback(() => {
  //   // create a new tab and add it to the tab register
  //   tabRegisterRef.current.push({
  //     title: "New Tab",
  //     tableData: [],
  //     count: 0,
  //   });

  //   // update the tab data
  //   setTableTabData(tabRegisterRef.current);

  //   // force a re-render
  //   forceUpdate();

  //   storeValue(STORAGE_KEYS.TABS, tabRegisterRef.current);
  //   saveAllData();
  // }, [forceUpdate, saveAllData]);

  const handleFullScreen = useCallback((fullScreen) => {
    fullScreenRef.current = fullScreen;
    setIsFullScreen(fullScreenRef.current);
  }, []);

  useEffect(() => {
    // ensure tableData is valid before assigning
    if (!tableData || !Array.isArray(tableData)) return;

    tableDataRef.current = tableData;
    // storeValue(STORAGE_KEYS.HITLIST, tableData);
    setTableTabData(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  return (
    <>
      <Box
        className={
          (isFullScreen ? "full-screen" : "") + " hitlist-tabs-container"
        }
        sx={{
          bgcolor: "background.paper",
        }}
        position="relative"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable hitlist tabs"
          indicatorColor="secondary"
          textColor="secondary"
          sx={{ maxWidth: "calc(100vw - 164px)" }}
        >
          {tabRegisterRef.current.map(_renderTab)}
          {/* <HitlistTabNew handleNewTab={handleNewTab} /> */}
          <StatusLoader
            status={status}
            generating={generating}
            onGenerateCancel={onGenerateCancel}
          />
        </Tabs>

        <HitlistTabsFullScreen
          isFullScreen={isFullScreen}
          handleFullScreen={handleFullScreen}
        />
      </Box>
      <HitlistTabPanel
        value={value}
        index={0}
        isFullScreen={isFullScreen}
        key={`panel-${0}`}
      >
        <HitlistTable
          generating={generating}
          hitlistId={hitlistId}
          saveAllData={saveAllData}
          topic={topic}
          tableData={tableTabData.filter(
            (row) => row.tabId === 0 || !row.tabId
          )}
          viewOnly={viewOnly}
          isFullScreen={isFullScreen}
          handlePush={handlePush}
          handlePull={handlePull}
          s
          handleDelete={handleDelete}
          tableId={0}
          condensed={false}
          columnVisibility={{
            status: false,
            assigned: false,
            ideas: true,
            push: true,
            pull: false,
            expand: false,
          }}
          updateTableData={updateTableData}
        />
      </HitlistTabPanel>
      <HitlistTabPanel
        value={value}
        index={1}
        isFullScreen={isFullScreen}
        key={`panel-${1}`}
      >
        <HitlistTable
          generating={generating}
          hitlistId={hitlistId}
          saveAllData={saveAllData}
          topic={topic}
          tableData={tableTabData.filter((row) => row.tabId === 1)}
          viewOnly={viewOnly}
          isFullScreen={isFullScreen}
          handlePush={handlePush}
          handlePull={handlePull}
          handleDelete={handleDelete}
          tableId={1}
          condensed={true}
          columnVisibility={{
            status: true,
            assigned: false,
            ideas: false,
            push: false,
            pull: true,
            enhance: true,
            expand: (!viewOnly),
          }}
          updateTableData={updateTableData}
        />
      </HitlistTabPanel>
    </>
  );
}

export default HitlistTabs;
