import { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function AddCreditsThanks(props) {
  const { theme } = props;

  const [credits, setCredits] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    // get the credits from the url
    const urlParams = new URLSearchParams(window.location.search);
    setCredits(parseInt(urlParams.get("credits")) || 0);

    return () => {};
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: theme.palette.background.paper,
        pt: 7,
        pb: 8,
      }}
    >
      <Container disableGutters maxWidth="sm" component="main">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {t("hitlist.credits.thanks.heading")}
        </Typography>
        <Typography
          component="p"
          variant="h6"
          align="center"
          color="text.secondary"
        >
          {t("hitlist.credits.thanks.subheading", { credits: credits })}
        </Typography>
      </Container>
      <Box justifyContent="center" sx={{ mt: 3, textAlign: "center" }}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          href="/hitlist"
        >
          {t("hitlist.credits.thanks.button")}
        </Button>
      </Box>
    </Container>
  );
}

export default AddCreditsThanks;
