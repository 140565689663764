import "./Title.css";
import { Input, Stack, IconButton, Tooltip } from "@mui/material";
import { memo, useEffect, useRef } from "react";
import { getValue, STORAGE_KEYS, storeValue } from "../../Storage";
import { useTranslation } from "react-i18next";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { CustomEvents, publish } from "../../Hitlist/Events/CustomEvents";

const Title = (props) => {
  const { saveAllData, title, viewOnly } = props;

  const valueRef = useRef("");

  const { t } = useTranslation();

  const updateTitle = async (title = null) => {
    if (!title) return;
    if (typeof title !== "string") return;

    let trimmedTitle = title.trim();
    if (title !== getValue(STORAGE_KEYS.TITLE)) {
      storeValue(STORAGE_KEYS.TITLE, trimmedTitle);
      setTitleField(trimmedTitle);
      saveAllData();
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.key === "Enter") {
      e.preventDefault();
      updateTitle(valueRef.current.value);
    }
  };

  const handleBlur = (e) => {
    updateTitle(valueRef.current.value);
  };

  const setTitleField = (title) => {
    if (title === null) return;

    valueRef.current.value = title;
    storeValue(STORAGE_KEYS.TITLE, title);
  };

  const handleShare = () => {
    publish(CustomEvents.SHARE, { title: valueRef.current.value });
  };

  useEffect(() => {
    setTitleField(title + ((viewOnly && title) ? " (view only)" : ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    valueRef.current.value = getValue(STORAGE_KEYS.TITLE);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      direction="row"
      id="title-field"
      component="form"
      sx={{
        "& > :not(style)": {
          ml: 5,
          mt: 0,
          borderBottomWidth: "1px",
        },
        width: 320,
        marginTop: viewOnly ? 0 : "-3px",
      }}
      noValidate
      autoComplete="off"
    >
      <Input
        size="small"
        color="primary"
        id="title-input"
        label=""
        type="text"
        placeholder={(!viewOnly) ? t("header.title.placeholder") : ""}
        variant="standard"
        inputRef={valueRef}
        onKeyDown={handleKeyPress}
        onBlur={handleBlur}
        autoComplete="off"
        fullWidth
        disabled={viewOnly}
        endAdornment={
          !viewOnly && (
            <Tooltip title="Share">
              <IconButton size="small" aria-label="share" onClick={handleShare}>
                <ShareOutlinedIcon />
              </IconButton>
            </Tooltip>
          )
        }
      />
    </Stack>
  );
};

export default memo(Title);
