import {
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  Button,
  LinearProgress,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { getTopicClusterArticles } from "../../../../Bots/HitlistBot";
import { STORAGE_KEYS, getValue } from "../../../../Storage";
import { AppConfigContext } from "../../../../AppConfigContext";

function HitlistTableMoreArticles(props) {
  const [articleIdeas, setTitleIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const { onArticleUpdate, data } = props;

  const dataFetchedRef = useRef(false);
  const apiControllerRef = useRef([]);

  const config = useContext(AppConfigContext);

  const getArticleIdeas = async () => {
    setLoading(true);
    dataFetchedRef.current = true;

    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      let ideas = await getTopicClusterArticles(
        controller.signal,
        data.row.title,
        getValue(STORAGE_KEYS.TARGET_COUNTRY) ||
          config.hitlist.generate.targetCountry || "US",
      );
      
      console.log("🚀 ~ file: HitlistTableMoreArticles.js:44 ~ getArticleIdeas ~ ideas:", ideas)

      if (Array.isArray(ideas) && ideas.length) {
        updateArticleIdeas(ideas);
      } else {
        setTitleIdeas(["No article ideas found. Please try again."]);
      }
    } catch (error) {
      setTitleIdeas(["Error finding article ideas. Please try again."]);
    } finally {
      setLoading(false);
      dataFetchedRef.current = false;
    }
  };

  const updateArticleIdeas = (newIdeas) => {
    setTitleIdeas(newIdeas);
    data.row.articleIdeas = newIdeas;
  };

  useEffect(() => {
    console.log("🚀 ~ file: HitlistTableMoreArticles.js:68 ~ useEffect ~ dataFetchedRef.current:", dataFetchedRef.current)
    if (!data.row.articleIdeas) {
      if (!dataFetchedRef.current) getArticleIdeas();
    } else {
      updateArticleIdeas(data.row.articleIdeas);
    }

    let apiCalls = apiControllerRef.current;

    return () => {
      // Abort any pending API calls
      if (!dataFetchedRef.current) apiCalls.forEach((controller) => controller.abort());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Grid container columns={12} alignItems="center" flex>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Related Article Ideas:</Typography>
        </Grid>
        <Grid item justifyContent="flex-end" xs={6}>
          <Tooltip title="Refresh Article Ideas">
            <IconButton
              color="neutral"
              size="small"
              onClick={getArticleIdeas}
              sx={{ float: "right" }}
            >
              <RefreshOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
      <LinearProgress
        sx={{ display: loading ? "flex" : "none", width: 100, mt: 3 }}
      />
      <List dense={true} sx={{ display: !loading ? "block" : "none" }}>
        {articleIdeas.map((idea, index) => (
          <ListItem alignItems="flex-start" sx={{ pl: 0 }} key={index}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              sx={{
                borderRadius: 3,
                py: 0,
                pl: 1,
                pr: 0,
                mr: 2,
                justifyContent: "center",
              }}
              onClick={() => {
                onArticleUpdate(data.row, idea);
              }}
            >
              <Typography fontSize="inherit" sx={{ mr: 0.25 }}>
                Add
              </Typography>
              <AddOutlinedIcon
                fontSize="small"
                color="secondary"
                sx={{ pr: 0.5 }}
              />
            </Button>
            <Typography variant="body2" sx={{fontSize: 12}}>{idea}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default memo(HitlistTableMoreArticles);
