import { useCookies } from "react-cookie";
import { Box, ThemeProvider, Grid } from "@mui/material";
import SettingsMenu from "./SettingsMenu";
import AppSettings from "./AppSettings";
import { memo, useEffect, useRef } from "react";

function Settings(props) {
  const { loggedIn, theme, handleSaveSettings } = props;

  const [cookies] = useCookies(null);

  const apiControllerRef = useRef([]);

  useEffect(() => {
    apiControllerRef.current = [];
    return () => {
      apiControllerRef.current.forEach((controller) => controller.abort());
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {loggedIn && (
        <>
          <Grid
            container
            columns={12}
            spacing={2}
            sx={{
              px: 2,
              height: "calc(100vh - 50px)",
              pt: 3,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Grid item xs={3}>
              <SettingsMenu theme={theme} />
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  mb: 4,
                  pr: 2,
                  scrollBehavior: "smooth",
                  overflowY: "auto",
                  height: "calc(100vh - 110px)",
                }}
              >
                <AppSettings
                  theme={theme}
                  handleSaveSettings={handleSaveSettings}
                  settings={cookies.Settings}
                  heading="Application Settings"
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </ThemeProvider>
  );
}

export default memo(Settings);
