import {
  Box,
  FormControl,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import AddSharpIcon from '@mui/icons-material/AddSharp';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import { memo, useRef } from "react";

const ariaLabel = { "aria-label": "description" };

function AddHintField(props) {
  const valueRef = useRef("");

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      sendHint();
    }
  };

  const sendHint = () => {
    props.onHintAdded(valueRef.current.value);
    valueRef.current.value = "";
  }

  return (
    <Box>
      <Stack direction="row" id="add-hint-field">
        <FormControl
          id="add-hint-field-entry"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flexGrow: "1",
          }}
        >
          <AddSharpIcon sx={{ mr: 2, my: 0.5 }} />
          <TextField
            placeholder="Type to add your own"
            variant="standard"
            sx={{ width: "100%", mt: 0 }}
            inputProps={ariaLabel}
            inputRef={valueRef}
            onKeyUp={handleKeyUp}
            focused
            autoComplete="off"
          />
          <Button
            endIcon={<SendSharpIcon />}
            type="submit"
            onClick={sendHint}
          >
          </Button>
        </FormControl>
      </Stack>
    </Box>
  );
}

export default memo(AddHintField);
