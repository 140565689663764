import { memo } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

function HitlistTableActionRewrite(props) {
  const { row, handleTitleRewrite, loading } = props;

  return (
    <GridActionsCellItem
      disabled={loading !== false || row.id === null || row.id === undefined}
      icon={<ReplayOutlinedIcon fontSize="small" />}
      label={`Rewrite Article Title`}
      onClick={() => handleTitleRewrite(row)}
      showInMenu
      sx={{ fontSize: 15 }}
    />
  );
}

export default memo(HitlistTableActionRewrite);
