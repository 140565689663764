import { memo, useContext, useEffect, useRef, useState } from "react";
import { AppConfigContext } from "../../../AppConfigContext";
import HintsAccordion from "./HintsAccordion";
import StringUtils from "../../../Utils/StringUtils";
import {
  CustomEvents,
  publish,
  subscribe,
  unsubscribe,
} from "../../Events/CustomEvents";
import { searchCompetitors } from "../../../Bots/DomainBot";
import { getValue, STORAGE_KEYS, storeValue } from "../../../Storage";
import { useCookies } from "react-cookie";
import ArrayUtils from "../../../Utils/ArrayUtils";
import { Grid } from "@mui/material";
import { ToastEvents } from "../../../Toast/Toast";

function DomainHints(props) {
  const {
    appInitialized,
    disableChat,
    disableMore,
    disableOpenAll,
    domain,
    domains,
    expanded,
    generating,
    handleChange,
    saveAllData,
    topic,
    handleDomainsLoading,
    viewOnly,
  } = props;

  const [loading, setLoading] = useState(false);
  const [hints, setHints] = useState([]);
  const [msg] = useState(props.msg || "Domains");
  const [errorMessage, setErrorMessage] = useState("");
  const [hidden, setHidden] = useState(false);
  const [gridSize, setGridSize] = useState(6);

  const [cookies] = useCookies(null);

  const config = useContext(AppConfigContext);

  const currDomains = useRef([]);
  const topicRef = useRef(getValue(STORAGE_KEYS.TOPIC));
  const domainRef = useRef();
  const maxDomainsRef = useRef(5);
  const apiControllerRef = useRef([]);
  const pageRef = useRef(1);

  // Get a unique identifier for this component to use with the accordion
  const type = config.hitlist.constants.STEP_DOMAIN;

  const handleHistoryChanged = (e) => {
    updateDomains(e.detail.data.competitors, true);
  };

  const handleCancel = () => {
    apiControllerRef.current.forEach((controller) => controller.abort());
    setLoading(false);
  };

  const handleClear = () => {
    updateDomains([]);
  };

  const handleMore = () => {
    getDomains(
      domainRef.current || getValue(STORAGE_KEYS.DOMAIN),
      topicRef.current || getValue(STORAGE_KEYS.TOPIC),
      true,
      false
    );
  };

  const handleMoreLikeThis = (domain = null) => {
    if (domain)
      getDomains(
        domain,
        topicRef.current || getValue(STORAGE_KEYS.TOPIC),
        true,
        false
      );
  };

  const handleRefresh = () => {
    getDomains(
      domainRef.current || getValue(STORAGE_KEYS.DOMAIN),
      topicRef.current || getValue(STORAGE_KEYS.TOPIC),
      false,
      true
    );
  };

  const handleErrorClose = () => {
    setErrorMessage("");
  };

  const handleDelete = (domainToDelete) => () => {
    let newDomains = currDomains.current.filter(
      (domain) => domain !== domainToDelete
    );
    updateDomains(newDomains);
  };

  const handleClick = (e) => {
    window.open(StringUtils.extractURL(e.target.textContent), "_blank");
  };

  const handleOpenAll = () => {
    for (let i = 0; i < currDomains.current.length; i++) {
      const url = currDomains.current[i].label;
      window.open(StringUtils.extractURL(url), "_blank");
    }
  };

  const addDomain = (domain) => {
    if (domain.includes(",")) {
      let splitHint = domain.split(",");
      splitHint.forEach((h) => {
        addDomain(h.trim());
      });
      return;
    }

    // strip the protocol and www from the domain
    domain = StringUtils.stripProtocolFromUrl(domain);

    let newDomain = { label: domain, index: currDomains.current.length };
    let newDomains = [...currDomains.current, newDomain];

    updateDomains(newDomains);
  };

  const getDomains = async (
    domain = null,
    topic = null,
    more = false,
    refresh = false
  ) => {
    if (!topic) {
      publish(
        ToastEvents.ERROR,
        "Could not read the topic to research. Please try again."
      );
      return;
    }

    if (refresh) {
      updateDomains([]);
      pageRef.current = 1;
    }

    setLoading(true);
    handleDomainsLoading(true);
    setErrorMessage("");

    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      let domains = await searchCompetitors(
        controller.signal,
        topic,
        domain,
        pageRef.current,
        maxDomainsRef.current,
        getValue(STORAGE_KEYS.TARGET_COUNTRY) ||
          config.hitlist.generate.targetCountry,
        currDomains.current,
        0,
        cookies.Settings.excludedDomains || config.hitlist.excludedDomains
      );

      if (domains) pageRef.current++;
      
      console.log("🚀 ~ file: DomainHints.js:170 ~ DomainHints ~ domains:", domains)

      currDomains.current = more
        ? [...currDomains.current, ...domains]
        : domains;

      // make sure all items are unique
      currDomains.current = ArrayUtils.uniqByKeepFirst(
        currDomains.current,
        (item) => item.label
      );

      updateDomains(currDomains.current);
    } catch (err) {
      updateDomains(currDomains.current);
    } finally {
      setLoading(false);
      handleDomainsLoading(false);
    }
  };

  const updateDomains = (newDomains) => {
    if (newDomains === null || newDomains === undefined) return;
    if (!Array.isArray(newDomains)) return;

    setDomains(newDomains);

    saveAllData();
  };

  const handleChatRequest = (e) => {
    setHidden(e.detail.data.type === config.hitlist.constants.STEP_NICHE);
    setGridSize(
      e.detail.data.type === config.hitlist.constants.STEP_DOMAIN ? 12 : 6
    );
  };

  const handleChat = () => {
    publish(CustomEvents.CHAT, {
      type: type,
      hints: hints,
      topic: props.topic,
    });
  };

  /**
   * When the chat is closed, show the hints again
   */
  const handleChatClosed = () => {
    setHidden(false);
    setGridSize(6);
  };

  const setDomains = (domains) => {
    if (!Array.isArray(domains)) return;

    currDomains.current = domains;
    setHints(domains);
    storeValue(STORAGE_KEYS.DOMAINS, domains);
  };

  useEffect(() => {
    if (!domain) return;
    domainRef.current = domain;
  }, [domain]);

  // Every time the topic changes generate new domains
  useEffect(() => {
    if (!topic || !appInitialized) return;
    if (topicRef.current !== topic) {
      // getDomains(null, topic, true, false);
      topicRef.current = topic;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  useEffect(() => {
    if (!Array.isArray(domains)) return;
    if (domains.length === 0) pageRef.current = 1;
    setDomains(domains);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domains]);

  useEffect(() => {
    // get the current domains
    currDomains.current = domains || getValue(STORAGE_KEYS.DOMAINS) || [];

    setDomains(currDomains.current);

    // get the max domains from the settings
    maxDomainsRef.current = cookies.Settings
      ? cookies.Settings.maxDomains
      : config.hitlist.generate.maxKeywordsGenerated || 5;

    let apiCalls = apiControllerRef.current;

    subscribe(CustomEvents.HISTORY_CHANGED, handleHistoryChanged);
    subscribe(CustomEvents.CHAT, handleChatRequest);
    subscribe(CustomEvents.CHAT_CLOSED, handleChatClosed);

    return () => {
      apiCalls.forEach((controller) => controller.abort());

      unsubscribe(CustomEvents.HISTORY_CHANGED, handleHistoryChanged);
      unsubscribe(CustomEvents.CHAT, handleChatRequest);
      unsubscribe(CustomEvents.CHAT_CLOSED, handleChatClosed);

      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={gridSize} hidden={hidden}>
      <HintsAccordion
        className="competitor-accordion"
        disabled={!domain}
        disableChat={disableChat}
        disableMore={disableMore}
        disableOpenAll={disableOpenAll}
        errorMessage={errorMessage}
        expanded={expanded}
        handleCancel={handleCancel}
        handleChange={handleChange}
        handleChat={handleChat}
        handleClear={handleClear}
        handleClick={handleClick}
        handleDelete={handleDelete}
        handleErrorClose={handleErrorClose}
        handleHintAdded={addDomain}
        handleMore={handleMore}
        handleMoreLikeThis={handleMoreLikeThis}
        handleRefresh={handleRefresh}
        handleOpenAll={handleOpenAll}
        hints={hints}
        loading={loading}
        msg={msg}
        more={maxDomainsRef.current}
        type={type}
        getMore={true}
        generating={generating}
        viewOnly={viewOnly}
      />
    </Grid>
  );
}

export default memo(DomainHints);
