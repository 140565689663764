import { memo } from "react";
import { Stack, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WhisperSpinner } from "react-spinners-kit";
import StopIcon from '@mui/icons-material/Stop';

function StatusLoader(props) {
  const { generating, status, onGenerateCancel } = props;

  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      className="notification"
      spacing={2}
      justifyContent="left"
      sx={{
        display: generating ? "flex" : "none",
        width: "100%",
        ml: 4,
      }}
    >
      <WhisperSpinner
        size={24}
        frontColor={theme.palette.primary.main}
        backColor={theme.palette.primary.main}
      ></WhisperSpinner>
      <Typography variant="subtitle2">
        {status || "Generating Hitlist"}
      </Typography>
      <Button
        variant="text"
        color="error"
        size="small"
        onClick={onGenerateCancel}
        startIcon={<StopIcon />}
      >
        <Typography variant="subtitle2" sx={{mt: 0.25, ml: -0.75}}>Stop</Typography>
      </Button>
    </Stack>
  );
}

export default memo(StatusLoader);
