import { memo } from "react";
import { Avatar } from "@mui/material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import Userfront from "@userfront/core";

function UserProfileImage(props) {
  const { width, height, border, alt, sx } = props;
  const fullName = Userfront.user.name || Userfront.user.email || "";
  const profileImage = Userfront.user.image || null;

  return (
    <Avatar sx={{ ...sx, width: width, height: height, border: border }}>
      {profileImage && (
        <img
          referrerPolicy="no-referrer"
          width="100%"
          src={profileImage}
          alt={alt}
          title={fullName}
        />
      )}
      {!profileImage && <Person2OutlinedIcon />}
    </Avatar>
  );
}

export default memo(UserProfileImage);
