import { memo, useState } from "react";
import { Typography, Box, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MultipleStopSharpIcon from "@mui/icons-material/MultipleStopSharp";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { CustomEvents, publish } from "../../Hitlist/Events/CustomEvents";
import { useTranslation } from "react-i18next";
import ExportMenuItem from "./ExportMenuItem";
import ExportMenuDivider from "./ExportMenuDivider";

function ExportMenu(props) {
  const { tableData } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();

  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePrint = () => {
    publish(CustomEvents.EXPORT_HITLIST, { format: "print" });
    handleClose();
  };

  const handleExportToCSV = () => {
    publish(CustomEvents.EXPORT_HITLIST, { format: "csv" });
    handleClose();
  };

  const handleExportToExcel = () => {
    publish(CustomEvents.EXPORT_HITLIST, { format: "xls" });
    handleClose();
  };

  const handleExportToContentAtScale = () => {
    publish(CustomEvents.EXPORT_HITLIST, { format: "contentAtScale" });
    handleClose();
  };

  const handleExportToContentWarrior = () => {
    publish(CustomEvents.EXPORT_HITLIST, { format: "contentWarrior" });
    handleClose();
  };

  const handleExportToShopia = () => {
    publish(CustomEvents.EXPORT_HITLIST, { format: "shopia" });
    handleClose();
  };

  const handleExportToWeWriteBlogPosts = () => {
    publish(CustomEvents.EXPORT_HITLIST, { format: "weWriteBlogPosts" });
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIntegrationClick = (url) => () => {
    window.open(url, "_blank");
  };

  return (
    <Box>
      <Tooltip
        arrow
        enterDelay={600}
        enterNextDelay={1200}
        title={t("header.export.tooltip")}
      >
        <span>
          <Button
            id="file-button"
            aria-controls={open ? "export-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownSharpIcon />}
            sx={{
              minWidth: 120,
              borderRadius: 20,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.text.primary,
            }}
            disabled={tableData ? tableData.length === 0 : false}
          >
            <Typography
              sx={{
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "0.875rem",
              }}
            >
              {t("header.export.text")}
            </Typography>
          </Button>
        </span>
      </Tooltip>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "file-button",
        }}
      >
        <ExportMenuItem
          onClick={handlePrint}
          text="Print"
          StartIcon={LocalPrintshopOutlinedIcon}
        />
        <ExportMenuDivider heading={t("header.export.downloads.heading")} />
        <ExportMenuItem
          onClick={handleExportToCSV}
          text={t("header.export.downloads.csv.text")}
          StartIcon={FileDownloadOutlinedIcon}
        />
        <ExportMenuItem
          onClick={handleExportToExcel}
          text={t("header.export.downloads.xls.text")}
          StartIcon={FileDownloadOutlinedIcon}
        />
        <ExportMenuDivider heading={t("header.export.integrations.heading")} />
        <ExportMenuItem
          onIconClick={handleIntegrationClick(
            "https://contentatscale.ai?fpr=lifers"
          )}
          onClick={handleExportToContentAtScale}
          text={t("header.export.integrations.contentAtScale.text")}
          StartIcon={MultipleStopSharpIcon}
          tooltip={t("header.export.integrations.contentAtScale.tooltip")}
        />
        <ExportMenuItem
          onIconClick={handleIntegrationClick(
            "https://app.shopia.ai/sign-up?via=lifers"
          )}
          onClick={handleExportToShopia}
          text={t("header.export.integrations.shopia.text")}
          StartIcon={MultipleStopSharpIcon}
          tooltip={t("header.export.integrations.shopia.tooltip")}
        />
        <ExportMenuItem
          onIconClick={handleIntegrationClick("https://contentwarrior.com/")}
          onClick={handleExportToContentWarrior}
          text={t("header.export.integrations.contentWarrior.text")}
          StartIcon={MultipleStopSharpIcon}
          tooltip={t("header.export.integrations.contentWarrior.tooltip")}
        />
        <ExportMenuItem
          onIconClick={handleIntegrationClick("https://wewriteblogposts.com/")}
          onClick={handleExportToWeWriteBlogPosts}
          text={t("header.export.integrations.weWriteBlogPosts.text")}
          StartIcon={MultipleStopSharpIcon}
          tooltip={t("header.export.integrations.weWriteBlogPosts.tooltip")}
        />
      </Menu>
    </Box>
  );
}

export default memo(ExportMenu);
