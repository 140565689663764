import { memo, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCookies } from "react-cookie";
import { CustomEvents, publish } from "../../Hitlist/Events/CustomEvents";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

function ResetPasswordModal(props) {
  const { open, handleClose, params } = props;
  const [cookies] = useCookies(null);

  const forgotEmailRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleSendResetLink = () => {
    if (forgotEmailRef.current.value === "") {
      enqueueSnackbar("Please enter a valid email.", {
        variant: "error",
      });
      return;
    } else {
      publish(CustomEvents.FORGOT_PASSWORD, {
        email: forgotEmailRef.current.value,
      });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {params.get("confirm") && (
            <Stack direction="row" alignItems="center">
              <DoneOutlinedIcon fontSize="medium" sx={{mr:1}} />
              <Typography variant="h6">Account Confirmed</Typography>
            </Stack>
          )}
          {!params.get("confirm") && "Forgot Password"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {params.get("confirm") &&
              "Thanks for confirming your account! Check the email address below, then select SEND PASSWORD LINK to receive an email to set your password."}
            {!params.get("confirm") &&
              "Enter your email address and we'll send you a link to reset your password."}
          </DialogContentText>
          <TextField
            sx={{ mt: 4 }}
            autoFocus
            margin="dense"
            id="email-address"
            label="Account Email Address"
            type="email"
            fullWidth
            variant="standard"
            defaultValue={params.get("email") || cookies.Email}
            inputRef={forgotEmailRef}
          />
        </DialogContent>
        <DialogActions>
          {!params.get("confirm") && (
            <Button onClick={handleClose}>Close</Button>
          )}
          <Button onClick={() => handleSendResetLink(forgotEmailRef.current)}>
            {params.get("confirm") && "Send Password Link"}
            {!params.get("confirm") && "Send"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(ResetPasswordModal);
