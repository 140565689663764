import { Box, Stack, TextField, Typography } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ProfileHeading from "./ProfileHeading";
import { APP_CONSTANTS } from "../Hitlist/Constants";
import { memo, useRef } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import Userfront from "@userfront/core";

function ProfilePasswordSettings(props) {
  const {
    theme,
    handleSaveNewPassword,
    heading,
    savingPassword,
    updatingProfile,
  } = props;

  const passwordInputRef = useRef(null);
  const confirmPasswordInputRef = useRef(null);
  const currentPasswordInputRef = useRef(null);

  const { t } = useTranslation();

  return (
    <Box
      id="profile-password-settings"
      sx={{
        backgroundColor: theme.palette.background.main,
        border: `1px solid ${theme.palette.divider}`,
        px: 2,
        pt: 2.5,
        pb: 3,
        mb: 2,
      }}
    >
      <ProfileHeading heading={heading} />
      {Userfront.user.data.loginType === APP_CONSTANTS.LOGIN_TYPE_EMAIL && (
        <>
          <Stack direction="row" spacing={2} sx={{ width: "80%" }}>
            <TextField
              id="current-profile-password"
              label={t("hitlist.profile.passwordSettingsCurrentPassword")}
              helperText={t("hitlist.profile.passwordSettingsCurrentPasswordHelp")}
              variant="standard"
              type="password"
              sx={{ width: "66%" }}
              inputRef={currentPasswordInputRef}
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ width: "80%", mt: 4 }}>
            <TextField
              id="profile-password"
              label={t("hitlist.profile.passwordSettingsNewPassword")}
              helperText={t("hitlist.profile.passwordSettingsNewPasswordHelp")}
              variant="standard"
              type="password"
              sx={{ width: "50%" }}
              inputRef={passwordInputRef}
            />
            <TextField
              id="profile-password-confirm"
              label={t("hitlist.profile.passwordSettingsConfirmPassword")}
              variant="standard"
              type="password"
              sx={{ width: "50%" }}
              inputRef={confirmPasswordInputRef}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
            sx={{ mt: 4 }}
          >
            <LoadingButton
              loading={savingPassword}
              loadingPosition="start"
              startIcon={<SaveOutlinedIcon />}
              variant="contained"
              color="secondary"
              size="large"
              disabled={updatingProfile}
              onClick={() =>
                handleSaveNewPassword(
                  currentPasswordInputRef.current.value,
                  passwordInputRef.current.value,
                  confirmPasswordInputRef.current.value
                )
              }
              className="flat-button"
            >
              {!savingPassword
                ? t("hitlist.profile.passwordSettingsButton")
                : t("hitlist.profile.passwordSettingsButtonLoading")}
            </LoadingButton>
          </Stack>
        </>
      )}
      {Userfront.user.data.loginType !== APP_CONSTANTS.LOGIN_TYPE_EMAIL && (
        <>
          <Typography
            variant="body2"
            color="muted"
            sx={{ mt: 2, width: "80%" }}
          >
            {t("hitlist.profile.passwordSettingsNotAvailable")}
          </Typography>
        </>
      )}
    </Box>
  );
}

export default memo(ProfilePasswordSettings);
