import { memo } from "react";
import { Tooltip, useTheme } from "@mui/material";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import LightbulbCircleOutlinedIcon from "@mui/icons-material/LightbulbCircleOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ContentCutOutlinedIcon from "@mui/icons-material/ContentCutOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import AutoFixNormalOutlinedIcon from "@mui/icons-material/AutoFixNormalOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import { APP_CONSTANTS } from "../../Constants";

function HitlistTableType(props) {
  const { value } = props;

  const iconSize = 15;

  const theme = useTheme();

  switch (value) {
    case APP_CONSTANTS.ARTICLE_TYPE_QUESTION:
      return (
        <Tooltip
          title="Common Topic Questions"
          arrow
          enterDelay={300}
          enterNextDelay={600}
        >
          <ContactSupportOutlinedIcon
            color="info"
            sx={{ width: iconSize, height: iconSize }}
          />
        </Tooltip>
      );
    case APP_CONSTANTS.ARTICLE_TYPE_COMPETITOR_ARTICLE:
      return (
        <Tooltip
          title="Similar Site Analysis"
          arrow
          enterDelay={300}
          enterNextDelay={600}
        >
          <LanguageOutlinedIcon
            color="info"
            sx={{ width: iconSize, height: iconSize }}
          />
        </Tooltip>
      );
      case APP_CONSTANTS.ARTICLE_TYPE_RELATED_ARTICLE:
        return (
          <Tooltip
            title="Keyword Research Idea"
            arrow
            enterDelay={300}
            enterNextDelay={600}
          >
            <ModelTrainingOutlinedIcon
              color="info"
              sx={{ width: iconSize, height: iconSize }}
            />
          </Tooltip>
        );
    case APP_CONSTANTS.ARTICLE_TYPE_ENHANCED:
      return (
        <Tooltip
          title="AI Enhanced"
          arrow
          enterDelay={300}
          enterNextDelay={600}
        >
          <AutoFixNormalOutlinedIcon
            color="info"
            sx={{ width: iconSize, height: iconSize }}
          />
        </Tooltip>
      );
    case APP_CONSTANTS.ARTICLE_TYPE_SNIPPET:
      return (
        <Tooltip
          title="Snippet Opportunity"
          arrow
          enterDelay={300}
          enterNextDelay={600}
        >
          <ContentCutOutlinedIcon
            color="info"
            sx={{ width: iconSize, height: iconSize }}
          />
        </Tooltip>
      );
    case APP_CONSTANTS.ARTICLE_TYPE_REDDIT:
      return (
        <Tooltip
          title="Social Media Question"
          arrow
          enterDelay={300}
          enterNextDelay={600}
        >
          <ShareOutlinedIcon
            color="info"
            sx={{ width: iconSize, height: iconSize }}
          />
        </Tooltip>
      );
    case APP_CONSTANTS.ARTICLE_TYPE_REWRITE:
      return (
        <Tooltip
          title="Currently Ranking For"
          arrow
          enterDelay={300}
          enterNextDelay={600}
        >
          <DriveFileRenameOutlineOutlinedIcon
            color="info"
            sx={{ width: iconSize, height: iconSize }}
          />
        </Tooltip>
      );
    case APP_CONSTANTS.ARTICLE_TYPE_AI_IDEA:
      return (
        <Tooltip
          title="AI Generated Article Idea"
          arrow
          enterDelay={300}
          enterNextDelay={600}
        >
          <LightbulbCircleOutlinedIcon
            color="info"
            sx={{ width: iconSize, height: iconSize }}
          />
        </Tooltip>
      );
    default:
      return (
        <LanguageOutlinedIcon
          disabled={true}
          sx={{ width: iconSize, height: iconSize, color: theme.palette.muted }}
        />
      );
  }
}

export default memo(HitlistTableType);
