import axios from "axios";
import axiosRetry from "axios-retry";
import { CustomEvents, publish } from "../Hitlist/Events/CustomEvents";
import { STORAGE_KEYS, getValue } from "../Storage";
import { throwError } from "../ErrorHandler/ErrorHandler";
import StringUtils from "../Utils/StringUtils";

const apiKey = process.env.REACT_APP_HUGGING_FACE_KEY;
const minCredit = 1;

const api = axios.create({
  headers: {
    "Content-Type": `application/json`,
    Accept: `application/json`,
    Authorization: `Bearer ${apiKey}`,
  },
  baseURL: process.env.REACT_APP_SIMILARITY_API,
});

const retryCondition = (error) => {
  console.error("🚀 ~ file: SimilarityApi.js ~ error:", error);
  return (
    axiosRetry.isNetworkError(error) ||
    axiosRetry.isRetryableError(error) ||
    error.code === "ERR_BAD_RESPONSE" ||
    error.status === 502
  );
};

// Huggingface inferences wind down to 0 and turn off with no use, so if we retry too quickly, we'll never get a response
axiosRetry(api, {
  retries: 5,
  retryDelay: (retryCount) => {
    return retryCount * 7000;
  },
  retryCondition: retryCondition,
});

const getCredits = async () => {
  return getValue(STORAGE_KEYS.CREDITS) || null;
};

const logTokens = (response) => {
  console.log("🚀 ~ file: SimilarityApi.js ~ response:", response);

  const tokenFactor = parseInt(process.env.REACT_APP_SIMILARITY_MULTIPLE); // how much more expensive than the baseline of GPT3.5

  if (!response) return;
  if (!response.data) return;

  if (response.data.length > 0) {
    let tokensUsed = 100 * tokenFactor;

    console.log("RAW TOKENS: ", tokensUsed);

    publish(CustomEvents.TOKENS_USED, {
      date: new Date(),
      model: "all-MiniLM-L6-v2",
      tokens: {
        prompt_tokens: 0,
        completion_tokens: tokensUsed,
        total_tokens: tokensUsed,
      },
    });
  }
};

const SimilarityApi = {
  /**
   * Fetches similarity scores between a source sentence and an array of comparison sentences.
   *
   * This method first checks if there are sufficient credits available before making the similarity comparison.
   * It prepares a request payload, cleans it up, and then sends a request to the similarity scoring API.
   *
   * @param {AbortSignal} signal - The AbortSignal to cancel fetch requests. Must be provided.
   * @param {string} source - The source sentence to compare against.
   * @param {Array<string>} comparisons - An array of sentences to compare with the source.
   *
   * @returns {Promise<Object>} A promise that resolves with the response data from the similarity API.
   *
   * @throws Will reject the promise if:
   *  - No signal, source, or comparisons are provided.
   *  - There are insufficient credits.
   *  - There is an error with the API request.
   *
   * @example
   * const controller = new AbortController();
   * const sourceSentence = "Hello World";
   * const comparisonSentences = ["Hello Universe", "Greetings World"];
   *
   * getSimilarityScores(controller.signal, sourceSentence, comparisonSentences).then(scores => {
   *   console.log(scores); // Outputs the similarity scores data.
   * }).catch(error => {
   *   console.error("Error fetching similarity scores:", error);
   * });
   */
  getSimilarityScores: async (signal, source, comparisons) => {
    if (!signal) return Promise.reject("No signal provided");
    if (!source) return Promise.reject("No source provided");
    if (!comparisons) return Promise.reject("No comparisons provided");

    let availableCredits;
    try {
      availableCredits = await getCredits();
    } catch (error) {
      // If getCredits fails, we throw or return an error
      return Promise.reject("Failed to retrieve credits.");
    }

    if (availableCredits < minCredit) {
      return Promise.reject(
        `Insufficient credits. Your current balance is ${availableCredits}`,
        402
      );
    }

    let body = JSON.stringify({
      inputs: {
        source_sentence: source,
        sentences: comparisons,
      },
    });

    body = StringUtils.cleanJSONObject(body);

    try {
      const response = await api.post(``, body, {
        signal: signal,
      });

      logTokens(response);

      return response.data;
    } catch (error) {
      // Propagate the error for calling code to handle
      throw error;
    }
  },
  /**
   * Finds and returns rows from a Map that have titles similar to others based on a provided similarity threshold.
   *
   * This method compares each row with every other row to determine title similarities.
   * Rows found similar to another will have a property `similar` set to true.
   * The returned array will contain only rows where `similar` is true.
   *
   * @param {AbortSignal} signal - The AbortSignal to cancel fetch requests. This must be provided.
   * @param {Map} rows - The Map of rows to check. Each entry should have a value with a 'title' property.
   * @param {number} [threshold=0.75] - The similarity threshold, above which titles are considered similar. Range is between 0 and 1.
   *
   * @returns {Promise<Array>} An array of rows where titles found similar (based on the threshold) to another row.
   *
   * @throws Will throw an error if no signal is provided or if the SimilarityApi encounters an error.
   *
   * @example
   * const controller = new AbortController();
   * const rowsMap = new Map([
   *   [1, { title: 'Hello World' }],
   *   [2, { title: 'Hello Universe' }],
   *   [3, { title: 'Greetings World' }]
   * ]);
   * findSimilarRows(controller.signal, rowsMap, 0.9).then(similarRows => {
   *   console.log(similarRows); // Might return rows with titles found to be similar, depending on similarity scores.
   * });
   */
  findSimilarRows: async (signal, rows, threshold = 0.75) => {
    const rowsToCheck = new Map([...rows]);

    for (let [key, value] of rows) {
      // Create a new Map for comparisons excluding the current key
      const comparisonMap = new Map([...rows]);
      comparisonMap.delete(key);

      const comparisonTitles = [...comparisonMap.values()].map((c) => c.title);

      const response = await SimilarityApi.getSimilarityScores(
        signal,
        value.title,
        comparisonTitles
      );

      // Update rowsToCheck for rows that meet the similarity threshold
      response.similarities.forEach((similarity, index) => {
        if (similarity >= threshold) {
          const similarKey = [...comparisonMap.keys()][index];
          const currentRow = rowsToCheck.get(similarKey);
          rowsToCheck.set(similarKey, { ...currentRow, similar: true });
        }
      });
    }

    // Return only those with similar = true
    return [...rowsToCheck.values()].filter((row) => row.similar === true);
  },

  /**
   * Removes rows from an array (or Map) that have titles too similar to others, based on a provided similarity threshold.
   *
   * @param {AbortSignal} signal - The AbortSignal to cancel fetch requests. This must be provided.
   * @param {Array|Map} resultsArray - The array or Map of results to filter. Each result should have a 'title' property.
   * @param {number} [threshold=0.75] - The similarity threshold, above which titles are considered too similar. Range is between 0 and 1.
   *
   * @returns {Promise<Array>} A filtered array of results where similar titles (based on the threshold) have been removed.
   *
   * @throws Will throw an error if no signal is provided.
   *
   * @example
   * const controller = new AbortController();
   * const results = [
   *   { title: 'Hello World' },
   *   { title: 'Hello Universe' },
   *   { title: 'Greetings World' }
   * ];
   * removeSimilarRows(controller.signal, results, 0.9).then(filteredResults => {
   *   console.log(filteredResults); // Might return only one of the similar titles, depending on similarity scores.
   * });
   */
  removeSimilarRows: async (signal, resultsArray, threshold = 0.75) => {
    if (!signal) return Promise.reject("No signal provided");
    if (!resultsArray || threshold === 1) return resultsArray;

    // if resultsArray is a Map, convert it to an array
    if (resultsArray instanceof Map) {
      resultsArray = Array.from(resultsArray.values());
    }

    let titlesOnlyArray = resultsArray.map((result) => result.title);
    const toRemove = new Set(); // A set to keep track of titles to remove

    for (let i = 0; i < titlesOnlyArray.length; i++) {
      let title = titlesOnlyArray[i];

      // Skip if title is already marked for removal
      if (toRemove.has(title)) continue;

      try {
        const scores = await SimilarityApi.getSimilarityScores(
          signal,
          title,
          titlesOnlyArray
        );

        if (scores && scores.similarities) {
          // Mark titles for removal that are too similar
          scores.similarities.forEach((score, index) => {
            if (score >= threshold && score < 1) {
              toRemove.add(titlesOnlyArray[index]);
            }
          });
        }
      } catch (error) {
        console.log("🚀 ~ file: SimilarityApi.js ~ error:", error);
      }
    }

    // Filter out titles marked for removal
    return resultsArray.filter((result) => !toRemove.has(result.title));
  },
  isUnique: async (signal, title, titles, threshold = 0.75) => {
    if (!signal) return Promise.reject("No signal provided");
    if (!title || !titles || titles.length === 0) return true;

    try {
      const scores = await SimilarityApi.getSimilarityScores(
        signal,
        title,
        titles
      );

      // remove any similarity scores that are 1 or above
      if (scores && scores.similarities) {
        scores.similarities = scores.similarities.filter((score) => score < 1);
        return scores.similarities.every((score) => score < threshold);
      }

      return true;
    } catch (error) {
      // Handle or log the error accordingly
      console.error("Error fetching similarity scores:", error);
      return true;
    }
  },
  isSimilar: async (signal, title, titles, threshold = 0.75) => {
    if (!signal) return Promise.reject("No signal provided");
    if (!title || !titles || titles.length === 0) return false;

    try {
      const scores = await SimilarityApi.getSimilarityScores(
        signal,
        title,
        titles
      );

      if (scores && scores.similarities) {
        // Return true if any similarities are found
        return scores.similarities.some(
          (score) => score >= threshold && score < 1
        );
      }

      return false;
    } catch (error) {
      // Handle or log the error accordingly
      console.error("Error fetching similarity scores:", error);
      return false;
    }
  },
};

export default SimilarityApi;
