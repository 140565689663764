import { useState, useEffect } from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ControlPointDuplicateOutlinedIcon from "@mui/icons-material/ControlPointDuplicateOutlined";

function AnnouncementBar(props) {
  const { open, msg, button, handleClose, handleClick } = props;

  const [announcement, setAnnouncement] = useState("Test Announcement");
  const [buttonText, setButtonText] = useState("Test Button Text");

  const theme = useTheme();

  useEffect(() => {
    setAnnouncement(msg);

    return () => {
      setAnnouncement("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msg]);
    

  useEffect(() => {
    setButtonText(button);

    return () => {
      setButtonText("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [button]);

  useEffect(() => {
    setAnnouncement(msg);

    return () => {
      setAnnouncement("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {open && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: theme.palette.error.main,
            height: "auto",
            width: "100%",
            borderBottom: "1px solid theme.red[500]",
            textAlign: "center",
            py: 0.5,
          }}
        >
          <Typography variant="caption" sx={{ color: "#fff", mt: 0.25 }}>
            {announcement}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              color: "#fff",
              borderColor: "#fff",
              py: 0,
              ml: 1,
            }}
            onClick={handleClick}
            endIcon={
              <ControlPointDuplicateOutlinedIcon
                fontSize="small"
                sx={{ p: 0, ml: 0 }}
              />
            }
          >
            <Typography variant="caption" sx={{ mt: 0.25, fontSize: 11 }}>
              {buttonText}
            </Typography>
          </Button>
          <IconButton
            size="small"
            sx={{ color: "#fff", ml: 1, position: "absolute", right: 10 }}
            onClick={handleClose}
          >
            <CloseOutlinedIcon fontSize="9px" sx={{ p: 0, m: 0 }} />
          </IconButton>
        </Stack>
      )}
    </>
  );
}

export default AnnouncementBar;
