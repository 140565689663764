import { memo } from "react";
import { Tooltip } from "@mui/material";
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";

function HitlistTableActionPush(props) {
  const { handlePush, row, generating } = props;
  return (
    <GridActionsCellItem
      icon={
        <Tooltip title="Send to Final Hitlist" enterDelay={300} enterNextDelay={600}>
          <ReadMoreOutlinedIcon fontSize="medium" />
        </Tooltip>
      }
      label="Push"
      onClick={() => {
        handlePush(row);
      }}
    />
  );
}

export default memo(HitlistTableActionPush);
