import "./Guide.css";
import { memo, useState, useContext, useEffect, useRef } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AppUserContext } from "../../AppUserContext";
import { AppConfigContext } from "../../AppConfigContext";
import Robot from "./Robot/Robot";
import User from "./User/User";
import ChatField from "./ChatField/ChatField";
import Skeleton from "@mui/material/Skeleton";
import StringUtils from "../../Utils/StringUtils";
import { useTranslation } from "react-i18next";
import {
  resetTopicChatHistory,
  resetTopicSummaryHistory,
  topicChat,
} from "../../Bots/TopicBot";
import {
  subscribe,
  CustomEvents,
  unsubscribe,
  publish,
} from "../Events/CustomEvents";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import { STORAGE_KEYS, getValue } from "../../Storage";
import InfoBar from "./ChatField/InfoBar/InfoBar";

function Guide(props) {
  const { onChatRequest, onGuideClose, onTopicConfirm } = props;
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const userDetails = useContext(AppUserContext);
  const config = useContext(AppConfigContext);

  const theme = useTheme();

  const bottomRef = useRef(null);
  const topicRef = useRef(null);
  const apiControllerRef = useRef([]);
  let messageRef = useRef([]);

  const { t } = useTranslation();

  let userTimeoutId;
  let robotTimeoutId;
  let infoTimeoutId;

  /**
   * Function to add a information message to the chat
   * @param {String} msg The message to add to the chat
   * @param {Boolean} animate Whether or not to animate the message
   * @param {Boolean} delayNext The delay in milliseconds to wait before adding the message
   * @returns {Promise} A promise that resolves when the message is added
   */
  const addInfoMessage = (msg) => {
    let linkedMessage = msg;

    let newMessage = <InfoBar msg={linkedMessage} />;

    messageRef.current = (prevMessages) => [...prevMessages, newMessage];
    setMessages(messageRef.current);
  };

  /**
   * Function to add a robot message to the chat
   * @param {String} msg The message to add to the chat
   * @param {Boolean} animate Whether or not to animate the message
   * @param {Int} delayNext The delay in milliseconds to wait before adding the message
   * @returns {Promise} A promise that resolves when the message is added
   */
  const addRobotMessage = (msg, animate = true, delayNext = false) => {
    setLoading(delayNext);

    let linkedMessage = StringUtils.addLinksToUrls(
      StringUtils.capitalizeString(msg)
    );

    let newMessage = (
      <Robot
        name="HitlistAI"
        img="./img/guide.png"
        msg={linkedMessage}
        align="left"
        animate={animate}
      />
    );

    messageRef.current = (prevMessages) => [...prevMessages, newMessage];
    setMessages(messageRef.current);

    return new Promise((resolve) => {
      robotTimeoutId = setTimeout(() => {
        setLoading(false);
        resolve();
      }, parseInt(config.chat.replySpeed) * (delayNext ? config.chat.talkingSpeed * 2 : 1));
    });
  };

  /**
   * Function to add a user message to the chat
   * @param {String} msg The user's message to add to the chat
   * @param {Boolean} animate Whether or not to animate the message
   * @param {Int} delayNext The delay in milliseconds to wait before adding the message
   * @returns {Promise}
   */
  const addUserMessage = (msg, animate = false, delayNext = false) => {
    let linkedMessage = StringUtils.addLinksToUrls(msg);

    setLoading(true);

    linkedMessage =
      linkedMessage.indexOf("<a ") !== 0
        ? StringUtils.capitalizeString(linkedMessage)
        : linkedMessage;

    let newMessage = (
      <User
        name={userDetails.userNickName}
        img={userDetails.userImage}
        msg={linkedMessage}
        align="left"
        animate={animate}
      />
    );

    messageRef.current = (prevMessages) => [...prevMessages, newMessage];
    setMessages(messageRef.current);

    // return a promise to time the replies better
    return new Promise((resolve, reject) => {
      userTimeoutId = setTimeout(() => {
        resolve();
      }, parseInt(config.chat.replySpeed) * (delayNext ? config.chat.talkingSpeed * 2 : 1));
    });
  };

  /**
   * Function to action a topic change
   * @param {String} msg The topic to change to
   */
  const doTopicChange = async (msg = null, editing = false) => {
    topicRef.current = msg;
    onTopicConfirm(msg);
    setLoading(false);
  };

  /**
   * Function to edit the topic to be used for the hitlist
   * @param {String} niche The topic to edit
   */
  const chat = async (msg, reset = false) => {
    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      let response = await topicChat(controller.signal, msg, reset);

      switch (typeof response) {
        case "string":
          await addRobotMessage(response);
          break;
        case "object":
          await addRobotMessage(response.reply);

          addInfoMessage(
            `Added ${response.topic} to hitlist. Keywords and domains are now being generated.`
          );

          doTopicChange(response.topic, true);

          break;
        default:
          break;
      }
    } catch (e) {
      await addRobotMessage(getConfusedMessage());
    } finally {
      clearInterval(robotTimeoutId);
    }
  };

  /**
   * Function to handle receiving a new user message
   * @param {String} msg The user's message to add to the chat
   */
  const handleNewUserMessage = async (msg) => {
    await addUserMessage(msg);
    clearInterval(userTimeoutId);
    chat(msg);
  };

  /**
   * Function to handle a chat request event from a hints component
   * @param {Object} e Custom event object with a data node containing the type of step to take
   */
  const handleChatRequest = async (e, clearChatWindow = false) => {
    topicRef.current =
      getValue(STORAGE_KEYS.TOPIC) || e.detail.data.topic || topicRef.current;

    onChatRequest();

    switch (e.detail.data.type) {
      case "niche":
        let editTopicMessage = "";
        let infoMessage = "";

        if (!messageRef.current.length) {
          infoMessage = `${
            topicRef.current ? `Helping to refine` : `Helping to find`
          } topic ideas${
            topicRef.current ? ` related to ${topicRef.current}` : ``
          } for your hitlist. ${
            topicRef.current
              ? `If you want to change your topic, just tell me.`
              : ``
          }`;

          addInfoMessage(infoMessage);
        }

        editTopicMessage = `I don't like my topic and need help. ${
          topicRef.current ? `My current topic is ${topicRef.current}.` : ``
        }`;

        chat(editTopicMessage, messageRef.current.length === 0);
        clearTimeout(infoTimeoutId);
        // }
        break;
      case "domain":
        break;
      default:
        break;
    }
  };

  const handleGuideClose = (e) => {
    publish(CustomEvents.CHAT_CLOSED);
    onGuideClose();
  };

  const handleGuideClear = (e) => {
    messageRef.current = [];
    setLoading(false);
    setMessages(messageRef.current);
    apiControllerRef.current.forEach((controller) => controller.abort());
    resetTopicChatHistory();
    resetTopicSummaryHistory();
  };

  /**
   * Function to scroll to the bottom of the chat window when a new message is finished being typed
   */
  const handleChatMessageFinished = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onListeningChange = (listening) => {
    addInfoMessage(listening ? "Listening..." : "Stopped listening");
  };

  function getConfusedMessage() {
    let oopsMessage = t("hitlist.guide.forgot", { returnObjects: true });
    let errorMessage =
      oopsMessage[Math.floor(Math.random() * oopsMessage.length)];
    return errorMessage;
  }

  /**
   * Function to scroll to the bottom of the chat window when a new message is added
   */
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  /**
   * Function that runs when the component mounts and unmounts only once per instance of the component.
   * This is where we set up the event listeners and clean up after ourselves.
   **/
  useEffect(() => {
    // set up the abort controller register for api calls
    apiControllerRef.current = [];

    // set up the event listeners
    subscribe(CustomEvents.CHAT, handleChatRequest);
    subscribe(CustomEvents.CHAT_MESSAGE_FINISHED, handleChatMessageFinished);

    return () => {
      // abort all api calls
      apiControllerRef.current.forEach((controller) => controller.abort());

      // unsubscribe from all events
      unsubscribe(CustomEvents.CHAT, handleChatRequest);
      unsubscribe(
        CustomEvents.CHAT_MESSAGE_FINISHED,
        handleChatMessageFinished
      );

      // clear all timeouts
      clearTimeout(userTimeoutId);
      clearTimeout(robotTimeoutId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      id="guide-container"
      sx={{
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Stack id="guide-buttons" direction="row">
        <Button
          size="small"
          color="neutral"
          id="guide-clear-button"
          onClick={handleGuideClear}
        >
          <ClearAllOutlinedIcon />
          <Typography variant="body2" sx={{ ml: 0.25, mt: 0.25 }}>
            Clear
          </Typography>
        </Button>
        <Button
          size="small"
          color="neutral"
          id="guide-close-button"
          onClick={handleGuideClose}
        >
          <CloseOutlinedIcon />
          <Typography variant="body2" sx={{ ml: 0.25, mt: 0.25 }}>
            Close
          </Typography>
        </Button>
      </Stack>
      <Box
        id="guide-chat-window"
        sx={{
          pl: 2,
          pr: 4,
          pt: 6,
          pb: 3,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        {messages.map((msg, index) => (
          <div key={index}>{msg}</div>
        ))}
        <Skeleton
          variant="rounded"
          animation="wave"
          width={"100%"}
          height="70px"
          sx={{
            display: !loading ? "none" : "block",
          }}
        ></Skeleton>
        <div ref={bottomRef} />
      </Box>
      <ChatField
        onReply={handleNewUserMessage}
        onListeningChange={onListeningChange}
      />
    </Box>
  );
}

export default memo(Guide);
