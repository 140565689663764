import {
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  Button,
  LinearProgress,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { memo, useContext, useEffect, useState, useRef } from "react";
import { getExtraKeywords } from "../../../../Bots/KeywordBot";
import { STORAGE_KEYS, getValue } from "../../../../Storage";
import { AppConfigContext } from "../../../../AppConfigContext";

function HitlistTableMoreKeywords(props) {
  const [keyWordIdeas, setKeywordIdeas] = useState([]);
  const [loading, setLoading] = useState(false);

  const { onKeywordsUpdate, data } = props;

  const dataFetchedRef = useRef(false);
  const apiControllerRef = useRef([]);

  const config = useContext(AppConfigContext);

  const getKeyWordIdeas = async () => {
    setLoading(true);
    dataFetchedRef.current = true;

    try {
      const controller = new AbortController();
      apiControllerRef.current.push(controller);

      let ideas = await getExtraKeywords(
        controller.signal,
        data.row.title,
        keyWordIdeas,
        6,
        false,
        getValue(STORAGE_KEYS.TARGET_COUNTRY) || config.hitlist.generate.targetCountry
      );
      if (Array.isArray(ideas) && ideas.length) {
        updateKeywordIdeas(ideas);
      } else {
        setKeywordIdeas(["No ideas found"]);
      }
    } catch (error) {
      // publish(ToastEvents.ERROR, error.message);
      setKeywordIdeas(["No ideas found"]);
    } finally {
      setLoading(false);
      dataFetchedRef.current = false;
    }
  };

  const updateKeywordIdeas = (newIdeas) => {
    setKeywordIdeas(newIdeas);
    data.row.keywordIdeas = newIdeas;
  };

  useEffect(() => {
    if (!data.row.keywordIdeas || !data.row.keywordIdeas.length) {
      if (!dataFetchedRef.current) getKeyWordIdeas();
    } else {
      updateKeywordIdeas(data.row.keywordIdeas);
    }

    apiControllerRef.current = [];

    return () => {
      // abort all api calls
      apiControllerRef.current.forEach((controller) => controller.abort());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Grid container columns={12} alignItems="center" flex>
        <Grid item xs={6}>
          <Typography variant="subtitle2">{`Keyword Ideas:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Tooltip title="Refresh Keyword Ideas">
            <IconButton
              color="neutral"
              size="small"
              onClick={getKeyWordIdeas}
              sx={{ float: "right" }}
            >
              <RefreshOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
      <LinearProgress sx={{ display: loading ? "flex" : "none", width: 100, mt:3 }} />
      <List dense={true} sx={{ display: !loading ? "block" : "none" }}>
        {keyWordIdeas.map((idea, index) => (
          <ListItem alignItems="flex-start" sx={{ pl: 0 }} key={index}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              sx={{
                borderRadius: 3,
                py: 0,
                pl: 1,
                pr: 0,
                mr: 2,
                justifyContent: "center",
              }}
              onClick={() => {
                onKeywordsUpdate(data.row, idea);
              }}
            >
              <Typography fontSize="inherit" sx={{ mr: 0.25 }}>
                Add
              </Typography>
              <AddOutlinedIcon
                fontSize="small"
                color="secondary"
                sx={{ pr: 0.5 }}
              />
            </Button>
            <Typography variant="body2" sx={{fontSize: 12}}>{idea.label.toLowerCase()}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default memo(HitlistTableMoreKeywords);
