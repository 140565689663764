/* eslint-disable no-useless-escape */

/**
 * This file contains a collection of functions that are used to manipulate strings.
 */
const StringUtils = {
  // Function to capitalize the first letter of the first word only
  capitalizeFirstLetter: (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  },
  // Function to convert a camel case string to a string with spaces
  camelCaseToSpaces: (str) => {
    return str.replace(/([A-Z])/g, " $1").trim();
  },
  // Function to remove everything after a hyphen
  removeAfterHyphen: (str) => {
    return str.split("-")[0];
  },
  // Function to capitalize a string
  capitalizeString: (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  // Function to convert a string to title case
  titleCaseString: (str, addQuestionMark = false) => {
    if (typeof str !== "string") return "";
    str = str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

    if (addQuestionMark && str.slice(-1) !== "?") {
      str += "?";
    }

    return str;
  },
  // Function to convert a string to camel case
  camelCaseString: (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  },
  // Function to replace any years in a string with the current year if the current month is before October, else replace it with the next year
  replaceYear: (str) => {
    const currentYear = new Date().getFullYear();
    const regex = /\b(19\d\d|20\d\d)\b/g;

    const replacedString = str.replace(regex, (year) => {
      if (parseInt(year) < currentYear) {
        return currentYear.toString();
      } else {
        return year;
      }
    });

    return replacedString;
  },
  // Function to abbreviate a number to a string with a K, M, or B.
  abbreviateNumber: (num, decimals = 1) => {
    num = parseInt(num);
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(decimals) + "B";
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(decimals) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(decimals) + "K";
    } else if (isNaN(num)) {
      return "";
    } else {
      return num.toString();
    }
  },
  // Function to extract the url from a string
  extractURL: (str) => {
    if (!str || str.length === 0) return str;

    const urlRegex =
      /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|agency|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|coffee|c[acdfghiklmnoruvxyz])|(?:dev|d[ejkmovz])|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|plus|p[aefghklmnrstwy])|qa|(?:review|r[eouw])|(?:solutions|s[abcdeghijklmnortuvyz])|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

    const url = str.match(urlRegex);

    if (!url) return null;

    let extractedUrl = url[0];

    if (!/^https?:\/\//i.test(extractedUrl)) {
      // if no www. is found, add it to the beginning of the url
      if (!/^www\./i.test(extractedUrl)) {
        extractedUrl = `www.${extractedUrl}`;
      }

      extractedUrl = `https://${extractedUrl}`;
    }

    return extractedUrl;
  },
  stripProtocolFromUrl: (str) => {
    // strip www
    str = str.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    // strip protocol
    str = str.replace(/^(?:https?:\/\/)?/i, "");
    return str;
  },
  // Function to extract the title from a url
  extractTitleFromUrl: (str) => {
    // Remove protocol and www if present
    let cleanedUrl = str.replace(/^(https?:\/\/)?(www\.)?/i, "");

    // Remove trailing slash if present
    if (cleanedUrl.endsWith("/")) {
      cleanedUrl = cleanedUrl.slice(0, -1);
    }

    // Extract the second last part of the URL after the second last slash
    const parts = cleanedUrl.split("/");
    const lastPart = parts[parts.length - 1];
    const secondLastPart = parts[parts.length - 2];

    // Use the second last part if the last part is a number, else use the last part
    let partToUse = StringUtils.isNumericString(lastPart)
      ? secondLastPart
      : lastPart;

    partToUse = partToUse.replace(/\.(html|htm|php|aspx|asp|jsp|cgi|pl)$/i, ""); // Remove common file extensions

    // Replace underscores and dashes with spaces
    const spaceSeparated = partToUse.replace(/[_-]/g, " ");

    // Convert to title case
    const titleCase = spaceSeparated
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return titleCase;
  },
  isNumericString: (str) => {
    // Check if the string can be parsed as a number
    if (!isNaN(str)) {
      return true; // It's a number
    }

    // Check if the string is a valid number when leading/trailing whitespace is removed
    const trimmedStr = str.trim();
    if (!isNaN(trimmedStr)) {
      return true; // It's a number
    }

    return false; // It's not a number
  },
  replaceNonCurrentYears: (str) => {
    const currentYear = new Date().getFullYear();

    return str.replace(/\b\d{4}\b/g, (match) => {
      const year = Number(match);
      if (isNaN(year) || year !== currentYear) {
        return String(currentYear);
      }
      return match;
    });
  },
  // Function to extract the values from a string that has been formatted as [value1, value2, value3].
  extractArrayFromString: (str) => {
    if (typeof str !== "string") {
      return [];
    }

    // const regex = /^\s*\[(.*)\]\s*$/s;
    const regex = /\[([^\[\]]*)\]/;
    const match = str
      .match(regex)[1]
      .split(",")
      .map((item) => item.trim().replace(/['"]/g, ""));

    if (!match) {
      return [];
    }

    try {
      const jsonArray = Array.isArray(match) ? match : JSON.parse(match);

      // for each item in the array, check if it is a string, if so, remove the quotes
      jsonArray.forEach((item, index) => {
        if (typeof item === "string") {
          jsonArray[index] = item.replace(/['"]/g, "");
        }
      });

      return jsonArray;
    } catch (error) {
      return [];
    }
  },
  // Function to extract the JSON object from a string that has been formatted as {JSON object}.
  extractObjectFromString: (str) => {
    const jsonObjectRegex = /{([^{}]+)}/; // Regular expression to match the JSON object within curly braces

    const match = str.match(jsonObjectRegex); // Find the first match of the regex in the string

    if (match) {
      try {
        let jsonString = match[0].replace(/\n/g, "").replace(/\\/g, ""); // Remove newlines and backslashes from the string
        const jsonObject = JSON.parse(jsonString); // Parse the modified string into a JSON object
        return jsonObject; // Return the extracted JSON object
      } catch (error) {}
    }

    return null; // Return null if no JSON object is extracted
  },
  // Function to format a string into a string with link tags
  addLinksToUrls: (str) => {
    if (!str) return str;

    // eslint-disable-next-line no-useless-escape
    const urlRegex =
      /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|agency|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|coffee|c[acdfghiklmnoruvxyz])|d[ejkmovz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|plus|p[aefghklmnrstuwy])|qa|(?:review|r[eouw])|(?:solutions|s[abcdeghijklmnortuvyz])|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

    return str.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
  },
  // Function to format an array of strings into a string with commas and an "and" before the last item.
  makeStringWithAnd: (arr) => {
    const separator = ", "; // Set the separator to a comma followed by a space
    const lastSeparator = " and "; // Set the last separator to the word "and"
    const str =
      arr.slice(0, -1).join(separator) + lastSeparator + arr.slice(-1);

    return str;
  },
  cleanJSONObject: (json) => {
    const jsonString = JSON.stringify(json);
    const cleanedString = jsonString.replace(/,([\s\n\r\t]*[}\]])/g, "$1");
    const cleanedJson = JSON.parse(cleanedString);
    return cleanedJson;
  },
  cleanGPT3Response: (str) => {
    return str.replace(/"|'|\\n|\\t|\\r|\\f|\\b|\\'/g, "");
  },
  convertNewLines: (str) => {
    const splitString = str.split("\n").map((str) => `<p>${str}</p>`);
    const newString = splitString.join("");
    const splitString2 = newString.split("\r").map((str) => `<p>${str}</p>`);
    const newString2 = splitString2.join("");
    const splitString3 = newString2.split("\n\n").map((str) => `<p>${str}</p>`);
    const newString3 = splitString3.join("");
    return newString3;
  },
  removeQuotes: (str) => {
    return str.replace(/["]+/g, "");
  },
  removeBackSlashes: (str) => {
    return str.replace(/\\/g, "");
  },
  removeBackTicks: (str) => {
    return str.replace(/`/g, "");
  },
  replaceSpacesWithDashes: (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  },
  getLanguageCodeName: (code) => {
    switch (code) {
      case "en":
        return "English";
      case "es":
        return "Spanish";
      case "fr":
        return "French";
      case "de":
        return "German";
      case "it":
        return "Italian";
      case "pt":
        return "Portuguese";
      case "ja":
        return "Japanese";
      case "ko":
        return "Korean";
      case "zh":
        return "Chinese";
      case "ru":
        return "Russian";
      case "ar":
        return "Arabic";
      case "tr":
        return "Turkish";
      case "pl":
        return "Polish";
      case "nl":
        return "Dutch";
      case "sv":
        return "Swedish";
      case "id":
        return "Indonesian";
      case "th":
        return "Thai";
      case "da":
        return "Danish";
      case "no":
        return "Norwegian";
      case "fi":
        return "Finnish";
      case "cs":
        return "Czech";
      case "hu":
        return "Hungarian";
      case "vi":
        return "Vietnamese";
      case "uk":
        return "Ukrainian";
      case "ro":
        return "Romanian";
      case "he":
        return "Hebrew";
      case "hi":
        return "Hindi";
      case "bn":
        return "Bengali";
      case "ms":
        return "Malay";
      case "el":
        return "Greek";
      default:
        return "English";
    }
  },
  getCountryCodeName: (countryCode) => {
    switch (countryCode) {
      case "US":
        return "United States";
      case "GB":
        return "United Kingdom";
      case "AU":
        return "Australia";
      case "BR":
        return "Brazil";
      case "CA":
        return "Canada";
      case "DE":
        return "Germany";
      case "ES":
        return "Spain";
      case "FR":
        return "France";
      case "IE":
        return "Ireland";
      case "IN":
        return "India";
      case "IT":
        return "Italy";
      case "MX":
        return "Mexico";
      case "NL":
        return "Netherlands";
      case "SG":
        return "Singapore";
      default:
        return countryCode;
    }
  },
};

export default StringUtils;
