import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, ThemeProvider, Grid } from "@mui/material";
// import ProfileImageSettings from "./ProfileImageSettings";
import ProfileSettings from "./ProfileSettings";
import ProfilePasswordSettings from "./ProfilePasswordSettings";
import { CustomEvents, publish } from "../Hitlist/Events/CustomEvents";
import { ToastEvents } from "../Toast/Toast";
import SettingsMenu from "../Settings/SettingsMenu";
import Userfront from "@userfront/core";

function Profile(props) {
  const { theme, loggedIn, updatingProfile } = props;
  const [savingPassword, setSavingPassword] = useState(false);

  const navigate = useNavigate();

  // const handleImageUpload = () => {
  //   console.log("Upload image");
  // };

  /**
   * Function to handle saving profile changes. The function gathers the new profile data and sends and event that is listened for by the App component.
   * @param {String} firstName new first name
   * @param {String} lastName new last name
   * @param {String} newEmail new email
   */
  const handleSaveProfile = async (firstName, lastName, newEmail) => {
    if (!Userfront.tokens.accessToken || !Userfront.user.email) {
      publish(ToastEvents.ERROR, "Cannot update profile");
      return;
    }

    // send event to update profile up the chain to the App component
    publish(CustomEvents.USER_UPDATED, {
      firstName,
      lastName,
      email: newEmail,
    });
  };

  const handleSaveNewPassword = async (currentPassword, password, confirm) => {
    if (!currentPassword) {
      publish(ToastEvents.ERROR, "Please enter your current password");
      return;
    }

    if (!password) {
      publish(ToastEvents.ERROR, "Please enter a password");
      return;
    }

    if (password !== confirm) {
      publish(ToastEvents.ERROR, "Passwords do not match");
      return;
    }

    setSavingPassword(true);

    try {
      const response = await Userfront.updatePassword({
        method: "jwt",
        existingPassword: currentPassword,
        password: password,
        redirect: false,
      });

      if (response.message === "OK") {
        publish(ToastEvents.SUCCESS, "Password updated successfully");
      } else {
        publish(ToastEvents.ERROR, "Password update failed");
      }
    } catch (error) {
      publish(ToastEvents.ERROR, "Password update failed");
    } finally {
      setSavingPassword(false);
    }
  };

  useEffect(() => {
    if (!Userfront.tokens.accessToken) {
      navigate("/login");
      return;
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {loggedIn && (
        <>
          <Grid container columns={12} spacing={2} sx={{ px: 2, pt: 3 }}>
            <Grid item xs={3}>
              <SettingsMenu theme={theme} />
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  mb: 4,
                  pr: 2,
                  scrollBehavior: "smooth",
                  overflowY: "scroll",
                  height: "calc(100vh - 110px)",
                }}
              >
                {/* <ProfileImageSettings
                  theme={theme}
                  handleImageUpload={handleImageUpload}
                  updatingProfile={updatingProfile}
                  savingPassword={savingPassword}
                  heading="Profile Image"
                /> */}
                <ProfileSettings
                  theme={theme}
                  handleSaveProfile={handleSaveProfile}
                  updatingProfile={updatingProfile}
                  savingPassword={savingPassword}
                  heading="Profile Settings"
                />
                <ProfilePasswordSettings
                  theme={theme}
                  handleSaveNewPassword={handleSaveNewPassword}
                  updatingProfile={updatingProfile}
                  savingPassword={savingPassword}
                  heading="Password Settings"
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </ThemeProvider>
  );
}

export default memo(Profile);
