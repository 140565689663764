import { memo, useContext, useEffect, useState } from "react";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  ListItemText,
} from "@mui/material";
import { AppConfigContext } from "../../AppConfigContext";
import { STORAGE_KEYS, getValue, storeValue } from "../../Storage";
import StringUtils from "../../Utils/StringUtils";
import { CustomEvents, subscribe, unsubscribe } from "../Events/CustomEvents";

function HeadlineStylePicker(props) {
  const { xs, saveAllData, headlineStyle, generating, viewOnly } = props;

  const [hidden, setHidden] = useState(false);
  const [headlineStyleDisplay, setHeadlineStyleDisplay] = useState("");

  const config = useContext(AppConfigContext);

  const handleChatRequest = () => {
    setHidden(true);
  };

  const handleChatClosed = () => {
    setHidden(false);
  };

  const updateHeadlineStyle = (e) => {
    if (!e.target.value) return;

    setHeadlineStyleDisplay(e.target.value || headlineStyle || "");

    if (e.target.value !== headlineStyle) {
      storeValue(STORAGE_KEYS.HEADLINE_STYLE, e.target.value);
      saveAllData();
    }
  };

  useEffect(() => {
    let style =
      headlineStyle && headlineStyle.length
        ? headlineStyle
        : getValue(STORAGE_KEYS.HEADLINE_STYLE) ||
          config.hitlist.generate.headlineStyle;

    setHeadlineStyleDisplay(style);
    storeValue(STORAGE_KEYS.HEADLINE_STYLE, style);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headlineStyle]);

  useEffect(() => {
    subscribe(CustomEvents.CHAT, handleChatRequest);
    subscribe(CustomEvents.CHAT_CLOSED, handleChatClosed);

    if (!getValue(STORAGE_KEYS.HEADLINE_STYLE)) {
      storeValue(config.hitlist.generate.headlineStyle);
    }

    setHeadlineStyleDisplay(getValue(STORAGE_KEYS.HEADLINE_STYLE));
    
    return () => {
      unsubscribe(CustomEvents.CHAT, handleChatRequest);
      unsubscribe(CustomEvents.CHAT_CLOSED, handleChatClosed);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid xs={xs} item hidden={hidden}>
      <FormControl
        variant="standard"
        size="small"
        margin="none"
        sx={{ width: "100%" }}
      >
        <InputLabel id="settings-target-headline-select-label" shrink>
          Article Style
        </InputLabel>
        <Select
          autoWidth
          labelId="settings-target-headline-select-label"
          id="settings-target-headline-select"
          value={headlineStyleDisplay}
          label="headline-picker"
          onChange={updateHeadlineStyle}
          sx={{ height: 35 }}
          disabled={generating || viewOnly}
        >
          {config.settings.HEADLINE_STYLES.map((option) => (
            <MenuItem
              value={option.value}
              key={StringUtils.replaceSpacesWithDashes(
                option.value
              ).toLowerCase()}
            >
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

export default memo(HeadlineStylePicker);
