import "./View.css";
import { memo, useRef, useEffect, useState } from "react";
import { Grid, ThemeProvider } from "@mui/material";
import Guide from "../Hitlist/Guide/Guide";
import Assistant from "../Hitlist/Assistant/Assistant";

function View(props) {
  const {
    appInitialized,
    domains,
    generating,
    getHitlist,
    hitlistId,
    keywords,
    onDomainConfirm,
    onGenerateCancel,
    handleSaveSettings,
    onTopicConfirm,
    resetHitlist,
    headlineStyle,
    saveAllData,
    status,
    tableData,
    tableState,
    targetCountry,
    theme,
    topic,
  } = props;

  const [guideActive, setGuideActive] = useState();


  const guideActiveRef = useRef(false);
  const apiControllerRef = useRef([]);

  const handleError = (err) => {};

  const onChatRequest = (e) => {
    guideActiveRef.current = true;
    setGuideActive(guideActiveRef.current);
  };

  const onGuideClose = (e) => {
    guideActiveRef.current = false;
    setGuideActive(guideActiveRef.current);
  };

  useEffect(() => {
    // reset the abort controller
    let apiCalls = apiControllerRef.current;

    return () => {
      // abort all api calls
      apiCalls.forEach((controller) => controller.abort());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return the hitlist component
  return (
    <ThemeProvider theme={theme}>
      <Grid id="hitlist-container" container spacing={0} columns={24}>
        <Grid
          item
          id="guide"
          className={`${guideActive ? "active" : "inactive"}`}
          xs={9}
          sx={{
            backgroundColor: theme.palette.background.paper,
            position: "relative",
          }}
        >
          <Guide
            onChatRequest={onChatRequest}
            onGuideClose={onGuideClose}
            onTopicConfirm={onTopicConfirm}
            onDomainConfirm={onDomainConfirm}
            viewOnly={true}
          />
        </Grid>
        <Grid item className="active" xs={guideActive ? 15 : 24}>
          <Assistant
            appInitialized={appInitialized}
            domains={domains}
            generating={generating}
            getHitlist={getHitlist}
            handleError={handleError}
            handleSaveSettings={handleSaveSettings}
            headlineStyle={headlineStyle}
            hitlistId={hitlistId}
            keywords={keywords}
            onChatRequest={onChatRequest}
            resetHitlist={resetHitlist}
            saveAllData={saveAllData}
            status={status}
            tableData={tableData}
            tableState={tableState}
            targetCountry={targetCountry}
            topic={topic}
            onGenerateCancel={onGenerateCancel}
            viewOnly={true}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default memo(View);
