import { memo } from "react";
import {
  Box,
  ThemeProvider,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import Userfront from "@userfront/core";
import SettingsHeading from "../Settings/SettingsHeading";
import SettingsMenu from "../Settings/SettingsMenu";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import { useTranslation } from "react-i18next";

function SubscriptionSettings(props) {
  const { loggedIn, theme } = props;

  const userData = Userfront.user.data;

  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      {loggedIn && (
        <>
          <Grid
            container
            columns={12}
            spacing={2}
            sx={{
              px: 2,
              height: "calc(100vh - 50px)",
              pt: 3,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Grid item xs={3}>
              <SettingsMenu theme={theme} />
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  mb: 4,
                  pr: 2,
                  scrollBehavior: "smooth",
                  overflowY: "auto",
                  height: "calc(100vh - 110px)",
                }}
              >
                <Box
                  id="application-settings"
                  sx={{
                    backgroundColor: theme.palette.background.main,
                    border: `1px solid ${theme.palette.divider}`,
                    px: 2,
                    pt: 2.5,
                    pb: 3,
                    mb: 2,
                  }}
                >
                  <SettingsHeading
                    heading={t("hitlist.subscription.heading")}
                  />
                  <Stack direction="column">
                    <Stack direction="row">
                      <Typography
                        variant="body"
                        sx={{ fontWeight: 500, mr: 0.5 }}
                      >
                        {t("hitlist.subscription.planLabel")}:
                      </Typography>
                      <Typography variant="body">
                        {(userData.price && userData.price.nickname) ||
                          (userData.plan && userData.plan.nickname) ||
                          userData.planName ||
                          "Hitlist Subscription"}
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ mb: 1.4 }}>
                      <Typography
                        variant="body"
                        sx={{ fontWeight: 500, mr: 0.5 }}
                      >
                        {t("hitlist.subscription.renewsLabel")}:
                      </Typography>
                      <Typography variant="body">
                        {new Date(
                          parseInt(userData.renewalDate) * 1000
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </Typography>
                    </Stack>
                    <Typography variant="body">
                      {t("hitlist.subscription.message")}
                    </Typography>
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      endIcon={<OpenInNewOutlinedIcon />}
                      sx={{ width: 250, mt: 3 }}
                      onClick={() => {
                        window.open(
                          process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL +
                            `?prefilled_email=${Userfront.user.email}`,
                          "_blank"
                        );
                      }}
                    >
                      {t("hitlist.subscription.button")}
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </ThemeProvider>
  );
}

export default memo(SubscriptionSettings);
