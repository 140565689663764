import "./HintButtons.css";
import { memo } from "react";
import { Box, IconButton, Stack, Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SmsSharpIcon from "@mui/icons-material/SmsSharp";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CopyPaste from "../CopyPaste/CopyPaste";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import StringUtils from "../../../../Utils/StringUtils";

function HintButtons(props) {
  const {
    hints,
    loading,
    lock,
    type,
    more,
    disableMore,
    disableChat,
    disableRefresh,
    disableCopy,
    disableDelete,
    disableOpenAll,
    generating,
    handleMore,
    handleChat,
    handleRefresh,
    handleClear,
    handleOpenAll,
    classNames,
  } = props;

  return (
    <Box
      xs={1}
      sx={{ mt: 1, ml: "auto" }}
      className={`hint-buttons ${classNames !== undefined && classNames}`}
    >
      <Stack direction="row" justifyContent={"flex-end"} alignItems={"center"}>
        {!disableMore ? (
          <Tooltip
            title={
              more
                ? `${
                    hints && hints.length
                      ? `Get up to ${more} more`
                      : StringUtils.titleCaseString(`Research ${type}`)
                  }`
                : `More`
            }
          >
            <span>
              <IconButton
                className={`add-more-button ${
                  (hints.length === 0 && !loading) && "highlight"
                }`}
                onClick={handleMore}
                disabled={loading || disableMore || generating}
                size="small"
              >
                {hints.length === 0 && !loading && (
                  <AddCircleIcon sx={{ color: `#fff`, fontSize: 18 }} />
                )}
                {(hints.length > 0 || loading) && (
                  <AddCircleOutlineOutlinedIcon
                    fontSize="small"
                  />
                )}
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          ""
        )}

        {!disableChat ? (
          <Tooltip title="Chat">
            <span>
              <IconButton onClick={handleChat} disabled={loading || generating}>
                <SmsSharpIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          ""
        )}

        {!disableRefresh ? (
          <Tooltip title="Start again">
            <span>
              <IconButton
                onClick={handleRefresh}
                disabled={
                  loading ||
                  !hints ||
                  !Array.isArray(hints) ||
                  hints.length === 0 ||
                  generating
                }
                sx={{ display: lock ? "none" : "inline-flex" }}
              >
                <ReplaySharpIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          ""
        )}

        {!disableCopy ? (
          <CopyPaste
            hints={hints}
            loading={loading}
            lock={lock}
            delimiter=", "
            disabled={
              loading ||
              !hints ||
              !Array.isArray(hints) ||
              hints.length === 0 ||
              generating
            }
          />
        ) : (
          ""
        )}

        {!lock && !disableDelete ? (
          <Tooltip title="Delete All">
            <span>
              <IconButton
                onClick={handleClear}
                disabled={
                  loading ||
                  !hints ||
                  !Array.isArray(hints) ||
                  hints.length === 0 ||
                  generating
                }
                sx={{
                  display: Array.isArray(hints) ? "inline-flex" : "none",
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          ""
        )}

        {!lock && !disableOpenAll ? (
          <>
            <Divider
              sx={{ height: 28, mt: 0.75, ml: 1.5, mr: 1.5 }}
              flexItem
              orientation="vertical"
            />
            <Tooltip title="Open All Links">
              <span>
                <IconButton
                  onClick={handleOpenAll}
                  disabled={
                    loading ||
                    !hints ||
                    !Array.isArray(hints) ||
                    hints.length === 0 ||
                    generating
                  }
                  sx={{
                    display: Array.isArray(hints) ? "inline-flex" : "none",
                  }}
                >
                  <OpenInNewOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        ) : (
          ""
        )}
      </Stack>
    </Box>
  );
}

export default memo(HintButtons);
