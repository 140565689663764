import axios from "axios";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const HitlistAPI = {
  getNotifications: async (signal) => {
    if (!signal) return Promise.reject("No signal provided.");

    try {
      const response = await api.get(`/notifications`, {
        signal: signal,
      });

      console.log( "🚀 ~ file: HitlistAPI.js ~ getNotifications: ~ response:", response );

      switch (response.status) {
        case 200:
          return response.data;
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI.js ~ getNotifications: ~ err:", err);
      return Promise.reject(err.message);
    }
  },
  /**
   * Get the user's credentials
   * @param {AbortSignal} signal A signal to cancel the request
   * @param {String} email The user's email
   * @param {String} type The type of credentials to get
   * @returns {Object} An object containing the user's credentials
   */
  getCredentials: async (signal, email, type = "reset") => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!email) return Promise.reject("No email provided.");
    if (!type) return Promise.reject("No type provided.");

    try {
      const response = await api.get(`/credentials/${email}/${type}`, {
        signal: signal,
      });

      console.log(
        "🚀 ~ file: HitlistAPI ~ getCredentials: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          return response.data;
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI ~ getCredentials: ~ err:", err);
      return Promise.reject(err.message);
    }
  },

  /**
   * Get all of the user's hitlists
   * @param {AbortSignal} signal A signal to cancel the request
   * @param {String} userEmail The user's email
   * @returns {Object} An object containing the user's hitlists
   */
  getAllHitlists: async (signal, userEmail) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!userEmail) return Promise.reject("No userEmail provided.");

    try {
      const response = await api.get(`/hitlists/${userEmail}`, {
        signal: signal,
      });

      console.log(
        "🚀 ~ file: HitlistAPI ~ getAllHitlists: ~ response:",
        response
      );

      return response.data;
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI ~ getAllHitlists: ~ err", err);
      return Promise.reject("Unknown error.");
    }
  },

  getSingleHitlist: async (signal, hitlistId, userEmail) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!hitlistId) return Promise.reject("No id provided.");
    if (!userEmail) return Promise.reject("No email provided.");

    try {
      const response = await api.get(`/hitlists/${hitlistId}/${userEmail}`, {
        signal: signal,
      });

      console.log(
        "🚀 ~ file: HitlistAPI ~ getSingleHitlist: ~ response:",
        response
      );

      return response.data;
    } catch (err) {
      console.warn(
        "🚀 ~ file: HitlistAPI ~ getSingleHitlist: ~ err",
        err
      );
      return Promise.reject("Unknown error.");
    }
  },

  getSingleHitlistViewOnly: async (signal, hitlistId) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!hitlistId) return Promise.reject("No id provided.");

    try {
      const response = await api.get(`/share/${hitlistId}`, {
        signal: signal,
      });

      console.log(
        "🚀 ~ file: HitlistAPI ~ getSingleHitlistViewOnly: ~ response:",
        response
      );

      return response.data;
    } catch (err) {
      console.warn(
        "🚀 ~ file: HitlistAPI ~ getSingleHitlistViewOnly: ~ err",
        err
      );
      return Promise.reject("Unknown error.");
    }
  },

  updateHitlist: async (
    signal,
    hitlistId,
    userEmail,
    hitlistTitle,
    hitlistData,
    hitlistKeywords,
    hitlistDomains,
    hitlistTopic,
    hitlistDomain,
    targetCountry,
    tableState,
    headlineStyle,
    tabs,
  ) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!hitlistId) return Promise.reject("No hitlistId provided.");
    if (!userEmail) return Promise.reject("No email provided.");

    // TODO: Do some data validation here

    try {
      const response = await api.put(
        `/hitlists/${hitlistId}`,
        {
          userEmail: userEmail,
          hitlistTitle: hitlistTitle,
          tableData: JSON.stringify(hitlistData),
          keywords: JSON.stringify(hitlistKeywords),
          hitlistDomains: JSON.stringify(hitlistDomains),
          topic: hitlistTopic,
          domain: hitlistDomain,
          targetCountry: targetCountry,
          tableState: JSON.stringify(tableState),
          headlineStyle: headlineStyle,
          tabs: JSON.stringify(tabs),
        },
        { signal: signal }
      );

      console.log(
        "🚀 ~ file: HitlistAPI ~ updateHitlist: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          return response.data;
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI ~ updateHitlist: ~ err", err);
      return Promise.reject("Unknown error.");
    }
  },

  saveNewHitlist: async (
    signal,
    userEmail,
    hitlistTitle = "** New Hitlist **",
    hitlistData = [],
    hitlistKeywords = [],
    hitlistDomains = [],
    hitlistTopic = "",
    hitlistDomain = "",
    targetCountry = "",
    tableState = [],
    headlineStyle = "",
    tabs = [],
  ) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!userEmail) return Promise.reject("No email provided.");

    try {
      const response = await api.post(
        "/hitlists",
        {
          userEmail: userEmail,
          hitlistTitle: hitlistTitle,
          tableData: JSON.stringify(hitlistData),
          keywords: JSON.stringify(hitlistKeywords),
          hitlistDomains: JSON.stringify(hitlistDomains),
          topic: hitlistTopic,
          domain: hitlistDomain,
          targetCountry: targetCountry,
          tableState: JSON.stringify(tableState),
          headlineStyle: headlineStyle,
          tabs: JSON.stringify(tabs),
        },
        { signal: signal }
      );

      console.log(
        "🚀 ~ file: HitlistAPI ~ saveHitlist: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          return response.data;
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI ~ saveHitlist: ~ err", err);
      return Promise.reject(err.message);
    }
  },

  duplicateHitlist: async (signal, hitlistId, userEmail, newTitle) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!hitlistId) return Promise.reject("No id provided.");
    if (!userEmail) return Promise.reject("No email provided.");

    try {
      const response = await api.post(
        `/hitlists/copy/${hitlistId}`,
        {
          userEmail: userEmail,
          newTitle: newTitle,
        },
        { signal: signal }
      );

      console.log(
        "🚀 ~ file: HitlistAPI ~ duplicateHitlist: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          return response.data;
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn(
        "🚀 ~ file: HitlistAPI ~ duplicateHitlist: ~ err",
        err
      );
      return Promise.reject(err.message);
    }
  },

  deleteHitlist: async (signal, hitlistId, userEmail) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!hitlistId) return Promise.reject("No id provided.");
    if (!userEmail) return Promise.reject("No email provided.");

    try {
      const response = await api.delete(`/hitlists/${hitlistId}/${userEmail}`, {
        signal: signal,
      });

      console.log(
        "🚀 ~ file: HitlistAPI ~ deleteHitlist: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          return Promise.resolve(response.data);
        case 400:
          return Promise.reject({ detail: "Bad request." });
        case 500:
          return Promise.reject({ detail: "Server error." });
        default:
          return Promise.reject({ detail: "Unknown error." });
      }
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI ~ deleteHitlist: ~ err", err);
      return Promise.reject({ detail: "Unknown Error." });
    }
  },

  saveSettings: async (signal, authToken, email, settings) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!authToken) return Promise.reject("No auth token provided.");
    if (!email) return Promise.reject("No email provided.");
    if (!settings) return Promise.reject("No settings provided.");

    try {
      const response = await api.put(
        `/settings`,
        {
          authToken: authToken,
          email: email,
          settings: settings,
        },
        { signal: signal }
      );

      console.log(
        "🚀 ~ file: HitlistAPI ~ saveSettings: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          return response.data;
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI ~ saveSettings: ~ err", err);
      return Promise.reject(err.message);
    }
  },

  getSettings: async (signal, authToken, email) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!authToken) return Promise.reject("No auth token provided.");
    if (!email) return Promise.reject("No email provided.");

    try {
      const response = await api.get(`/settings/${authToken}/${email}`, {
        signal: signal,
      });

      console.log(
        "🚀 ~ file: HitlistAPI ~ getSettings: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          if (response.data) {
            return response.data;
          } else {
            return Promise.reject("No settings found.");
          }
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI ~ getSettings: ~ err", err);
      return Promise.reject(err.message);
    }
  },

  /**
   * Function to get the products from the database
   * @param {AbortSignal} signal The signal to cancel the request
   * @returns Object containing the products
   */
  getAllProducts: async (signal) => {
    if (!signal) return Promise.reject("No signal provided.");

    try {
      const response = await api.get(`/products`, {
        signal: signal,
      });

      console.log(
        "🚀 ~ file: HitlistAPI ~ getProducts: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          if (response.data) {
            return response.data;
          } else {
            return Promise.reject("No settings found.");
          }
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI ~ getProducts: ~ err", err);
      return Promise.reject(err.message);
    }
  },

  getBundleProducts: async (signal) => {
    if (!signal) return Promise.reject("No signal provided.");

    try {
      const response = await api.get(`/products/bundles`, {
        signal: signal,
      });

      console.log(
        "🚀 ~ file: HitlistAPI ~ getBundleProducts: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          if (response.data) {
            return response.data;
          } else {
            return Promise.reject("No settings found.");
          }
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI ~ getBundleProducts: ~ err", err);
      return Promise.reject(err.message);
    }
  },

  /**
   * Updates the number of credits used for the user
   * @param {AbortSignal} signal The abort signal to cancel the request
   * @param {String} authToken The auth token for the user
   * @param {String} email The email for the user
   * @param {Int} usedCredits The number of credits used
   * @returns {Object} The response object
   */
  updateUsedCredits: async (signal, authToken, email, usedCredits) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!authToken) return Promise.reject("No auth token provided.");
    if (!email) return Promise.reject("No email provided.");
    if (!usedCredits) return Promise.reject("No used credits provided.");

    try {
      const response = await api.put(
        `/credits/used`,
        {
          authToken: authToken,
          email: email,
          credits: usedCredits,
        },
        { signal: signal }
      );

      console.log(
        "🚀 ~ file: HitlistAPI ~ updateUsedCredits: ~ response:",
        response
      );

      if (response.status === 200) {
        switch (response.data.code) {
          case 400:
          case 500:
            return Promise.reject(response.data.message);
          default:
            break;
        }
        return response.data;
      } else {
        return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn(
        "🚀 ~ file: HitlistAPI ~ updateUsedCredits: ~ err",
        err
      );
      return Promise.reject(err.message);
    }
  },

  updateTotalCredits: async (signal, authToken, email, totalCredits) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!authToken) return Promise.reject("No auth token provided.");
    if (!email) return Promise.reject("No email provided.");
    if (!totalCredits) return Promise.reject("No total credits provided.");

    try {
      const response = await api.put(
        `/credits/total`,
        {
          authToken: authToken,
          email: email,
          credits: totalCredits,
        },
        { signal: signal }
      );

      console.log(
        "🚀 ~ file: HitlistAPI ~ updateTotalCredits: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          return response.data;
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn(
        "🚀 ~ file: HitlistAPI ~ updateTotalCredits: ~ err",
        err
      );
      return Promise.reject(err.message);
    }
  },

  updateCreditsExpiry: async (signal, authToken, email, expiryDate) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!authToken) return Promise.reject("No auth token provided.");
    if (!email) return Promise.reject("No email provided.");
    if (!expiryDate) return Promise.reject("No expiry date provided.");

    try {
      const response = await api.put(
        `/credits/expiry`,
        {
          authToken: authToken,
          email: email,
          expiryDate: expiryDate,
        },
        { signal: signal }
      );

      console.log(
        "🚀 ~ file: HitlistAPI ~ updateCreditsExpiry: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          return response.data;
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn(
        "🚀 ~ file: HitlistAPI ~ updateCreditsExpiry: ~ err",
        err
      );
      return Promise.reject(err.message);
    }
  },

  /**
   * Gets the number of credits for the user
   * @param {AbortSignal} signal An abort signal to cancel the request
   * @param {String} authToken The auth token for the user
   * @param {String} email The email for the user
   * @returns {Object} The response object
   */
  getCredits: async (signal, authToken, email) => {
    if (!signal) return Promise.reject("No signal provided.");
    if (!authToken) return Promise.reject("No auth token provided.");
    if (!email) return Promise.reject("No email provided.");

    try {
      const response = await api.get(`/credits/${authToken}/${email}`, {
        signal: signal,
      });

      console.log(
        "🚀 ~ file: HitlistAPI ~ getCredits: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          return response.data;
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.warn("🚀 ~ file: HitlistAPI ~ getCredits: ~ err", err);
      return Promise.reject(err.message);
    }
  },

  checkIfActive: async (url) => {
    if (!url) return Promise.reject("No url provided.");

    // if no protocol is provided, add the https protocol
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = `https://${url}`;
    }

    try {
      const response = await api.post(`/check`, {
        url: url,
      });

      console.log(
        "🚀 ~ file: HitlistAPI ~ checkIfActive: ~ response:",
        response
      );

      switch (response.status) {
        case 200:
          return response.data;
        case 400:
          return Promise.reject("Bad request.");
        case 500:
          return Promise.reject("Server error.");
        default:
          return Promise.reject("Unknown error.");
      }
    } catch (err) {
      console.error(
        "🚀 ~ file: HitlistAPI ~ checkIfUrlActive: ~ err:",
        err
      );
      return Promise.reject(err.message);
    }
  },
};
