import {
  IconButton,
  Grid,
  Tooltip,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { AppConfigContext } from "../../AppConfigContext";
import {
  CustomEvents,
  publish,
  subscribe,
  unsubscribe,
} from "../Events/CustomEvents";
import { getValue, storeValue, STORAGE_KEYS } from "../../Storage";
import { useTranslation } from "react-i18next";

const TopicField = (props) => {
  const { xs, topic, saveAllData, generating, viewOnly } = props;

  const [gridSize, setGridSize] = useState(xs);
  const [hidden, setHidden] = useState(false);

  const inputRef = useRef("");

  const config = useContext(AppConfigContext);

  const theme = useTheme();

  const { t } = useTranslation();

  const updateTopic = useCallback(
    async (topic = null, save = true) => {
      if (topic === null) return;
      if (typeof topic !== "string") return;

      let trimmedTopic = topic.trim();
        storeValue(STORAGE_KEYS.TOPIC, trimmedTopic);
        // setTopicField(trimmedTopic);
        inputRef.current.value = trimmedTopic;
        if (save) {
          saveAllData();
        }
    },
    [saveAllData]
  );

  const handleHistoryChanged = (e) => {
    updateTopic(e.detail.data.topic, false);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.key === "Enter") {
      e.preventDefault();
      updateTopic(inputRef.current.value);
    }
  };

  const handleBlur = (e) => {
    updateTopic(inputRef.current.value);
  };

  const handleChat = () => {
    let prompt = inputRef.current.value || getValue(STORAGE_KEYS.TOPIC);
    publish(CustomEvents.CHAT, {
      topic: prompt,
      type: config.hitlist.constants.STEP_NICHE,
    });
  };

  const handleChatRequest = (e) => {
    setHidden(e.detail.data.type === config.hitlist.constants.STEP_DOMAIN);
    setGridSize(
      e.detail.data.type === config.hitlist.constants.STEP_DOMAIN ? xs : 15.5
    );
  };

  const handleChatClosed = (e) => {
    setHidden(false);
    setGridSize(xs);
  };

  useEffect(() => {
    updateTopic(topic, false);
  }, [topic, updateTopic]);

  useEffect(() => {
    inputRef.current.value = getValue(STORAGE_KEYS.TOPIC);

    subscribe(CustomEvents.HISTORY_CHANGED, handleHistoryChanged);
    subscribe(CustomEvents.CHAT, handleChatRequest);
    subscribe(CustomEvents.CHAT_CLOSED, handleChatClosed);

    return () => {
      unsubscribe(CustomEvents.HISTORY_CHANGED, handleHistoryChanged);
      unsubscribe(CustomEvents.CHAT, handleChatRequest);
      unsubscribe(CustomEvents.CHAT_CLOSED, handleChatClosed);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid xs={gridSize} item hidden={hidden}>
      <FormControl variant="standard" sx={{ width: "100%" }}>
        <InputLabel htmlFor="topic-field" shrink>
          Niche or Topic
        </InputLabel>
        <Input
          id="topic-field"
          label={t("hitlist.topicField.label")}
          autoComplete="off"
          placeholder={t("hitlist.topicField.placeholder")}
          variant="standard"
          inputProps={{ "aria-label": "research topic" }}
          inputRef={inputRef}
          onKeyDown={handleKeyPress}
          onBlur={handleBlur}
          className="topic-field"
          disabled={generating || viewOnly}
          fullWidth
          endAdornment={
            <Tooltip
              title={t("hitlist.topicField.tooltip")}
              enterDelay={600}
              enterNextDelay={1200}
            >
              <IconButton
                onClick={handleChat}
                sx={{ color: (generating) ? theme.palette.muted : theme.palette.secondary.main }}
                aria-label={t("hitlist.topicField.tooltip")}
                size="small"
                disabled={generating || viewOnly}
              >
                <AutoAwesomeOutlinedIcon  />
              </IconButton>
            </Tooltip>
          }
        />
      </FormControl>
    </Grid>
  );
};

export default memo(TopicField);
