import { useCallback, memo, useRef } from "react";
import { TextField } from "@mui/material";

function GridCellEditMultiline(props) {
  const { params, sx, rows } = props;
  const { id, value, field, api } = params;

  const valueRef = useRef(value);

  const handleBlur = useCallback((e) => {
    api.setEditCellValue({
      id,
      field,
      value: valueRef.current.value,
    });
  }, [api, id, field]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      api.setEditCellValue({
        id,
        field,
        value: valueRef.current.value,
      });
    }
  }, [api, id, field]);

  return (
    <TextField
      autoComplete="off"
      autoFocus
      fullWidth
      multiline
      rows={rows || 2}
      variant="outlined"
      size="small"
      InputProps={{ sx: sx, inputRef: valueRef, onBlur: handleBlur, defaultValue: value, onKeyDown: handleKeyDown }}
      // onChange={(e) => handleChange(e)}
    />
  );
}

export default memo(GridCellEditMultiline);
