import { memo, useEffect } from "react";
import { useSnackbar } from "notistack";
import { subscribe, unsubscribe } from "../Hitlist/Events/CustomEvents";

const ToastEvents = {
  ERROR: "toastError",
  SUCCESS: "toastSuccess",
  INFO: "toastInfo",
};

const Toast = memo(function Toast() {
  const { enqueueSnackbar } = useSnackbar();

  const handleShowError = (e) => {
    enqueueSnackbar(e.detail.data, {
      variant: "error",
    });
  };

  const handleShowSuccess = (e) => {
    enqueueSnackbar(e.detail.data, {
      variant: "success",
    });
  };

  const handleShowInfo = (e) => {
    enqueueSnackbar(e.detail.data, {
      variant: "info",
    });
  };

  useEffect(() => {
    subscribe(ToastEvents.ERROR, handleShowError);
    subscribe(ToastEvents.SUCCESS, handleShowSuccess);
    subscribe(ToastEvents.INFO, handleShowInfo);

    return () => {
      unsubscribe(ToastEvents.ERROR, handleShowError);
      unsubscribe(ToastEvents.SUCCESS, handleShowSuccess);
      unsubscribe(ToastEvents.INFO, handleShowInfo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
});

export { Toast, ToastEvents };
