import { APP_CONSTANTS } from "../Hitlist/Constants";
import { CustomEvents, publish } from "../Hitlist/Events/CustomEvents";
import { GPT3 } from "../Apis/OpenAiApi";
import ArrayUtils from "../Utils/ArrayUtils";
import SpyFuAPI from "../Apis/SpyFuApi";
import StringUtils from "../Utils/StringUtils";
import { ToastEvents } from "../Toast/Toast";

const hitlistSystemInstruction = {
  role: "system",
  content:
    "You are an SEO researcher that communicates exclusively through JSON objects. Your responses will only include JSON object and will not include any additional text or sentences.",
};

let hitlistChatHistory = [hitlistSystemInstruction];

const resetChatHistory = () => {
  hitlistChatHistory = [hitlistSystemInstruction];
};

const generateHitlist = async (
  signal,
  researchKeywords,
  researchDomains,
  maxKeywordResults,
  maxDomainResults,
  targetCountry
) => {
  if (!signal) return [];
  if (!researchKeywords && !researchDomains) return [];

  let mergedData = [],
    keywordData = [],
    domainData = [],
    relatedArticleData = [],
    totalToCheck = 0,
    i = 0,
    l = researchKeywords.length;

  totalToCheck = researchKeywords.length + researchDomains.length;

  // loop through all the relatedTerms and call getTopicQuestions
  for (i = 0; i < l; i++) {
    let relatedTerm = researchKeywords[i];

    if (!relatedTerm.label) continue;

    publish(CustomEvents.GENERATE_HITLIST_UPDATE, {
      total: totalToCheck,
      current: i + 1,
      message: `Getting article ideas for ${relatedTerm.label}`,
    });

    let relatedQuestions = await getTopicQuestions(
      signal,
      relatedTerm.label,
      targetCountry,
      1,
      Math.ceil(maxKeywordResults / 2)
    );

    let relatedArticles = await getTopicArticles(
      signal,
      relatedTerm.label,
      targetCountry,
      1,
      Math.ceil(maxKeywordResults / 2)
    );

    if (relatedQuestions && Array.isArray(relatedQuestions))
      keywordData = [...keywordData, ...relatedQuestions];
    if (relatedArticles && Array.isArray(relatedArticles))
      keywordData = [...keywordData, ...relatedArticles];
  }

  l = researchDomains.length;

  // loop through researchDomains and call getDomainResults
  for (i = 0; i < l; i++) {
    let domain = researchDomains[i];

    if (!domain.label) continue;

    publish(CustomEvents.GENERATE_HITLIST_UPDATE, {
      total: totalToCheck,
      current: researchKeywords.length + i,
      message: `Analyzing ${domain.label}`,
    });

    // a random page number between 1 and 2 to get varied results
    let randomPage = Math.floor(Math.random() * 2) + 1;

    let domainResults = await getDomainResults(
      signal,
      domain.label,
      randomPage,
      maxDomainResults,
      targetCountry,
      domain.label === domain
    );

    if (domainResults) domainData = [...domainData, ...domainResults];
  }

  mergedData = [
    ...mergedData,
    ...keywordData,
    ...domainData,
    ...relatedArticleData,
  ];

  // filter out all root and support domains, leaving only article ideas
  let filteredData = mergedData.filter((item) => {
    if (item.link) {
      const url = new URL(item.link);
      if (url)
        return (
          url.pathname !== "/" &&
          !url.pathname.includes("/support") &&
          !url.pathname.includes("/about") &&
          !url.pathname.includes("/contact") &&
          !url.pathname.includes("/privacy") &&
          !url.pathname.includes("/terms")
        );
      return true;
    } else {
      return true;
    }
  });

  // remove duplicates from the mergedData array
  filteredData = ArrayUtils.removeDuplicates(filteredData, "link");
  filteredData = ArrayUtils.removeDuplicates(filteredData, "title");

  // remove "near me" and "near you" titles
  filteredData = filteredData.filter((item) => {
    if (item.title) {
      return (
        !item.title.toLowerCase().includes("near me") &&
        !item.title.toLowerCase().includes("near you")
      );
    } else {
      return true;
    }
  });

  // re-index the array so each id is unique
  filteredData = ArrayUtils.reIndex(filteredData, "id");

  return filteredData;
};

const getTopicClusterArticles = async (
  signal,
  title,
  targetCountry = "US",
  totalOptions = 5,
  resetHistory = true
) => {
  if (!signal) return [];
  if (!title) return [];

  if (resetHistory) resetChatHistory();

  let userPrompt = {
    role: "user",
    content: `Return the next ${totalOptions} logical article ideas you would write after writing the original article about ${title} if targeting the country represented by the country code of ${targetCountry}. Return the results in this exact format: {data: [title1, ..., title${totalOptions}]}: ${title}`,
  };

  hitlistChatHistory.push(userPrompt);

  try {
    let response = await GPT3.generateChat(signal, hitlistChatHistory, 0.7);
    console.log("🚀 ~ file: HitlistBot.js:174 ~ response:", response)

    if (response) {
      let responseArray = null;

      switch (response.choices[0].finish_reason) {
        case "stop":
          responseArray = StringUtils.extractArrayFromString(
            response.choices[0].message.content
          );
          break;
        case "function_call":
          console.log(JSON.parse(response.choices[0].message.function_call.arguments));
          responseArray =
            JSON.parse(response.choices[0].message.function_call.arguments)
              .titles ||
            JSON.parse(response.choices[0].message.function_call.arguments) ||
            [];
          break;
        default:
          console.log("No results found");
          break;
      }

      if (response.choices.length > 0) {
        hitlistChatHistory.push({
          role: "assistant",
          content: responseArray.toString(),
        });
      }

      return responseArray;
    } else {
      throw new Error("Response doesn't look valid: ", response);
    }
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
    return null;
  }
};

/**
 * A function that takes a title and returns a new title that is more descriptive and SEO-friendly using GPT
 * @param {String} title The current title as a string
 * @param {String} url A URL to check for the title of the page
 * @param {Int} totalOptions The number of options to return
 * @returns An array of titles that are more descriptive and SEO-friendly
 */
const reWriteTitle = async (
  signal,
  title,
  totalOptions = 5,
  resetHistory = false,
  targetLanguage = "en"
) => {
  if (!signal) return [];
  if (!title) return [];

  if (resetHistory) resetChatHistory();

  let userPrompt = {
    role: "user",
    content: `Rewrite this title in ${totalOptions} different ways to be more descriptive for the reader, SEO-friendly, and targeted at ${targetLanguage}. Remove any years from the new titles. Return the results as an object that contains an array of titles attached to a data node in this exact format: {data: [title1, title2, title 3]}: ${title}`,
  };

  hitlistChatHistory.push(userPrompt);

  try {
    let response = await GPT3.generateChat(signal, hitlistChatHistory);

    if (response) {
      if (response.choices.length > 0) {
        hitlistChatHistory.push({
          role: "assistant",
          content: response.choices[0].message.content,
        });

        let responseObject = StringUtils.extractObjectFromString(
          response.choices[0].message.content
        );

        return responseObject.data;
      } else {
        throw new Error("No title found in response: ", response);
      }
    } else {
      throw new Error("Response doesn't look valid: ", response);
    }
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
    return null;
  }
};

const enhanceSingleResult = async (
  signal,
  row,
  topic,
  headlineStyle = "SEO headline",
  getRelated = true,
  resetHistory = true,
  targetCountry = "US",
  targetLanguage = "en"
) => {
  if (!signal) return null;
  if (resetHistory) resetChatHistory();

  const languageName = StringUtils.getLanguageCodeName(targetLanguage);
  const countryName = StringUtils.getCountryCodeName(targetCountry);

  // getRelated means it is auto-enhancing the initial result after generating a hitlist the first time
  const enhanceSystemPrompt = {
    role: "system",
    content: getRelated
      ? row.type === APP_CONSTANTS.ARTICLE_TYPE_QUESTION
        ? `You are an AI that finds the next question a reader would want to read in ${languageName} about after the question provided by the user. Your response will be a single question written as a ${headlineStyle} targeting ${countryName} along with two new short-tail SEO keywords that include the topic and are related to the new question.`
        : `You are an AI that finds the next article a reader would want to read in ${languageName} after the article given by the user. Your response will be a single suggested article in ${languageName} written as a ${headlineStyle} targeting ${countryName}, along with two new short-tail SEO keywords that include the topic and are related to the new article.`
      : `You are an AI that rewrites a title to be more SEO-friendly in ${languageName} as a ${headlineStyle} targeting ${countryName}, along with two short-tail SEO keywords in ${languageName} that include the topic and are related to the new title.`,
  };

  let chatHistory = [enhanceSystemPrompt];

  const returnResultSchema = [
    {
      name: "process_single_result",
      description: `A function to process results from GPT3 for a single result`,
      parameters: {
        type: "object",
        properties: {
          content: {
            type: "array",
            description: `An array of objects containing a title and keywords`,
            items: {
              type: "object",
              properties: {
                title: {
                  type: "string",
                  description: `An article title in ${languageName}`,
                },
                keywords: {
                  type: "array",
                  description: `An array of SEO keyword phrase objects for the title in ${languageName}`,
                  items: {
                    type: "object",
                    description: `A two words or more SEO keyword phrase in ${languageName}`,
                    properties: {
                      label: {
                        type: "string",
                        description: `A two words or more SEO keyword phrase in ${languageName}`,
                      },
                    },
                  },
                },
              },
            },
          },
        },
        required: ["title", "keywords"],
      },
    },
  ];

  let userPrompt = {
    role: "user",
    content: `[Topic] ${topic}. [Title] ${row.title}.`,
  };

  chatHistory.push(userPrompt);

  try {
    let response = await GPT3.generateChat(
      signal,
      chatHistory,
      getRelated ? 1.3 : 0.9,
      500,
      returnResultSchema
    );

    if (response) {
      if (response.choices[0].finish_reason === "function_call") {
        let parsedResult = JSON.parse(
          response.choices[0].message.function_call.arguments
        );
        return parsedResult.content;
      } else if (response.choices[0].finish_reason === "stop") {
        return { title: response.choices[0].message.content, keywords: [] };
      }
    } else {
      return { title: row.title, keywords: [] };
    }
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
  }
};

const getDomainResults = async (
  signal,
  domain,
  pageNumber = 1,
  totalResults = 10,
  targetCountry = "US",
  original = false
) => {
  try {
    let data = await SpyFuAPI.getMostValuableKeywords(
      signal,
      domain,
      pageNumber,
      totalResults,
      targetCountry
    );

    if (data && data.resultCount > 0) {
      let filteredData = data.results;

      filteredData = ArrayUtils.matchAndCombine(
        data.results,
        "topRankedUrl",
        "keyword"
      );

      let formattedData = filteredData.map((item, index) => {
        let extractedTitle = StringUtils.extractTitleFromUrl(item.topRankedUrl);

        let articleType = APP_CONSTANTS.ARTICLE_TYPE_COMPETITOR_ARTICLE;

        // assign a type based off the domain url
        if (domain.indexOf("reddit.com/") > -1) {
          articleType = APP_CONSTANTS.ARTICLE_TYPE_REDDIT;
        } else if (
          domain.indexOf("twitter.com/") > -1 ||
          domain.indexOf("facebook.com/") > -1 ||
          domain.indexOf("instagram.com/") > -1 ||
          domain.indexOf("tiktok.com/") > -1
        ) {
          articleType = APP_CONSTANTS.ARTICLE_TYPE_SOCIAL;
        } else if (extractedTitle.includes("#:~:text=")) {
          articleType = APP_CONSTANTS.ARTICLE_TYPE_SNIPPET;
        }

        const formattedItem = {
          id: index + 1,
          type: articleType,
          countryCode: targetCountry,
          source: domain,
          link: StringUtils.extractURL(item.topRankedUrl),
          title: extractedTitle,
          keywords: [],
          volume: item.searchVolume || 0,
          opportunity: item.keywordDifficulty || 0,
        };

        return formattedItem;
      });

      // filter out any titles that are too short (usually means they are categories, not articles)
      formattedData = formattedData.filter((item) => item.title.length > 10);

      // replace the words "near me" and "near you" from titles
      formattedData = formattedData.map((item) => {
        item.title = item.title.replace(" near me", "");
        item.title = item.title.replace(" near you", "");
        return item;
      });

      // // remove the .asp from any titles that have .asp
      // formattedData = formattedData.map((item) => {
      //   if (item.title.toLowerCase().includes(".asp")) {
      //     item.title = item.title.replace(".asp", "");
      //   }
      //   return item;
      // });

      // // remove the .asp from any titles that have .php
      // formattedData = formattedData.map((item) => {
      //   if (item.title.toLowerCase().includes(".php")) {
      //     item.title = item.title.replace(".php", "");
      //   }
      //   return item;
      // });

      // // remove any items what have titles with no spaces in them
      // formattedData = formattedData.filter((item) => item.title.includes(" "));

      return formattedData;
    } else {
      throw new Error(`No ranking results found for ${domain}.`);
    }
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
    return null;
  }
};

const getTopicQuestions = async (
  signal,
  topic,
  targetCountry = "US",
  pageNumber = 1,
  totalResults = 10
) => {
  try {
    let data = await SpyFuAPI.getQuestionKeywords(
      signal,
      topic,
      pageNumber,
      totalResults,
      targetCountry
    );

    if (data && data.resultCount > 0) {
      let uniqueData = ArrayUtils.uniqByKeepFirst(
        data.results,
        (item) => item.keyword
      );

      let formattedData = uniqueData.map((item, index) => {
        let extractedTitle = StringUtils.titleCaseString(item.keyword, true);
        extractedTitle = StringUtils.replaceNonCurrentYears(extractedTitle);

        // TODO: Go and get keywords from an API for the keywords

        return {
          id: index + 1,
          countryCode: targetCountry,
          type: !extractedTitle.includes("#:~:text=")
            ? APP_CONSTANTS.ARTICLE_TYPE_QUESTION
            : APP_CONSTANTS.ARTICLE_TYPE_SNIPPET,
          link: "", // TODO: Figure out how to go and get a link for this question
          keywords: [],
          title: StringUtils.titleCaseString(item.keyword, true),
          opportunity: item.rankingDifficulty || 0,
          ranked: item.rank || "",
          volume: item.searchVolume || "",
          difficulty: item.rankingDifficulty || 0,
          status: "Select a Status",
          assigned: "Assign",
          data: item,
        };
      });

      return formattedData;
    } else {
      // TODO: If no results are found, try to get the results from GPT4
      throw new Error(`No questions found for ${topic}.`);
    }
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
    return null;
  }
};

const getTopicArticles = async (
  signal,
  topic,
  targetCountry = "US",
  pageNumber = 1,
  totalResults = 10
) => {
  try {
    let data = await SpyFuAPI.getTransactionKeywords(
      signal,
      topic,
      pageNumber,
      totalResults,
      targetCountry
    );

    if (data && data.resultCount > 0) {
      let uniqueData = ArrayUtils.uniqByKeepFirst(
        data.results,
        (item) => item.keyword
      );

      let formattedData = uniqueData.map((item, index) => {
        let extractedTitle = StringUtils.titleCaseString(item.keyword, true);
        extractedTitle = StringUtils.replaceNonCurrentYears(extractedTitle);

        // TODO: Go and get keywords from an API for the keywords

        return {
          id: index + 1,
          countryCode: targetCountry,
          type: !extractedTitle.includes("#:~:text=")
            ? APP_CONSTANTS.ARTICLE_TYPE_RELATED_ARTICLE
            : APP_CONSTANTS.ARTICLE_TYPE_SNIPPET,
          link: "", // TODO: Figure out how to go and get a link for this question
          keywords: [],
          title: StringUtils.titleCaseString(item.keyword, true),
          opportunity: item.rankingDifficulty || 0,
          ranked: item.rank || "",
          volume: item.searchVolume || "",
          difficulty: item.rankingDifficulty || 0,
          status: "Select a Status",
          assigned: "Assign",
          data: item,
        };
      });

      return formattedData;
    } else {
      // TODO: If no results are found, try to get the results from OpenAI
      throw new Error(`No questions found for ${topic}.`);
    }
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
    return null;
  }
};

export {
  enhanceSingleResult,
  generateHitlist,
  getTopicClusterArticles,
  reWriteTitle,
};
