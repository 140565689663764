import { memo } from "react";

import { Typography } from "@mui/material";
function HitlistTableRowNumber(props) {
  const { row, loading } = props;
  return (
    <Typography variant="caption" sx={{ mt: 0.5 }}>
      {!loading && row && row.id && row.id + 1}
      {loading && ``}
    </Typography>
  );
}

export default memo(HitlistTableRowNumber);
