import { memo } from "react";
import MessageBar from "../ChatField/MessageBar/MessageBar";

function Robot(props) {
  const { img, name, msg, align, animate } = props;
  return (
    <MessageBar
      img={img}
      name={name}
      msg={msg}
      align={align}
      animate={animate}
      type="robot"
    />
  );
}

export default memo(Robot);