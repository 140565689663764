import { memo } from "react";
import { Grid, Typography, Button, Stack } from "@mui/material";
import MobileOffOutlinedIcon from "@mui/icons-material/MobileOffOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { useTranslation } from "react-i18next";

function Mobile() {
  const { t } = useTranslation();
  return (
    <Grid rows={12} container className="mobile-message">
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Stack direct="columns">
          <MobileOffOutlinedIcon sx={{ fontSize: 100, mb: 3, mx: "auto" }} />
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 4 }}>
            <Typography variant="h6" align="center">
              {t("hitlist.mobile.heading")}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 2 }}>
            <ComputerOutlinedIcon fontSize="large" sx={{ mr: 1 }} />
            <Typography variant="body2" align="left">
              {t("hitlist.mobile.message1")}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 5 }}>
            <LiveHelpOutlinedIcon fontSize="large" sx={{ mr: 1 }} />
            <Typography variant="body2" align="left">
              {t("hitlist.mobile.message2")}
            </Typography>
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            href="https://hitlist.ai/docs"
            size="large"
            fullWidth
            endIcon={<EastOutlinedIcon />}
          >
            {t("hitlist.mobile.button")}
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
}

export default memo(Mobile);
