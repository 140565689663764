import { memo } from "react";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import StringUtils from "../Utils/StringUtils";

function SettingsDropdown(props) {
  const { label, value, onChange, data, help, sx } = props;

  const dropdownId = label
    ? `settings-target-${StringUtils.replaceSpacesWithDashes(label)}`
    : "settings-dropdown";
    
  const labelId = `${dropdownId}-label`;

  return (
    <FormControl variant="standard" sx={sx}>
      <InputLabel id={labelId} sx={{fontWeight: 500}}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={dropdownId}
        value={value}
        onChange={onChange}
      >
        {data &&
          data.length > 0 &&
          data.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
      <Typography variant="caption" color="caption" sx={{ mt: 0.5, mb: 1 }}>
        {help}
      </Typography>
    </FormControl>
  );
}

export default memo(SettingsDropdown);
