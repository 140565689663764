import {
  Box,
  Fade,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";
import { memo } from "react";

function ErrorMessage(props) {
  const theme = useTheme();

  return (
    <Fade in={props.msg.length > 0}>
      <Box
        className="errorMessage"
        sx={{
          display: props.msg.length ? "block" : "none",
          px: 1.5,
          py: 0.25,
          color: "warning",
          backgroundColor: theme.palette.background.error,
          alignItems: "center",
          width: "100%",
          position: "relative",
        }}
      >
        <Typography
          color="error"
          variant="body2"
          sx={{ mr: 2, fontWeight: 500, fontSize: "0.875em" }}
        >
          {props.msg}
        </Typography>
        <Stack
          direction="row"
          sx={{ position: "absolute", right: "0", top: "-2px" }}
        >
          <IconButton size="small" onClick={props.handleRefresh}>
            <ReplaySharpIcon
              fontSize="inherit"
              sx={{ color: theme.palette.error.main }}
            />
          </IconButton>
          <IconButton size="small" onClick={props.handleErrorClose}>
            <CloseSharpIcon
              fontSize="inherit"
              sx={{ color: theme.palette.error.main }}
            />
          </IconButton>
        </Stack>
      </Box>
    </Fade>
  );
}

export default memo(ErrorMessage);