import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/browser";
import { initReactI18next } from "react-i18next";
import packageJson from "../package.json";
import { LicenseInfo } from "@mui/x-license-pro";

import en from "./translations/en.json";
import fr from "./translations/fr.json";

import i18n from "i18next";

import MobileRedirect from "./MobileRedirect";
import ErrorPage from "./ErrorPage/ErrorPage";

import Userfront from "@userfront/core";

import ReactGA from "react-ga4";

import "./fonts.css";
import "./index.css";

// Turn off console logging in production mode
if (process.env.REACT_APP_PRODUCTION === "true") {
  window.console = {};
  window.console.log = function () {};
  window.console.warn = function () {};
  window.console.error = function () {};
  window.console.trace = function () {};
}

// initialize Userfront
Userfront.init(process.env.REACT_APP_USERFRONT_ID);

// set license info for MUI data grid Premium
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PREMIUM_LICENSE_KEY);

// initialize Google Analytics
try {
  ReactGA.initialize(process.env.REACT_APP_GA4_ID);
} catch (error) {}

// initialize Sentry
try {
  Sentry.init({
    dsn: "https://5fa8aebcf23744a38b6b127cd60f6a19@app.glitchtip.com/3683",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: packageJson.version,
    autoSessionTracking: false,
  });
} catch (error) {}

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

const logError = (error, componentStack) => {
  console.log("🚀 ~ file: index.js:56 ~ logError ~ error:", error);
  console.log("Error stack: ", componentStack);
};

const resetApp = (details) => {
  console.log(details);
  // Brute force the removal of all cookies as that is the common reason for errors
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
  // send the window to /login
  window.location.href = "/login";
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary
      onError={logError}
      FallbackComponent={ErrorPage}
      resetApp={resetApp}
    >
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <BrowserRouter>
          <MobileRedirect />
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
