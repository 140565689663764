import { memo } from "react";
import { Typography, Divider } from "@mui/material";

function SettingsHeading(props) {
  const { heading } = props;
  return (
    <>
      <Typography variant="h6">{heading}</Typography>
      <Divider sx={{ mt: 2, mb: 3 }} />
    </>
  );
}

export default memo(SettingsHeading);
