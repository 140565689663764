import { memo } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";

function HitlistTableActionMoreKeywords(props) {
  const { row, handleGetKeywords, loading } = props;

  return (
    <GridActionsCellItem
      disabled={loading !== false || row.id === null || row.id === undefined}
      icon={<KeyOutlinedIcon fontSize="small" />}
      label={`Add More Keywords`}
      title="Add more keywords for selected article ideas"
      onClick={() => handleGetKeywords(row)}
      showInMenu
      sx={{ fontSize: 15 }}
    />
  );
}

export default memo(HitlistTableActionMoreKeywords);
