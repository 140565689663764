import { memo } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

HitlistTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function HitlistTabPanel(props) {
  const { children, value, index, isFullScreen, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`hitlist-tabpanel-${index}`}
      aria-labelledby={`hitlist-tab-${index}`}
      className={(isFullScreen ? "full-screen" : "") + " hitlist-panel"}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: "-1px" }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

HitlistTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default HitlistTabPanel;
