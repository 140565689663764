import { HitlistAPI } from "../Apis/HitlistAPI";
import { CustomEvents, publish } from "../Hitlist/Events/CustomEvents";
import Userfront from "@userfront/core";
import { STORAGE_KEYS, storeValue } from "../Storage";

const throwError = async (message, status = 400) => {
  let error = new Error(message);
  error.status = status;

  console.log("🚀 ~ file: ErrorHandler.js:8 ~ throwError ~ status:", status);

  const controller = new AbortController();

  if (status === 402) {
    // double check the credits before throwing the error
    let credits = await HitlistAPI.getCredits(
      controller.signal,
      Userfront.tokens.accessToken,
      Userfront.user.email
    );

    console.log(
      "🚀 ~ file: ErrorHandler.js:21 ~ throwError ~ credits:",
      credits
    );

    let creditsRemaining = Math.max(
      0,
      parseFloat(credits.credits_total) - parseFloat(credits.credits_used) + parseFloat(credits.pack_credits)
    );

    console.log(
      "🚀 ~ file: ErrorHandler.js:27 ~ throwError ~ creditsRemaining:",
      creditsRemaining
    );

    storeValue(STORAGE_KEYS.CREDITS, creditsRemaining);

    if (creditsRemaining < 1) {
      publish(CustomEvents.BUY_CREDITS);
      // throw error;
    }
  } else {
    throw error;
  }
};

export { throwError };
