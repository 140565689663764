import { Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
function HitlistTableActionDivider(props) {
  const { label } = props;
  const theme = useTheme();
  return (
    <Divider
      sx={{ color: theme.palette.muted, fontSize: 11, mx: 2, pt: 1 }}
    >
      {label}
    </Divider>
  );
}

export default HitlistTableActionDivider;
